import React from "react";
import { Link } from "react-router-dom";
import { Footer } from "../components/includes/Footer";
import { Header } from "../components/includes/Header";

const Home = () => {
  return (
    <>
      <div>
        <Header />
        {/* MAin Navigation END    */}
        {/* Banner Slider Start    */}
        <div className="carousel slide">
          <div className="col-lg-8 col-sm-12 m-auto text-center">
            <div className="slider-content  ">
              <h1>Agile-Sync IoT Platform</h1>
              <h4>
                Agile Sync is a user-friendly IoT platform , specially crafted
                to seamlessly integrate IEL-300 Energy logger devices. This
                platform empowers you to enhance energy efficiency, track
                performance, and promote sustainability while offering instant
                data insights, remote monitoring features, and customizable
                controls within a unified IoT portal
              </h4>
            </div>
          </div>
        </div>

        {/* Banner Slider End    */}
        {/* Top Features STYLES  */}

        <section id="top-features">
          <div className="container">
            <div className="top-feature-bg">
              <div className="row">
                <div
                  className="col-lg-12 col-sm-12"
                  style={{
                    "text-align": "center",
                  }}
                >
                  <div data-aos="fade-up">
                    <img
                      src="assets/images/home2.png"
                      alt="img"
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="carousel slide">
          <div className="col-lg-8 col-sm-12 m-auto text-center">
            <div className="slider-content  "></div>
          </div>
        </div>

        <div className="carousel slide">
          <div className="col-lg-8 col-sm-12 m-auto text-center">
            <div className="slider-content  ">
              <h2 style={{ marginBottom: "25px", marginTop: "25px" }}>
                Integrating IEL-300 Devices With Agile Sync IoT Platform
                Consists Of Three Main Steps:
              </h2>
              <ol className="steps" type="I">
                <li>
                  <h4>
                    Configuration On The IEL-300 Device: Wifi Connection And
                    Associate Device To User Account.
                  </h4>
                </li>
                <li>
                  {" "}
                  <h4>Logging In To Agile Sync Account. </h4>
                </li>
                <li>
                  {" "}
                  <h4>
                    Associate IEL-300 Device To User Account At Agile Sync
                    Portal.{" "}
                  </h4>
                </li>
              </ol>

              <h3>For detailed guide lines , refer to user manual document.</h3>
              <a href="#" className="read-more">
                {" "}
                Read More <i className="fa fa-caret-right" />
              </a>
            </div>
          </div>
        </div>

        <section id="contact" style={{ padding: "0px" }}>
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-sm-12">
                <div className="contact-form2">
                  <h4 className="text-uppercase">Contact us</h4>
                  <h4>Send us an email and we will get back to you soon</h4>
                  <form action="#">
                    <div className="form-group">
                      <input
                        type="name"
                        placeholder="Put your name"
                        name="name"
                        id="name"
                        className="form-control"
                      />
                    </div>{" "}
                    <div className="form-group">
                      <input
                        type="email"
                        placeholder="Put your email"
                        name="email"
                        id="email"
                        className="form-control"
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        placeholder="Write subject "
                        name="subject"
                        id="subject"
                        className="form-control"
                      />
                    </div>
                    <div className="form-group">
                      <textarea
                        name="message"
                        id="message"
                        cols={30}
                        rows={4}
                        placeholder="Write Your Message"
                        className="form-control"
                        defaultValue={""}
                      />
                    </div>
                    <a href="#" className="btn btn-primary">
                      Send
                    </a>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* REVIEWS STYLES END */}
        {/* Footer STYLES  */}

        <Footer />

        {/* FOOTER STYLES END */}
      </div>
    </>
  );
};

export default Home;
