import React, { useState, useEffect } from "react";
import BoxSet from "./BoxSet";
import BoxCompressor from "./BoxCompressor";
import DeviceInfo from "./DeviceInfo";
import "./mystyles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-solid-svg-icons";


import GppGoodIcon from '@mui/icons-material/GppGood';
import WarningIcon from '@mui/icons-material/Warning';



const styles = {
  rowStyle: {
    "padding-left": "2px",
    "padding-right": "2px",
    borderRadius: "10px",

    // flex: 1,
    // backgroundColor: "#C4D9EE", // white with 80% opacity
  },
  
  
};
const iconStyle = {
  position: 'absolute',
  bottom: '900px', // Change these values as needed
  right: '20px',  // Change these values as needed
  fontSize: '48px'
};


const MyComponent = (props) => {
  const { Payload } = props;
  // console.log("Payload.Heater_SF:", Payload.Heater_SF);
  // it should be placed here 
  // the first time you received your payload you mdife it 

  //Payload.Heater_SF = 2 ;
  
  // header
  const [location, setLocation] = useState("");
  const [customer, setCustomer] = useState("");
  const [deviceName, setDeviceName] = useState("");

  // alarm
  const [alarmCondition, setAlarmCondition] = useState(false);

  useEffect(() => {
    console.log("Checking alarm conditions...");
   // console.log("Payload.Heater_SF:", Payload.Heater_SF); // Log the value of Heater_SF

    const condition =
      Payload.Remote === 1 ||
      Payload.AFS_Status === 4 ||
      Payload.Unit_STS === 3 ||
      Payload.Compressor_1_Status === 7 ||
      Payload.Compressor_1_Status === 8 ||
      Payload.Compressor_1_Status === 9 ||
      Payload.Compressor_1_Status === 10 ||
      Payload.Line_1_HP_Switch === 2 ||
      Payload.Line_1_LP_Switch === 2 ||
      Payload.Compressor_2_Status === 7 ||
      Payload.Compressor_2_Status === 8 ||
      Payload.Compressor_2_Status === 9 ||
      Payload.Compressor_2_Status === 10 ||
      Payload.Line_2_HP_Switch === 2 ||
      Payload.Line_2_LP_Switch === 2 ||
      Payload.Compressor_3_Status === 7 ||
      Payload.Compressor_3_Status === 8 ||
      Payload.Compressor_3_Status === 9 ||
      Payload.Compressor_3_Status === 10 ||
      Payload.Line_3_HP_Switch === 2 ||
      Payload.Line_3_LP_Switch === 2 ||
      Payload.Compressor_4_Status === 7 ||
      Payload.Compressor_4_Status === 8 ||
      Payload.Compressor_4_Status === 9 ||
      Payload.Compressor_4_Status === 10 ||
      Payload.Line_4_HP_Switch === 2 ||
      Payload.Line_4_LP_Switch === 2 ||
      Payload.Heater_1 === 2 ||
      Payload.Heater_2 === 2 ||
      Payload.Heater_SF === 2;
      console.log("Payload", Payload);

    console.log("Alarm condition:", condition); // Log the computed condition
    setAlarmCondition(condition);
  }, [Payload]);


  
  const handleApply = (name, customer, location) => {
    setDeviceName(name);
    setCustomer(customer);
    setLocation(location);
  };

  const deviceTypeLookup = {
    110: "1-Stage Cooling only",
    111: "1-Stage Cooling & Heating",
    120: "2-Stage Cooling only",
    121: "2-Stage Cooling & 2-Heating",
    140: "4-Stage Cooling only",
    141: "4-Stage Cooling & 2-Heating",
  };

  const deviceType = deviceTypeLookup[Payload.Device];

  const TimersSetPoints = [
    { name: "HP_ODT (Sec)", dynamic: Payload.HP_ODT },
    { name: "LP_ODT (Sec)", dynamic: Payload.LP_ODT },
    { name: "Min_On_Off (Sec)", dynamic: Payload.Min_On_Off },
    { name: "Min_Off_On (Sec)", dynamic: Payload.Min_Off_On },
    { name: "CM_to_CM (Sec)", dynamic: Payload.CM_to_CM },
    { name: "AFS_Time (Sec)", dynamic: Payload.AFS_Time },
  ];

  const Input = [
    // { name: "Thermostat", dynamic: Payload.Thermostat },
    { name: "Remote", dynamic: Payload.Remote },
    { name: "AFS_Status", dynamic: Payload.AFS_Status },
    { name: "Unit_STS", dynamic: Payload.Unit_STS },
    { name: "FCS", dynamic: Payload.FCS },
  ];

  const COMP_1 = [
    {
      name: "Compressor(1).Status",
      names: "Status",
      dynamic: Payload.Compressor_1_Status,
    },
    {
      names: "Solenoid Valve",
      name: "Line(1).SolenoidValve",
      dynamic: Payload.Line_1_Solenoid_Valve,
    },
    {
      names: "HP Switch",
      name: "Line(1).HPSwitch",
      dynamic: Payload.Line_1_HP_Switch,
    },
    {
      names: "LP Switch",
      name: "Line(1).LPSwitch",
      dynamic: Payload.Line_1_LP_Switch,
    },
    {
      names: "CM Operation Hours",
      name: "Line(1).CM_Hours",
      dynamic: Payload.Line_1_CM_Hours,
    },
    {
      names: "Cycle Count",
      name: "Line(1).CycleCount",
      dynamic: Payload.Line_1_Cycle_Count,
    },
    {
      names: "HP Trip Count",
      name: "Line(1).HP_Count",
      dynamic: Payload.Line_1_HP_Count,
    },
    {
      names: "LP Trip Count",
      name: "Line(1).LP_Count",
      dynamic: Payload.Line_1_LP_Count,
    },
  ];

  const COMP_2 = [
    { name: "Compressor(1).Status", dynamic: Payload.Compressor_2_Status },
    {
      name: "Line(2).SolenoidValve",
      dynamic: Payload.Line_2_Solenoid_Valve,
    },
    {
      name: "Line(2).HPSwitch",
      dynamic: Payload.Line_2_HP_Switch,
    },
    {
      name: "Line(2).LPSwitch",
      dynamic: Payload.Line_2_LP_Switch,
    },
    {
      name: "Line(2).CM_Hours",
      dynamic: Payload.Line_2_CM_Hours,
    },
    {
      name: "Line(2).CycleCount",
      dynamic: Payload.Line_2_Cycle_Count,
    },
    {
      name: "Line(2).HP_Count",
      dynamic: Payload.Line_2_HP_Count,
    },
    {
      name: "Line(2).LP_Count",
      dynamic: Payload.Line_2_LP_Count,
    },
  ];

  const COMP_3 = [
    { name: "Compressor(3).Status", dynamic: Payload.Compressor_3_Status },
    {
      name: "Line(3).SolenoidValve",
      dynamic: Payload.Line_3_Solenoid_Valve,
    },
    {
      name: "Line(3).HPSwitch",
      dynamic: Payload.Line_3_HP_Switch,
    },
    {
      name: "Line(3).LPSwitch",
      dynamic: Payload.Line_3_LP_Switch,
    },
    {
      name: "Line(3).CM_Hours",
      dynamic: Payload.Line_3_CM_Hours,
    },
    {
      name: "Line(3).CycleCount",
      dynamic: Payload.Line_3_Cycle_Count,
    },
    {
      name: "Line(3).HP_Count",
      dynamic: Payload.Line_3_HP_Count,
    },
    {
      name: "Line(3).LP_Count",
      dynamic: Payload.Line_3_LP_Count,
    },
  ];

  const COMP_4 = [
    { name: "Compressor(4).Status", dynamic: Payload.Compressor_4_Status },
    {
      name: "Line(4).SolenoidValve",
      dynamic: Payload.Line_4_Solenoid_Valve,
    },
    {
      name: "Line(4).HPSwitch",
      dynamic: Payload.Line_4_HP_Switch,
    },
    {
      name: "Line(4).LPSwitch",
      dynamic: Payload.Line_4_LP_Switch,
    },
    {
      name: "Line(4).CM_Hours",
      dynamic: Payload.Line_4_CM_Hours,
    },
    {
      name: "Line(4).CycleCount",
      dynamic: Payload.Line_4_Cycle_Count,
    },
    {
      name: "Line(4).HP_Count",
      dynamic: Payload.Line_4_HP_Count,
    },
    {
      name: "Line(4).LP_Count",
      dynamic: Payload.Line_4_LP_Count,
    },
  ];

 

  const Heating = [
    { name: "Heater_1", dynamic: Payload.Heater_1 },
    { name: "Heater_2", dynamic: Payload.Heater_2 },
    { name: "Heater_SF", dynamic: Payload.Heater_SF },
  ];

  const Fans = [
    { name: "Indoor_Fan", dynamic: Payload.Indoor_Fan },
    { name: "Outdoor_Fan_1", dynamic: Payload.Outdoor_Fan_1 },
    { name: "Outdoor_Fan_2", dynamic: Payload.Outdoor_Fan_2 },
    { name: "Outdoor_Fan_3", dynamic: Payload.Outdoor_Fan_3 },
  ];

  const thermostatBits = [
    (Payload.Thermostat & 0b00000001) > 0, // Bit0: Source
    (Payload.Thermostat & 0b00000010) > 0, // Bit1: G1
    (Payload.Thermostat & 0b00000100) > 0, // Bit2: Y1
    (Payload.Thermostat & 0b00001000) > 0, // Bit3: Y2
    (Payload.Thermostat & 0b00010000) > 0, // Bit4: Y3
    (Payload.Thermostat & 0b00100000) > 0, // Bit5: Y4
    (Payload.Thermostat & 0b01000000) > 0, // Bit6: W1
    (Payload.Thermostat & 0b10000000) > 0, // Bit7: W2
  ];

  const ThermostatBits = [
    { name: "Source", dynamic: thermostatBits[0] },
    { name: "G1", dynamic: thermostatBits[1] },
    { name: "Y1", dynamic: thermostatBits[2] },
    { name: "Y2", dynamic: thermostatBits[3] },
    { name: "Y3", dynamic: thermostatBits[4] },
    { name: "Y4", dynamic: thermostatBits[5] },
    { name: "W1", dynamic: thermostatBits[6] },
    { name: "W2", dynamic: thermostatBits[7] },
  ];

  const settingsBits = [
    (Payload.Settings & 0b00000001) > 0, // Bit0
    (Payload.Settings & 0b00000010) > 0, // Bit1
    (Payload.Settings & 0b00000100) > 0, // Bit2
    (Payload.Settings & 0b00001000) > 0, // Bit3
    (Payload.Settings & 0b00010000) > 0, // Bit4
    (Payload.Settings & 0b00100000) > 0, // Bit5
  ];

  const SettingsBits = [
    { name: "Mode", dynamic: settingsBits[0] },
    { name: "PDS", dynamic: settingsBits[1] },
    { name: "1-Stage", dynamic: settingsBits[2] },
    { name: "2-Stage", dynamic: settingsBits[3] },
    { name: "4-Stage", dynamic: settingsBits[4] },
    { name: "Balance", dynamic: settingsBits[4] },
  ];

  //console.log("Settings Bits:", settingsBits);

const alarmIcon = alarmCondition ? (
  <WarningIcon style={{ ...iconStyle, color: "red" }} />
) : (
  <GppGoodIcon style={{ ...iconStyle, color: "green" }} />
);

  

  return (
    <div className="MyComponent">
      <DeviceInfo
        deviceName={deviceName}
        deviceType={deviceType}
        customer={customer}
        location={location}
        onApply={handleApply}
      />
 <div className="alarmIcon"  >
        {alarmIcon}
      </div>

      <div class="container">
        <div class="row">
          <div class="col-6 col-md-6" style={styles.rowStyle}>
            <BoxCompressor
              title="COMP 1"
              values={[COMP_1, COMP_2, COMP_3, COMP_4]}
            />
          </div>{" "}
          <div class="col-3 col-md-3" style={styles.rowStyle}>
            <BoxSet title="Fans" values={Fans} />
          </div>{" "}
          <div class="col-3 col-md-3" style={styles.rowStyle}>
            <BoxSet title="Heating" values={Heating} />
          </div>{" "}
        </div>
        <div class="row">
          <div class="col-3 col-md-3" style={styles.rowStyle}>
            <BoxSet title="Timers Setpoints" values={TimersSetPoints} />
          </div>{" "}
          <div class="col-3 col-md-3" style={styles.rowStyle}>
            <BoxSet title="Input" values={Input} />
          </div>{" "}
          <div class="col-3 col-md-3" style={styles.rowStyle}>
            <BoxSet title="Settings" values={SettingsBits} />
          </div>{" "}
          <div class="col-3 col-md-3" style={styles.rowStyle}>
            <BoxSet title="Thermostat" values={ThermostatBits} />
          </div>
        </div>
      </div>

      {/* <div
        style={{
          display: "flex",
          justifyContent: "center",
          gap: "20px",
          marginBottom: "20px",
        }}
      >
        <BoxSet title="Timers Setpoints" values={TimersSetPoints} />
        <BoxSet title="Input" values={Input} />
        <BoxSet title="Settings" values={SettingsBits} />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          gap: "20px",
          marginBottom: "20px",
        }}
      >
        <BoxSet title="COMP 1" values={COMP_1} />
        <BoxSet title="Thermostat" values={ThermostatBits} />
        <BoxSet title="COMP 2" values={COMP_2} />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          gap: "20px",
          marginBottom: "20px",
        }}
      >
        <BoxSet title="Fans" values={Fans} />
        <BoxSet title="Heating" values={Heating} />
      </div> */}
    </div>
  );
};

export default MyComponent;
