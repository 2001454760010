// import * as React from "react";
import React, { useState, useEffect, useRef, MouseEvent } from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";
import UserService from "../services/user.service";
import Brightness1Icon from "@mui/icons-material/Brightness1";
import { green, red } from "@mui/material/colors";
import ConnectivityTable from "../components/ConnectivityTable";
import { useSelector } from "react-redux";
const moment = require('moment');

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "device_status",
    numeric: true,
    disablePadding: true,
    label: "device_status",
  },
  {
    id: "Label",
    numeric: true,
    disablePadding: false,
    label: "Label",
  },
  // {
  //   id: "name",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "Name",
  // },
  {
    id: "identification",
    numeric: false,
    disablePadding: false,
    label: "Identification ",
  },
  // {
  //   id: "Update",
  //   numeric: true,
  //   disablePadding: false,
  //   label: "Update",
  // },
  {
    id: "lifeTime",
    numeric: true,
    disablePadding: false,
    label: "lifeTime",
  },
];

const DEFAULT_ORDER = "asc";
const DEFAULT_ORDER_BY = "calories";
const DEFAULT_ROWS_PER_PAGE = 50;

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (newOrderBy) => (event) => {
    onRequestSort(event, newOrderBy);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={"left"}
            padding={"normal"}
            // sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Nutrition
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function EnhancedTable() {
  const [order, setOrder] = React.useState(DEFAULT_ORDER);
  const [orderBy, setOrderBy] = React.useState(DEFAULT_ORDER_BY);
  const [selected, setSelected] = React.useState({});
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [visibleRows, setVisibleRows] = React.useState(null);
  const [rowsPerPage, setRowsPerPage] = React.useState(DEFAULT_ROWS_PER_PAGE);
  const [paddingHeight, setPaddingHeight] = React.useState(0);
  const [rows, setRows] = React.useState([]);
  const { user } = useSelector((state) => state.auth);
   const user_id = user.data.profile.id;
  useEffect(() => {
    UserService.GetDeviceList(user_id)
      .then((res) => {
        console.log(res.data.data.devices.sort((b, a) => a.is_connected - b.is_connected ));
        //   setRows(res.data.data.devices);
        setRows((prevState) => {
          console.log(prevState);
          let rowsOnMount = stableSort(
           res.data.data.devices.sort((b, a) => a.is_connected - b.is_connected ),
            getComparator(DEFAULT_ORDER, DEFAULT_ORDER_BY)
          );

          rowsOnMount = rowsOnMount.slice(
            0 * DEFAULT_ROWS_PER_PAGE,
            0 * DEFAULT_ROWS_PER_PAGE + DEFAULT_ROWS_PER_PAGE
          );

          setVisibleRows(rowsOnMount);
          return res.data.data.devices.sort((b, a) => a.is_connected - b.is_connected );
        });
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      UserService.GetDeviceList()
        .then((res) => {
          console.log(res.data.data.devices);
          //   setRows(res.data.data.devices);
          setRows((prevState) => {
            console.log(prevState);
            let rowsOnMount = stableSort(
             res.data.data.devices.sort((b, a) => a.is_connected - b.is_connected ),
              getComparator(DEFAULT_ORDER, DEFAULT_ORDER_BY)
            );

            rowsOnMount = rowsOnMount.slice(
              0 * DEFAULT_ROWS_PER_PAGE,
              0 * DEFAULT_ROWS_PER_PAGE + DEFAULT_ROWS_PER_PAGE
            );

            setVisibleRows(rowsOnMount);
            return res.data.data.devices.sort((b, a) => a.is_connected - b.is_connected );
          });
        })
        .catch((err) => console.log(err));
    }, 10000);
    return () => clearInterval(interval);
  }, []);

  React.useEffect(() => {
    let rowsOnMount = stableSort(
      rows,
      getComparator(DEFAULT_ORDER, DEFAULT_ORDER_BY)
    );

    rowsOnMount = rowsOnMount.slice(
      0 * DEFAULT_ROWS_PER_PAGE,
      0 * DEFAULT_ROWS_PER_PAGE + DEFAULT_ROWS_PER_PAGE
    );

    setVisibleRows(rowsOnMount);
  }, []);

  const handleRequestSort = React.useCallback(
    (event, newOrderBy) => {
      const isAsc = orderBy === newOrderBy && order === "asc";
      const toggledOrder = isAsc ? "desc" : "asc";
      setOrder(toggledOrder);
      setOrderBy(newOrderBy);

      const sortedRows = stableSort(
        rows,
        getComparator(toggledOrder, newOrderBy)
      );
      const updatedRows = sortedRows.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      );

      setVisibleRows(updatedRows);
    },
    [order, orderBy, page, rowsPerPage]
  );

  const handleClick = (event, name) => {
    setSelected(name);
  };

  const handleChangePage = React.useCallback(
    (event, newPage) => {
      setPage(newPage);

      const sortedRows = stableSort(rows, getComparator(order, orderBy));
      const updatedRows = sortedRows.slice(
        newPage * rowsPerPage,
        newPage * rowsPerPage + rowsPerPage
      );

      setVisibleRows(updatedRows);

      // Avoid a layout jump when reaching the last page with empty rows.
      const numEmptyRows =
        newPage > 0
          ? Math.max(0, (1 + newPage) * rowsPerPage - rows.length)
          : 0;

      const newPaddingHeight = (dense ? 33 : 53) * numEmptyRows;
      setPaddingHeight(newPaddingHeight);
    },
    [order, orderBy, dense, rowsPerPage]
  );

  const handleChangeRowsPerPage = React.useCallback(
    (event) => {
      const updatedRowsPerPage = parseInt(event.target.value, 10);
      setRowsPerPage(updatedRowsPerPage);

      setPage(0);

      const sortedRows = stableSort(rows, getComparator(order, orderBy));
      const updatedRows = sortedRows.slice(
        0 * updatedRowsPerPage,
        0 * updatedRowsPerPage + updatedRowsPerPage
      );

      setVisibleRows(updatedRows);

      // There is no layout jump to handle on the first page.
      setPaddingHeight(0);
    },
    [order, orderBy]
  );

  return (
    <section className="main-slider">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-8 col-sm-12">
            <Box sx={{ width: "100%" }}>
              <Paper sx={{ width: "100%", mb: 2 }}>
                {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
                <TableContainer>
                  <Table
                    sx={{ minWidth: 750 }}
                    aria-labelledby="tableTitle"
                    size={dense ? "small" : "small"}
                  >
                    <EnhancedTableHead
                      numSelected={selected.length}
                      order={order}
                      orderBy={orderBy}
                      onRequestSort={handleRequestSort}
                      rowCount={rows.length}
                    />
                    <TableBody>
                      {visibleRows
                        ? visibleRows.map((row, index) => {
                            const labelId = `enhanced-table-checkbox-${index}`;

                            return (
                              <TableRow
                                hover
                                onClick={(event) => handleClick(event, row)}
                                key={row.id}
                                sx={{ cursor: "pointer" }}
                              >
                                <TableCell>
                                  <TableCell align="left">
                                    {row.device_status ? (
                                      <Brightness1Icon
                                        sx={{ color: green[500] }}
                                      />
                                    ) : (
                                      <Brightness1Icon
                                        sx={{ color: red[500] }}
                                      />
                                    )}
                                  </TableCell>
                                </TableCell>
                                <TableCell align="left">{row.label}</TableCell>
                                {/* <TableCell align="left">
                                  {row.device_name}
                                </TableCell> */}
                                <TableCell align="left">
                                  {row.device_id}
                                </TableCell>
                                {/* <TableCell align="left">
                                  { moment(row.updated_date).add(1, 'hours').format('YYYY-MM-DD HH:mm:ss')}
                                </TableCell> */}
                                <TableCell align="left">
                                  { moment(row.life_beat_timestamp).format('YYYY-MM-DD HH:mm:ss')}
                                </TableCell>
                              </TableRow>
                            );
                          })
                        : null}
                      {paddingHeight > 0 && (
                        <TableRow
                          style={{
                            height: paddingHeight,
                          }}
                        >
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={rows.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Paper>
            </Box>
          </div>
          <div className="col-lg-4 col-sm-12">
          
            <div className="widget_categories right-widget top_heding ">
                            {/* <h4><b>{areaName}</b> - {devicename} */}
                            <h4>

                                {selected.device_name}
                            </h4>
                          </div>
            <ConnectivityTable device={selected.device_id} />
          </div>
        </div>
      </div>
    </section>
  );
}
