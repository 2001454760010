import React from "react";
import { Paper } from "@mui/material";

const Box = ({ isDisabled, name, dynamic }) => {
  //  console.log("Name:", name);
  //  console.log("Dynamic:", dynamic);
 // console.log("isDisabled:", isDisabled);

  const renderContent = () => {
    switch (name) {
      case "Remote":
        switch (dynamic) {
          case 1:
            return "OFF";
          case 2:
            return "ON";
          case 5:
            return "Bypassed";
          default:
            return dynamic;
        }
      case "AFS_Status":
        switch (dynamic) {
          case 1:
            return "Open / Fan is OFF";
          case 2:
            return "Open / Fan is ON";
          case 3:
            return "Closed / Fan is ON";
          case 4:
            return "Trip";
          case 5:
            return "Bypassed";
          default:
            return dynamic;
        }
      case "Unit_STS":
        switch (dynamic) {
          case 1:
            return "OFF - No Trip";
          case 2:
            return "ON - No Trip";
          case 3:
            return "Trip";
          default:
            return dynamic;
        }

      case "FCS":
        switch (dynamic) {
          case 0:
            return "All FCS Open";
          case 1:
            return "1 FCS Closed";
          case 2:
            return "2 FCS Closed";
          case 3:
            return "3 FCS Closed";
          case 4:
            return "4 FCS Closed";
          default:
            return dynamic;
        }

      case "Compressor(1).Status":
      case "Compressor(2).Status":
        switch (dynamic) {
          case 0:
            return "NA";
          case 1:
            return "OFF";
          case 2:
            return "OFF ....";
          case 3:
            return "ON";
          case 4:
            return "ON ....";
          case 5:
            return "Hold - LP";
          case 6:
            return "Hold - HP";
          case 7:
            return "Locked - LP";
          case 8:
            return "Locked - HP";
          case 9:
            return "Locked -AFS";
          case 10:
            return "Locked - REM";
          case 25:
            return "Disabled";
          default:
            return dynamic;
        }
      case "Line(1).SolenoidValve":
      case "Line(2).SolenoidValve":
      case "Line(3).SolenoidValve":
      case "Line(4).SolenoidValve":
        switch (dynamic) {
          case 2:
            return "Open";
          case 3:
            return "Closed";
          case 10:
            return "......";
          default:
            return dynamic;
        }
      case "Source":
        return dynamic ? "Virtual" : "Physical";
      case "Heater_1":
      case "Heater_2":
        switch (dynamic) {
          case 1:
            return "RUN";
          case 2:
            return "Locked";
          case 3:
            return "Waiting";
          case 4:
            return "OFF";
          default:
            return dynamic;
        }
      case "Heater_SF":
        switch (dynamic) {
          case 1:
            return "No Trip";
          case 2:
            return " Trip";
          case 3:
            return "Bypassed";
          default:
            return dynamic;
        }
      case "Line(1).HPSwitch":
      case "Line(2).HPSwitch":
      case "Line(3).HPSwitch":
      case "Line(4).HPSwitch":

      case "Line(1).LPSwitch":
      case "Line(2).LPSwitch":
      case "Line(3).LPSwitch":
      case "Line(4).LPSwitch":

        switch (dynamic) {
          case 2:
            return "Open";
          case 3:
            return "Closed";
          default:
            return dynamic;
        }

      case "Indoor_Fan":
      case "Outdoor_Fan_1":
      case "Outdoor_Fan_2":
      case "Outdoor_Fan_3":
        switch (dynamic) {
          case 1:
            return "ON";
          case 2:
            return "OFF";
          default:
            return dynamic;
        }

      // Settings bits
      case "Mode":
        return dynamic ? "Test Mode" : "Normal mode";

      case "PDS":
        return dynamic ? "Active" : "Not active";

      case "1-Stage":
        return dynamic ? "Single Stage" : "-";

      case "2-Stage":
        return dynamic ? "Two Stage" : "-";

      case "4-Stage":
        return dynamic ? "Four Stage" : "-";
        case "Balance":
          return dynamic ? "Comp. (1) Lead" : "Balance";

      // thermostat bits
      case "G1":
      case "Y1":
      case "Y2":
      case "Y3":
      case "Y4":
      case "W1":
      case "W2":
        return dynamic ? "ON" : "OFF";
      default:
        return dynamic;
    }
  };

  // console.log("Render content:", renderContent());

  const determineFontWeight = () => {
    switch (name) {
      case "HP_ODT (Sec)":
      case "LP_ODT (Sec)":
      case "Min_On_Off (Sec)":
      case "Min_Off_On (Sec)":
      case "CM_to_CM (Sec)":
      case "AFS_Time (Sec)":
      case "Thermostat":
      case "Mode":

      case "Line(1).CM_Hours":
      case "Line(1).CycleCount":
      case "Line(1).HP_Count":
      case "Line(1).LP_Count":
      case "Line(2).CM_Hours":
      case "Line(2).CycleCount":
      case "Line(2).HP_Count":
      case "Line(2).LP_Count":
        return "bold";
      default:
        return "normal";
    }
  };
  const determineFontSize = () => {
    switch (name) {
      case "HP_ODT (Sec)":
      case "LP_ODT (Sec)":
      case "Min_On_Off (Sec)":
      case "Min_Off_On (Sec)":
      case "CM_to_CM (Sec)":
      case "AFS_Time (Sec)":
      case "Thermostat":
      case "Line(1).CM_Hours":
      case "Line(1).CycleCount":
      case "Line(1).HP_Count":
      case "Line(1).LP_Count":
      case "Line(2).CM_Hours":
      case "Line(2).CycleCount":
      case "Line(2).HP_Count":
      case "Line(2).LP_Count":
        return "16px";
      default:
        return "10px";
    }
  };

  const determineFontColor = () => {
    switch (name) {
      case "HP_ODT (Sec)":
      case "LP_ODT (Sec)":
      case "Min_On_Off (Sec)":
      case "Min_Off_On (Sec)":
      case "CM_to_CM (Sec)":
      case "AFS_Time (Sec)":
      case "Thermostat":
      case "Settings":
      case "Line(1).CM_Hours":
      case "Line(1).CycleCount":
      case "Line(1).HP_Count":
      case "Line(1).LP_Count":
      case "Line(2).CM_Hours":
      case "Line(2).CycleCount":
      case "Line(2).HP_Count":
      case "Line(2).LP_Count":
        return "black";

      default:
        return "#E2EFFC";
    }
  };
  const determineColor = () => {
    if (
      isDisabled == 1 &&
      (name === "Line(1).SolenoidValve" ||
        name === "Line(2).SolenoidValve" ||
        name === "Line(3).SolenoidValve" ||
        name === "Line(4).SolenoidValve" ||
        name === "Compressor(1).Status" ||
        name === "Compressor(2).Status" ||
        name === "Compressor(3).Status" ||
        name === "Compressor(4).Status" ||
        name === "Line(1).HPSwitch" ||
        name === "Line(2).HPSwitch" ||
        name === "Line(3).HPSwitch" ||
        name === "Line(4).HPSwitch" ||
        name === "Line(1).LPSwitch" ||
        name === "Line(2).LPSwitch" ||
        name === "Line(3).LPSwitch" ||
        name === "Line(4).LPSwitch" ||
        name === "Line(1).LP_Count" ||
        name === "Line(2).LP_Count" ||
        name === "Line(3).LP_Count" ||
        name === "Line(4).LP_Count" ||
        name === "Line(1).HP_Count" ||
        name === "Line(2).HP_Count" ||
        name === "Line(3).HP_Count" ||
        name === "Line(4).HP_Count" ||
        name === "Line(1).CycleCount" ||
        name === "Line(2).CycleCount" ||
        name === "Line(3).CycleCount" ||
        name === "Line(4).CycleCount" ||
        name === "Line(1).CM_Hours" ||
        name === "Line(2).CM_Hours" ||
        name === "Line(3).CM_Hours" ||
        name === "Line(4).CM_Hours" 

      
      )
    )
      return "gray";
    switch (name) {
      case "Remote":
        switch (dynamic) {
          case 1:
            return "red";
          case 2:
            return "green";
          case 5:
            return "grey";
          default:
            return "#fff";
        }
      case "AFS_Status":
        switch (dynamic) {
          case 1:
            return "orange";
          case 2:
            return "orange";
          case 3:
            return "green";
          case 4:
            return "red";
          case 5:
            return "grey";
          default:
            return "#fff";
        }
      case "Unit_STS":
        switch (dynamic) {
          case 1:
            return "grey";
          case 2:
            return "green";
          case 3:
            return "red";
          default:
            return "#fff";
        }
      case "FCS":
        switch (dynamic) {
          case 0:
          case 1:
          case 2:
          case 3:
          case 4:
            return "blue";

          default:
            return "#fff";
        }
      case "Compressor(1).Status":
      case "Compressor(2).Status":
        switch (dynamic) {
          case 0:
            return "grey";
          case 1:
            return "grey";
          case 2:
            return "grey";
          case 3:
            return "green";
          case 4:
            return "green";
          case 5:
            return "orange";
          case 6:
            return "orange";
          case 7:
            return "red";
          case 8:
            return "red";
          case 9:
            return "red";
          case 10:
            return "red";
            case 25:
              return "grey";
          default:
            return "#fff";
        }
      case "Line(1).SolenoidValve":
      case "Line(2).SolenoidValve":


      switch (dynamic) {
        case 2:
          return "green";
        case 3:
        case 10:
          return "grey";

        default:
          return "#fff";
      }


      case "Line(1).HPSwitch":
      case "Line(2).HPSwitch":
      case "Line(3).HPSwitch":
      case "Line(4).HPSwitch":

      case "Line(1).LPSwitch":
      case "Line(2).LPSwitch":
      case "Line(3).LPSwitch":
      case "Line(4).LPSwitch":

        switch (dynamic) {
          case 2:
            return "red";
          case 3:
            return "green";

          default:
            return "#fff";
        }

      case "Heater_1":
      case "Heater_2":
        switch (dynamic) {
          case 1:
            return "green";
          case 2:
            return "red";
          case 3:
            return "orange";
          case 4:
            return "grey";
          default:
            return "#fff";
        }
      case "Heater_SF":
        switch (dynamic) {
          case 1:
            return "green";
          case 2:
            return "red";
          case 3:
            return "grey";
          default:
            return "#fff";
        }

      case "Indoor_Fan":
      case "Outdoor_Fan_1":
      case "Outdoor_Fan_2":
      case "Outdoor_Fan_3":
        switch (dynamic) {
          case 1:
            return "green";
          case 2:
            return "grey";
        }

      case "Source":
        return dynamic ? "orange" : "green";

      case "G1":
      case "Y1":
      case "Y2":
      case "Y3":
      case "Y4":
      case "W1":
      case "W2":
        return dynamic ? "green" : "grey";
      default:
        return "#fff";

      case "Mode":
      case "PDS":
      case "1-Stage":
      case "2-Stage":
      case "4-Stage":
      case "Balance":
        return "blue";
    }
  };

  // console.log("Background color:", determineColor());
  const boxStyle = {
    display: "flex", // Use flexbox
    flexDirection: "column", // Stack children vertically
    alignItems: "center", // Center horizontally
    justifyContent: "center", // Center vertically
    padding: "5px 10px",
    marginLeft: "0px",
    textAlign: "center",
    borderRadius: "5px",
    // border: "1px solid #333",
    color: determineFontColor(),
    backgroundColor: determineColor(),
    fontSize: determineFontSize(), // Decreased font size
    width: "60px",
    height: "20px",
    fontWeight: determineFontWeight(),
    boxShadow: "0px 0px 10px 0px grey",
    lineHeight: "1", // Decreased line height
    marginTop: "2.5px", // Adjust this value to move the dynamic box down
  };

  const keyStyle = {
    fontSize: "12px", // Decreased font size for the keys.
    fontWeight: "bold", // Make the text bold
    lineHeight: "1", // Decreased line height for the keys
  };

  return (
    <div style={{ marginBottom: "10px" }}>
      <Paper variant="outlined" elevation={2} className="box" style={boxStyle}>
        {renderContent()}
      </Paper>
    </div>
  );
};

export default Box;
