import React from "react";
import { Card, CardContent, Grid, Typography } from "@mui/material";
import Skeleton from "react-loading-skeleton";
import { useState, useEffect } from "react";
// import UserService from "../services/user.service";
import { FreeBreakfast } from "@mui/icons-material";


const PhaseBox = (props) => {
  const { isGraphStatsLoading, voltage, current, power, color,type } = props;
  // console.log(isGraphStatsLoading, voltage, current, power, color);
  // console.log(voltage);
  const labeslFontSize = 8;
  const dataFontSize = 18;
  const unitFontSize = 16;
  let size = 4 ;

  if (type) size = 6 ;
  return (
    <Grid spacing={1} item xs={12} sm={6} md={4}>
      {" "}
      <Grid
        container
        spacing={1}
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
      >
        <Grid spacing={1} item xs={12} sm={6} md={size}>
          <Card
            className=""
            sx={{
              // maxWidth: 345,
              boxShadow: 2,
              margin: "0 5px 10px",
              ":hover": {
                boxShadow: 5,
              },

              "border-top": `solid 3px ${color}`,
            }}
          >
            <CardContent>
              <Typography
                variant="h5"
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  "padding-left": 5,
                  "padding-right": 5,
                  fontSize: unitFontSize - 2,
                }}
              >
                Voltage
              </Typography>
              <Typography
                variant="h5"
                component="div"
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  padding: 0,
                  fontSize: dataFontSize,
                }}
              >
                {isGraphStatsLoading ? (
                  <Skeleton height={15} width={100} />
                ) : (
                  <span>
                    <span>
                      <span>{parseFloat(voltage).toFixed(2)}</span>
                    </span>
                  </span>
                )}
              </Typography>

              <Typography
                variant="h5"
                component="div"
                style={{
                  textAlign: "right",
                  fontWeight: "bold",
                  padding: 0,
                  fontSize: unitFontSize,
                  margin: 0,
                  "padding-top": "-10%",
                }}
              >
                {isGraphStatsLoading ? (
                  <Skeleton height={15} width={100} />
                ) : (
                  <span>V</span>
                )}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid spacing={1} item xs={12} sm={6} md={size}>
          <Card
            className=""
            sx={{
              // maxWidth: 345,
              boxShadow: 2,
              margin: "0 5px 10px",
              ":hover": {
                boxShadow: 5,
              },
              "border-top": `solid 3px ${color}`,
            }}
          >
            <CardContent>
              <Typography
                variant="h5"
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  "padding-left": 5,
                  "padding-right": 5,
                  fontSize: unitFontSize - 2,
                }}
              >
                Current
              </Typography>
              <Typography
                variant="h5"
                component="div"
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  padding: 0,
                  fontSize: dataFontSize,
                }}
              >
                {isGraphStatsLoading ? (
                  <Skeleton height={15} width={100} />
                ) : (
                  <span>
                    <span>{current}</span>
                  </span>
                )}
              </Typography>
              <Typography
                variant="h5"
                component="div"
                style={{
                  textAlign: "right",
                  fontWeight: "bold",
                  padding: 0,
                  fontSize: unitFontSize,
                }}
              >
                {isGraphStatsLoading ? (
                  <Skeleton height={15} width={100} />
                ) : (
                  <span>A</span>
                )}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        {type?null:<Grid spacing={1} item xs={12} sm={6} md={size}>
          <Card
            className=""
            sx={{
              // maxWidth: 345,
              boxShadow: 2,
              margin: "0 5px 10px",
              ":hover": {
                boxShadow: 5,
              },
              "border-top": `solid 3px ${color}`,
            }}
          >
            <CardContent>
              <Typography
                gutterBottom
                variant="h5"
                component="div"
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  margin: 0,
                  fontSize: unitFontSize - 2,
                }}
              >
                Power
              </Typography>
              <Typography
                variant="h5"
                component="div"
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  padding: 0,
                  fontSize: dataFontSize,
                }}
              >
                {isGraphStatsLoading ? (
                  <Skeleton height={15} width={100} />
                ) : (
                  <span>
                    <span>{parseFloat(power).toFixed(2)}</span>
                  </span>
                )}
              </Typography>
              <Typography
                variant="h5"
                component="div"
                style={{
                  textAlign: "right",
                  fontWeight: "bold",
                  padding: 0,
                  fontSize: unitFontSize,
                }}
              >
                {isGraphStatsLoading ? (
                  <Skeleton height={15} width={100} />
                ) : (
                  <span>KW</span>
                )}
              </Typography>
            </CardContent>
          </Card>
        </Grid>}
        
      </Grid>
    </Grid>
  );
};


const PFBox = (props) => {
  const { isGraphStatsLoading, Unit, StaticTxtValue5,StaticValue5, power, color,type } = props;
  // console.log(isGraphStatsLoading, voltage, current, power, color);
  // console.log(voltage);
  const labeslFontSize = 8;
  const dataFontSize = 16;
  const unitFontSize = 15;
  let size = 4 ;

  if (type) size = 6 ;
  return (
          <Card
            className=""
            sx={{
              // maxWidth: 345,
              boxShadow: 2,
              margin: "0 5px 10px",
              ":hover": {
                boxShadow: 5,
              },

              "border-top": `solid 3px ${color}`,
              "height": '100px',
            }}
          >
            <CardContent>
              <Typography
                variant="h5"
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  "padding-left": 5,
                  "padding-right": 5,
                  fontSize: unitFontSize - 2,
                }}
              >
                {StaticTxtValue5}
              </Typography>
              <Typography
                variant="h5"
                component="div"
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  padding: 0,
                  fontSize: dataFontSize,
                }}
              >
                {isGraphStatsLoading ? (
                  <Skeleton height={15} width={100} />
                ) : (
                  <span>
                    <span>
                      <span>{StaticValue5}</span>
                    </span>
                  </span>
                )}
              </Typography>

              <Typography
                variant="h5"
                component="div"
                style={{
                  textAlign: "right",
                  fontWeight: "bold",
                  padding: 0,
                  fontSize: unitFontSize,
                  margin: 0,
                  "padding-top": "-10%",
                }}
              >
                {isGraphStatsLoading ? (
                  <Skeleton height={15} width={100} />
                ) : (
                  <span> {Unit} </span>
                )}
              </Typography>
            </CardContent>
          </Card>

        // <h1>hi</h1>

  );
};
export default PFBox;
