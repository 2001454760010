import { useCustomCompareEffect } from "use-custom-compare";
import isEqual from "lodash/isEqual";
import UserService from "../../services/user.service";
import React, { useState, useEffect, useRef } from "react";
import moment from "moment-timezone";
import ReactPaginate from "react-paginate";
import IconButton from "@mui/material/IconButton";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ReactApexChart from "react-apexcharts";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import Switch from "@mui/material/Switch";
import { Typography } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Card, CardContent, Grid } from "@mui/material";
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";
import Skeleton from "react-loading-skeleton";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import InboxIcon from "@mui/icons-material/Inbox";
import DraftsIcon from "@mui/icons-material/Drafts";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import GppGoodIcon from "@mui/icons-material/GppGood";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { object } from "yup";
import { map } from "highcharts";

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export default function App(props) {
  const { device_id, edit } = props;
  const [data0, setData0] = useState([]);
  const [data1, setData1] = useState([]);
  const [data2, setData2] = useState([]);
  const [data3, setData3] = useState([]);
  const chartRef = useRef();

  const [chartConfig, setChartConfig] = useState({
    chart: {
      id: "total-power-chart",
      // type: "line",
      // stacked: true,
      height: 500,
      foreColor: "#000000",
      zoom: {
        type: "x",
        enabled: true,
        autoScaleYaxis: true,
      },

      toolbar: {
        autoSelected: "zoom",
      },
    },
    dataLabels: {
      enabled: false,
    },
    markers: {
      size: 0,
    },
    xaxis: {
      type: "datetime",
      tooltip: {
        enabled: false,
      },
      labels: {
        rotate: -90,
        rotateAlways: true,
        minHeight: 100,
        maxHeight: 160,
        show: true,
        align: "left",
        trim: true,
        // minWidth: 0,
        // maxWidth: 1200,
        style: {
          colors: ["#000000"],
          fontSize: "15px",
          // fontFamily: 'Helvetica, Arial, sans-serif',
          fontWeight: 600,
        },
      },
    },
    stroke: {
      width: 2,
    },
    colors: ["#0054F1", "#0054F1", "#0054F1", "#0054F1"],
    yaxis: {
      opposite: true,
      // formatter: (value) => {
      //   return value ;
      // },
      min: 0,
      decimalsInFloat: 2,
      forceNiceScale: true,
      axisBorder: {
        show: true,
        color: "#78909C",
        offsetX: 0,
        offsetY: 0,
      },
      axisTicks: {
        show: true,
        borderType: "solid",
        color: "#78909C",
        width: 6,
        offsetX: 0,
        offsetY: 0,
      },
      labels: {
        show: true,
        align: "right",
        minWidth: 0,
        maxWidth: 160,
        style: {
          colors: [],
          fontSize: "15px",
          // fontFamily: 'Helvetica, Arial, sans-serif',
          fontWeight: 600,
        },
        offsetX: 0,
        offsetY: 0,
        rotate: 0,
        // formatter: (value) => { return val },
      },
      crosshairs: {
        show: true,
        position: "back",
        stroke: {
          color: "#b6b6b6",
          width: 0.6,
          dashArray: [0, 1, 2],
        },
      },
      tooltip: {
        enabled: false,
        offsetX: 0,
      },
      title: {
        text: "[KW2]",
        rotate: 0,
        offsetX: 40,
        offsetY: -170,
        style: {
          color: undefined,
          fontSize: "15px",
          // fontFamily: 'Helvetica, Arial, sans-serif',
          fontWeight: 600,
          // cssClass: 'apexcharts-yaxis-title',
        },
      },
    },
    legend: {
      showForSingleSeries: true,
      offsetX: 7,
      //  offsetY:5,
      position: "top",
      fontSize: "18px",
      //  width:1
      //  fontWeight: 800,
      markers: {
        width: 12,
        height: 12,
        strokeWidth: 2,
        strokeColor: "#fff",
        fillColors: undefined,
        radius: 12,
        offsetX: -5,
        // offsetY: 5
      },
    },
  });
  console.log("props", props);

  function getData() {
    console.log("get process");
    const TimeFormat = props.TimeFormat;
    const payload = {
      deviceId: props.device_id,
      type: props.TimeFormat == "daily" ? "day" : "month",
      year: props.EnergyYear,
      month: props.EnergyMonth,
    };
    UserService.PostEnergyData(payload)
      .then((resData) => {
        if (resData.data.type === "success") {
          let res = Object.values(resData.data.data.deviceData);
          console.table(res);

          let filteredArray = res.filter(
            (item) => item.energy_type === props.EnergyType
          );
          console.table(filteredArray);
          filteredArray = filteredArray.map((v, index) => {
            // console.log(v, index);
            // if (payload.type == "day") return [index + 1, v.value];
            // else return [months[index], v.value];
            if (payload.type == "day") return [new Date(2000+v.year_index,v.month_index-1,v.day_index),v.value]
           else  return [new Date(2000+v.year_index,v.month_index-1,15),v.value]
          });
          const localUnit = "KWH";

          setData0(filteredArray);
          console.table(filteredArray);
          setChartConfig({
            chart: {
              id: "total-power-chart",
              // type: "line",
              // stacked: true,
              height: 500,
              foreColor: "#000000",
              zoom: {
                type: "x",
                enabled: true,
                autoScaleYaxis: true,
              },
              toolbar: {
                autoSelected: "zoom",
              },
            },
            tooltip: {
              enabled: true,
              color: "#000000",
              style: {
                fontSize: "16px",
                // fontFamily: undefined
              },
              // shared: true,
              x: {
                formatter: function (timestamp) {
                  // console.log(val)
                  if (TimeFormat === "hourly")
                    return moment(timestamp).format("DD MMMM YYYY HH:00");
                  if (TimeFormat === "daily")
                    return moment(timestamp).format("DD MMMM YYYY");
                  if (TimeFormat === "monthly")
                    return moment(timestamp).format("YYYY MMMM");
                },
              },
              y: [
                {
                  formatter: function (y) {
                    if (typeof y !== "undefined") {
                      return y.toFixed(2) + " " + "[KW]";
                    }
                    return y;
                  },
                },
                {
                  formatter: function (y) {
                    if (typeof y !== "undefined") {
                      return y.toFixed(2) + " " + "[KW]";
                    }
                    return y;
                  },
                },
                {
                  formatter: function (y) {
                    if (typeof y !== "undefined") {
                      return y.toFixed(2) + " " + "[KW]";
                    }
                    return y;
                  },
                },
                {
                  formatter: function (y) {
                    if (typeof y !== "undefined") {
                      return y.toFixed(2) + " " + localUnit;
                    }
                    return y;
                  },
                },
              ],
            },
            dataLabels: {
              enabled: false,
            },
            markers: {
              size: 0,
            },
            xaxis: {
              type: "datetime",
              tickAmount: TimeFormat === "daily" ?31:11,
              tooltip: {
                enabled: false,
              },
              labels: {
                rotate: -90,
                rotateAlways: true,
                minHeight: 100,
                maxHeight: 160,
                show: true,
                align: "left",
                trim: false,
                formatter: function (val, timestamp) {
                  if (TimeFormat === "daily")
                    return moment(timestamp).format("DD MMMM");
                  if (TimeFormat === "monthly")
                    return moment(timestamp).format("MMMM");

                },
                style: {
                  colors: ["#000000"],
                  fontSize: "15px",
                  // fontFamily: 'Helvetica, Arial, sans-serif',
                  fontWeight: 600,
                },
              },
            },
            stroke: {
              width: 2,
            },
            colors: ["#64006C", "#F30202", "#0054F1", "#FFD93D"],
            yaxis: {
              opposite: false,
              formatter: (value) => {
                return value;
              },
              // min: 0,
              decimalsInFloat: 2,
              forceNiceScale: true,
              axisBorder: {
                show: true,
                color: "#78909C",
                offsetX: 0,
                offsetY: 0,
              },
              axisTicks: {
                show: true,
                borderType: "solid",
                color: "#78909C",
                width: 6,
                offsetX: 0,
                offsetY: 0,
              },
              labels: {
                show: true,
                align: "right",
                minWidth: 0,
                maxWidth: 160,
                style: {
                  colors: [],
                  fontSize: "15px",
                  // fontFamily: 'Helvetica, Arial, sans-serif',
                  fontWeight: 600,
                },
                offsetX: 0,
                offsetY: 0,
                rotate: 0,
                // formatter: (value) => { return val },
              },
              crosshairs: {
                show: true,
                position: "back",
                stroke: {
                  color: "#b6b6b6",
                  width: 0.6,
                  dashArray: [0, 1, 2],
                },
              },
              tooltip: {
                enabled: false,
                offsetX: 0,
              },
              title: {
                text: "[" + localUnit + "]",
                rotate: 0,
                offsetX: 40,
                offsetY: -170,
                style: {
                  color: undefined,
                  fontSize: "15px",
                  // fontFamily: 'Helvetica, Arial, sans-serif',
                  fontWeight: 600,
                  // cssClass: 'apexcharts-yaxis-title',
                },
              },
            },
            legend: {
              showForSingleSeries: true,
              offsetX: 7,
              //  offsetY:5,
              position: "top",
              fontSize: "18px",
              //  width:1
              //  fontWeight: 800,
              markers: {
                width: 12,
                height: 12,
                strokeWidth: 2,
                strokeColor: "#fff",
                fillColors: undefined,
                radius: 12,
                offsetX: -5,
                // offsetY: 5
              },
            },
          });
        } else {
          // toast.error("Device not created.", { toastId: 2345353643 });
          console.log("error");
        }
      })
      .catch((error) => {
        {
          console.log("error", error);
        }
      });
  }

  useEffect(() => {
    getData();
  }, [props]);

  return (
    <ReactApexChart
      height={450}
      ref={chartRef}
      options={chartConfig}
      series={[{ name: "Data", data: data0 }]}
      type="bar"
    />
  );
}
