import React, { useState, useEffect, useRef, MouseEvent } from "react";
import { Footer } from "../components/includes/Footer";
import { Header } from "../components/includes/Header";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment-timezone";
// import TreeMenu, { defaultChildren, ItemComponent } from 'react-simple-tree-menu';
//import "react-simple-tree-menu/dist/main.css";
import { useSelector } from "react-redux";
import UserService from "../services/user.service";
// import TableTemplate from "../components/TableTemplate";
import TableTemplate from "../components/TableTemplate";

const oldheadCells = [
  {
    id: "device_status",
    numeric: false,
    disablePadding: false,
    label: "Status",
  },
  {
    id: "id",
    numeric: false,
    disablePadding: false,
    label: "ID",
  },
  {
    id: "device_name",
    numeric: false,
    disablePadding: false,
    label: "Name",
  },
  {
    id: "device_id",
    numeric: false,
    disablePadding: false,
    label: "Identification",
  },
  {
    id: "life_beat_timestamp",
    numeric: false,
    disablePadding: false,
    label: "life_beat_timestamp",
  },
  {
    id: "updates_data",
    numeric: false,
    disablePadding: false,
    label: "Updates Data",
  },
];



const Connectivity = () => {
  const [DeviceList, setDeviceList] = React.useState([]);
  const { user } = useSelector((state) => state.auth);
  const userID = user.data.profile.id;
  useEffect(() => {
    const interval = setInterval(() => {
      UserService.GetDeviceList(userID)
        .then((res) => {
          console.log(res.data.data.devices);
          // setDeviceList(res.data.data.devices);
          setDeviceList((prevState) => {
            console.log(prevState);
            return res.data.data.devices;
          });
        })
        .catch((err) => console.log(err));
    }, 10000);
    return () => clearInterval(interval);
  }, [DeviceList]);

  return (
    <div>
      <Header />

      <section className="main-slider">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <TableTemplate headCells={oldheadCells} rows={DeviceList} />
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};
export default Connectivity;
