import React, { useState, useEffect, useRef, MouseEvent } from "react";
import UserService from "../../services/user.service";
import MyComponent from "./PucDashboardComponents/MainCompo";



export default function App(props) {
  const { DeviceID } = props;
  //console.log("deviceId", DeviceID);
  const [Payload, setPayload] = useState({});

  useEffect(() => {
  // console.log("first data");
    UserService.GetLatestDeviceStatsDataPuc(DeviceID)
      .then((res) => {

        // console.log(
        //   "!!!!!!!!!!!!!!!!!!!!**********PUC:",
        //   res.data.data.deviceData[0]
        // );
        setPayload(
          res.data.data.deviceData[0]);
      })
      .catch((err) => {
       // console.log(err);
      });
  }, []);

  
  useEffect(() => {
    // console.log("first data");
      UserService.GetLatestDeviceStatsDataPuc(DeviceID)
        .then((res) => {
  
          // console.log(
          //   "!!!!!!!!!!!!!!!!!!!!**********PUC:",
          //   res.data.data.deviceData[0]
          // );
          setPayload(
            res.data.data.deviceData[0]);
        })
        .catch((err) => {
         // console.log(err);
        });
    }, [DeviceID]);
  useEffect(() => {
    const interval = setInterval(() => {
      // console.log("Refsh 10 second",getLastData());

      // setPayload(getLastData());
      //console.log("Refsh 10 second");
      UserService.GetLatestDeviceStatsDataPuc(DeviceID)
        .then((res) => {

          // console.log(
          //   "!!!!!!!!!!!!!!!!!!!!**********PUC:",
          //   res.data.data.deviceData[0]
          // );

          // Payload = res.data.data.deviceData[0];
          setPayload(res.data.data.deviceData[0]);
        })
        .catch((err) => {
        //  console.log(err);
        });
    }, 10 * 1000);
    return () => clearInterval(interval);
  }, [DeviceID]);

  return (
    <>
      <MyComponent Payload={Payload}></MyComponent>
    </>
  );
}
