import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const borderColor = "#90e5fc";
const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    borderBottomColor: "#bff0fd",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 24,
    fontSize: 12,
    fontStyle: "bold",
  },
  description: {
    width: "50%",
    textAlign: "center",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingRight: 8,
  },
  totalKwhr: {
    width: "25%",
    textAlign: "center",
    paddingRight: 8,
    borderRightColor: borderColor,
    borderRightWidth: 1,

  },
  totalAmount: {
    width: "25%",
    textAlign: "center",
    paddingRight: 8,
  },
});
function formatNumberWithCommas(inputNumber) {
  // Convert the number to a string
  let numberString = inputNumber.toString();

  // Check if the number has a decimal part
  if (numberString.includes(".")) {
    // Split the string into integer and decimal parts
    let parts = numberString.split(".");

    // Format the integer part with commas
    let formattedInteger = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    // Combine the formatted integer part with the decimal part
    return formattedInteger + "." + parts[1];
  } else {
    // Format the integer part with commas
    return numberString.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  
}

const InvoiceTableFooter = ({ items, TariffRate, statementType }) => {
  const total = items
    .map((item) => item.kwhr)
    .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  return (
    <View style={styles.row}>
      <Text style={styles.description}>TOTAL</Text>
      {/* <Text style={styles.totalKwhr}>
        {Number.parseFloat(total).toFixed(2)}
      </Text> */}
      {statementType == "statement" ? (
        <Text style={{
            width: "50%",
            textAlign: "center",
            paddingRight: 8,
          }}>
          {formatNumberWithCommas(Number.parseFloat(total).toFixed(2))}
        </Text>
      ) : (
        <>
          <Text style={styles.totalKwhr}>
            {formatNumberWithCommas(formatNumberWithCommas(Number.parseFloat(total).toFixed(2)))}
          </Text>
          <Text style={styles.totalAmount}>
            {formatNumberWithCommas(Number.parseFloat(total * TariffRate).toFixed(2)) + ' $'}
          </Text>
        </>
      )}
    </View>
  );
};

export default InvoiceTableFooter;
