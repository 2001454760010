import React from "react";
import InvoiceTableHeader from "./InvoiceTableHeader";
import InvoiceTableRow from "./InvoiceTableRow";
import InvoiceTableBlankSpace from "./InvoiceTableBlankSpace";
import InvoiceTableFooter from "./InvoiceTableFooter";
import { Text, View, StyleSheet, Image } from "@react-pdf/renderer";

const tableRowsCount = 11;

const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 24,
    borderWidth: 1,
    borderColor: "#bff0fd",
  },
});

const InvoiceItemsTable = ({ invoice, statementType }) => (
  <>
    <View style={styles.tableContainer}>
      <InvoiceTableHeader statementType={statementType} />
      <InvoiceTableRow
        statementType={statementType}
        items={invoice.items}
        TariffRate={invoice.TariffRate}
      />
      {/* <InvoiceTableBlankSpace rowsCount={ tableRowsCount - invoice.items.length} /> */}
      <InvoiceTableFooter
        statementType={statementType}
        items={invoice.items}
        TariffRate={invoice.TariffRate}
      />
    </View>
    <View style={styles.row}>
      <Text style={styles.reportTitle}>
      </Text>
      <Text style={styles.reportTitle}>
      </Text>
    </View>

    <View style={styles.row}>
      <Text style={styles.reportTitle}>
        Arrêté de la présente facture la somme de :
      </Text>
      <Text style={styles.reportTitle}>
        Deux mille neuf cent dix Dirhams et quatre-vingt-seize centimes
      </Text>
    </View>
  </>
);

export default InvoiceItemsTable;
