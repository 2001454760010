import React, { useState } from "react";
import "./mystyles.css";

const DeviceInfo = ({
  deviceName,
  deviceType,
  customer,
  location,
  onApply,
}) => {
  const [inputLocation, setInputLocation] = useState(location);
  const [inputCustomer, setInputCustomer] = useState(customer);
  const [inputDeviceName, setInputDeviceName] = useState(deviceName);

  const handleApply = () => {
    onApply(inputDeviceName, inputCustomer, inputLocation);
  };

  return (
    <>
      <div className="row device-info-container">
        <div class="col-2">
          <div className="info-row">
            <div className="info-label">Device Name:</div>
          </div>
        </div>
        <div class="col-4">
          <div className="box input-field  box device-type-box">
            <input
              type="text"
              value={inputDeviceName}
              onChange={(e) => setInputDeviceName(e.target.value)}
              placeholder="Device Name"
            />
          </div>
        </div>
        <div class="col-2">
          <div className="info-label">Customer:</div>
        </div>
        <div class="col-4">
          <div className="box input-field">
            <input
              type="text"
              value={inputCustomer}
              onChange={(e) => setInputCustomer(e.target.value)}
              placeholder="Customer"
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div class="col-2">
          <div className="info-row">
            <div className="info-label">Device Type:</div>
          </div>
        </div>

        <div class="col-4">
          <div className="box device-type-box">{deviceType}</div>
        </div>
        <div class="col-2">
          <div className="info-label">Location:</div>
        </div>

        <div class="col-4">
          <div className="box input-field">
            <input
              type="text"
              value={inputLocation}
              onChange={(e) => setInputLocation(e.target.value)}
              placeholder="Location"
            />
          </div>
        </div>
      </div>
      <div class="row apply-button-col">
        <div
          class="col-12"
        >
          <div className="button-row">
            <button className="apply-button" onClick={handleApply}>
              Apply
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeviceInfo;
