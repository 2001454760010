import React, { Fragment } from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import ClientDetail from "./ClientDetail";
const styles = StyleSheet.create({
  headerContainer: {
    marginTop: 36,
  },
  billTo: {
    marginTop: 20,
    paddingBottom: 3,
    fontFamily: "Helvetica-Oblique",
  },
});

const BillTo = ({ invoice }) => (
  <Fragment>
    {/* <ClientDetail></ClientDetail> */}
    {/* <View style={styles.headerContainer}>
      <Text>Account Number:</Text>
      <Text>Customer:</Text>
      <Text>Statement Date:</Text>
      <Text>Start: Jan-2021 End Sep-2021</Text>
    </View> */}
  </Fragment>
);

export default BillTo;
