import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import UserService from "../services/user.service";
import React, { useState, useEffect, useRef } from "react";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Button from "@mui/material/Button";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
// import EditNoteIcon from '@mui/icons-material/EditNote';
import EditIcon from "@mui/icons-material/Edit";
import { Mode } from "@mui/icons-material";
export default function SharedTable(props) {
  // const { clickedDevice, acessProfiles } = props;
  // console.log(props);

  const [mode, setMode] = useState("create"); //create, edit
  const [idToUpdate, setidToUpdate] = useState(null); //create, edit
  const [rows, setRows] = useState([]);
  const [name, setName] = useState([""]);
  const [checkboxValues, setCheckboxValues] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);

  const handleCheckboxChange = (event, index) => {
    const { checked } = event.target;
    const newCheckboxValues = [...checkboxValues];
    newCheckboxValues[index] = checked;
    setCheckboxValues(newCheckboxValues);
    console.log(newCheckboxValues);
  };

  const handleChangeName = (event) => {
    setName(event.target.value);
    //Update DB with new Statement Name
    // console.log(event.target.value);
  };
  const SaveUpdateProfile = () => {
    console.log("SaveUpdateProfile ", idToUpdate);
    let payload = {
      name,
      Accumulated_Energy_Solis: checkboxValues[0],
      Accumulated_Energy_IEL: checkboxValues[1],
      Zero_Total_Power: checkboxValues[2],
      Power_Total_IEL_A: checkboxValues[3],
      Power_Load: checkboxValues[4],
      Power_load_menu: checkboxValues[5],
      Bill_menu: checkboxValues[6],
      Hourly_menu: checkboxValues[7],
      Bottom_boxes: checkboxValues[8],
      site_detail: checkboxValues[9],
      site_edit: checkboxValues[10],
      energy_type: checkboxValues[11],
      rer: checkboxValues[12],
    };
    console.log(idToUpdate, payload);
    UserService.EditAccessProfile(idToUpdate, payload)
      .then((res) => {
        console.log("GetAllAccessProfile : ", res);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const DiscaredUpdateProfile = () => {
    console.log("DiscaredUpdateProfile ");
    setMode("create");
    setCheckboxValues([
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
    ]);
    setName("");
  };

  const createProfile = () => {
    console.log([name, ...checkboxValues]);
    let payload = {
      name,
      Accumulated_Energy_Solis: checkboxValues[0],
      Accumulated_Energy_IEL: checkboxValues[1],
      Zero_Total_Power: checkboxValues[2],
      Power_Total_IEL_A: checkboxValues[3],
      Power_Load: checkboxValues[4],
      Power_load_menu: checkboxValues[5],
      Bill_menu: checkboxValues[6],
      Hourly_menu: checkboxValues[7],
      Bottom_boxes: checkboxValues[8],
      site_detail: checkboxValues[9],
      site_edit: checkboxValues[10],
      energy_type: checkboxValues[11],
      rer: checkboxValues[12],
    };
    UserService.CreateAccessProfile(payload)
      .then((res) => {
        console.log("GetAllAccessProfile : ", res);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    console.log("first load");
    UserService.GetAllAccessProfile()
      .then((res) => {
        //   console.log("GetAllAccessProfile : ", res.data.data.devices);
        console.log(res.data.data.devices);
        console.log("keys : ", Object.keys(res.data.data.devices[0]));
        setRows(res.data.data.devices);

        // setAccessProfiles(res.data.data.devices);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const deleteProfile = (id) => {
    console.log("Profile id to delete", id);

    UserService.DeleteAccessProfileById(id)
      .then((res) => {
        console.log("!!!!!!!!!!!!!!!!!!!!********** List:", res.data.data);
        // setPayload(res.data.data.deviceData[0]);
        const result = res.data.data;
        //setRows(res.data.data.deviceData[0].map(x=>{return {id:x.id,email:x.email}}));
        console.log(result);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const UpdateProfile = (id) => {
    console.log("Profile id to update", id);
    setMode("edit");
    setidToUpdate(id);
    // look for the selcted id and put it variable on the check list
    // in rows look for the is number id
    let foundItem = rows.find((item) => item.id === id);
    if (foundItem) {
      console.log("Found item:", foundItem);
      setName(foundItem.name);
      setCheckboxValues([
        foundItem.Accumulated_Energy_Solis,
        foundItem.Accumulated_Energy_IEL,
        foundItem.Zero_Total_Power,
        foundItem.Power_Total_IEL_A,
        foundItem.Power_Load,
        foundItem.Power_load_menu,
        foundItem.Bill_menu,
        foundItem.Hourly_menu,
        foundItem.Bottom_boxes,
        foundItem.site_detail,
        foundItem.site_edit,
        foundItem.energy_type,
        foundItem.rer,
      ]);

      // let payload = {
      //   name,
      //   Accumulated_Energy_Solis: checkboxValues[0],
      //   Accumulated_Energy_IEL: checkboxValues[1],
      //   Zero_Total_Power: checkboxValues[2],
      //   Power_Total_IEL_A: checkboxValues[3],
      //   Power_Load: checkboxValues[4],
      // };
      // console.log(id,payload);
      // // UserService.EditAccessProfile(id,payload)
      // //   .then((res) => {
      // //     console.log("GetAllAccessProfile : ", res);
      // //     setTimeout(() => {
      // //       window.location.reload();
      // //     }, 2000);
      // //   })
      // //   .catch((error) => {
      // //     console.log(error);
      // //   });
    } else {
      console.log("Item not found.");
    }
  };

  // useEffect(() => {
  //   geList(clickedDevice);
  // }, []);

  // useEffect(() => {
  //   geList(clickedDevice);
  // }, [clickedDevice]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <h2>Create Profile</h2>
          <Grid container spacing={2}>
            <Grid item xs={12}></Grid>
            <Grid item xs={12}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <TextField
                      required
                      id="outlined-required"
                      label="Profile Name"
                      defaultValue="Profile Name"
                      value={name}
                      onChange={handleChangeName}
                    />
                  }
                />{" "}
                <br></br>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkboxValues[0] || false}
                      onChange={(e) => handleCheckboxChange(e, 0)}
                    />
                  }
                  label="Accumulated_Energy_Solis"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkboxValues[1] || false}
                      onChange={(e) => handleCheckboxChange(e, 1)}
                    />
                  }
                  label="Accumulated_Energy_IEL"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkboxValues[2] || false}
                      onChange={(e) => handleCheckboxChange(e, 2)}
                    />
                  }
                  label="Zero_Total_Power"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkboxValues[3] || false}
                      onChange={(e) => handleCheckboxChange(e, 3)}
                    />
                  }
                  label="Power_Total_IEL_A"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkboxValues[4] || false}
                      onChange={(e) => handleCheckboxChange(e, 4)}
                    />
                  }
                  label="Power_Load"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkboxValues[5] || false}
                      onChange={(e) => handleCheckboxChange(e, 5)}
                    />
                  }
                  label="Power_load_menu"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkboxValues[6] || false}
                      onChange={(e) => handleCheckboxChange(e, 6)}
                    />
                  }
                  label="Bill_menu"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkboxValues[7] || false}
                      onChange={(e) => handleCheckboxChange(e, 7)}
                    />
                  }
                  label="Hourly_menu"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkboxValues[8] || false}
                      onChange={(e) => handleCheckboxChange(e, 8)}
                    />
                  }
                  label="Bottom_boxes"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkboxValues[9] || false}
                      onChange={(e) => handleCheckboxChange(e, 9)}
                    />
                  }
                  label="site_detail"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkboxValues[10] || false}
                      onChange={(e) => handleCheckboxChange(e, 10)}
                    />
                  }
                  label="site_edit"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkboxValues[11] || false}
                      onChange={(e) => handleCheckboxChange(e, 11)}
                    />
                  }
                  label="energy_type"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkboxValues[12] || false}
                      onChange={(e) => handleCheckboxChange(e, 12)}
                    />
                  }
                  label="rer"
                />
              </FormGroup>
              {mode == "create" ? (
                <Button variant="contained" onClick={createProfile}>
                  Create
                </Button>
              ) : (
                <>
                  <Button
                    variant="contained"
                    onClick={SaveUpdateProfile}
                    sx={{ background: "green" }}
                  >
                    Save
                  </Button>
                  <Button
                    variant="contained"
                    onClick={DiscaredUpdateProfile}
                    sx={{ background: "red" }}
                  >
                    Discard
                  </Button>
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={8}>
          <h2>Profiles</h2>
          <TableContainer component={Paper}>
            <Table
            // sx={{ minWidth: 650 }}
            >
              <TableHead>
                <TableRow>
                  {/* <TableCell align="left">{"id"}</TableCell> */}
                  <TableCell align="left">Action</TableCell>
                  <TableCell align="left">{"name"}</TableCell>
                  <TableCell align="left">
                    {"Accumulated_Energy_Solis"}
                  </TableCell>
                  <TableCell align="left">{"Accumulated_Energy_IEL"}</TableCell>
                  <TableCell align="left">{"Zero_Total_Power"}</TableCell>
                  <TableCell align="left">{"Power_Total_IEL_A"}</TableCell>
                  <TableCell align="left">{"Power_Load"}</TableCell>
                  <TableCell align="left">{"Power_load_menu"}</TableCell>
                  <TableCell align="left">{"Bill_menu"}</TableCell>
                  <TableCell align="left">{"Hourly_menu"}</TableCell>
                  <TableCell align="left">{"Bottom_boxes"}</TableCell>
                  <TableCell align="left">{"site_detail"}</TableCell>
                  <TableCell align="left">{"site_edit"}</TableCell>
                  <TableCell align="left">{"energy_type"}</TableCell>
                  <TableCell align="left">{"rer"}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, j) => (
                  <TableRow key={row.id}>
                    {/* <TableCell align="left">{row.id}</TableCell> */}
                    <TableCell align="left">
                      <IconButton
                        sx={{ color: "green" }}
                        onClick={() => {
                          // alert(row.id);
                          if (
                            window.confirm(
                              "Are you sure you want to update this Profile ?"
                            )
                          ) {
                            // Save it!
                            UpdateProfile(row.id);
                            console.log(row.id);
                          } else {
                            // Do nothing!
                            // console.log('Thing was not saved to the database.');
                          }
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        sx={{ color: "red" }}
                        onClick={() => {
                          // alert(row.id);
                          if (
                            window.confirm(
                              "Are you sure you want to delete this Profile ?"
                            )
                          ) {
                            // Save it!
                            deleteProfile(row.id);
                            console.log(row.id);
                          } else {
                            // Do nothing!
                            // console.log('Thing was not saved to the database.');
                          }
                        }}
                      >
                        <RemoveCircleOutlineIcon />
                      </IconButton>
                    </TableCell>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>
                      <Checkbox
                        disabled
                        checked={row.Accumulated_Energy_Solis}
                      />
                    </TableCell>
                    <TableCell>
                      <Checkbox disabled checked={row.Accumulated_Energy_IEL} />
                    </TableCell>
                    <TableCell>
                      <Checkbox disabled checked={row.Zero_Total_Power} />
                    </TableCell>
                    <TableCell>
                      <Checkbox disabled checked={row.Power_Total_IEL_A} />
                    </TableCell>
                    <TableCell>
                      <Checkbox disabled checked={row.Power_Load} />
                    </TableCell>
                    <TableCell>
                      <Checkbox disabled checked={row.Power_load_menu} />
                    </TableCell>
                    <TableCell>
                      <Checkbox disabled checked={row.Bill_menu} />
                    </TableCell>
                    <TableCell>
                      <Checkbox disabled checked={row.Hourly_menu} />
                    </TableCell>
                    <TableCell>
                      <Checkbox disabled checked={row.Bottom_boxes} />
                    </TableCell>
                    <TableCell>
                      <Checkbox disabled checked={row.site_detail} />
                    </TableCell>
                    <TableCell>
                      <Checkbox disabled checked={row.site_edit} />
                    </TableCell>
                    <TableCell>
                      <Checkbox disabled checked={row.energy_type} />
                    </TableCell>
                    <TableCell>
                      <Checkbox disabled checked={row.rer} />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </>
  );
}

// const geList = () => {
//   setRows([]);
//   UserService.GetSharedWithUserList(clickedDevice)
//     .then((res) => {
//       console.log("!!!!!!!!!!!!!!!!!!!!********** List:", res.data.data);
//       // setPayload(res.data.data.deviceData[0]);
//       // const result = res.data.data.deviceData[0];
//       if (res.data.data.deviceData[0])
//         setRows(
//           res.data.data.deviceData[0].map((x) => {
//             // return { id: x.id, email: x.email,profile:x.profile };
//             // console.log(
//             //   acessProfiles.find((obj) => obj.id === x.profile).name
//             // );
//             return {
//               id: x.id,
//               email: x.email,
//               profile: acessProfiles.find((obj) => obj.id === x.profile).name,
//             };
//           })
//         );
//       // console.log(res.data.data.deviceData[0].map(x=>{return {id:x.id,email:x.email}}));
//     })
//     .catch((err) => {
//       console.log(err);
//     });
// };
