import React from "react";
import { Card, CardContent, Grid, Typography } from "@mui/material";
import Skeleton from "react-loading-skeleton";
import { useState, useEffect } from "react";
// import UserService from "../services/user.service";
import { FreeBreakfast } from "@mui/icons-material";
import WarningIcon from "@mui/icons-material/Warning";
import { color } from "highcharts";
import GppGoodIcon from "@mui/icons-material/GppGood";
import InfoIcon from "@mui/icons-material/Info";
import IconButton from "@mui/material/IconButton";

const DeviceStats = React.memo((props) => {
  const {
    virtual,
    StaticTxtValue1,
    StaticTxtValue2,
    StaticTxtValue3,
    StaticTxtValue4,
    StaticTxtValue5,
    StaticTxtValue6,
    StaticValue1,
    StaticValue2,
    StaticValue3,
    StaticValue4,
    StaticValue5,
    StaticValue6,
    StaticValue7,
    StaticUnit1,
    StaticUnit2,
    StaticUnit3,
    StaticUnit4,
    StaticUnit5,
    StaticUnit6,
    StaticSize1,
    StaticSize2,
    StaticSize3,
    StaticSize4,
    StaticSize5,
    StaticSize6,
    isDeviceID,
    // isGraphStatsLoading,
    sendDataToParent,
  } = props;
  // console.log(props);

  const [isGraphStatsLoading, setisGraphStatsLoading] = useState(false);

  // const labeslFontSize = 17;
  // const dataFontSize = labeslFontSize;
  // const unitFontSize = labeslFontSize + 3;

  const labeslFontSize = 18;
  const dataFontSize = labeslFontSize + 16;
  const unitFontSize = labeslFontSize + 3;

  function Box_2test(props) {
    // console.log(props);
    const { param } = props;
    return (
      <Grid
        spacing={1}
        item
        xs={12}
        sm={12}
        md={param[3]}
        // sx={{ height: "320px" }}
      >
        <Card
          className=""
          // sx={{ height: "50px" }}
          // sx={{ height: "50px",":hover": {boxShadow: 5}, boxShadow: 2}}
          sx={{
            height: "55px",
            "border-bottom": `solid 5px #64006C`,
            boxShadow: 2,
            ":hover": { boxShadow: 5 },
          }}

          // sx={{
          //   //  "border-radius": "4% ",

          //   maxWidth: 345,
          //   // maxHeight: " 100%",
          //   //  Height: "320px",
          //   boxShadow: 2,
          //   margin: "0 5px 10px",
          //   ":hover": {
          //     boxShadow: 5,
          //   },
          //   "border-bottom": `solid 5px #64006C`,
          // }}
        >
          <CardContent
            style={{
              textAlign: "center",
              fontWeight: "bold",
              "padding-left": 5,
              "padding-right": 5,
              fontSize: dataFontSize,
            }}
          >
            <Typography
              variant="h5"
              component="div"
              style={{
                textAlign: "center",
                fontWeight: "bold",
                padding: 0,
                fontSize: 20,
              }}
            >
              {isGraphStatsLoading ? (
                <Skeleton height={15} width={100} />
              ) : (
                <span>
                  <span>
                    {!param[4] ? (
                      <span>{param[1] + " " + param[2]}</span>
                    ) : (
                      <>
                        {!param[2] ? (
                          <WarningIcon
                            style={{ color: "green", "font-size": 50 }}
                          ></WarningIcon>
                        ) : (
                          <WarningIcon
                            style={{ color: "red", "font-size": 50 }}
                          ></WarningIcon>
                        )}
                      </>

                      //
                    )}
                  </span>
                </span>
              )}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    );
  }

  function Box_2alarm(props) {
    // console.log("alarm", props);
    const { param } = props;
    return (
      <Grid
        spacing={1}
        item
        xs={12}
        sm={12}
        md={param[3]}
        // sx={{ height: "320px" }}
      >
        <div
          className=""
          // sx={{ height: "50px" }}
          // sx={{ height: "50px",":hover": {boxShadow: 5}, boxShadow: 2}}
          style={{
            height: "56px",
            // "border-bottom": `solid 5px #64006C`,
            boxShadow: 2,
            ":hover": { boxShadow: 5 },
          }}

          // sx={{
          //   //  "border-radius": "4% ",

          //   maxWidth: 345,
          //   // maxHeight: " 100%",
          //   //  Height: "320px",
          //   boxShadow: 2,
          //   margin: "0 5px 10px",
          //   ":hover": {
          //     boxShadow: 5,
          //   },
          //   "border-bottom": `solid 5px #64006C`,
          // }}
        >
          <CardContent
            style={{
              textAlign: "center",
              fontWeight: "bold",
              "padding-left": 5,
              "padding-right": 5,
              fontSize: dataFontSize,
            }}
          >
            <Typography
              variant="h5"
              component="div"
              style={{
                textAlign: "center",
                fontWeight: "bold",
                padding: 0,
                fontSize: dataFontSize,
              }}
            >
              {isGraphStatsLoading ? (
                <Skeleton height={15} width={100} />
              ) : (
                <>
                  {!param[4] ? (
                    <span>{param[1] + " " + param[2]}</span>
                  ) : (
                    <span>
                      {/* Alarm */}

                      {param[1] == 2 ? (
                        // <WarningIcon sx={{ color: "green",transform: "scale(1.8)" }}></WarningIcon>
                        <IconButton aria-label="delete">
                        
                        <GppGoodIcon
                          sx={{ color: "green", transform: "scale(1.8)", 
                            marginRight: "20px", }}
                          onClick={() => {
                            sendDataToParent("alarm");
                          }}
                        />
                        </IconButton>

                      ) : (
                        <IconButton aria-label="delete">
                        <WarningIcon
                          sx={{ color: "red", transform: "scale(1.8)" }}
                          onClick={() => {
                            sendDataToParent("alarm");
                          }}
                        ></WarningIcon>
                      </IconButton>
                      )}
                      <IconButton aria-label="delete">
                        <InfoIcon
                          style={{
                            color: "blue",
                            "font-size": 40,
                            marginLeft: "20px",
                          //  marginRight: "20px",
                          }}
                          onClick={() => {
                            sendDataToParent("site");
                          }}
                        ></InfoIcon>
                      </IconButton>
                    </span>

                    //
                  )}
                </>
              )}
            </Typography>
          </CardContent>
        </div>
      </Grid>
    );
  }

  function Box(props) {
    // console.log(props);
    const { param } = props;
    return (
      <Grid
        spacing={1}
        item
        xs={12}
        sm={6}
        md={param[3]}
        // sx={{ height: "320px" }}
      >
        <Card
          className=""
          // sx={{ height: "110px",paddingTop:"20px",":hover": {boxShadow: 5}, boxShadow: 2}}
          sx={{
            height: "120px",
            paddingTop: "0px",
            "border-bottom": `solid 5px #64006C`,
            boxShadow: 2,
            ":hover": { boxShadow: 5 },
          }}
          // sx={{
          //   //  "border-radius": "4% ",

          //   maxWidth: 345,
          //   // maxHeight: " 100%",
          //   //  Height: "320px",
          //   boxShadow: 2,
          // margin: "0 5px 10px",
          // ":hover": {
          //   boxShadow: 5,
          // },
          // "border-bottom": `solid 5px #64006C`,
          // }}
        >
          <CardContent
            style={{
              textAlign: "center",
              fontWeight: "bold",
              "padding-left": 5,
              "padding-right": 5,
              fontSize: dataFontSize,
            }}
          >
            <Typography
              variant="h5"
              component="div"
              style={{
                textAlign: "center",
                fontWeight: "bold",
                fontSize: labeslFontSize,
              }}
            >
              {param[0]}
            </Typography>
            <Typography
              variant="h5"
              component="div"
              style={{
                textAlign: "center",
                fontWeight: "bold",
                padding: 0,
                fontSize: dataFontSize,
              }}
            >
              {isGraphStatsLoading ? (
                <Skeleton height={15} width={100} />
              ) : (
                <span>
                  <span>
                    <span>{param[1]}</span>
                  </span>
                </span>
              )}
            </Typography>
            <Typography
              variant="h5"
              component="div"
              style={{
                textAlign: "right",
                fontWeight: "bold",
                padding: 0,
                fontSize: unitFontSize,
                margin: 0,
                "padding-top": "-10%",
              }}
            >
              {isGraphStatsLoading ? (
                <Skeleton height={15} width={100} />
              ) : (
                <span>{param[2]}</span>
              )}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    );
  }

  return (
    <Grid
      container
      spacing={1}
      direction="row"
      justify="flex-start"
      alignItems="flex-start"
      sx={{ marginBottom: "30px" }}
    >
      <Box param={[StaticTxtValue1, StaticValue1, StaticUnit1, StaticSize1]} />
      <Box param={[StaticTxtValue2, StaticValue2, StaticUnit2, StaticSize2]} />
      <Box param={[StaticTxtValue3, StaticValue3, StaticUnit3, StaticSize3]} />
      <Box param={[StaticTxtValue4, StaticValue4, StaticUnit4, StaticSize4]} />
      {virtual ? (
        <Box
          param={[StaticTxtValue5, StaticValue5, StaticUnit4, StaticSize5]}
        />
      ) : (
        <Grid
          spacing={1}
          item
          md={2}
          // sx={{ height: "320px" }}
        >
          <Grid
            container
            spacing={1}
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
          >
            {/* <div           style={{
            height: "40px"}}>
            {!StaticValue7 ? (
              <WarningIcon sx={{ color: "green" }}></WarningIcon>
            ) : (
              <WarningIcon sx={{ color: "red" }}></WarningIcon>
            )}
          </div> */}

            <Box_2alarm param={["Alarm", StaticValue7, StaticUnit6, 12, 1]} />
            <Box_2test
              param={[StaticTxtValue6, StaticValue6, StaticUnit6, 12]}
            />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
});

const PhaseBox = (props) => {
  const { isGraphStatsLoading, voltage, current, power, color } = props;
  // console.log(isGraphStatsLoading, voltage, current, power, color);
  // console.log(voltage);
  const labeslFontSize = 8;
  const dataFontSize = 18;
  const unitFontSize = 16;
  return (
    <Grid spacing={1} item xs={12} sm={6} md={4}>
      {" "}
      <Grid
        container
        spacing={1}
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
      >
        <Grid spacing={1} item xs={12} sm={6} md={4}>
          <Card
            className=""
            sx={{
              // maxWidth: 345,
              boxShadow: 2,
              margin: "0 5px 10px",
              ":hover": {
                boxShadow: 5,
              },

              "border-top": `solid 3px ${color}`,
            }}
          >
            <CardContent>
              <Typography
                variant="h5"
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  "padding-left": 5,
                  "padding-right": 5,
                  fontSize: unitFontSize - 2,
                }}
              >
                Voltage
              </Typography>
              <Typography
                variant="h5"
                component="div"
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  padding: 0,
                  fontSize: dataFontSize,
                }}
              >
                {isGraphStatsLoading ? (
                  <Skeleton height={15} width={100} />
                ) : (
                  <span>
                    <span>
                      <span>{parseFloat(voltage).toFixed(2)}</span>
                    </span>
                  </span>
                )}
              </Typography>

              <Typography
                variant="h5"
                component="div"
                style={{
                  textAlign: "right",
                  fontWeight: "bold",
                  padding: 0,
                  fontSize: unitFontSize,
                  margin: 0,
                  "padding-top": "-10%",
                }}
              >
                {isGraphStatsLoading ? (
                  <Skeleton height={15} width={100} />
                ) : (
                  <span>V</span>
                )}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid spacing={1} item xs={12} sm={6} md={4}>
          <Card
            className=""
            sx={{
              // maxWidth: 345,
              boxShadow: 2,
              margin: "0 5px 10px",
              ":hover": {
                boxShadow: 5,
              },
              "border-top": `solid 3px ${color}`,
            }}
          >
            <CardContent>
              <Typography
                variant="h5"
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  "padding-left": 5,
                  "padding-right": 5,
                  fontSize: unitFontSize - 2,
                }}
              >
                Current
              </Typography>
              <Typography
                variant="h5"
                component="div"
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  padding: 0,
                  fontSize: dataFontSize,
                }}
              >
                {isGraphStatsLoading ? (
                  <Skeleton height={15} width={100} />
                ) : (
                  <span>
                    <span>{current}</span>
                  </span>
                )}
              </Typography>
              <Typography
                variant="h5"
                component="div"
                style={{
                  textAlign: "right",
                  fontWeight: "bold",
                  padding: 0,
                  fontSize: unitFontSize,
                }}
              >
                {isGraphStatsLoading ? (
                  <Skeleton height={15} width={100} />
                ) : (
                  <span>A</span>
                )}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid spacing={1} item xs={12} sm={6} md={4}>
          <Card
            className=""
            sx={{
              // maxWidth: 345,
              boxShadow: 2,
              margin: "0 5px 10px",
              ":hover": {
                boxShadow: 5,
              },
              "border-top": `solid 3px ${color}`,
            }}
          >
            <CardContent>
              <Typography
                gutterBottom
                variant="h5"
                component="div"
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  margin: 0,
                  fontSize: unitFontSize - 2,
                }}
              >
                Power
              </Typography>
              <Typography
                variant="h5"
                component="div"
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  padding: 0,
                  fontSize: dataFontSize,
                }}
              >
                {isGraphStatsLoading ? (
                  <Skeleton height={15} width={100} />
                ) : (
                  <span>
                    <span>{parseFloat(power).toFixed(2)}</span>
                  </span>
                )}
              </Typography>
              <Typography
                variant="h5"
                component="div"
                style={{
                  textAlign: "right",
                  fontWeight: "bold",
                  padding: 0,
                  fontSize: unitFontSize,
                }}
              >
                {isGraphStatsLoading ? (
                  <Skeleton height={15} width={100} />
                ) : (
                  <span>KW</span>
                )}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DeviceStats;
