import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const borderColor = "#90e5fc";
const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    borderBottomColor: "#bff0fd",
    backgroundColor: "#bff0fd",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 24,
    textAlign: "center",
    fontStyle: "bold",
    flexGrow: 1,
  },
  from: {
    width: "25%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
  to: {
    width: "25%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
  unit: {
    width: "15%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
  price: {
    width: "15%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
  kwhr: {
    width: "25%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
  amount: {
    width: "25%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
});

const InvoiceTableHeader = ({ statementType }) => (
  <View style={styles.container}>
    <Text style={styles.from}>Désignation</Text>
    {/* <Text style={styles.to}>To</Text> */}
    {/* <Text style={styles.kwhr}>KWhr</Text>


    {statementType == "statement" ? null : (
      <Text style={styles.amount}>Amount</Text>
    )} */}

    {statementType == "statement" ? (
      <Text
        style={{
          width: "50%",
          borderRightColor: borderColor,
          borderRightWidth: 1,
        }}
      >
        KWhr
      </Text>
    ) : (
      <>
        <Text style={styles.unit}>Unité</Text>
        <Text style={styles.kwhr}>Quantité</Text>
        <Text style={styles.price}>Prix HT</Text>
        <Text style={styles.amount}>Montant HT</Text>
      </>
    )}
  </View>
);

export default InvoiceTableHeader;
