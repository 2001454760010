import React, { Fragment } from "react";
import { Text, View, StyleSheet, Image } from "@react-pdf/renderer";
import moment from "moment-timezone";
import logo from "./logo.png";

const borderColor = "#90e5fc";
const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    alignItems: "center",
    height: 24,
    fontStyle: "bold",
  },
  description: {
    width: "15%",
    textAlign: "left",
    // paddingLeft: 8,
    fontWeight: "normal",
  },
  qty: {
    width: "25%",
    textAlign: "left",
    // paddingRight: 8,
  },
  rate: {
    width: "25%",
    textAlign: "left",
    // paddingRight: 8,
    fontWeight: "extrabold",
  },
  amount: {
    width: "25%",
    textAlign: "left",
    // paddingRight: 8,
    fontWeight: "extrabold",
  },
  start: {
    width: "8%",
    textAlign: "left",
    // paddingRight: 8,
  },
  end: {
    width: "17%",
    textAlign: "left",
    // paddingRight: 8,
  },
  // reportTitle: {
  //   width: "50%",
  //   textAlign: "left",
  //   // paddingRight: 8,
  // },
  logo: {
    width: 180,
    height: 39,
    // marginLeft: "auto",
    // marginRight: "auto",
    // maxWidth: "100%",
    // maxHeight: "100%",
    // width: "50%",
    alignItems: "right",
    alignContent: "right",
  },
  reportTitle: {
    // color: '#61dafb',
    // letterSpacing: 4,
    fontSize: 15,
    textAlign: "left",
    textTransform: "uppercase",
    width: "100%",
    background: "red",
    borderRadius: "50%",
    // backgroundColor: '#61dafb',
    // backgroundColor :"blue"
    borderBottom: "solid 1px",
  },
  tableContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 24,
    borderWidth: 1,
    borderColor: "#bff0fd",
  },
  row2: {
    flexDirection: "row",
    // borderBottomColor: "#bff0fd",
    // borderBottomWidth: 1,
    alignItems: "center",
    height: 24,
    fontStyle: "bold",
  },
});

const ClientDetail = ({ invoice, statementType }) => {
  return (
    <Fragment>
      <View style={styles.row}>
        <Text style={styles.reportTitle}>TELECOM SOLAR MOROCCO 1 Sarl</Text>

        {/* <Text
          style={{
            fontSize: 15,
            textAlign: "left",
            textTransform: "uppercase",
            width: "10%",
          }}
        >
          {" "}
        </Text>

        <Image style={styles.logo} src={logo} /> */}
      </View>

      <View style={styles.row}>
        <Text
          style={{
            width: "80%",
            textAlign: "left",
            // paddingRight: 8,
            fontWeight: "extrabold",
          }}
        ></Text>
        <Text
          style={{
            width: "20%",
            textAlign: "left",
            // paddingRight: 8,
            fontWeight: "extrabold",
          }}
        >
          Le: {moment().format("DD-MMM-YYYY")}
        </Text>
      </View>

      <View style={styles.row}>
        <Text style={styles.reportTitle}> </Text>
        <Text style={styles.reportTitle}> </Text>
        <Text style={styles.reportTitle}> </Text>
      </View>

      <View style={styles.row}>
        <Text style={styles.reportTitle}></Text>
        {/* <Image style={styles.logo} src={logo} /> */}
      </View>
      <View style={styles.row}>
        <Text style={styles.description}>Facture N° : </Text>
        <Text style={styles.qty}>{invoice.devicename}</Text>
        <Text style={styles.rate}> </Text>
        <Text style={styles.amount}></Text>
      </View>

      <View style={styles.row}>
        <Text style={styles.description}>Référence : </Text>
        <Text style={styles.qty}>SAAS Project</Text>

        {/* <Text
          style={{
            flexDirection: "row",
            // borderBottomColor: "#bff0fd",
            // backgroundColor: "#bff0fd",
            borderWidth: 1,
            alignItems: "center",
            // height: 24,
            textAlign: "center",
            fontStyle: "bold",
            flexGrow: 1,
            width: "50%",
            // height: "80px",
          }}
        >
          Orange Sa LOT LA COLLINE IMM. LES QUATRE TEMPS S. MAAROUF CASABLANCA
          -Maroc 001524628000001
        </Text> */}
        <View
          style={{
            flexDirection: "row",
            flexWrap: "wrap",
            marginTop: 24,
            borderWidth: 2,
            borderColor: "black",
            height: "100px",
            width: "60%",
            textAlign: "left",
          }}
        >
          <View style={styles.row2}>
            <Text
              style={{
                width: "100%",
                textAlign: "center",
                paddingRight: 8,
              }}
            >
              Orange Sa
            </Text>
          </View>
          <View style={styles.row2}>
            <Text
              style={{
                width: "100%",
                textAlign: "center",
                paddingRight: 8,
              }}
            >
              LOT LA COLLINE IMM. LES QUATRE TEMPS
            </Text>
          </View>
          <View style={styles.row2}>
            <Text
              style={{
                width: "100%",
                textAlign: "center",
                paddingRight: 8,
              }}
            >
              S. MAAROUF CASABLANCA – Maroc
            </Text>
          </View>
          <View style={styles.row3}>
            <Text
              style={{
                width: "100%",
                textAlign: "center",
                paddingRight: 8,
              }}
            >
              001524628000001
            </Text>
          </View>
        </View>
      </View>
      <View style={styles.row}>
        <Text style={styles.description}></Text>
        <Text style={styles.qty}></Text>
        <Text style={styles.rate}> </Text>
        <Text style={styles.amount}></Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.description}></Text>
        <Text style={styles.qty}></Text>
        <Text style={styles.rate}> </Text>
        <Text style={styles.amount}></Text>
      </View>
      {/* {statementType == "statement" ? null : (
        <View style={styles.row}>
          {" "}
          <Text style={styles.description}>Tarrif Rate:</Text>
          <Text style={styles.qty}>{invoice.TariffRate} $ </Text>
        </View>
      )} */}
    </Fragment>
  );
};

export default ClientDetail;
