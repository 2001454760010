import React from "react";
import TitleBox from "./TitleBox";
import Box from "./Box";
import { BorderLeft, BorderRight, Height } from "@mui/icons-material";
import { Typography } from "@mui/material";

const styles = {
  boxSetContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center", // Center horizontally
    // width: "95%", // Adjusted width to 100%
    // maxWidth: "500px", // Added max-width to limit the width
    borderRadius: "10px",
    padding: "10px",
    marginBottom: "10px",
    backgroundColor: "#C4D9EE", // white with 80% opacity
    margin: "4px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center", // Center horizontally
    // width: "95%", // Adjusted width to 100%
    // maxWidth: "500px", // Added max-width to limit the width
    minWidth: "250px", // Added max-width to limit the width
    borderRadius: "10px",
    padding: "10px",
    marginBottom: "10px",
    backgroundColor: "#C4D9EE", // white with 80% opacity
    "margin-top": "4px",
    "margin-bottom": "4px",
    "margin-left": "4px",
    "margin-right": "4px",
  },
  contentContainer: {
    width: "100%",
  },
  boxRow: {
    display: "flex",
    alignItems: "center", // Center vertically
    width: "100%",
    //marginBottom: "30px",
    justifyContent: "center", // Center horizontally
  },
  borderedRow: {
    display: "flex",
    alignItems: "center", // Center vertically
    width: "100%",
    margin: "4px",
    border: "2px solid white", // Border around the row
    borderRadius: "5px", // Border radius
    // padding: "0px", // Padding inside the border
    justifyContent: "center",
    height: "30px",
    textAlign: "left",
    backgroundColor: "#f3fffb",
  },
  borderedRowWithout: {
    display: "flex",
    // alignItems: "bottom", // Center vertically
    width: "100%",
    margin: "4px",
    // border: "2px solid white", // Border around the row
    borderRadius: "5px", // Border radius
    // padding: "0px", // Padding inside the border
    justifyContent: "center",
    textAlign: "center",
    alignItems: "center", // Center vertically

    height: "30px",
    backgroundColor: "#f3fffb",
  },
  staticText: {
    fontWeight: "bold",
    width: "150px", // Adjusted width of static text
    textAlign: "center", // Align static text to the center
    fontSize: "14px", // Decreased font size for the keys.
    color: "black",
    lineHeight: "1",

    // marginRight: "5px", // Added margin to create space between text and box
    //marginBottom: "10px",
  },
  staticTextHeader: {
    fontWeight: "bold",
    width: "150px", // Adjusted width of static text
    textAlign: "center", // Align static text to the center
    fontSize: "14px", // Decreased font size for the keys.
    color: "black",
    width: "100%",
    "border-right": "solid 3px white",
    "border-left": "solid 3px white",
    /* text-align: center; */
    /* padding-left: 1%; */
    // "textAlign": 'center',
    // "verticalAlign": 'middle',
    // paddingLeft :"1%"
    // marginRight: "5px", // Added margin to create space between text and box
    //marginBottom: "10px",
  },
  cellStyle: {
    BorderLeft: "3px solid white",
    BorderRight: "3px solid white",

    // "border-right": "solid 3px white",
    // "border-left": "solid 3px white",
    // "border-left": "solid 3px white",
    border: "solid 3px white",
    /* text-align: center; */
    /* padding-left: 1%; */
    // "textAlign": 'center',
    // "verticalAlign": 'middle',
    paddingLeft: "1%",

    // padding: "8px",
  },
  headerrow: {
    display: "flex",
    "justify-content": "center",
    "align-items": "center",
    "padding-left": "0px",
    "padding-right": "0px",
  },
  headerrow: {
    display: "flex",
    "justify-content": "center",
    "align-items": "center",
    "padding-left": "0px",
    "padding-right": "0px",
  },
};

const BoxSet = ({ title, values }) => {
  //console.log(values);
  const state_1 = values[0][0].dynamic== 25 ? 1 : 0;
  const state_2 = values[1][0].dynamic== 25 ? 1 : 0;
  const state_3 = values[2][0].dynamic== 25 ? 1 : 0;
  const state_4 = values[3][0].dynamic== 25 ? 1 : 0;
  const State = [state_1, state_2, state_3, state_4]
 // console.log(State);
  // values = values.COMP_1;
  return (
    // <div class="col-3">
    <div style={styles.boxSetContainer}>
      {/* <table style={{ width: "100%" }}>
        <tr>
          <th>
            <span style={styles.staticText}></span>
          </th>
          <td style={styles.cellStyle}>
            <Box name={"Line(1).LP_Count"} dynamic={1} />
          </td>
          <td style={styles.cellStyle}>
            <Box name={"Line(1).LP_Count"} dynamic={2} />
          </td>{" "}
          <td style={styles.cellStyle}>
            <Box name={"Line(1).LP_Count"} dynamic={3} />
          </td>{" "}
          <td style={styles.cellStyle}>
            <Box name={"Line(1).LP_Count"} dynamic={4} />
          </td>
        </tr>

        {values.map(({ name, dynamic }) => (
          <>
            <tr>
              <td>
                <span style={styles.staticText}>{name}</span>
              </td>
              <td style={styles.cellStyle}>
                <Box name={name} dynamic={dynamic} />
              </td>
              <td style={styles.cellStyle}>
                <Box name={name} dynamic={dynamic} />
              </td>
              <td style={styles.cellStyle}>
                <Box name={name} dynamic={dynamic} />
              </td>
              <td style={styles.cellStyle}>
                <Box name={name} dynamic={dynamic} />
              </td>
            </tr>
          </>
        ))}
      </table> */}

      <div
        class="row"
        style={{
          "background-color": "rgb(243, 255, 251)",
          width: "102%",
          "border-radius": "5px",
          "margin-bottom": "4px",
        }}
      >
        {/* <div class="col-3">
          <span style={styles.staticText}>Compressor</span>
        </div>

        <div class="col-2" style={styles.headerrow}>
          <span style={styles.staticText}>1</span>
        </div>
        <div class="col-2" style={styles.headerrow}>
          <span style={styles.staticText}>2</span>
        </div>
        <div class="col-2" style={styles.headerrow}>
          <span style={styles.staticText}>3</span>
        </div>
        <div class="col-2" style={styles.headerrow}>
          <span style={styles.staticText}>4</span>
        </div> */}
        <div class="col-3" style={styles.borderedRow}>
          {/* <Box name={"Line(1).CM_Hours"} dynamic={"compressors"} /> */}
          <span style={styles.staticText}>{"compressors"}</span>
        </div>
        <div class="col-2" style={styles.borderedRow}>
          <span style={styles.staticText}>{"1"}</span>
        </div>
        <div class="col-2" style={styles.borderedRow}>
          <span style={styles.staticText}>{"2"}</span>
        </div>
        <div class="col-2" style={styles.borderedRow}>
          <span style={styles.staticText}>{"3"}</span>
        </div>
        <div class="col-2" style={styles.borderedRow}>
          <span style={styles.staticText}>{"4"}</span>
        </div>
      </div>
      <div class="row">
        {values[0].map((v, i) => (
          <>
            {/* <div key={values[0][i].name} style={styles.borderedRow}> */}
            <div class="col-3" style={styles.borderedRow}>
              <span style={styles.staticText}>{values[0][i].names}</span>
            </div>
            <div class="col-2" style={styles.borderedRowWithout}>
              <Box name={values[0][i].name} isDisabled={State[0]} dynamic={values[0][i].dynamic} />
            </div>{" "}
            <div class="col-2" style={styles.borderedRowWithout}>
              <Box name={values[0][i].name} isDisabled={State[1]} dynamic={values[1][i].dynamic} />
            </div>{" "}
            <div class="col-2" style={styles.borderedRowWithout}>
              <Box name={values[0][i].name} isDisabled={State[2]} dynamic={values[2][i].dynamic} />
            </div>
            <div class="col-2" style={styles.borderedRowWithout}>
              <Box name={values[0][i].name} isDisabled={State[3]} dynamic={values[3][i].dynamic} />
            </div>
            {/* </div> */}
          </>
        ))}
      </div>
    </div>
  );
};

export default BoxSet;
