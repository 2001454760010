import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import IconButton from "@mui/material/IconButton";
import UserService from "../services/user.service";
import React, { useState, useEffect, useRef } from "react";
import EditIcon from "@mui/icons-material/Edit";

export default function SharedTable(props) {
  const { clickedDevice, acessProfiles } = props;
  console.log(props);

  const [rows, setRows] = useState([]);

  const geList = (deviceId) => {
    setRows([]);
    UserService.GetSharedWithUserList(clickedDevice)
      .then((res) => {
        console.log("!!!!!!!!!!!!!!!!!!!!********** List:", res.data.data);
        // setPayload(res.data.data.deviceData[0]);
        // const result = res.data.data.deviceData[0];
        if (res.data.data.deviceData[0])
          setRows(
            res.data.data.deviceData[0].map((x) => {
              // return { id: x.id, email: x.email,profile:x.profile };
              // console.log(
              //   acessProfiles.find((obj) => obj.id === x.profile).name
              // );
              return {
                id: x.id,
                email: x.email,
                profile: acessProfiles.find((obj) => obj.id === x.profile).name,
              };
            })
          );
        // console.log(res.data.data.deviceData[0].map(x=>{return {id:x.id,email:x.email}}));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const unshareDevice = (id) => {
    console.log("device to stop share", id);
    UserService.unsahreDevice(id)
      .then((res) => {
        console.log("!!!!!!!!!!!!!!!!!!!!********** List:", res.data.data);
        // setPayload(res.data.data.deviceData[0]);
        const result = res.data.data;
        //setRows(res.data.data.deviceData[0].map(x=>{return {id:x.id,email:x.email}}));
        console.log(result);
        geList();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    geList(clickedDevice);
  }, []);

  useEffect(() => {
    geList(clickedDevice);
  }, [clickedDevice]);

  return (
    <TableContainer component={Paper}>
      <Table
      // sx={{ minWidth: 650 }}
      >
        <TableHead>
          <TableRow>
            <TableCell>Users</TableCell>
            <TableCell align="left">Remove</TableCell>
            <TableCell align="left">Profile</TableCell>
            {/* <TableCell align="left">Update</TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.name}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell align="left">{row.email}</TableCell>
              <TableCell align="left">
                <IconButton
                  sx={{ color: "red" }}
                  onClick={() => {
                    // alert(row.id);
                    if (
                      window.confirm(
                        "Are you sure you want to unshare this device ?"
                      )
                    ) {
                      // Save it!
                      unshareDevice(row.id);
                    } else {
                      // Do nothing!
                      // console.log('Thing was not saved to the database.');
                    }
                  }}
                >
                  <RemoveCircleOutlineIcon />
                </IconButton>
              </TableCell>
              <TableCell align="left">{row.profile}</TableCell>
              <TableCell align="left"></TableCell>
              {/* <TableCell align="left">
                <IconButton
                  sx={{ color: "red" }}
                  onClick={() => {
                    // alert(row.id);

                      update(row.id);

                  }}
                >
                  <RemoveCircleOutlineIcon />
                </IconButton>
              </TableCell> */}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
