import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Footer } from "../components/includes/Footer";
import { Header } from "../components/includes/Header";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import UserService from "../services/user.service";
import { useParams } from "react-router-dom";
import moment from "moment-timezone";

const ResetPassword = () => {
  const navigate = useNavigate();
  let { token } = useParams();
  console.log("routes path", token);
  const [isValid, setisValid] = useState(false);

  UserService.ValidMail(token)
    .then((res) => {
      console.log(res);
      if (res.data.message === "Not found.") {
        toast.error("Invalid token.", { toastId: 23453643 });
      }
      if (res.data.message === "time_over") {
        toast.error("Reset password link is expire. Please try again.", {
          toastId: 25453643,
        });
      }
      if (res.data.message === "Mail Validated") {
        toast.success("Mail successfully activated.", { toastId: 234536 });
        setisValid(true);
        setTimeout(() => {
          navigate("/portal");
        }, 5000);
      }
    })
    .catch((error) => {
      console.log(error);
      {
        error && toast.error(error.response.data.message);
      }
    });

  return (
    <>
      <div>
        <Header />
        {/* MAin Navigation END    */}
        <section className="main-slider">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-sm-12">
                <div className="contact-form2 login_wraper">
                  {!isValid ? (
                    <h4 className="text-uppercase">Checking mail</h4>
                  ) : (
                    <h4 className="text-uppercase">Mail is validated Redirection</h4>
                  )}
                </div>
              </div>
              <div className="col-lg-12 col-sm-12">
                <div
                  id="carouselExampleSlidesOnly"
                  className="carousel slide"
                  data-ride="carousel"
                >
                  <div className="carousel-inner">
                    <div className="carousel-item active">
                      <img
                        src="/assets/images/slider/slider-1.jpg"
                        className="d-block w-100"
                        alt="..."
                      />
                    </div>
                    <div className="carousel-item">
                      <img
                        src="/assets/images/slider/slider-2.jpg"
                        className="d-block w-100"
                        alt="..."
                      />
                    </div>
                    <div className="carousel-item">
                      <img
                        src="/assets/images/slider/slider-3.jpg"
                        className="d-block w-100"
                        alt="..."
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Footer STYLES  */}
        <Footer />
        {/* FOOTER STYLES END */}
      </div>
    </>
  );
};

export default ResetPassword;
