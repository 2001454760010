import { useCustomCompareEffect } from "use-custom-compare";
import isEqual from "lodash/isEqual";
import UserService from "../../services/user.service";
import React, { useState, useEffect, useRef } from "react";
import moment from "moment-timezone";
import ReactPaginate from "react-paginate";
import IconButton from "@mui/material/IconButton";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ReactApexChart from "react-apexcharts";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import Switch from "@mui/material/Switch";
import { Typography } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Card, CardContent, Grid } from "@mui/material";
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";
import Batteries from "./Batteries";
import Alarams from "./Alarms";
// import DatePicker from "react-datepicker";
import DatePicker, { CalendarContainer } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Skeleton from "react-loading-skeleton";

import inflect from "i";
const tzone = "Asia/Amman";
function getBitValue(value, index) {
 return getBitValue0(value, index)?0:1;
}
function getBitValue0(value, index) {
  // Vérifier que l'indice est valide
  if (index < 0 || index >= 32) {
    console.error("L'indice doit être compris entre 0 et 31.");
    return null;
  }

  // Utiliser l'opérateur de décalage pour obtenir la valeur du bit à l'indice donné
  return (value >> index) & 1;
}
function fillMissingGaps(data, type) {
  let result = [];
  const template = {};
  const template2 = {};

  const startOfMonth = parseInt(
    moment(data[0][0]).startOf("month").format("DD")
  );
  const endOfMonth = parseInt(moment(data[0][0]).endOf("month").format("DD"));
  const Day = parseInt(moment(data[0][0]).format("DD"));
  const Month = parseInt(moment(data[0][0]).endOf("month").format("MM"));
  const Year = parseInt(moment(data[0][0]).endOf("month").format("YYYY"));

  if (type == "hourly") {
    data.forEach((x) => {
      template2[moment(x[0]).format("HH")] = x;
    });
    for (let num = 0; num <= 23; num++) {
      if (num < 10) {
        template["0" + num] = [
          moment(`${Year}-${Month}-${Day}`).format(`YYYY-MM-DD 0${num}:00:00`),
          null,
        ];
      } else {
        template["" + num] = [
          moment(`${Year}-${Month}-${Day}`).format(`YYYY-MM-DD ${num}:00:00`),
          null,
        ];
      }
    }
    // console.log("+++", template);
    //console.log(template2);

    result = Object.values({ ...template, ...template2 }).sort((a, b) =>
      a[0].localeCompare(b[0], "en", { numeric: true })
    );
    return result;
  } else if (type == "daily") {
    data.forEach((x) => {
      template2[moment(x[0]).format("DD")] = x;
    });

    for (let num = startOfMonth; num <= endOfMonth; num++) {
      if (num < 10) {
        template["0" + num] = [
          moment(`${Year}-${Month}-${num}`).format("YYYY-MM-DD  00:00:00"),
          null,
        ];
      } else {
        template["" + num] = [
          moment(`${Year}-${Month}-${num}`).format("YYYY-MM-DD  00:00:00"),
          null,
        ];
      }
    }

    result = Object.values({ ...template, ...template2 }).sort((a, b) =>
      a[0].localeCompare(b[0], "en", { numeric: true })
    );
    // console.log("***", result);
    // console.log("+++", template);
    // console.log("+++", template2);
    // console.log("*/*/", result);
    return result;
  } else if (type == "monthly") {
    data.forEach((x) => {
      template2[moment(x[0]).format("MM")] = x;
    });

    for (let num = 1; num <= 12; num++) {
      if (num < 10) {
        template["0" + num] = [
          moment(`${Year}-0${num}-02`).format("YYYY-MM-DD  00:00:00"),
          null,
        ];
      } else {
        template["" + num] = [
          moment(`${Year}-${num}-02`).format("YYYY-MM-DD  00:00:00"),
          null,
        ];
      }
    }
    console.log("+++", template2);
    result = Object.values({ ...template, ...template2 }).sort((a, b) =>
      a[0].localeCompare(b[0], "en", { numeric: true })
    );
    // console.log("***", result);
    // console.log("+++", template2);
    // console.log("*/*/", result);
    return result;
  }
}

function calculateDifferences(inputArray) {
  // Create a new array to store the results
  let resultArray = [];

  // Loop through the input array starting from the second element (index 1)
  for (let i = 1; i < inputArray.length; i++) {
    // Calculate the difference between T_Energy_Acc of current element and T_Energy_Acc of the previous element
    let diff = (
      inputArray[i].T_Energy_Acc - inputArray[i - 1].T_Energy_Acc
    ).toFixed(2);
    let LoadDiff = (
      inputArray[i].LoadDiff - inputArray[i - 1].LoadDiff
    ).toFixed(2);
    let SolarDiff = (
      inputArray[i].SolarDiff - inputArray[i - 1].SolarDiff
    ).toFixed(2);
    diff = diff > 0 ? diff : 0;
    LoadDiff = LoadDiff > 0 ? LoadDiff : 0;
    SolarDiff = SolarDiff > 0 ? SolarDiff : 0;
    // Create a new object with the 'diff' property and other properties from the current element
    let resultElement = { ...inputArray[i], diff, LoadDiff, SolarDiff };

    // Add the new object to the result array
    resultArray.push(resultElement);
  }

  // Return the result array
  return resultArray;
}

const TwoBoxes2 = (props) => {
  const { isGraphStatsLoading, data, color, name } = props;
  const labeslFontSize = 8;
  const dataFontSize = 18;
  const unitFontSize = 16;
  return (
    <Grid spacing={1} item xs={12} sm={6} md={3}>
      {" "}
      <Card
        sx={{
          boxShadow: 2,
          ":hover": {
            boxShadow: 5,
          },

          border: `solid 3px ${color}`,
        }}
      >
        <CardContent>
          <Grid
            container
            spacing={1}
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
          >
            <Grid spacing={1} item xs={12} sm={6} md={6}>
              <Card
                className=""
                sx={{
                  boxShadow: 2,
                  ":hover": {
                    boxShadow: 5,
                  },
                }}
              >
                <CardContent>
                  <Typography
                    variant="h5"
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      "padding-left": 5,
                      "padding-right": 5,
                      fontSize: unitFontSize - 2,
                    }}
                  >
                    Voltage
                  </Typography>
                  <Typography
                    variant="h5"
                    component="div"
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      padding: 0,
                      fontSize: dataFontSize,
                    }}
                  >
                    {isGraphStatsLoading ? (
                      <Skeleton height={15} width={100} />
                    ) : (
                      <span>
                        <span>
                          <span>{parseFloat(data.param1).toFixed(1)}</span>
                        </span>
                      </span>
                    )}
                  </Typography>

                  <Typography
                    variant="h5"
                    component="div"
                    style={{
                      textAlign: "right",
                      fontWeight: "bold",
                      padding: 0,
                      fontSize: unitFontSize,
                      margin: 0,
                      "padding-top": "-10%",
                    }}
                  >
                    {isGraphStatsLoading ? (
                      <Skeleton height={15} width={100} />
                    ) : (
                      <span>V</span>
                    )}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>

            <Grid spacing={1} item xs={12} sm={6} md={6}>
              <Card
                className=""
                sx={{
                  // maxWidth: 345,
                  boxShadow: 2,
                  //   margin: "0 5px 10px",
                  ":hover": {
                    boxShadow: 5,
                  },
                }}
              >
                <CardContent>
                  <Typography
                    variant="h5"
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      "padding-left": 5,
                      "padding-right": 5,
                      fontSize: unitFontSize - 2,
                    }}
                  >
                    Current
                  </Typography>
                  <Typography
                    variant="h5"
                    component="div"
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      padding: 0,
                      fontSize: dataFontSize,
                    }}
                  >
                    {isGraphStatsLoading ? (
                      <Skeleton height={15} width={100} />
                    ) : (
                      <span>
                        <span>{data.param2}</span>
                      </span>
                    )}
                  </Typography>
                  <Typography
                    variant="h5"
                    component="div"
                    style={{
                      textAlign: "right",
                      fontWeight: "bold",
                      padding: 0,
                      fontSize: unitFontSize,
                    }}
                  >
                    {isGraphStatsLoading ? (
                      <Skeleton height={15} width={100} />
                    ) : (
                      <span>A</span>
                    )}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Card>
        <Typography
          variant="h5"
          component="div"
          sx={{
            textAlign: "center",
            color: "white",
            backgroundColor: color,
          }}
        >
          {name}
        </Typography>
      </Card>
    </Grid>
  );
};

const TwoBoxes = (props) => {
  const { isGraphStatsLoading, data, color, name, type } = props;
  console.log("Two", props);
  // console.log(voltage);
  const labeslFontSize = 8;
  const dataFontSize = 25;
  const unitFontSize = 16;
  return (
    // <Grid spacing={1} item xs={12} sm={4} md={4}>
    <Grid spacing={1} item xs={12} sm={6} md={3}>
      {" "}
      <Card
        sx={{
          boxShadow: 2,
          ":hover": {
            boxShadow: 5,
          },

          border: `solid 3px ${color}`,
        }}
      >
        <CardContent>
          <Grid
            container
            spacing={1}
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
          >
            <Grid spacing={1} item xs={12} sm={6} md={6}>
              <Card
                className=""
                sx={{
                  boxShadow: 2,
                  ":hover": {
                    boxShadow: 5,
                  },
                }}
              >
                <CardContent>
                  <Typography
                    variant="h5"
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      "padding-left": 5,
                      "padding-right": 5,
                      fontSize: unitFontSize - 2,
                    }}
                  >
                    Current
                  </Typography>
                  <Typography
                    variant="h5"
                    component="div"
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      padding: 0,
                      fontSize: dataFontSize,
                    }}
                  >
                    {isGraphStatsLoading ? (
                      <Skeleton height={15} width={100} />
                    ) : (
                      <span>
                        <span>{parseFloat(data.param2).toFixed(1)}</span>
                      </span>
                    )}
                  </Typography>
                  <Typography
                    variant="h5"
                    component="div"
                    style={{
                      textAlign: "right",
                      fontWeight: "bold",
                      padding: 0,
                      fontSize: unitFontSize,
                    }}
                  >
                    {isGraphStatsLoading ? (
                      <Skeleton height={15} width={100} />
                    ) : (
                      <span>A</span>
                    )}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid spacing={1} item xs={12} sm={6} md={6}>
              <Card
                className=""
                sx={{
                  boxShadow: 2,
                  ":hover": {
                    boxShadow: 5,
                  },
                }}
              >
                <CardContent>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      margin: 0,
                      fontSize: unitFontSize - 2,
                    }}
                  >
                    {/* Power */}
                    {type ? "SOC" : "Power"}
                  </Typography>
                  <Typography
                    variant="h5"
                    component="div"
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      padding: 0,
                      fontSize: dataFontSize,
                    }}
                  >
                    {isGraphStatsLoading ? (
                      <Skeleton height={15} width={100} />
                    ) : (
                      <span>
                        <span>{parseFloat(data.param3).toFixed(1)}</span>
                      </span>
                    )}
                  </Typography>
                  <Typography
                    variant="h5"
                    component="div"
                    style={{
                      textAlign: "right",
                      fontWeight: "bold",
                      padding: 0,
                      fontSize: unitFontSize,
                    }}
                  >
                    {isGraphStatsLoading ? (
                      <Skeleton height={15} width={100} />
                    ) : (
                      <>{type ? <span> % </span> : <span> KW </span>}</>
                    )}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Card>
        <Typography
          variant="h5"
          component="div"
          sx={{
            textAlign: "center",
            color: "white",
            backgroundColor: color,
            //   paddingLeft: "10px",
            //   borderRadius:"25%"
          }}
        >
          {name}
        </Typography>
      </Card>
    </Grid>
  );
};
const ThreeBoxes = (props) => {
  const { isGraphStatsLoading, data, color, name, type } = props;
  // console.log(props);
  // console.log(voltage);
  const labeslFontSize = 8;
  const dataFontSize = 25;
  const unitFontSize = 16;
  return (
    <Grid spacing={1} item xs={12} sm={4} md={4}>
      {" "}
      <Card
        sx={{
          boxShadow: 2,
          ":hover": {
            boxShadow: 5,
          },

          border: `solid 3px ${color}`,
        }}
      >
        <CardContent>
          <Grid
            container
            spacing={1}
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
          >
            <Grid spacing={1} item xs={12} sm={6} md={4}>
              <Card
                className=""
                sx={{
                  boxShadow: 2,
                  ":hover": {
                    boxShadow: 5,
                  },
                }}
              >
                <CardContent>
                  <Typography
                    variant="h5"
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      "padding-left": 5,
                      "padding-right": 5,
                      fontSize: unitFontSize - 2,
                    }}
                  >
                    Voltage
                  </Typography>
                  <Typography
                    variant="h5"
                    component="div"
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      padding: 0,
                      fontSize: dataFontSize,
                    }}
                  >
                    {isGraphStatsLoading ? (
                      <Skeleton height={15} width={100} />
                    ) : (
                      <span>
                        <span>
                          <span>{parseFloat(data.param1).toFixed(1)}</span>
                        </span>
                      </span>
                    )}
                  </Typography>

                  <Typography
                    variant="h5"
                    component="div"
                    style={{
                      textAlign: "right",
                      fontWeight: "bold",
                      padding: 0,
                      fontSize: unitFontSize,
                      margin: 0,
                      "padding-top": "-10%",
                    }}
                  >
                    {isGraphStatsLoading ? (
                      <Skeleton height={15} width={100} />
                    ) : (
                      <span>V</span>
                    )}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid spacing={1} item xs={12} sm={6} md={4}>
              <Card
                className=""
                sx={{
                  boxShadow: 2,
                  ":hover": {
                    boxShadow: 5,
                  },
                }}
              >
                <CardContent>
                  <Typography
                    variant="h5"
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      "padding-left": 5,
                      "padding-right": 5,
                      fontSize: unitFontSize - 2,
                    }}
                  >
                    Current
                  </Typography>
                  <Typography
                    variant="h5"
                    component="div"
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      padding: 0,
                      fontSize: dataFontSize,
                    }}
                  >
                    {isGraphStatsLoading ? (
                      <Skeleton height={15} width={100} />
                    ) : (
                      <span>
                        <span>{parseFloat(data.param2).toFixed(1)}</span>
                      </span>
                    )}
                  </Typography>
                  <Typography
                    variant="h5"
                    component="div"
                    style={{
                      textAlign: "right",
                      fontWeight: "bold",
                      padding: 0,
                      fontSize: unitFontSize,
                    }}
                  >
                    {isGraphStatsLoading ? (
                      <Skeleton height={15} width={100} />
                    ) : (
                      <span>A</span>
                    )}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid spacing={1} item xs={12} sm={6} md={4}>
              <Card
                className=""
                sx={{
                  boxShadow: 2,
                  ":hover": {
                    boxShadow: 5,
                  },
                }}
              >
                <CardContent>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      margin: 0,
                      fontSize: unitFontSize - 2,
                    }}
                  >
                    {/* Power */}
                    {type ? "SOC" : "Power"}
                  </Typography>
                  <Typography
                    variant="h5"
                    component="div"
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      padding: 0,
                      fontSize: dataFontSize,
                    }}
                  >
                    {isGraphStatsLoading ? (
                      <Skeleton height={15} width={100} />
                    ) : (
                      <span>
                        {/* <span>{parseFloat(data.param3/1000).toFixed(1)}</span> */}
                        <span>{parseFloat(data.param3).toFixed(2)}</span>
                      </span>
                    )}
                  </Typography>
                  <Typography
                    variant="h5"
                    component="div"
                    style={{
                      textAlign: "right",
                      fontWeight: "bold",
                      padding: 0,
                      fontSize: unitFontSize,
                    }}
                  >
                    {isGraphStatsLoading ? (
                      <Skeleton height={15} width={100} />
                    ) : (
                      <>{type ? <span> % </span> : <span> KW </span>}</>
                    )}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Card>
        <Typography
          variant="h5"
          component="div"
          sx={{
            textAlign: "center",
            color: "white",
            backgroundColor: color,
            //   paddingLeft: "10px",
            //   borderRadius:"25%"
          }}
        >
          {name}
        </Typography>
      </Card>
    </Grid>
  );
};

const FourBoxes = (props) => {
  const { isGraphStatsLoading, data, color } = props;
  // console.log(isGraphStatsLoading, voltage, current, power, color);
  // console.log(voltage);
  const labeslFontSize = 8;
  const dataFontSize = 25;
  const unitFontSize = 16;
  return (
    <Grid spacing={0} item xs={12} sm={5} md={5}>
      {" "}
      <Card
        sx={{
          // maxWidth: 345,
          boxShadow: 2,
          //   margin: "0 5px 10px",
          ":hover": {
            boxShadow: 5,
          },

          border: `solid 3px ${color}`,
          //   "borderRadius":"10%"
        }}
      >
        <CardContent>
          <Grid
            container
            spacing={1}
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
            // sx={{
            //   // maxWidth: 345,
            //   boxShadow: 2,
            //   margin: "0 5px 10px",
            //   ":hover": {
            //     boxShadow: 5,
            //   },

            //   "border-bottom": `solid 3px ${color}`,
            // }}
          >
            <Grid spacing={1} item xs={12} sm={6} md={3}>
              <Card
                className=""
                sx={{
                  // maxWidth: 345,
                  boxShadow: 2,
                  //   margin: "0 5px 10px",
                  ":hover": {
                    boxShadow: 5,
                  },

                  //   "border-top": `solid 3px ${color}`,
                }}
              >
                <CardContent>
                  <Typography
                    variant="h5"
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      // "padding-left": 5,
                      // "padding-right": 5,
                      fontSize: unitFontSize - 2,
                    }}
                  >
                    Status
                  </Typography>
                  <Typography
                    variant="h5"
                    component="div"
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      padding: 0,
                      fontSize: dataFontSize - 2,
                    }}
                  >
                    {isGraphStatsLoading ? (
                      <Skeleton height={15} width={100} />
                    ) : (
                      <span>
                        <span>
                          <span>
                            {data.DG_Status ? data.DG_Status : "(N.A)"}
                          </span>
                        </span>
                      </span>
                    )}
                  </Typography>

                  <Typography
                    variant="h5"
                    component="div"
                    style={{
                      textAlign: "right",
                      fontWeight: "bold",
                      padding: 0,
                      fontSize: unitFontSize,
                      margin: 0,
                      "padding-top": "-10%",
                    }}
                  >
                    {isGraphStatsLoading ? (
                      <Skeleton height={15} width={100} />
                    ) : (
                      <span> &nbsp;</span>
                    )}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>

            <Grid spacing={1} item xs={12} sm={6} md={3}>
              <Card
                className=""
                sx={{
                  // maxWidth: 345,
                  boxShadow: 2,
                  //   margin: "0 5px 10px",
                  ":hover": {
                    boxShadow: 5,
                  },
                  // "border-top": `solid 3px ${color}`,
                }}
              >
                <CardContent>
                  <Typography
                    variant="h5"
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      // "padding-left": 5,
                      // "padding-right": 5,
                      fontSize: unitFontSize - 2,
                    }}
                  >
                    Power
                  </Typography>
                  <Typography
                    variant="h5"
                    component="div"
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      padding: 0,
                      fontSize: dataFontSize,
                    }}
                  >
                    {isGraphStatsLoading ? (
                      <Skeleton height={15} width={100} />
                    ) : (
                      <span>
                        <span>{parseFloat(data.DG_Power).toFixed(1)}</span>
                      </span>
                    )}
                  </Typography>
                  <Typography
                    variant="h5"
                    component="div"
                    style={{
                      textAlign: "right",
                      fontWeight: "bold",
                      padding: 0,
                      fontSize: unitFontSize,
                    }}
                  >
                    {isGraphStatsLoading ? (
                      <Skeleton height={15} width={100} />
                    ) : (
                      <span>KW</span>
                    )}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid spacing={1} item xs={12} sm={6} md={3}>
              <Card
                className=""
                sx={{
                  // maxWidth: 345,
                  boxShadow: 2,
                  //   margin: "0 5px 10px",
                  ":hover": {
                    boxShadow: 5,
                  },

                  //   "border-top": `solid 3px ${color}`,
                }}
              >
                <CardContent
                  style={{
                    padding: "5px",
                  }}
                >
                  <Typography
                    variant="h5"
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      // "padding-left": 5,
                      // "padding-right": 5,
                      fontSize: unitFontSize - 2,
                    }}
                  >
                    Percentage
                  </Typography>
                  <Typography
                    variant="h5"
                    component="div"
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      padding: 0,
                      fontSize: dataFontSize,
                    }}
                  >
                    {isGraphStatsLoading ? (
                      <Skeleton height={15} width={100} />
                    ) : (
                      <span>
                        <span>
                          <span>{data.Fuel_Percentage}</span>
                        </span>
                      </span>
                    )}
                  </Typography>

                  <Typography
                    variant="h5"
                    component="div"
                    style={{
                      textAlign: "right",
                      fontWeight: "bold",
                      padding: 0,
                      fontSize: unitFontSize,
                      margin: 0,
                      "padding-top": "-10%",
                    }}
                  >
                    {isGraphStatsLoading ? (
                      <Skeleton height={15} width={100} />
                    ) : (
                      <span>%</span>
                    )}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>

            <Grid spacing={1} item xs={12} sm={6} md={3}>
              <Card
                className=""
                sx={{
                  // maxWidth: 345,
                  boxShadow: 2,
                  //   margin: "0 5px 10px",
                  ":hover": {
                    boxShadow: 5,
                  },
                  // "border-top": `solid 3px ${color}`,
                }}
              >
                <CardContent>
                  <Typography
                    variant="h5"
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      // "padding-left": 5,
                      // "padding-right": 5,
                      fontSize: unitFontSize - 2,
                    }}
                  >
                    Voltage
                  </Typography>
                  <Typography
                    variant="h5"
                    component="div"
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      padding: 0,
                      fontSize: dataFontSize,
                    }}
                  >
                    {isGraphStatsLoading ? (
                      <Skeleton height={15} width={100} />
                    ) : (
                      <span>
                        <span>
                          {parseFloat(data.DGBATT_Voltage).toFixed(1)}
                        </span>
                      </span>
                    )}
                  </Typography>
                  <Typography
                    variant="h5"
                    component="div"
                    style={{
                      textAlign: "right",
                      fontWeight: "bold",
                      padding: 0,
                      fontSize: unitFontSize,
                    }}
                  >
                    {isGraphStatsLoading ? (
                      <Skeleton height={15} width={100} />
                    ) : (
                      <span>V</span>
                    )}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Card>
        <Typography
          variant="h5"
          component="div"
          sx={{
            textAlign: "center",
            color: "white",
            backgroundColor: color,
            //   paddingLeft: "10px",
            //   borderRadius:"25%"
          }}
        >
          Diesel Generator
        </Typography>
      </Card>
    </Grid>
  );
};

function subtract30Days(date) {
  // Subtract 30 days
  date.setDate(date.getDate() - 30);

  // Get the individual date components
  var year = date.getFullYear();
  var month = String(date.getMonth() + 1).padStart(2, "0");
  var day = String(date.getDate()).padStart(2, "0");
  var hours = String(date.getHours()).padStart(2, "0");
  var minutes = String(date.getMinutes()).padStart(2, "0");
  var seconds = String(date.getSeconds()).padStart(2, "0");

  // Format the date string
  var formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

  return formattedDate;
}
function formatDate(date) {
  date = new Date(date);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

export default function App(props) {
  const {
    isGraphDataFromSocket,
    graphDataFromSocket,
    isFilterGraphData,
    graphDataFromFilter,
    device_id,
    phase,
    userID,
    isSharedDevice,
    isDeviceStatus,
    StatementName,
  } = props;

  // console.log("props from Appex", props);
  const chartRef = useRef();
  const [gData, setGData] = useState([]);
  const [isLoadingGraph, setisLoadingGraph] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [data, setData] = useState([]);
  const [data1, setData1] = useState([]);
  const [data2, setData2] = useState([]);
  const [data3, setData3] = useState([]);
  const [data4, setData4] = useState([]);
  const [data5, setData5] = useState([]);
  const [data6, setData6] = useState([]);
  const [data7, setData7] = useState([]);
  const [startDate, setstartDate] = useState("");
  const [endDate, setendDate] = useState("");
  const [LastDataFront, setLastDataFront] = useState(0);
  const [FirstDataBack, setFirstDataBack] = useState(0);
  const [DataAvailable, setDataAvailable] = useState(false);
  const [isGraphStatsLoading, setIsGraphStatsLoading] = useState(1);
  const [Rt, setRt] = React.useState(true);
  const [TimeFormat, setTtimeFormat] = React.useState("daily");
  const [AlarmSelection, setTAlarmSelection] = React.useState("0");
  const [Parameter, setParameter] = React.useState("power");
  const [SolarPower, setSolarPower] = useState({});
  const [Load, setLoad] = useState({});
  const [DieselGenerator, setDieselGenerator] = useState({});
  const [Bat1, setBat1] = useState({});
  const [Bat2, setBat2] = useState({});
  const [Bat3, setBat3] = useState({});
  const [ProcessData, setProcessData] = useState({});

  const format = "daterange-initial";

  const firstdate = moment().startOf("month").unix();
  //console.log("firstdate", firstdate);
  const lastdate = moment().endOf("month").unix();
  //console.log("lastdate", lastdate);
  const [chartConfig, setChartConfig] = useState({
    chart: {
      id: "total-power-chart",
      // type: "line",
      // stacked: true,
      height: 500,
      foreColor: "#000000",
      zoom: {
        type: "x",
        enabled: true,
        autoScaleYaxis: true,
      },

      toolbar: {
        autoSelected: "zoom",
      },
    },
    dataLabels: {
      enabled: false,
    },
    markers: {
      size: 0,
    },
    xaxis: {
      type: "datetime",
      tooltip: {
        enabled: false,
      },
      labels: {
        rotate: -90,
        rotateAlways: true,
        minHeight: 100,
        maxHeight: 160,
        show: true,
        align: "left",
        trim: true,
        // minWidth: 0,
        // maxWidth: 1200,
        style: {
          colors: ["#000000"],
          fontSize: "15px",
          // fontFamily: 'Helvetica, Arial, sans-serif',
          fontWeight: 600,
        },
      },
    },
    stroke: {
      width: 2,
    },
    colors: ["#0054F1", "#0054F1", "#0054F1", "#0054F1"],
    yaxis: {
      opposite: true,
      // formatter: (value) => {
      //   return value ;
      // },
      min: 0,
      decimalsInFloat: 2,
      forceNiceScale: true,
      axisBorder: {
        show: true,
        color: "#78909C",
        offsetX: 0,
        offsetY: 0,
      },
      axisTicks: {
        show: true,
        borderType: "solid",
        color: "#78909C",
        width: 6,
        offsetX: 0,
        offsetY: 0,
      },
      labels: {
        show: true,
        align: "right",
        minWidth: 0,
        maxWidth: 160,
        style: {
          colors: [],
          fontSize: "15px",
          // fontFamily: 'Helvetica, Arial, sans-serif',
          fontWeight: 600,
        },
        offsetX: 0,
        offsetY: 0,
        rotate: 0,
        // formatter: (value) => { return val },
      },
      crosshairs: {
        show: true,
        position: "back",
        stroke: {
          color: "#b6b6b6",
          width: 0.6,
          dashArray: [0, 1, 2],
        },
      },
      tooltip: {
        enabled: false,
        offsetX: 0,
      },
      title: {
        text: "[KW2]",
        rotate: 0,
        offsetX: 40,
        offsetY: -170,
        style: {
          color: undefined,
          fontSize: "15px",
          // fontFamily: 'Helvetica, Arial, sans-serif',
          fontWeight: 600,
          // cssClass: 'apexcharts-yaxis-title',
        },
      },
    },
    legend: {
      showForSingleSeries: true,
      offsetX: 7,
      //  offsetY:5,
      position: "top",
      fontSize: "18px",
      //  width:1
      //  fontWeight: 800,
      markers: {
        width: 12,
        height: 12,
        strokeWidth: 2,
        strokeColor: "#fff",
        fillColors: undefined,
        radius: 12,
        offsetX: -5,
        // offsetY: 5
      },
    },
  });
  const [startDate2, setStartDate2] = useState(new Date());
  const handleChangeFormat = (event) => {
    setTtimeFormat(event.target.value);
    console.log(event.target.value);

    // handleApply();
  };

  const handleApply = (event, picker) => {
    setRt(false);
    const format = "daterange";
    let startDateObj = picker.startDate._d;
    let startDateObjDate = new Date(startDateObj);
    console.log("startDateObjDate", startDateObjDate);
    let startDateday = startDateObjDate.getDate();
    let startDatemonth = startDateObjDate.getMonth() + 1;
    let startDateyear = startDateObjDate.getFullYear();
    setstartDate(`${startDateyear}-${startDatemonth}-${startDateday}`);
    console.log(
      "final start date----",
      `${startDateyear}-${startDatemonth}-${startDateday}`
    );

    let endDateObj = picker.endDate._d;
    let endDateObjDate = new Date(endDateObj);
    console.log("endDateObjDate", endDateObjDate);

    let endDateday = endDateObjDate.getDate();
    let endDatemonth = endDateObjDate.getMonth() + 1;
    let endDateyear = endDateObjDate.getFullYear();
    let endDateHour = endDateObjDate.getHours();
    let endDateMinute = endDateObjDate.getMinutes();
    let endDateSecond = endDateObjDate.getMinutes();
    // let endDate = endDateyear + "-" + endDatemonth + "-" + endDateday;
    setendDate(`${endDateyear}-${endDatemonth}-${endDateday}`);
    console.log(
      "final end date----",
      `${endDateyear}-${endDatemonth}-${endDateday} ${endDateHour}:${endDateMinute}:${endDateSecond}`
    );
    if (Parameter === "power") {
      UserService.GetLinkedDeviceTemperatureData(
        device_id,
        "Load_Power",
        "daterange",
        `${startDateyear}-${startDatemonth}-${startDateday} 00:00:00`,
        `${endDateyear}-${endDatemonth}-${endDateday} 23:59:59`
      )
        .then((res) => {
          let powerDataFromDB = res.data.data.deviceData;
          console.log(powerDataFromDB)
          let myData;
          let myData1;
          let myData2;
          let myData3;
          if (typeof powerDataFromDB != "undefined" && !res.data.data.error) {
            myData = Object.keys(powerDataFromDB).map((key) => {
              return [
                powerDataFromDB[key].time,
                powerDataFromDB[key].Load_Power,
                // powerDataFromDB[key].device_data_id,
              ];
            });

            myData1 = Object.keys(powerDataFromDB).map((key) => {
              return [
                powerDataFromDB[key].time,
                powerDataFromDB[key].Solar_Power,
                // powerDataFromDB[key].device_data_id,
              ];
            });
            myData2 = Object.keys(powerDataFromDB).map((key) => {
              return [
                powerDataFromDB[key].time,
                powerDataFromDB[key].Solar_Power,
                // powerDataFromDB[key].device_data_id,
              ];
            });
            myData3 = Object.keys(powerDataFromDB).map((key) => {
              return [
                powerDataFromDB[key].time,
                powerDataFromDB[key].BATT_Power,
                // powerDataFromDB[key].device_data_id,
              ];
            });

            console.log("last data ID", myData.at(-1));
            setLastDataFront(myData.at(-1));
            setFirstDataBack(myData.at(0));
          } else {
            myData = [];
          }
          if (myData.length > 0) {
            // console.log("Graph Data!!!!!", myData)
            setData(myData);
            setData1(myData1);
            setData2(myData2);
            setData3(myData3);
            console.log(data);
          }
          setisLoadingGraph(false);
        })
        .catch((err) => {
          setisLoadingGraph(false);
          console.log(err);
        });
    } else if (Parameter === "energy") {
      console.log(" get data energy");
      let QueryStarDate;
      let QueryendDate;

      if (TimeFormat === "hourly") {
        //24hr
        QueryStarDate = moment(picker.startDate._d).format(
          "YYYY-MM-DD  00:00:00"
        );
        QueryendDate = moment(picker.startDate._d).format(
          "YYYY-MM-DD  23:59:59"
        );
        console.log("<<===", QueryStarDate, QueryendDate);
      } else if (TimeFormat === "daily") {
        QueryStarDate = moment(picker.startDate._d)
          .startOf("month")
          .format("YYYY-MM-DD  00:00:00");
        QueryendDate = moment(picker.startDate._d)
          .endOf("month")
          .format("YYYY-MM-DD  23:59:59");
        console.log("<<===", QueryStarDate, QueryendDate);
      } else {
        QueryStarDate = moment(picker.startDate._d)
          .subtract(1, "year")
          .format("YYYY-MM-DD  00:00:00");
        QueryendDate = moment(picker.startDate._d)
          .subtract(1, "year")
          .format("YYYY-MM-DD  23:59:59");
        console.log("<<===", QueryStarDate, QueryendDate);
      }

      let Param = TimeFormat === "hourly" ? "T_Energy_Hr_A" : "T_Energy_D_A";
      console.log(TimeFormat, Param);

      UserService.GetLinkedDeviceData(
        device_id,
        "timerange",
        TimeFormat,
        QueryStarDate,
        QueryendDate
      )
        .then((res) => {
          let localUnit = "KWH";
          let powerDataFromDB = res.data.data.deviceData;
          powerDataFromDB = calculateDifferences(
            Object.values(powerDataFromDB)
          );
          let myData;
          let myData1;
          if (typeof powerDataFromDB != "undefined" && !res.data.data.error) {
            myData = powerDataFromDB.map((key) => {
              return [key.time2, key["LoadDiff"]];
            });
            console.log("*****", myData);
            myData1 = powerDataFromDB.map((key) => {
              return [key.time2, key["SolarDiff"]];
            });

            myData = fillMissingGaps(myData, TimeFormat);
            myData1 = fillMissingGaps(myData1, TimeFormat);

            function fillMissingGaps(data, type) {
              let result = [];
              const template = {};
              const template2 = {};

              const startOfMonth = parseInt(
                moment(data[0][0]).startOf("month").format("DD")
              );
              const endOfMonth = parseInt(
                moment(data[0][0]).endOf("month").format("DD")
              );
              const Day = parseInt(moment(data[0][0]).format("DD"));
              const Month = parseInt(
                moment(data[0][0]).endOf("month").format("MM")
              );
              const Year = parseInt(
                moment(data[0][0]).endOf("month").format("YYYY")
              );

              if (type == "hourly") {
                data.forEach((x) => {
                  template2[moment(x[0]).format("HH")] = x;
                });
                for (let num = 0; num <= 23; num++) {
                  if (num < 10) {
                    template["0" + num] = [
                      moment(`${Year}-${Month}-${Day}`).format(
                        `YYYY-MM-DD 0${num}:00:00`
                      ),
                      null,
                    ];
                  } else {
                    template["" + num] = [
                      moment(`${Year}-${Month}-${Day}`).format(
                        `YYYY-MM-DD ${num}:00:00`
                      ),
                      null,
                    ];
                  }
                }
                // console.log("+++", template);
                //console.log(template2);

                result = Object.values({ ...template, ...template2 }).sort(
                  (a, b) => a[0].localeCompare(b[0], "en", { numeric: true })
                );
                return result;
              } else if (type == "daily") {
                data.forEach((x) => {
                  template2[moment(x[0]).format("DD")] = x;
                });

                for (let num = startOfMonth; num <= endOfMonth; num++) {
                  if (num < 10) {
                    template["0" + num] = [
                      moment(`${Year}-${Month}-${num}`).format(
                        "YYYY-MM-DD  00:00:00"
                      ),
                      null,
                    ];
                  } else {
                    template["" + num] = [
                      moment(`${Year}-${Month}-${num}`).format(
                        "YYYY-MM-DD  00:00:00"
                      ),
                      null,
                    ];
                  }
                }

                result = Object.values({ ...template, ...template2 }).sort(
                  (a, b) => a[0].localeCompare(b[0], "en", { numeric: true })
                );
                // console.log("***", result);
                // console.log("+++", template);
                // console.log("+++", template2);
                // console.log("*/*/", result);
                return result;
              } else if (type == "monthly") {
                data.forEach((x) => {
                  template2[moment(x[0]).format("MM")] = x;
                });

                for (let num = 1; num <= 12; num++) {
                  if (num < 10) {
                    template["0" + num] = [
                      moment(`${Year}-0${num}-02`).format(
                        "YYYY-MM-DD  00:00:00"
                      ),
                      null,
                    ];
                  } else {
                    template["" + num] = [
                      moment(`${Year}-${num}-02`).format(
                        "YYYY-MM-DD  00:00:00"
                      ),
                      null,
                    ];
                  }
                }
                console.log("+++", template2);
                result = Object.values({ ...template, ...template2 }).sort(
                  (a, b) => a[0].localeCompare(b[0], "en", { numeric: true })
                );
                // console.log("***", result);
                // console.log("+++", template2);
                // console.log("*/*/", result);
                return result;
              }
            }

            console.log("last data ID", myData.at(-1));
            setLastDataFront(myData.at(-1));
            setFirstDataBack(myData.at(0));
          } else {
            myData = [];
          }
          if (myData.length > 0) {
            // console.log("Graph Data!!!!!", myData)
            // myData = fillMissingGaps(myData, TimeFormat);

            setData(myData);
            setData1(myData1);
            setChartConfig({
              chart: {
                id: "total-power-chart",
                // type: "line",
                // stacked: true,
                height: 500,
                foreColor: "#000000",
                zoom: {
                  type: "x",
                  enabled: true,
                  autoScaleYaxis: true,
                },
                toolbar: {
                  autoSelected: "zoom",
                },
              },
              tooltip: {
                enabled: true,
                color: "#000000",
                style: {
                  fontSize: "16px",
                  // fontFamily: undefined
                },
                // shared: true,
                x: {
                  format: "dd MMM yyyy hh:mm TT",
                },
                y: [
                  {
                    formatter: function (y) {
                      if (typeof y !== "undefined") {
                        return y.toFixed(2) + " " + localUnit;
                      }
                      return y;
                    },
                  },
                  {
                    formatter: function (y) {
                      if (typeof y !== "undefined") {
                        return y.toFixed(2) + " " + localUnit;
                      }
                      return y;
                    },
                  },
                  {
                    formatter: function (y) {
                      if (typeof y !== "undefined") {
                        return y.toFixed(2) + " " + localUnit;
                      }
                      return y;
                    },
                  },
                  {
                    formatter: function (y) {
                      if (typeof y !== "undefined") {
                        return y.toFixed(2) + " " + localUnit;
                      }
                      return y;
                    },
                  },
                ],
              },
              dataLabels: {
                enabled: false,
              },
              markers: {
                size: 0,
              },
              xaxis: {
                type: "datetime",
                tickAmount: myData.length - 1,
                tooltip: {
                  enabled: false,
                },
                labels: {
                  rotate: -90,
                  rotateAlways: true,
                  minHeight: 100,
                  maxHeight: 160,
                  show: true,
                  align: "left",
                  // trim: true,
                  formatter: function (val, timestamp) {
                    if (TimeFormat === "hourly")
                      return moment(timestamp).format("HH:00");
                    if (TimeFormat === "daily")
                      return moment(timestamp).format("DD MMM");
                    if (TimeFormat === "monthly")
                      return moment(timestamp).format("MMM YYYY");
                  },
                  style: {
                    colors: ["#000000"],
                    fontSize: "15px",
                    // fontFamily: 'Helvetica, Arial, sans-serif',
                    fontWeight: 600,
                  },
                },
              },
              stroke: {
                width: 2,
              },
              colors: ["#64006C", "#0054F1", "#00FF00", "#FFD93D"],
              yaxis: {
                opposite: false,
                formatter: (value) => {
                  return value;
                },
                min: 0,
                decimalsInFloat: 2,
                forceNiceScale: true,
                axisBorder: {
                  show: true,
                  color: "#78909C",
                  offsetX: 0,
                  offsetY: 0,
                },
                axisTicks: {
                  show: true,
                  borderType: "solid",
                  color: "#78909C",
                  width: 6,
                  offsetX: 0,
                  offsetY: 0,
                },
                labels: {
                  show: true,
                  align: "right",
                  minWidth: 0,
                  maxWidth: 160,
                  style: {
                    colors: [],
                    fontSize: "15px",
                    // fontFamily: 'Helvetica, Arial, sans-serif',
                    fontWeight: 600,
                  },
                  offsetX: 0,
                  offsetY: 0,
                  rotate: 0,
                  // formatter: (value) => { return val },
                },
                crosshairs: {
                  show: true,
                  position: "back",
                  stroke: {
                    color: "#b6b6b6",
                    width: 0.6,
                    dashArray: [0, 1, 2],
                  },
                },
                tooltip: {
                  enabled: false,
                  offsetX: 0,
                },
                title: {
                  text: "[" + localUnit + "]",
                  rotate: 0,
                  offsetX: 40,
                  offsetY: -170,
                  style: {
                    color: undefined,
                    fontSize: "15px",
                    // fontFamily: 'Helvetica, Arial, sans-serif',
                    fontWeight: 600,
                    // cssClass: 'apexcharts-yaxis-title',
                  },
                },
              },
              legend: {
                showForSingleSeries: true,
                offsetX: 7,
                //  offsetY:5,
                position: "top",
                fontSize: "18px",
                //  width:1
                //  fontWeight: 800,
                markers: {
                  width: 12,
                  height: 12,
                  strokeWidth: 2,
                  strokeColor: "#fff",
                  fillColors: undefined,
                  radius: 12,
                  offsetX: -5,
                  // offsetY: 5
                },
              },
            });
          }
          setisLoadingGraph(false);
        })
        .catch((err) => {
          setisLoadingGraph(false);
          console.log(err);
        });
    } else if (Parameter === "energy2") {
      console.log(" get data energy");
      let Param = TimeFormat === "daily" ? "T_Energy_Hr_A" : "T_Energy_D_A";
      UserService.GetLinkedDeviceData(
        device_id,
        "timerange",
        TimeFormat,
        `${startDateyear}-${startDatemonth}-${startDateday} 00:00:00`,
        `${endDateyear}-${endDatemonth}-${endDateday} 23:59:59`
      )
        .then((res) => {
          let powerDataFromDB = res.data.data.deviceData;
          let myData;
          let myData1;
          let myData2;
          let myData3;
          if (typeof powerDataFromDB != "undefined" && !res.data.data.error) {
            myData = Object.keys(powerDataFromDB).map((key) => {
              return [
                powerDataFromDB[key].time2,
                powerDataFromDB[key]["T_Energy_Acc"],
                // powerDataFromDB[key].device_data_id,
              ];
            });

            myData1 = Object.keys(powerDataFromDB).map((key) => {
              return [
                powerDataFromDB[key].time2,
                powerDataFromDB[key]["diff"],
                // powerDataFromDB[key].device_data_id,
              ];
            });
            console.log("last data ID", myData.at(-1));
            setLastDataFront(myData.at(-1));
            setFirstDataBack(myData.at(0));
          } else {
            myData = [];
            myData1 = [];
          }
          if (myData.length > 0) {
            // console.log("Graph Data!!!!!", myData)
            setData(myData);
            setData1(myData1);
            // setData2(myData2);
            // setData3(myData3);
            // console.log(data);
          }
          setisLoadingGraph(false);
        })
        .catch((err) => {
          setisLoadingGraph(false);
          console.log(err);
        });
    } else if (Parameter === "temperature2") {
      UserService.GetLinkedDeviceTemperatureData(
        device_id,
        "temperature",
        "daterange",
        `${startDateyear}-${startDatemonth}-${startDateday} 00:00:00`,
        `${endDateyear}-${endDatemonth}-${endDateday} 23:59:59`
      )
        .then((res) => {
          let powerDataFromDB = res.data.data.deviceData;
          let myData;

          if (typeof powerDataFromDB != "undefined" && !res.data.data.error) {
            myData = Object.keys(powerDataFromDB).map((key) => {
              return [
                powerDataFromDB[key].time,
                powerDataFromDB[key].value,
                // powerDataFromDB[key].device_data_id,
              ];
            });

            console.log("last data ID", myData.at(-1));
            setLastDataFront(myData.at(-1));
            setFirstDataBack(myData.at(0));
          } else {
            myData = [];
          }
          if (myData.length > 0) {
            // console.log("Graph Data!!!!!", myData)
            setData(myData);
            console.log(data);
          }
          setisLoadingGraph(false);
        })
        .catch((err) => {
          setisLoadingGraph(false);
          console.log(err);
        });
    } else if (Parameter === "temperature") {
      UserService.GetLinkedDeviceTemperatureData(
        device_id,
        "Load_Power",
        "daterange",
        `${startDateyear}-${startDatemonth}-${startDateday} 00:00:00`,
        `${endDateyear}-${endDatemonth}-${endDateday} 23:59:59`
      )
        .then((res) => {
          let powerDataFromDB = res.data.data.deviceData;
          let myData;
          let myData1;
          let myData2;
          let myData3;
          let myData4;
          let lastValueNotNull= 0 ;
          if (typeof powerDataFromDB != "undefined" && !res.data.data.error) {
            myData = Object.keys(powerDataFromDB).map((key) => {
lastValueNotNull = powerDataFromDB[key].Temp_Bat1 ;
              
              return [
                powerDataFromDB[key].time,
                powerDataFromDB[key].Temp_Bat1 > 0
                  ? powerDataFromDB[key].Temp_Bat1
                  : lastValueNotNull,
                // powerDataFromDB[key].device_data_id,
              ];
            });

            myData1 = Object.keys(powerDataFromDB).map((key) => {
lastValueNotNull = powerDataFromDB[key].Temp_Bat2 ;
            
              return [
                powerDataFromDB[key].time,
                powerDataFromDB[key].Temp_Bat2 > 0
                  ? powerDataFromDB[key].Temp_Bat2
                  : lastValueNotNull,
                // powerDataFromDB[key].device_data_id,
              ];
            });
            myData2 = Object.keys(powerDataFromDB).map((key) => {
lastValueNotNull = powerDataFromDB[key].Temp_Bat3 ;
             
              return [
                powerDataFromDB[key].time,
                powerDataFromDB[key].Temp_Bat3 > 0
                  ? powerDataFromDB[key].Temp_Bat3
                  : lastValueNotNull,
                // powerDataFromDB[key].device_data_id,
              ];
            });
            myData3 = Object.keys(powerDataFromDB).map((key) => {
lastValueNotNull = powerDataFromDB[key].Temp_Cabinte ;
             
              return [
                powerDataFromDB[key].time,
                powerDataFromDB[key].Temp_Cabinte > 0
                  ? powerDataFromDB[key].Temp_Cabinte
                  : lastValueNotNull,
                // powerDataFromDB[key].device_data_id,
              ];
            });
            myData3 = Object.keys(powerDataFromDB).map((key) => {
lastValueNotNull = powerDataFromDB[key].Temp_Outdoor ;
return [
                powerDataFromDB[key].time,
                powerDataFromDB[key].Temp_Outdoor >= 0
                  ? powerDataFromDB[key].Temp_Outdoor
                  : lastValueNotNull,
                // powerDataFromDB[key].device_data_id,
              ];
            });

            console.log("last data ID", myData.at(-1));
            setLastDataFront(myData.at(-1));
            setFirstDataBack(myData.at(0));
          } else {
            myData = [];
          }
          if (myData.length > 0) {
            // console.log("Graph Data!!!!!", myData)
            setData(myData);
            setData1(myData1);
            setData2(myData2);
            setData3(myData3);
            setData4(myData4);
            console.log(data);
          }
          setisLoadingGraph(false);
        })
        .catch((err) => {
          setisLoadingGraph(false);
          console.log(err);
        });
    } else if (Parameter === "soc") {
      UserService.GetLinkedDeviceTemperatureData(
        device_id,
        "Load_Power",
        "daterange",
        `${startDateyear}-${startDatemonth}-${startDateday} 00:00:00`,
        `${endDateyear}-${endDatemonth}-${endDateday} 23:59:59`
      )
        .then((res) => {
          let powerDataFromDB = res.data.data.deviceData;
          let myData;
          let myData1;
          let myData2;
          let myData3;
          let myData4;
          if (typeof powerDataFromDB != "undefined" && !res.data.data.error) {
            myData = Object.keys(powerDataFromDB).map((key) => {
              return [
                powerDataFromDB[key].time,
                powerDataFromDB[key].BAT1_SoC > 0
                  ? powerDataFromDB[key].BAT1_SoC
                  : null,
                // powerDataFromDB[key].device_data_id,
              ];
            });

            myData1 = Object.keys(powerDataFromDB).map((key) => {
              return [
                powerDataFromDB[key].time,
                powerDataFromDB[key].BAT2_SoC > 0
                  ? powerDataFromDB[key].BAT2_SoC
                  : null,
                // powerDataFromDB[key].device_data_id,
              ];
            });
            myData2 = Object.keys(powerDataFromDB).map((key) => {
              return [
                powerDataFromDB[key].time,
                powerDataFromDB[key].BAT3_SoC > 0
                  ? powerDataFromDB[key].BAT3_SoC
                  : null,
                // powerDataFromDB[key].device_data_id,
              ];
            });
            myData3 = Object.keys(powerDataFromDB).map((key) => {
              return [
                powerDataFromDB[key].time,
                powerDataFromDB[key].Temp_Cabinte > 0
                  ? powerDataFromDB[key].Temp_Cabinte
                  : null,
                // powerDataFromDB[key].device_data_id,
              ];
            });
            myData3 = Object.keys(powerDataFromDB).map((key) => {
              return [
                powerDataFromDB[key].time,
                powerDataFromDB[key].Temp_Outdoor >= 0
                  ? powerDataFromDB[key].Temp_Outdoor
                  : null,
                // powerDataFromDB[key].device_data_id,
              ];
            });

            console.log("last data ID", myData.at(-1));
            setLastDataFront(myData.at(-1));
            setFirstDataBack(myData.at(0));
          } else {
            myData = [];
          }
          if (myData.length > 0) {
            // console.log("Graph Data!!!!!", myData)
            setData(myData);
            setData1(myData1);
            setData2(myData2);
            setData3(myData3);
            setData4(myData4);
            console.log(data);
          }
          setisLoadingGraph(false);
        })
        .catch((err) => {
          setisLoadingGraph(false);
          console.log(err);
        });
    } else if (Parameter === "mainbus") {
      UserService.GetLinkedDeviceTemperatureData(
        device_id,
        "MainBus_Voltage",
        "daterange",
        `${startDateyear}-${startDatemonth}-${startDateday} 00:00:00`,
        `${endDateyear}-${endDatemonth}-${endDateday} 23:59:59`
      )
        .then((res) => {
          let powerDataFromDB = res.data.data.deviceData;
          let myData;
          let myData1;
          let myData2;
          let myData3;
          if (typeof powerDataFromDB != "undefined" && !res.data.data.error) {
            myData = Object.keys(powerDataFromDB).map((key) => {
              return [
                powerDataFromDB[key].time,
                powerDataFromDB[key].MainBus_Voltage,
                // powerDataFromDB[key].device_data_id,
              ];
            });
            console.log("last data ID", myData.at(-1));
            setLastDataFront(myData.at(-1));
            setFirstDataBack(myData.at(0));
          } else {
            myData = [];
          }
          if (myData.length > 0) {
            // console.log("Graph Data!!!!!", myData)
            setData(myData);
            setData1([]);
            setData2([]);
            setData3([]);
            console.log(data);
          }
          setisLoadingGraph(false);
        })
        .catch((err) => {
          setisLoadingGraph(false);
          console.log(err);
        });
    } else if (Parameter === "genhours1") {
      UserService.GetLinkedDeviceData(
        device_id,
        "timerange",
        TimeFormat,
        `${startDateyear}-${startDatemonth}-${startDateday} 00:00:00`,
        `${endDateyear}-${endDatemonth}-${endDateday} 23:59:59`
      )
        .then((res) => {
          let powerDataFromDB = res.data.data.deviceData;
          let myData;
          let myData1;
          let myData2;
          let myData3;
          if (typeof powerDataFromDB != "undefined" && !res.data.data.error) {
            myData = Object.keys(powerDataFromDB).map((key) => {
              return [
                powerDataFromDB[key].time,
                powerDataFromDB[key].Genset_HoursDiff,
                // powerDataFromDB[key].device_data_id,
              ];
            });

            console.log("last data ID", myData.at(-1));
            setLastDataFront(myData.at(-1));
            setFirstDataBack(myData.at(0));
          } else {
            myData = [];
          }
          if (myData.length > 0) {
            // console.log("Graph Data!!!!!", myData)
            setData(myData);
            setData1([]);
            setData2([]);
            setData3([]);
            console.log(data);
          }
          setisLoadingGraph(false);
        })
        .catch((err) => {
          setisLoadingGraph(false);
          console.log(err);
        });
    } else if (Parameter === "genhours") {
      console.log(" get data energy");
      let QueryStarDate;
      let QueryendDate;

      if (TimeFormat === "hourly") {
        //24hr
        QueryStarDate = moment(picker.startDate._d).format(
          "YYYY-MM-DD  00:00:00"
        );
        QueryendDate = moment(picker.startDate._d).format(
          "YYYY-MM-DD  23:59:59"
        );
        console.log("<<===", QueryStarDate, QueryendDate);
      } else if (TimeFormat === "daily") {
        QueryStarDate = moment(picker.startDate._d)
          .startOf("month")
          .format("YYYY-MM-DD  00:00:00");
        QueryendDate = moment(picker.startDate._d)
          .endOf("month")
          .format("YYYY-MM-DD  23:59:59");
        console.log("<<===", QueryStarDate, QueryendDate);
      } else {
        QueryStarDate = moment(picker.startDate._d)
          .subtract(1, "year")
          .format("YYYY-MM-DD  00:00:00");
        QueryendDate = moment(picker.startDate._d)
          .subtract(1, "year")
          .format("YYYY-MM-DD  23:59:59");
        console.log("<<===", QueryStarDate, QueryendDate);
      }

      let Param = TimeFormat === "hourly" ? "T_Energy_Hr_A" : "T_Energy_D_A";
      console.log(TimeFormat, Param);

      UserService.GetLinkedDeviceData(
        device_id,
        "timerange",
        TimeFormat,
        QueryStarDate,
        QueryendDate
      )
        .then((res) => {
          let localUnit = "HR";
          let powerDataFromDB = res.data.data.deviceData;
          let myData;
          let myData1;
          if (typeof powerDataFromDB != "undefined" && !res.data.data.error) {
            myData = Object.keys(powerDataFromDB).map((key) => {
              return [
                powerDataFromDB[key].time2,
                powerDataFromDB[key]["Genset_HoursDiff"],
              ];
            });

            myData1 = Object.keys(powerDataFromDB).map((key) => {
              return [
                powerDataFromDB[key].time2,
                powerDataFromDB[key]["SolarDiff"],
              ];
            });

            console.log("last data ID", myData.at(-1));
            setLastDataFront(myData.at(-1));
            setFirstDataBack(myData.at(0));
          } else {
            myData = [];
          }
          if (myData.length > 0) {
            // console.log("Graph Data!!!!!", myData)
            // myData = fillMissingGaps(myData, TimeFormat);

            setData(myData);
            setData1(myData1);
            setChartConfig({
              chart: {
                id: "total-power-chart",
                // type: "line",
                // stacked: true,
                height: 500,
                foreColor: "#000000",
                zoom: {
                  type: "x",
                  enabled: true,
                  autoScaleYaxis: true,
                },
                toolbar: {
                  autoSelected: "zoom",
                },
              },
              tooltip: {
                enabled: true,
                color: "#000000",
                style: {
                  fontSize: "16px",
                  // fontFamily: undefined
                },
                // shared: true,
                x: {
                  format: "dd MMM yyyy hh:mm TT",
                },
                y: [
                  {
                    formatter: function (y) {
                      if (typeof y !== "undefined") {
                        return y.toFixed(2) + " " + localUnit;
                      }
                      return y;
                    },
                  },
                  {
                    formatter: function (y) {
                      if (typeof y !== "undefined") {
                        return y.toFixed(2) + " " + localUnit;
                      }
                      return y;
                    },
                  },
                  {
                    formatter: function (y) {
                      if (typeof y !== "undefined") {
                        return y.toFixed(2) + " " + localUnit;
                      }
                      return y;
                    },
                  },
                  {
                    formatter: function (y) {
                      if (typeof y !== "undefined") {
                        return y.toFixed(2) + " " + localUnit;
                      }
                      return y;
                    },
                  },
                ],
              },
              dataLabels: {
                enabled: false,
              },
              markers: {
                size: 0,
              },
              xaxis: {
                type: "datetime",
                tickAmount: myData.length - 1,
                tooltip: {
                  enabled: false,
                },
                labels: {
                  rotate: -90,
                  rotateAlways: true,
                  minHeight: 100,
                  maxHeight: 160,
                  show: true,
                  align: "left",
                  // trim: true,
                  formatter: function (val, timestamp) {
                    if (TimeFormat === "hourly")
                      return moment(timestamp).format("HH:00");
                    if (TimeFormat === "daily")
                      return moment(timestamp).format("DD MMM");
                    if (TimeFormat === "monthly")
                      return moment(timestamp).format("MMM YYYY");
                  },
                  style: {
                    colors: ["#000000"],
                    fontSize: "15px",
                    // fontFamily: 'Helvetica, Arial, sans-serif',
                    fontWeight: 600,
                  },
                },
              },
              stroke: {
                width: 2,
              },
              colors: ["#64006C", "#0054F1", "#00FF00", "#FFD93D"],
              yaxis: {
                opposite: false,
                formatter: (value) => {
                  return value;
                },
                min: 0,
                decimalsInFloat: 2,
                forceNiceScale: true,
                axisBorder: {
                  show: true,
                  color: "#78909C",
                  offsetX: 0,
                  offsetY: 0,
                },
                axisTicks: {
                  show: true,
                  borderType: "solid",
                  color: "#78909C",
                  width: 6,
                  offsetX: 0,
                  offsetY: 0,
                },
                labels: {
                  show: true,
                  align: "right",
                  minWidth: 0,
                  maxWidth: 160,
                  style: {
                    colors: [],
                    fontSize: "15px",
                    // fontFamily: 'Helvetica, Arial, sans-serif',
                    fontWeight: 600,
                  },
                  offsetX: 0,
                  offsetY: 0,
                  rotate: 0,
                  // formatter: (value) => { return val },
                },
                crosshairs: {
                  show: true,
                  position: "back",
                  stroke: {
                    color: "#b6b6b6",
                    width: 0.6,
                    dashArray: [0, 1, 2],
                  },
                },
                tooltip: {
                  enabled: false,
                  offsetX: 0,
                },
                title: {
                  text: "[" + localUnit + "]",
                  rotate: 0,
                  offsetX: 40,
                  offsetY: -170,
                  style: {
                    color: undefined,
                    fontSize: "15px",
                    // fontFamily: 'Helvetica, Arial, sans-serif',
                    fontWeight: 600,
                    // cssClass: 'apexcharts-yaxis-title',
                  },
                },
              },
              legend: {
                showForSingleSeries: true,
                offsetX: 7,
                //  offsetY:5,
                position: "top",
                fontSize: "18px",
                //  width:1
                //  fontWeight: 800,
                markers: {
                  width: 12,
                  height: 12,
                  strokeWidth: 2,
                  strokeColor: "#fff",
                  fillColors: undefined,
                  radius: 12,
                  offsetX: -5,
                  // offsetY: 5
                },
              },
            });
          }
          setisLoadingGraph(false);
        })
        .catch((err) => {
          setisLoadingGraph(false);
          console.log(err);
        });
    } else if (Parameter === "battcon") {
      UserService.GetLinkedDeviceTemperatureData(
        device_id,
        "Load_Power",
        "daterange",
        `${startDateyear}-${startDatemonth}-${startDateday} 00:00:00`,
        `${endDateyear}-${endDatemonth}-${endDateday} 23:59:59`
      )
        .then((res) => {
          let powerDataFromDB = res.data.data.deviceData;
          let myData;
          let myData1;
          let myData2;
          let myData3;
          if (typeof powerDataFromDB != "undefined" && !res.data.data.error) {
            myData = Object.keys(powerDataFromDB).map((key) => {
              return [
                powerDataFromDB[key].time,
                getBitValue(powerDataFromDB[key].GEN_STAT, 8),
                // powerDataFromDB[key].device_data_id,
              ];
            });

            myData1 = Object.keys(powerDataFromDB).map((key) => {
              return [
                powerDataFromDB[key].time,
                getBitValue(powerDataFromDB[key].GEN_STAT, 9),
                // powerDataFromDB[key].device_data_id,
              ];
            });
            myData2 = Object.keys(powerDataFromDB).map((key) => {
              return [
                powerDataFromDB[key].time,
                getBitValue(powerDataFromDB[key].GEN_STAT, 10),
                // powerDataFromDB[key].device_data_id,
              ];
            });
            myData3 = Object.keys(powerDataFromDB).map((key) => {
              return [
                powerDataFromDB[key].time,
                getBitValue(powerDataFromDB[key].GEN_STAT, 11),
                // powerDataFromDB[key].device_data_id,
              ];
            });

            console.log("last data ID", myData.at(-1));
            setLastDataFront(myData.at(-1));
            setFirstDataBack(myData.at(0));
          } else {
            myData = [];
          }
          if (myData.length > 0) {
            // console.log("Graph Data!!!!!", myData)
            setData(myData);
            setData1(myData1);
            setData2(myData2);
            setData3(myData3);
            console.log(data);
          }
          setisLoadingGraph(false);
        })
        .catch((err) => {
          setisLoadingGraph(false);
          console.log(err);
        });
    }
  };

  function fillMissingGaps(data, type) {
    let result = [];
    const template = {};
    const template2 = {};

    const startOfMonth = parseInt(
      moment(data[0][0]).startOf("month").format("DD")
    );
    const endOfMonth = parseInt(moment(data[0][0]).endOf("month").format("DD"));
    const Day = parseInt(moment(data[0][0]).format("DD"));
    const Month = parseInt(moment(data[0][0]).endOf("month").format("MM"));
    const Year = parseInt(moment(data[0][0]).endOf("month").format("YYYY"));

    if (type == "hourly") {
      data.forEach((x) => {
        template2[moment(x[0]).format("HH")] = x;
      });
      for (let num = 0; num <= 23; num++) {
        if (num < 10) {
          template["0" + num] = [
            moment(`${Year}-${Month}-${Day}`).format(
              `YYYY-MM-DD 0${num}:00:00`
            ),
            null,
          ];
        } else {
          template["" + num] = [
            moment(`${Year}-${Month}-${Day}`).format(`YYYY-MM-DD ${num}:00:00`),
            null,
          ];
        }
      }
      // console.log("+++", template);
      //console.log(template2);

      result = Object.values({ ...template, ...template2 }).sort((a, b) =>
        a[0].localeCompare(b[0], "en", { numeric: true })
      );
      return result;
    } else if (type == "daily") {
      data.forEach((x) => {
        template2[moment(x[0]).format("DD")] = x;
      });

      for (let num = startOfMonth; num <= endOfMonth; num++) {
        if (num < 10) {
          template["0" + num] = [
            moment(`${Year}-${Month}-${num}`).format("YYYY-MM-DD  00:00:00"),
            null,
          ];
        } else {
          template["" + num] = [
            moment(`${Year}-${Month}-${num}`).format("YYYY-MM-DD  00:00:00"),
            null,
          ];
        }
      }

      result = Object.values({ ...template, ...template2 }).sort((a, b) =>
        a[0].localeCompare(b[0], "en", { numeric: true })
      );
      // console.log("***", result);
      // console.log("+++", template);
      // console.log("+++", template2);
      // console.log("*/*/", result);
      return result;
    } else if (type == "monthly") {
      data.forEach((x) => {
        template2[moment(x[0]).format("MM")] = x;
      });

      for (let num = 1; num <= 12; num++) {
        if (num < 10) {
          template["0" + num] = [
            moment(`${Year}-0${num}-02`).format("YYYY-MM-DD  00:00:00"),
            null,
          ];
        } else {
          template["" + num] = [
            moment(`${Year}-${num}-02`).format("YYYY-MM-DD  00:00:00"),
            null,
          ];
        }
      }
      console.log("+++", template2);
      result = Object.values({ ...template, ...template2 }).sort((a, b) =>
        a[0].localeCompare(b[0], "en", { numeric: true })
      );
      // console.log("***", result);
      // console.log("+++", template2);
      // console.log("*/*/", result);
      return result;
    }
  }

  function getTodayData() {
    // console.log("getTodayData", Parameter);
    const endDateObjDate = new Date(Date.now());
    const startDateObjDate = new Date(Date.now() - 3600 * 1000 * 24);
    // console.log("useEffect ", startDateObjDate);
    let startDateday = startDateObjDate.getDate();
    let startDatemonth = startDateObjDate.getMonth() + 1;
    let startDateyear = startDateObjDate.getFullYear();

    let endDateday = endDateObjDate.getDate();
    let endDatemonth = endDateObjDate.getMonth() + 1;
    let endDateyear = endDateObjDate.getFullYear();

    let startDateHour = startDateObjDate.getHours();
    let startDateMinute = startDateObjDate.getMinutes();
    let startDateSecond = startDateObjDate.getMinutes();

    // get initial data from API
    if (device_id) {
      // console.log("!!!!!call power initial use effect!!!!!!!!!!")
      setisLoadingGraph(true);
      // UserService.GetLinkedDeviceData(
      //   device_id,
      //   phase,
      //   "daterange",
      //   `${startDateyear}-${startDatemonth}-${startDateday}`,
      //   `${startDateyear}-${startDatemonth}-${startDateday}`
      // )
      let localUnit;
      if (Parameter === "power") {
        // console.log(" get data power");
        localUnit = "KW";
        UserService.GetLinkedDeviceTemperatureData(
          device_id,
          "Load_Power",
          "daterange",
          `${startDateyear}-${startDatemonth}-${startDateday} ${startDateHour}:${startDateMinute}:${startDateSecond}`,
          `${endDateyear}-${endDatemonth}-${endDateday} 23:59:59`
          // startDate + " 00:00:00"
        )
          .then((res) => {
            //console.log("power initial res:", res.data.data.deviceData)
            let powerDataFromDB = res.data.data.deviceData;
            // console.log("res Data!!!!!!!!!", powerDataFromDB);
            let myData;
            let myData1;
            let myData2;
            let myData3;
            if (typeof powerDataFromDB != "undefined" && !res.data.data.error) {
              myData = Object.keys(powerDataFromDB).map((key) => {
                return [
                  powerDataFromDB[key].time,
                  powerDataFromDB[key].Load_Power,
                  // powerDataFromDB[key].device_data_id,
                ];
              });
              myData1 = Object.keys(powerDataFromDB).map((key) => {
                return [
                  powerDataFromDB[key].time,
                  powerDataFromDB[key].Solar_Power,
                  // powerDataFromDB[key].device_data_id,
                ];
              });
              myData2 = Object.keys(powerDataFromDB).map((key) => {
                return [
                  powerDataFromDB[key].time,
                  powerDataFromDB[key].BATT_Power,
                  // powerDataFromDB[key].device_data_id,
                ];
              });
              // myData3 = Object.keys(powerDataFromDB).map((key) => {
              //   return [
              //     powerDataFromDB[key].time,
              //     powerDataFromDB[key].L3_Power_A,
              //     // powerDataFromDB[key].device_data_id,
              //   ];
              // });
            } else {
              myData = [];
            }
            if (myData.length > 0) {
              // console.log("last data ID", myData.at(-1));
              setLastDataFront(myData.at(-1));
              setFirstDataBack(myData.at(0));
              setendDate(
                `${startDateyear}-${startDatemonth}-${startDateday} ${startDateHour}:${startDateMinute}:${startDateSecond}`
              );
              // console.log("Graph Data!!!!!", myData);
              // myData
              setData(myData);
              setData1(myData1);
              setData2(myData2);
              // setData3(myData3);
              setChartConfig({
                chart: {
                  id: "total-power-chart",
                  // type: "line",
                  // stacked: true,
                  height: 500,
                  foreColor: "#000000",
                  zoom: {
                    type: "x",
                    enabled: true,
                    autoScaleYaxis: true,
                  },
                  toolbar: {
                    autoSelected: "zoom",
                  },
                },
                tooltip: {
                  enabled: true,
                  color: "#000000",
                  style: {
                    fontSize: "16px",
                    // fontFamily: undefined
                  },
                  // shared: true,
                  x: {
                    format: "dd MMM yyyy hh:mm TT",
                  },
                  y: [
                    {
                      formatter: function (y) {
                        if (typeof y !== "undefined") {
                          return y.toFixed(2) + " " + localUnit;
                        }
                        return y;
                      },
                    },
                    {
                      formatter: function (y) {
                        if (typeof y !== "undefined") {
                          return y.toFixed(2) + " " + localUnit;
                        }
                        return y;
                      },
                    },
                    {
                      formatter: function (y) {
                        if (typeof y !== "undefined") {
                          return y.toFixed(2) + " " + localUnit;
                        }
                        return y;
                      },
                    },
                    {
                      formatter: function (y) {
                        if (typeof y !== "undefined") {
                          return y.toFixed(2) + " " + localUnit;
                        }
                        return y;
                      },
                    },
                  ],
                },
                dataLabels: {
                  enabled: false,
                },
                markers: {
                  size: 0,
                },
                xaxis: {
                  type: "datetime",
                  tickAmount: myData.length - 1,
                  tooltip: {
                    enabled: false,
                  },
                  labels: {
                    rotate: -90,
                    rotateAlways: true,
                    minHeight: 100,
                    maxHeight: 160,
                    show: true,
                    align: "left",
                    // trim: true,
                    // formatter: function (val, timestamp) {
                    //   if (TimeFormat === "hourly")
                    //     return moment(timestamp).format("HH:00");
                    //   if (TimeFormat === "daily")
                    //     return moment(timestamp).format("DD MMM");
                    //   if (TimeFormat === "monthly")
                    //     return moment(timestamp).format("MMM YYYY");
                    // },
                    style: {
                      colors: ["#000000"],
                      fontSize: "15px",
                      // fontFamily: 'Helvetica, Arial, sans-serif',
                      fontWeight: 600,
                    },
                  },
                },
                stroke: {
                  width: 2,
                },
                // colors: ["#64006C",  "#0054F1", "#00FF00", "#FFD93D"],
                colors: ["#64006C", "#0054F1", "#00FF00", "#FFD93D"],
                yaxis: {
                  opposite: false,
                  formatter: (value) => {
                    return value;
                  },
                  min: 0,
                  decimalsInFloat: 2,
                  forceNiceScale: true,
                  axisBorder: {
                    show: true,
                    color: "#78909C",
                    offsetX: 0,
                    offsetY: 0,
                  },
                  axisTicks: {
                    show: true,
                    borderType: "solid",
                    color: "#78909C",
                    width: 6,
                    offsetX: 0,
                    offsetY: 0,
                  },
                  labels: {
                    show: true,
                    align: "right",
                    minWidth: 0,
                    maxWidth: 160,
                    style: {
                      colors: [],
                      fontSize: "15px",
                      // fontFamily: 'Helvetica, Arial, sans-serif',
                      fontWeight: 600,
                    },
                    offsetX: 0,
                    offsetY: 0,
                    rotate: 0,
                    // formatter: (value) => { return val },
                  },
                  crosshairs: {
                    show: true,
                    position: "back",
                    stroke: {
                      color: "#b6b6b6",
                      width: 0.6,
                      dashArray: [0, 1, 2],
                    },
                  },
                  tooltip: {
                    enabled: false,
                    offsetX: 0,
                  },
                  title: {
                    text: "[" + localUnit + "]",
                    rotate: 0,
                    offsetX: 40,
                    offsetY: -170,
                    style: {
                      color: undefined,
                      fontSize: "15px",
                      // fontFamily: 'Helvetica, Arial, sans-serif',
                      fontWeight: 600,
                      // cssClass: 'apexcharts-yaxis-title',
                    },
                  },
                },
                legend: {
                  showForSingleSeries: true,
                  offsetX: 7,
                  //  offsetY:5,
                  position: "top",
                  fontSize: "18px",
                  //  width:1
                  //  fontWeight: 800,
                  markers: {
                    width: 12,
                    height: 12,
                    strokeWidth: 2,
                    strokeColor: "#fff",
                    fillColors: undefined,
                    radius: 12,
                    offsetX: -5,
                    // offsetY: 5
                  },
                },
              });
              // console.log(data);
            } else {
              //No Data
              setDataAvailable(false);
            }
            setisLoadingGraph(false);
          })
          .catch((err) => {
            setisLoadingGraph(false);
            setDataAvailable(false);
            // console.log(err)
          });
      } else if (Parameter === "energy") {
        // phase = "Load_Power";
        localUnit = "KWH";
        let QueryStarDate;
        let QueryendDate;
        console.log(" get data energy");
        let Param = TimeFormat === "hourly" ? "T_Energy_Hr_A" : "T_Energy_D_A";

        // if (TimeFormat === "daily" || TimeFormat === "hourly")
        //   QueryendDate = `${startDateyear}-${startDatemonth}-${startDateday} ${startDateHour}:${startDateMinute}:${startDateSecond}`;
        // else QueryendDate = subtract30Days(new Date());

        if (TimeFormat === "hourly") {
          //24hr
          QueryStarDate = moment().format("YYYY-MM-DD  00:00:00");
          QueryendDate = moment().format("YYYY-MM-DD  23:59:59");
        } else if (TimeFormat === "daily") {
          QueryendDate = moment().format("YYYY-MM-DD  00:00:00");
          QueryStarDate = moment()
            .startOf("month")
            .format("YYYY-MM-DD  00:00:00");
        } else {
          QueryendDate = moment().format("YYYY-MM-DD  00:00:00");
          QueryStarDate = moment()
            .subtract(12, "month")
            .format("YYYY-MM-DD  23:59:59");
        }

        console.log("new current ===>>", QueryStarDate, QueryendDate);

        UserService.GetLinkedDeviceData(
          device_id,
          "timerange",
          TimeFormat,
          QueryStarDate,
          QueryendDate
        ) //need to be refrshed
          .then((res) => {
            //console.log("power initial res:", res.data.data.deviceData)
            let powerDataFromDB = res.data.data.deviceData;
            console.log("res Data first!!!!!!!!!", powerDataFromDB);
            let myData;
            let myData1;
            powerDataFromDB = calculateDifferences(
              Object.values(powerDataFromDB)
            );
            console.log("res Data!!!!!!!!!", powerDataFromDB);

            if (typeof powerDataFromDB != "undefined" && !res.data.data.error) {
              myData = powerDataFromDB.map((key) => {
                return [
                  key.time2,
                  key["LoadDiff"],
                  // key.device_data_id,
                ];
              });
              myData1 = powerDataFromDB.map((key) => {
                return [
                  key.time2,
                  key["SolarDiff"],
                  // key.device_data_id,
                ];
              });
            } else {
              myData = [];
            }

            myData = fillMissingGaps(myData, TimeFormat);
            myData1 = fillMissingGaps(myData1, TimeFormat);
            if (myData.length > 0) {
              console.log("last data ID", myData.at(-1));
              setLastDataFront(myData.at(-1));
              setFirstDataBack(myData.at(0));
              setendDate(
                `${startDateyear}-${startDatemonth}-${startDateday} ${startDateHour}:${startDateMinute}:${startDateSecond}`
              );
              // myData = fillMissingGaps(myData, TimeFormat);

              setData(myData);
              setData1(myData1);

              setChartConfig({
                chart: {
                  id: "total-power-chart",
                  // type: "line",
                  // stacked: true,
                  height: 500,
                  foreColor: "#000000",
                  zoom: {
                    type: "x",
                    enabled: true,
                    autoScaleYaxis: true,
                  },
                  toolbar: {
                    autoSelected: "zoom",
                  },
                },
                tooltip: {
                  enabled: true,
                  color: "#000000",
                  style: {
                    fontSize: "16px",
                    // fontFamily: undefined
                  },
                  // shared: true,
                  x: {
                    format: "dd MMM yyyy hh:mm TT",
                  },
                  y: [
                    {
                      formatter: function (y) {
                        if (typeof y !== "undefined") {
                          return y.toFixed(2) + " " + localUnit;
                        }
                        return y;
                      },
                    },
                    {
                      formatter: function (y) {
                        if (typeof y !== "undefined") {
                          return y.toFixed(2) + " " + localUnit;
                        }
                        return y;
                      },
                    },
                    {
                      formatter: function (y) {
                        if (typeof y !== "undefined") {
                          return y.toFixed(2) + " " + localUnit;
                        }
                        return y;
                      },
                    },
                    {
                      formatter: function (y) {
                        if (typeof y !== "undefined") {
                          return y.toFixed(2) + " " + localUnit;
                        }
                        return y;
                      },
                    },
                  ],
                },
                dataLabels: {
                  enabled: false,
                },
                markers: {
                  size: 0,
                },
                xaxis: {
                  type: "datetime",
                  tickAmount: myData.length - 1,
                  tooltip: {
                    enabled: false,
                  },
                  labels: {
                    rotate: -90,
                    rotateAlways: true,
                    minHeight: 100,
                    maxHeight: 160,
                    show: true,
                    align: "left",
                    // trim: true,
                    formatter: function (val, timestamp) {
                      if (TimeFormat === "hourly")
                        return moment(timestamp).format("HH:00");
                      if (TimeFormat === "daily")
                        return moment(timestamp).format("DD MMM");
                      if (TimeFormat === "monthly")
                        return moment(timestamp).format("MMM YYYY");
                    },
                    style: {
                      colors: ["#000000"],
                      fontSize: "15px",
                      // fontFamily: 'Helvetica, Arial, sans-serif',
                      fontWeight: 600,
                    },
                  },
                },
                stroke: {
                  width: 2,
                },
                colors: ["#64006C", "#0054F1", "#00FF00", "#FFD93D"],
                yaxis: {
                  opposite: false,
                  formatter: (value) => {
                    return value;
                  },
                  min: 0,
                  decimalsInFloat: 2,
                  forceNiceScale: true,
                  axisBorder: {
                    show: true,
                    color: "#78909C",
                    offsetX: 0,
                    offsetY: 0,
                  },
                  axisTicks: {
                    show: true,
                    borderType: "solid",
                    color: "#78909C",
                    width: 6,
                    offsetX: 0,
                    offsetY: 0,
                  },
                  labels: {
                    show: true,
                    align: "right",
                    minWidth: 0,
                    maxWidth: 160,
                    style: {
                      colors: [],
                      fontSize: "15px",
                      // fontFamily: 'Helvetica, Arial, sans-serif',
                      fontWeight: 600,
                    },
                    offsetX: 0,
                    offsetY: 0,
                    rotate: 0,
                    // formatter: (value) => { return val },
                  },
                  crosshairs: {
                    show: true,
                    position: "back",
                    stroke: {
                      color: "#b6b6b6",
                      width: 0.6,
                      dashArray: [0, 1, 2],
                    },
                  },
                  tooltip: {
                    enabled: false,
                    offsetX: 0,
                  },
                  title: {
                    text: "[" + localUnit + "]",
                    rotate: 0,
                    offsetX: 40,
                    offsetY: -170,
                    style: {
                      color: undefined,
                      fontSize: "15px",
                      // fontFamily: 'Helvetica, Arial, sans-serif',
                      fontWeight: 600,
                      // cssClass: 'apexcharts-yaxis-title',
                    },
                  },
                },
                legend: {
                  showForSingleSeries: true,
                  offsetX: 7,
                  //  offsetY:5,
                  position: "top",
                  fontSize: "18px",
                  //  width:1
                  //  fontWeight: 800,
                  markers: {
                    width: 12,
                    height: 12,
                    strokeWidth: 2,
                    strokeColor: "#fff",
                    fillColors: undefined,
                    radius: 12,
                    offsetX: -5,
                    // offsetY: 5
                  },
                },
              });
            } else {
              //No Data
              setDataAvailable(false);
            }
            setisLoadingGraph(false);
          })
          .catch((err) => {
            setisLoadingGraph(false);
            setDataAvailable(false);
            // console.log(err)
          });
      } else if (Parameter === "temperature2") {
        localUnit = "°C";
        console.log(" get data temperature");
        UserService.GetLinkedDeviceTemperatureData(
          device_id,
          "Temp_Bat1",
          "daterange",
          `${startDateyear}-${startDatemonth}-${startDateday} ${startDateHour}:${startDateMinute}:${startDateSecond}`,
          `${endDateyear}-${endDatemonth}-${endDateday} 23:59:59`
          // startDate + " 00:00:00"
        )
          .then((res) => {
            //console.log("power initial res:", res.data.data.deviceData)
            let powerDataFromDB = res.data.data.deviceData;
            console.log("res Data!!!!!!!!!", powerDataFromDB);
            let myData;

            if (typeof powerDataFromDB != "undefined" && !res.data.data.error) {
              myData = Object.keys(powerDataFromDB).map((key) => {
                return [
                  powerDataFromDB[key].time,
                  powerDataFromDB[key].value,
                  // powerDataFromDB[key].device_data_id,
                ];
              });
            } else {
              myData = [];
            }
            if (myData.length > 0) {
              console.log("last data ID", myData.at(-1));
              setLastDataFront(myData.at(-1));
              setFirstDataBack(myData.at(0));
              setendDate(
                `${startDateyear}-${startDatemonth}-${startDateday} ${startDateHour}:${startDateMinute}:${startDateSecond}`
              );
              // console.log("Graph Data!!!!!", myData);
              // myData
              setData(myData);
              setChartConfig({
                chart: {
                  id: "total-power-chart",
                  // type: "line",
                  // stacked: true,
                  height: 500,
                  foreColor: "#000000",
                  zoom: {
                    type: "x",
                    enabled: true,
                    autoScaleYaxis: true,
                  },
                  toolbar: {
                    autoSelected: "zoom",
                  },
                },
                tooltip: {
                  enabled: true,
                  color: "#000000",
                  style: {
                    fontSize: "16px",
                    // fontFamily: undefined
                  },
                  // shared: true,
                  x: {
                    format: "dd MMM yyyy hh:mm TT",
                  },
                  y: [
                    {
                      formatter: function (y) {
                        if (typeof y !== "undefined") {
                          return y.toFixed(2) + " " + localUnit;
                        }
                        return y;
                      },
                    },
                    {
                      formatter: function (y) {
                        if (typeof y !== "undefined") {
                          return y.toFixed(2) + " " + localUnit;
                        }
                        return y;
                      },
                    },
                    {
                      formatter: function (y) {
                        if (typeof y !== "undefined") {
                          return y.toFixed(2) + " " + localUnit;
                        }
                        return y;
                      },
                    },
                    {
                      formatter: function (y) {
                        if (typeof y !== "undefined") {
                          return y.toFixed(2) + " " + localUnit;
                        }
                        return y;
                      },
                    },
                  ],
                },
                dataLabels: {
                  enabled: false,
                },
                markers: {
                  size: 0,
                },
                xaxis: {
                  type: "datetime",
                  tickAmount: myData.length - 1,
                  tooltip: {
                    enabled: false,
                  },
                  labels: {
                    rotate: -90,
                    rotateAlways: true,
                    minHeight: 100,
                    maxHeight: 160,
                    show: true,
                    align: "left",
                    // trim: true,
                    // formatter: function (val, timestamp) {
                    //   if (TimeFormat === "hourly")
                    //     return moment(timestamp).format("HH:00");
                    //   if (TimeFormat === "daily")
                    //     return moment(timestamp).format("DD MMM");
                    //   if (TimeFormat === "monthly")
                    //     return moment(timestamp).format("MMM YYYY");
                    // },
                    style: {
                      colors: ["#000000"],
                      fontSize: "15px",
                      // fontFamily: 'Helvetica, Arial, sans-serif',
                      fontWeight: 600,
                    },
                  },
                },
                stroke: {
                  width: 2,
                },
                colors: ["#64006C", "#0054F1", "#00FF00", "#FFD93D"],
                yaxis: {
                  opposite: false,
                  formatter: (value) => {
                    return value;
                  },
                  min: 0,
                  decimalsInFloat: 2,
                  forceNiceScale: true,
                  axisBorder: {
                    show: true,
                    color: "#78909C",
                    offsetX: 0,
                    offsetY: 0,
                  },
                  axisTicks: {
                    show: true,
                    borderType: "solid",
                    color: "#78909C",
                    width: 6,
                    offsetX: 0,
                    offsetY: 0,
                  },
                  labels: {
                    show: true,
                    align: "right",
                    minWidth: 0,
                    maxWidth: 160,
                    style: {
                      colors: [],
                      fontSize: "15px",
                      // fontFamily: 'Helvetica, Arial, sans-serif',
                      fontWeight: 600,
                    },
                    offsetX: 0,
                    offsetY: 0,
                    rotate: 0,
                    // formatter: (value) => { return val },
                  },
                  crosshairs: {
                    show: true,
                    position: "back",
                    stroke: {
                      color: "#b6b6b6",
                      width: 0.6,
                      dashArray: [0, 1, 2],
                    },
                  },
                  tooltip: {
                    enabled: false,
                    offsetX: 0,
                  },
                  title: {
                    text: "[" + localUnit + "]",
                    rotate: 0,
                    offsetX: 40,
                    offsetY: -170,
                    style: {
                      color: undefined,
                      fontSize: "15px",
                      // fontFamily: 'Helvetica, Arial, sans-serif',
                      fontWeight: 600,
                      // cssClass: 'apexcharts-yaxis-title',
                    },
                  },
                },
                legend: {
                  showForSingleSeries: true,
                  offsetX: 7,
                  //  offsetY:5,
                  position: "top",
                  fontSize: "18px",
                  //  width:1
                  //  fontWeight: 800,
                  markers: {
                    width: 12,
                    height: 12,
                    strokeWidth: 2,
                    strokeColor: "#fff",
                    fillColors: undefined,
                    radius: 12,
                    offsetX: -5,
                    // offsetY: 5
                  },
                },
              });

              // console.log(data);
            } else {
              //No Data
              setDataAvailable(false);
            }
            setisLoadingGraph(false);
          })
          .catch((err) => {
            setisLoadingGraph(false);
            setDataAvailable(false);
            // console.log(err)
          });
      } else if (Parameter === "temperature") {
        // console.log(" get data power");
        localUnit = "°C";
        UserService.GetLinkedDeviceTemperatureData(
          device_id,
          "Load_Power",
          "daterange",
          `${startDateyear}-${startDatemonth}-${startDateday} ${startDateHour}:${startDateMinute}:${startDateSecond}`,
          `${endDateyear}-${endDatemonth}-${endDateday} 23:59:59`
          // startDate + " 00:00:00"
        )
          .then((res) => {
            //console.log("power initial res:", res.data.data.deviceData)
            let powerDataFromDB = res.data.data.deviceData;
            // console.log("res Data!!!!!!!!!", powerDataFromDB);
            let myData;
            let myData1;
            let myData2;
            let myData3;
            let myData4;
            let lastValueNotNull = 0 ;
            if (typeof powerDataFromDB != "undefined" && !res.data.data.error) {
              myData = Object.keys(powerDataFromDB).map((key) => {
                if(powerDataFromDB[key].Temp_Bat1 > 0) 
                lastValueNotNull = powerDataFromDB[key].Temp_Bat1 ;
                return [
                  powerDataFromDB[key].time,
                  powerDataFromDB[key].Temp_Bat1 > 0
                    ? powerDataFromDB[key].Temp_Bat1
                    : lastValueNotNull,
                  // powerDataFromDB[key].device_data_id,
                ];
              });
              myData1 = Object.keys(powerDataFromDB).map((key) => {
                if(powerDataFromDB[key].Temp_Bat2 > 0) 
                lastValueNotNull = powerDataFromDB[key].Temp_Bat2 ;
                return [
                  powerDataFromDB[key].time,
                  powerDataFromDB[key].Temp_Bat2 > 0
                    ? powerDataFromDB[key].Temp_Bat2
                    : lastValueNotNull,
                  // powerDataFromDB[key].device_data_id,
                ];
              });
              myData2 = Object.keys(powerDataFromDB).map((key) => {
                if(powerDataFromDB[key].Temp_Bat3 > 0) 
                lastValueNotNull = powerDataFromDB[key].Temp_Bat3 ;
                return [
                  powerDataFromDB[key].time,
                  powerDataFromDB[key].Temp_Bat3 > 0
                    ? powerDataFromDB[key].Temp_Bat3
                    : lastValueNotNull,
                  // powerDataFromDB[key].device_data_id,
                ];
              });
              myData3 = Object.keys(powerDataFromDB).map((key) => {
                if(powerDataFromDB[key].Temp_Cabinte > 0) 
                lastValueNotNull = powerDataFromDB[key].Temp_Cabinte ;
                return [
                  powerDataFromDB[key].time,
                  powerDataFromDB[key].Temp_Cabinte > 0
                    ? powerDataFromDB[key].Temp_Cabinte
                    : lastValueNotNull,
                  // powerDataFromDB[key].device_data_id,
                ];
              });
              myData4 = Object.keys(powerDataFromDB).map((key) => {
                if(powerDataFromDB[key].Temp_Outdoor >= 0) 
                lastValueNotNull = powerDataFromDB[key].Temp_Outdoor ;
                return [
                  powerDataFromDB[key].time,
                  powerDataFromDB[key].Temp_Outdoor >= 0
                    ? powerDataFromDB[key].Temp_Outdoor
                    : lastValueNotNull,

                  // powerDataFromDB[key].device_data_id,
                ];
              });
            } else {
              myData = [];
            }
            if (myData.length > 0) {
              // console.log("last data ID", myData.at(-1));
              setLastDataFront(myData.at(-1));
              setFirstDataBack(myData.at(0));
              setendDate(
                `${startDateyear}-${startDatemonth}-${startDateday} ${startDateHour}:${startDateMinute}:${startDateSecond}`
              );
              // console.log("Graph Data!!!!!", myData);
              // myData
              setData(myData);
              setData1(myData1);
              setData2(myData2);
              setData3(myData3);
              setData4(myData4);

              setChartConfig({
                chart: {
                  id: "total-power-chart",
                  // type: "line",
                  // stacked: true,
                  height: 500,
                  foreColor: "#000000",
                  zoom: {
                    type: "x",
                    enabled: true,
                    autoScaleYaxis: true,
                  },
                  toolbar: {
                    autoSelected: "zoom",
                  },
                },
                tooltip: {
                  enabled: true,
                  color: "#000000",
                  style: {
                    fontSize: "16px",
                    // fontFamily: undefined
                  },
                  // shared: true,
                  x: {
                    format: "dd MMM yyyy hh:mm TT",
                  },
                  y: [
                    {
                      formatter: function (y) {
                        if (typeof y !== "undefined") {
                          return y.toFixed(2) + " " + localUnit;
                        }
                        return y;
                      },
                    },
                    {
                      formatter: function (y) {
                        if (typeof y !== "undefined") {
                          return y.toFixed(2) + " " + localUnit;
                        }
                        return y;
                      },
                    },
                    {
                      formatter: function (y) {
                        if (typeof y !== "undefined") {
                          return y.toFixed(2) + " " + localUnit;
                        }
                        return y;
                      },
                    },
                    {
                      formatter: function (y) {
                        if (typeof y !== "undefined") {
                          return y.toFixed(2) + " " + localUnit;
                        }
                        return y;
                      },
                    },
                  ],
                },
                dataLabels: {
                  enabled: false,
                },
                markers: {
                  size: 0,
                },
                xaxis: {
                  type: "datetime",
                  tickAmount: myData.length - 1,
                  tooltip: {
                    enabled: false,
                  },
                  labels: {
                    rotate: -90,
                    rotateAlways: true,
                    minHeight: 100,
                    maxHeight: 160,
                    show: true,
                    align: "left",
                    // trim: true,
                    // formatter: function (val, timestamp) {
                    //   if (TimeFormat === "hourly")
                    //     return moment(timestamp).format("HH:00");
                    //   if (TimeFormat === "daily")
                    //     return moment(timestamp).format("DD MMM");
                    //   if (TimeFormat === "monthly")
                    //     return moment(timestamp).format("MMM YYYY");
                    // },
                    style: {
                      colors: ["#000000"],
                      fontSize: "15px",
                      // fontFamily: 'Helvetica, Arial, sans-serif',
                      fontWeight: 600,
                    },
                  },
                },
                stroke: {
                  width: 2,
                },
                colors: ["#00FF00", "#FFA500", "#FFFF00", "#800080", "#FF0000"],
                yaxis: {
                  opposite: false,
                  formatter: (value) => {
                    return value;
                  },
                  min: 0,
                  decimalsInFloat: 2,
                  forceNiceScale: true,
                  axisBorder: {
                    show: true,
                    color: "#78909C",
                    offsetX: 0,
                    offsetY: 0,
                  },
                  axisTicks: {
                    show: true,
                    borderType: "solid",
                    color: "#78909C",
                    width: 6,
                    offsetX: 0,
                    offsetY: 0,
                  },
                  labels: {
                    show: true,
                    align: "right",
                    minWidth: 0,
                    maxWidth: 160,
                    style: {
                      colors: [],
                      fontSize: "15px",
                      // fontFamily: 'Helvetica, Arial, sans-serif',
                      fontWeight: 600,
                    },
                    offsetX: 0,
                    offsetY: 0,
                    rotate: 0,
                    // formatter: (value) => { return val },
                  },
                  crosshairs: {
                    show: true,
                    position: "back",
                    stroke: {
                      color: "#b6b6b6",
                      width: 0.6,
                      dashArray: [0, 1, 2],
                    },
                  },
                  tooltip: {
                    enabled: false,
                    offsetX: 0,
                  },
                  title: {
                    text: "[" + localUnit + "]",
                    rotate: 0,
                    offsetX: 40,
                    offsetY: -170,
                    style: {
                      color: undefined,
                      fontSize: "15px",
                      // fontFamily: 'Helvetica, Arial, sans-serif',
                      fontWeight: 600,
                      // cssClass: 'apexcharts-yaxis-title',
                    },
                  },
                },
                legend: {
                  showForSingleSeries: true,
                  offsetX: 7,
                  //  offsetY:5,
                  position: "top",
                  fontSize: "18px",
                  //  width:1
                  //  fontWeight: 800,
                  markers: {
                    width: 12,
                    height: 12,
                    strokeWidth: 2,
                    strokeColor: "#fff",
                    fillColors: undefined,
                    radius: 12,
                    offsetX: -5,
                    // offsetY: 5
                  },
                },
              });
              // console.log(data);
            } else {
              //No Data
              setDataAvailable(false);
            }
            setisLoadingGraph(false);
          })
          .catch((err) => {
            setisLoadingGraph(false);
            setDataAvailable(false);
            // console.log(err)
          });
      } else if (Parameter === "soc") {
        // console.log(" get data power");
        localUnit = "%";
        UserService.GetLinkedDeviceTemperatureData(
          device_id,
          "Load_Power",
          "daterange",
          `${startDateyear}-${startDatemonth}-${startDateday} ${startDateHour}:${startDateMinute}:${startDateSecond}`,
          `${endDateyear}-${endDatemonth}-${endDateday} 23:59:59`
          // startDate + " 00:00:00"
        )
          .then((res) => {
            //console.log("power initial res:", res.data.data.deviceData)
            let powerDataFromDB = res.data.data.deviceData;
            // console.log("res Data!!!!!!!!!", powerDataFromDB);
            let myData;
            let myData1;
            let myData2;
            let myData3;
            let myData4;
            if (typeof powerDataFromDB != "undefined" && !res.data.data.error) {
              myData = Object.keys(powerDataFromDB).map((key) => {
                return [
                  powerDataFromDB[key].time,
                  powerDataFromDB[key].BAT1_SoC > 0
                    ? powerDataFromDB[key].BAT1_SoC
                    : null,
                  // powerDataFromDB[key].device_data_id,
                ];
              });
              myData1 = Object.keys(powerDataFromDB).map((key) => {
                return [
                  powerDataFromDB[key].time,
                  powerDataFromDB[key].BAT2_SoC > 0
                    ? powerDataFromDB[key].BAT2_SoC
                    : null,
                  // powerDataFromDB[key].device_data_id,
                ];
              });
              myData2 = Object.keys(powerDataFromDB).map((key) => {
                return [
                  powerDataFromDB[key].time,
                  powerDataFromDB[key].BAT3_SoC > 0
                    ? powerDataFromDB[key].BAT3_SoC
                    : null,
                  // powerDataFromDB[key].device_data_id,
                ];
              });
              myData3 = Object.keys(powerDataFromDB).map((key) => {
                return [
                  powerDataFromDB[key].time,
                  powerDataFromDB[key].Temp_Cabinte > 0
                    ? powerDataFromDB[key].Temp_Cabinte
                    : null,
                  // powerDataFromDB[key].device_data_id,
                ];
              });
              myData4 = Object.keys(powerDataFromDB).map((key) => {
                return [
                  powerDataFromDB[key].time,
                  powerDataFromDB[key].Temp_Outdoor >= 0
                    ? powerDataFromDB[key].Temp_Outdoor
                    : null,

                  // powerDataFromDB[key].device_data_id,
                ];
              });
            } else {
              myData = [];
            }
            if (myData.length > 0) {
              // console.log("last data ID", myData.at(-1));
              setLastDataFront(myData.at(-1));
              setFirstDataBack(myData.at(0));
              setendDate(
                `${startDateyear}-${startDatemonth}-${startDateday} ${startDateHour}:${startDateMinute}:${startDateSecond}`
              );
              // console.log("Graph Data!!!!!", myData);
              // myData
              setData(myData);
              setData1(myData1);
              setData2(myData2);
              setData3(myData3);
              setData4(myData4);

              setChartConfig({
                chart: {
                  id: "total-power-chart",
                  // type: "line",
                  // stacked: true,
                  height: 500,
                  foreColor: "#000000",
                  zoom: {
                    type: "x",
                    enabled: true,
                    autoScaleYaxis: true,
                  },
                  toolbar: {
                    autoSelected: "zoom",
                  },
                },
                tooltip: {
                  enabled: true,
                  color: "#000000",
                  style: {
                    fontSize: "16px",
                    // fontFamily: undefined
                  },
                  // shared: true,
                  x: {
                    format: "dd MMM yyyy hh:mm TT",
                  },
                  y: [
                    {
                      formatter: function (y) {
                        if (typeof y !== "undefined") {
                          return y.toFixed(2) + " " + localUnit;
                        }
                        return y;
                      },
                    },
                    {
                      formatter: function (y) {
                        if (typeof y !== "undefined") {
                          return y.toFixed(2) + " " + localUnit;
                        }
                        return y;
                      },
                    },
                    {
                      formatter: function (y) {
                        if (typeof y !== "undefined") {
                          return y.toFixed(2) + " " + localUnit;
                        }
                        return y;
                      },
                    },
                    {
                      formatter: function (y) {
                        if (typeof y !== "undefined") {
                          return y.toFixed(2) + " " + localUnit;
                        }
                        return y;
                      },
                    },
                  ],
                },
                dataLabels: {
                  enabled: false,
                },
                markers: {
                  size: 0,
                },
                xaxis: {
                  type: "datetime",
                  tickAmount: myData.length - 1,
                  tooltip: {
                    enabled: false,
                  },
                  labels: {
                    rotate: -90,
                    rotateAlways: true,
                    minHeight: 100,
                    maxHeight: 160,
                    show: true,
                    align: "left",
                    // trim: true,
                    // formatter: function (val, timestamp) {
                    //   if (TimeFormat === "hourly")
                    //     return moment(timestamp).format("HH:00");
                    //   if (TimeFormat === "daily")
                    //     return moment(timestamp).format("DD MMM");
                    //   if (TimeFormat === "monthly")
                    //     return moment(timestamp).format("MMM YYYY");
                    // },
                    style: {
                      colors: ["#000000"],
                      fontSize: "15px",
                      // fontFamily: 'Helvetica, Arial, sans-serif',
                      fontWeight: 600,
                    },
                  },
                },
                stroke: {
                  width: 4,
                },
                colors: ["#0054F1", "#800080", "#FF0000", "#800080", "#FF0000"],
                yaxis: {
                  opposite: false,
                  formatter: (value) => {
                    return value;
                  },
                  min: 0,
                  max: 99,
                  decimalsInFloat: 2,
                  forceNiceScale: true,
                  axisBorder: {
                    show: true,
                    color: "#78909C",
                    offsetX: 0,
                    offsetY: 0,
                  },
                  axisTicks: {
                    show: true,
                    borderType: "solid",
                    color: "#78909C",
                    width: 6,
                    offsetX: 0,
                    offsetY: 0,
                  },
                  labels: {
                    show: true,
                    align: "right",
                    minWidth: 0,
                    maxWidth: 160,
                    style: {
                      colors: [],
                      fontSize: "15px",
                      // fontFamily: 'Helvetica, Arial, sans-serif',
                      fontWeight: 600,
                    },
                    offsetX: 0,
                    offsetY: 0,
                    rotate: 0,
                    // formatter: (value) => { return val },
                  },
                  crosshairs: {
                    show: true,
                    position: "back",
                    stroke: {
                      color: "#b6b6b6",
                      width: 0.6,
                      dashArray: [0, 1, 2],
                    },
                  },
                  tooltip: {
                    enabled: false,
                    offsetX: 0,
                  },
                  title: {
                    text: "[" + localUnit + "]",
                    rotate: 0,
                    offsetX: 40,
                    offsetY: -170,
                    style: {
                      color: undefined,
                      fontSize: "15px",
                      // fontFamily: 'Helvetica, Arial, sans-serif',
                      fontWeight: 600,
                      // cssClass: 'apexcharts-yaxis-title',
                    },
                  },
                },
                legend: {
                  showForSingleSeries: true,
                  offsetX: 7,
                  //  offsetY:5,
                  position: "top",
                  fontSize: "18px",
                  //  width:1
                  //  fontWeight: 800,
                  markers: {
                    width: 12,
                    height: 12,
                    strokeWidth: 2,
                    strokeColor: "#fff",
                    fillColors: undefined,
                    radius: 12,
                    offsetX: -5,
                    // offsetY: 5
                  },
                },
              });
              // console.log(data);
            } else {
              //No Data
              setDataAvailable(false);
            }
            setisLoadingGraph(false);
          })
          .catch((err) => {
            setisLoadingGraph(false);
            setDataAvailable(false);
            // console.log(err)
          });
      } else if (Parameter === "mainbus") {
        // console.log(" get data power");
        localUnit = "V";
        UserService.GetLinkedDeviceTemperatureData(
          device_id,
          "MainBus_Voltage",
          "daterange",
          `${startDateyear}-${startDatemonth}-${startDateday} ${startDateHour}:${startDateMinute}:${startDateSecond}`,
          `${endDateyear}-${endDatemonth}-${endDateday} 23:59:59`
          // startDate + " 00:00:00"
        )
          .then((res) => {
            //console.log("power initial res:", res.data.data.deviceData)
            let powerDataFromDB = res.data.data.deviceData;
            // console.log("res Data!!!!!!!!!", powerDataFromDB);
            let myData;
            let myData1;
            let myData2;
            let myData3;
            if (typeof powerDataFromDB != "undefined" && !res.data.data.error) {
              myData = Object.keys(powerDataFromDB).map((key) => {
                return [
                  powerDataFromDB[key].time,
                  powerDataFromDB[key].MainBus_Voltage,
                  // powerDataFromDB[key].device_data_id,
                ];
              });
              // myData1 = Object.keys(powerDataFromDB).map((key) => {
              //   return [
              //     powerDataFromDB[key].time,
              //     powerDataFromDB[key].Solar_Power,
              //     // powerDataFromDB[key].device_data_id,
              //   ];
              // });
              // myData2 = Object.keys(powerDataFromDB).map((key) => {
              //   return [
              //     powerDataFromDB[key].time,
              //     powerDataFromDB[key].BATT_Power,
              //     // powerDataFromDB[key].device_data_id,
              //   ];
              // });
              // myData3 = Object.keys(powerDataFromDB).map((key) => {
              //   return [
              //     powerDataFromDB[key].time,
              //     powerDataFromDB[key].L3_Power_A,
              //     // powerDataFromDB[key].device_data_id,
              //   ];
              // });
            } else {
              myData = [];
            }
            if (myData.length > 0) {
              // console.log("last data ID", myData.at(-1));
              setLastDataFront(myData.at(-1));
              setFirstDataBack(myData.at(0));
              setendDate(
                `${startDateyear}-${startDatemonth}-${startDateday} ${startDateHour}:${startDateMinute}:${startDateSecond}`
              );
              // console.log("Graph Data!!!!!", myData);
              // myData
              setData(myData);
              setData1([]);
              setData2([]);
              // setData3(myData3);
              setChartConfig({
                chart: {
                  id: "total-power-chart",
                  // type: "line",
                  // stacked: true,
                  height: 500,
                  foreColor: "#000000",
                  zoom: {
                    type: "x",
                    enabled: true,
                    autoScaleYaxis: true,
                  },
                  toolbar: {
                    autoSelected: "zoom",
                  },
                },
                tooltip: {
                  enabled: true,
                  color: "#000000",
                  style: {
                    fontSize: "16px",
                    // fontFamily: undefined
                  },
                  // shared: true,
                  x: {
                    format: "dd MMM yyyy hh:mm TT",
                  },
                  y: [
                    {
                      formatter: function (y) {
                        if (typeof y !== "undefined") {
                          return y.toFixed(2) + " " + localUnit;
                        }
                        return y;
                      },
                    },
                    {
                      formatter: function (y) {
                        if (typeof y !== "undefined") {
                          return y.toFixed(2) + " " + localUnit;
                        }
                        return y;
                      },
                    },
                    {
                      formatter: function (y) {
                        if (typeof y !== "undefined") {
                          return y.toFixed(2) + " " + localUnit;
                        }
                        return y;
                      },
                    },
                    {
                      formatter: function (y) {
                        if (typeof y !== "undefined") {
                          return y.toFixed(2) + " " + localUnit;
                        }
                        return y;
                      },
                    },
                  ],
                },
                dataLabels: {
                  enabled: false,
                },
                markers: {
                  size: 0,
                },
                xaxis: {
                  type: "datetime",
                  tickAmount: myData.length - 1,
                  tooltip: {
                    enabled: false,
                  },
                  labels: {
                    rotate: -90,
                    rotateAlways: true,
                    minHeight: 100,
                    maxHeight: 160,
                    show: true,
                    align: "left",
                    // trim: true,
                    // formatter: function (val, timestamp) {
                    //   if (TimeFormat === "hourly")
                    //     return moment(timestamp).format("HH:00");
                    //   if (TimeFormat === "daily")
                    //     return moment(timestamp).format("DD MMM");
                    //   if (TimeFormat === "monthly")
                    //     return moment(timestamp).format("MMM YYYY");
                    // },
                    style: {
                      colors: ["#000000"],
                      fontSize: "15px",
                      // fontFamily: 'Helvetica, Arial, sans-serif',
                      fontWeight: 600,
                    },
                  },
                },
                stroke: {
                  width: 2,
                },
                colors: ["#FF0000", "#0054F1", "#00FF00", "#FFD93D"],
                yaxis: {
                  opposite: false,
                  formatter: (value) => {
                    return value;
                  },
                  min: 0,
                  decimalsInFloat: 2,
                  forceNiceScale: true,
                  axisBorder: {
                    show: true,
                    color: "#78909C",
                    offsetX: 0,
                    offsetY: 0,
                  },
                  axisTicks: {
                    show: true,
                    borderType: "solid",
                    color: "#78909C",
                    width: 6,
                    offsetX: 0,
                    offsetY: 0,
                  },
                  labels: {
                    show: true,
                    align: "right",
                    minWidth: 0,
                    maxWidth: 160,
                    style: {
                      colors: [],
                      fontSize: "15px",
                      // fontFamily: 'Helvetica, Arial, sans-serif',
                      fontWeight: 600,
                    },
                    offsetX: 0,
                    offsetY: 0,
                    rotate: 0,
                    // formatter: (value) => { return val },
                  },
                  crosshairs: {
                    show: true,
                    position: "back",
                    stroke: {
                      color: "#b6b6b6",
                      width: 0.6,
                      dashArray: [0, 1, 2],
                    },
                  },
                  tooltip: {
                    enabled: false,
                    offsetX: 0,
                  },
                  title: {
                    text: "[" + localUnit + "]",
                    rotate: 0,
                    offsetX: 40,
                    offsetY: -170,
                    style: {
                      color: undefined,
                      fontSize: "15px",
                      // fontFamily: 'Helvetica, Arial, sans-serif',
                      fontWeight: 600,
                      // cssClass: 'apexcharts-yaxis-title',
                    },
                  },
                },
                legend: {
                  showForSingleSeries: true,
                  offsetX: 7,
                  //  offsetY:5,
                  position: "top",
                  fontSize: "18px",
                  //  width:1
                  //  fontWeight: 800,
                  markers: {
                    width: 12,
                    height: 12,
                    strokeWidth: 2,
                    strokeColor: "#fff",
                    fillColors: undefined,
                    radius: 12,
                    offsetX: -5,
                    // offsetY: 5
                  },
                },
              });
              // console.log(data);
            } else {
              //No Data
              setDataAvailable(false);
            }
            setisLoadingGraph(false);
          })
          .catch((err) => {
            setisLoadingGraph(false);
            setDataAvailable(false);
            // console.log(err)
          });
      } else if (Parameter === "genhours1") {
        // console.log(" get data power");
        localUnit = "Hr";
        UserService.GetLinkedDeviceData(
          device_id,
          "timerange",
          TimeFormat,
          `${startDateyear}-${startDatemonth}-${startDateday} ${startDateHour}:${startDateMinute}:${startDateSecond}`,
          `${endDateyear}-${endDatemonth}-${endDateday} 23:59:59`
          // startDate + " 00:00:00"
        )
          .then((res) => {
            //console.log("power initial res:", res.data.data.deviceData)
            let powerDataFromDB = res.data.data.deviceData;
            // console.log("res Data!!!!!!!!!", powerDataFromDB);
            let myData;
            let myData1;
            let myData2;
            let myData3;
            if (typeof powerDataFromDB != "undefined" && !res.data.data.error) {
              myData = Object.keys(powerDataFromDB).map((key) => {
                return [
                  powerDataFromDB[key].time,
                  powerDataFromDB[key].Genset_HoursDiff,
                  // powerDataFromDB[key].device_data_id,
                ];
              });
              myData1 = Object.keys(powerDataFromDB).map((key) => {
                return [
                  powerDataFromDB[key].time,
                  powerDataFromDB[key].Solar_Power,
                  // powerDataFromDB[key].device_data_id,
                ];
              });
              myData2 = Object.keys(powerDataFromDB).map((key) => {
                return [
                  powerDataFromDB[key].time,
                  powerDataFromDB[key].BATT_Power,
                  // powerDataFromDB[key].device_data_id,
                ];
              });
              // myData3 = Object.keys(powerDataFromDB).map((key) => {
              //   return [
              //     powerDataFromDB[key].time,
              //     powerDataFromDB[key].L3_Power_A,
              //     // powerDataFromDB[key].device_data_id,
              //   ];
              // });
            } else {
              myData = [];
            }
            if (myData.length > 0) {
              // console.log("last data ID", myData.at(-1));
              setLastDataFront(myData.at(-1));
              setFirstDataBack(myData.at(0));
              setendDate(
                `${startDateyear}-${startDatemonth}-${startDateday} ${startDateHour}:${startDateMinute}:${startDateSecond}`
              );
              // console.log("Graph Data!!!!!", myData);
              // myData
              setData(myData);
              setData1([]);
              setData2([]);
              // setData3(myData3);
              setChartConfig({
                chart: {
                  id: "total-power-chart",
                  // type: "line",
                  // stacked: true,
                  height: 500,
                  foreColor: "#000000",
                  zoom: {
                    type: "x",
                    enabled: true,
                    autoScaleYaxis: true,
                  },
                  toolbar: {
                    autoSelected: "zoom",
                  },
                },
                tooltip: {
                  enabled: true,
                  color: "#000000",
                  style: {
                    fontSize: "16px",
                    // fontFamily: undefined
                  },
                  // shared: true,
                  x: {
                    format: "dd MMM yyyy hh:mm TT",
                  },
                  y: [
                    {
                      formatter: function (y) {
                        if (typeof y !== "undefined") {
                          return y.toFixed(2) + " " + localUnit;
                        }
                        return y;
                      },
                    },
                    {
                      formatter: function (y) {
                        if (typeof y !== "undefined") {
                          return y.toFixed(2) + " " + localUnit;
                        }
                        return y;
                      },
                    },
                    {
                      formatter: function (y) {
                        if (typeof y !== "undefined") {
                          return y.toFixed(2) + " " + localUnit;
                        }
                        return y;
                      },
                    },
                    {
                      formatter: function (y) {
                        if (typeof y !== "undefined") {
                          return y.toFixed(2) + " " + localUnit;
                        }
                        return y;
                      },
                    },
                  ],
                },
                dataLabels: {
                  enabled: false,
                },
                markers: {
                  size: 0,
                },
                xaxis: {
                  type: "datetime",
                  tickAmount: myData.length - 1,
                  tooltip: {
                    enabled: false,
                  },
                  labels: {
                    rotate: -90,
                    rotateAlways: true,
                    minHeight: 100,
                    maxHeight: 160,
                    show: true,
                    align: "left",
                    // trim: true,
                    // formatter: function (val, timestamp) {
                    //   if (TimeFormat === "hourly")
                    //     return moment(timestamp).format("HH:00");
                    //   if (TimeFormat === "daily")
                    //     return moment(timestamp).format("DD MMM");
                    //   if (TimeFormat === "monthly")
                    //     return moment(timestamp).format("MMM YYYY");
                    // },
                    style: {
                      colors: ["#000000"],
                      fontSize: "15px",
                      // fontFamily: 'Helvetica, Arial, sans-serif',
                      fontWeight: 600,
                    },
                  },
                },
                stroke: {
                  width: 2,
                },
                colors: ["#64006C", "#0054F1", "#00FF00", "#FFD93D"],
                yaxis: {
                  opposite: false,
                  formatter: (value) => {
                    return value;
                  },
                  min: 0,
                  decimalsInFloat: 2,
                  forceNiceScale: true,
                  axisBorder: {
                    show: true,
                    color: "#78909C",
                    offsetX: 0,
                    offsetY: 0,
                  },
                  axisTicks: {
                    show: true,
                    borderType: "solid",
                    color: "#78909C",
                    width: 6,
                    offsetX: 0,
                    offsetY: 0,
                  },
                  labels: {
                    show: true,
                    align: "right",
                    minWidth: 0,
                    maxWidth: 160,
                    style: {
                      colors: [],
                      fontSize: "15px",
                      // fontFamily: 'Helvetica, Arial, sans-serif',
                      fontWeight: 600,
                    },
                    offsetX: 0,
                    offsetY: 0,
                    rotate: 0,
                    // formatter: (value) => { return val },
                  },
                  crosshairs: {
                    show: true,
                    position: "back",
                    stroke: {
                      color: "#b6b6b6",
                      width: 0.6,
                      dashArray: [0, 1, 2],
                    },
                  },
                  tooltip: {
                    enabled: false,
                    offsetX: 0,
                  },
                  title: {
                    text: "[" + localUnit + "]",
                    rotate: 0,
                    offsetX: 40,
                    offsetY: -170,
                    style: {
                      color: undefined,
                      fontSize: "15px",
                      // fontFamily: 'Helvetica, Arial, sans-serif',
                      fontWeight: 600,
                      // cssClass: 'apexcharts-yaxis-title',
                    },
                  },
                },
                legend: {
                  showForSingleSeries: true,
                  offsetX: 7,
                  //  offsetY:5,
                  position: "top",
                  fontSize: "18px",
                  //  width:1
                  //  fontWeight: 800,
                  markers: {
                    width: 12,
                    height: 12,
                    strokeWidth: 2,
                    strokeColor: "#fff",
                    fillColors: undefined,
                    radius: 12,
                    offsetX: -5,
                    // offsetY: 5
                  },
                },
              });
              // console.log(data);
            } else {
              //No Data
              setDataAvailable(false);
            }
            setisLoadingGraph(false);
          })
          .catch((err) => {
            setisLoadingGraph(false);
            setDataAvailable(false);
            // console.log(err)
          });
      } else if (Parameter === "genhours") {
        // phase = "Load_Power";
        localUnit = "HR";
        let QueryStarDate;
        let QueryendDate;
        console.log(" get data energy");
        let Param = TimeFormat === "hourly" ? "T_Energy_Hr_A" : "T_Energy_D_A";

        // if (TimeFormat === "daily" || TimeFormat === "hourly")
        //   QueryendDate = `${startDateyear}-${startDatemonth}-${startDateday} ${startDateHour}:${startDateMinute}:${startDateSecond}`;
        // else QueryendDate = subtract30Days(new Date());

        if (TimeFormat === "hourly") {
          //24hr
          QueryStarDate = moment().format("YYYY-MM-DD  00:00:00");
          QueryendDate = moment().format("YYYY-MM-DD  23:59:59");
        } else if (TimeFormat === "daily") {
          QueryendDate = moment().format("YYYY-MM-DD  00:00:00");
          QueryStarDate = moment()
            .startOf("month")
            .format("YYYY-MM-DD  00:00:00");
        } else {
          QueryendDate = moment().format("YYYY-MM-DD  00:00:00");
          QueryStarDate = moment()
            .subtract(12, "month")
            .format("YYYY-MM-DD  23:59:59");
        }

        console.log("new current ===>>", QueryStarDate, QueryendDate);

        UserService.GetLinkedDeviceData(
          device_id,
          "timerange",
          TimeFormat,
          QueryStarDate,
          QueryendDate
        ) //need to be refrshed
          .then((res) => {
            //console.log("power initial res:", res.data.data.deviceData)
            let powerDataFromDB = res.data.data.deviceData;
            console.log("res Data!!!!!!!!!", powerDataFromDB);
            let myData;
            let myData1;
            if (typeof powerDataFromDB != "undefined" && !res.data.data.error) {
              myData = Object.keys(powerDataFromDB).map((key) => {
                return [
                  key.time2,
                  key["Genset_HoursDiff"],
                  // key.device_data_id,
                ];
              });
              myData1 = Object.keys(powerDataFromDB).map((key) => {
                return [
                  key.time2,
                  key["SolarDiff"],
                  // key.device_data_id,
                ];
              });
            } else {
              myData = [];
            }
            if (myData.length > 0) {
              console.log("last data ID", myData.at(-1));
              setLastDataFront(myData.at(-1));
              setFirstDataBack(myData.at(0));
              setendDate(
                `${startDateyear}-${startDatemonth}-${startDateday} ${startDateHour}:${startDateMinute}:${startDateSecond}`
              );
              // myData = fillMissingGaps(myData, TimeFormat);

              setData(myData);
              // setData1(myData1);

              setChartConfig({
                chart: {
                  id: "total-power-chart",
                  // type: "line",
                  // stacked: true,
                  height: 500,
                  foreColor: "#000000",
                  zoom: {
                    type: "x",
                    enabled: true,
                    autoScaleYaxis: true,
                  },
                  toolbar: {
                    autoSelected: "zoom",
                  },
                },
                tooltip: {
                  enabled: true,
                  color: "#000000",
                  style: {
                    fontSize: "16px",
                    // fontFamily: undefined
                  },
                  // shared: true,
                  x: {
                    format: "dd MMM yyyy hh:mm TT",
                  },
                  y: [
                    {
                      formatter: function (y) {
                        if (typeof y !== "undefined") {
                          return y.toFixed(2) + " " + localUnit;
                        }
                        return y;
                      },
                    },
                    {
                      formatter: function (y) {
                        if (typeof y !== "undefined") {
                          return y.toFixed(2) + " " + localUnit;
                        }
                        return y;
                      },
                    },
                    {
                      formatter: function (y) {
                        if (typeof y !== "undefined") {
                          return y.toFixed(2) + " " + localUnit;
                        }
                        return y;
                      },
                    },
                    {
                      formatter: function (y) {
                        if (typeof y !== "undefined") {
                          return y.toFixed(2) + " " + localUnit;
                        }
                        return y;
                      },
                    },
                  ],
                },
                dataLabels: {
                  enabled: false,
                },
                markers: {
                  size: 0,
                },
                xaxis: {
                  type: "datetime",
                  tickAmount: myData.length - 1,
                  tooltip: {
                    enabled: false,
                  },
                  labels: {
                    rotate: -90,
                    rotateAlways: true,
                    minHeight: 100,
                    maxHeight: 160,
                    show: true,
                    align: "left",
                    // trim: true,
                    formatter: function (val, timestamp) {
                      if (TimeFormat === "hourly")
                        return moment(timestamp).format("HH:00");
                      if (TimeFormat === "daily")
                        return moment(timestamp).format("DD MMM");
                      if (TimeFormat === "monthly")
                        return moment(timestamp).format("MMM YYYY");
                    },
                    style: {
                      colors: ["#000000"],
                      fontSize: "15px",
                      // fontFamily: 'Helvetica, Arial, sans-serif',
                      fontWeight: 600,
                    },
                  },
                },
                stroke: {
                  width: 2,
                },
                colors: ["#64006C", "#0054F1", "#00FF00", "#FFD93D"],
                yaxis: {
                  opposite: false,
                  formatter: (value) => {
                    return value;
                  },
                  min: 0,
                  decimalsInFloat: 2,
                  forceNiceScale: true,
                  axisBorder: {
                    show: true,
                    color: "#78909C",
                    offsetX: 0,
                    offsetY: 0,
                  },
                  axisTicks: {
                    show: true,
                    borderType: "solid",
                    color: "#78909C",
                    width: 6,
                    offsetX: 0,
                    offsetY: 0,
                  },
                  labels: {
                    show: true,
                    align: "right",
                    minWidth: 0,
                    maxWidth: 160,
                    style: {
                      colors: [],
                      fontSize: "15px",
                      // fontFamily: 'Helvetica, Arial, sans-serif',
                      fontWeight: 600,
                    },
                    offsetX: 0,
                    offsetY: 0,
                    rotate: 0,
                    // formatter: (value) => { return val },
                  },
                  crosshairs: {
                    show: true,
                    position: "back",
                    stroke: {
                      color: "#b6b6b6",
                      width: 0.6,
                      dashArray: [0, 1, 2],
                    },
                  },
                  tooltip: {
                    enabled: false,
                    offsetX: 0,
                  },
                  title: {
                    text: "[" + localUnit + "]",
                    rotate: 0,
                    offsetX: 40,
                    offsetY: -170,
                    style: {
                      color: undefined,
                      fontSize: "15px",
                      // fontFamily: 'Helvetica, Arial, sans-serif',
                      fontWeight: 600,
                      // cssClass: 'apexcharts-yaxis-title',
                    },
                  },
                },
                legend: {
                  showForSingleSeries: true,
                  offsetX: 7,
                  //  offsetY:5,
                  position: "top",
                  fontSize: "18px",
                  //  width:1
                  //  fontWeight: 800,
                  markers: {
                    width: 12,
                    height: 12,
                    strokeWidth: 2,
                    strokeColor: "#fff",
                    fillColors: undefined,
                    radius: 12,
                    offsetX: -5,
                    // offsetY: 5
                  },
                },
              });
            } else {
              //No Data
              setDataAvailable(false);
            }
            setisLoadingGraph(false);
          })
          .catch((err) => {
            setisLoadingGraph(false);
            setDataAvailable(false);
            // console.log(err)
          });
      } else if (Parameter === "battcon") {
        // console.log(" get data power");
        localUnit = "";
        UserService.GetLinkedDeviceTemperatureData(
          device_id,
          "Load_Power",
          "daterange",
          `${startDateyear}-${startDatemonth}-${startDateday} ${startDateHour}:${startDateMinute}:${startDateSecond}`,
          `${endDateyear}-${endDatemonth}-${endDateday} 23:59:59`
          // startDate + " 00:00:00"
        )
          .then((res) => {
            //console.log("power initial res:", res.data.data.deviceData)
            let powerDataFromDB = res.data.data.deviceData;
            // console.log("res Data!!!!!!!!!", powerDataFromDB);
            let myData;
            let myData1;
            let myData2;
            let myData3;
            if (typeof powerDataFromDB != "undefined" && !res.data.data.error) {
              myData = Object.keys(powerDataFromDB).map((key) => {
                return [
                  powerDataFromDB[key].time,
                  getBitValue(powerDataFromDB[key].GEN_STAT, 8),
                  // powerDataFromDB[key].device_data_id,
                ];
              });
              myData1 = Object.keys(powerDataFromDB).map((key) => {
                return [
                  powerDataFromDB[key].time,
                  getBitValue(powerDataFromDB[key].GEN_STAT, 9),
                  // powerDataFromDB[key].device_data_id,
                ];
              });
              myData2 = Object.keys(powerDataFromDB).map((key) => {
                return [
                  powerDataFromDB[key].time,
                  getBitValue(powerDataFromDB[key].GEN_STAT, 10),
                  // powerDataFromDB[key].device_data_id,
                ];
              });
              myData3 = Object.keys(powerDataFromDB).map((key) => {
                return [
                  powerDataFromDB[key].time,
                  getBitValue(powerDataFromDB[key].GEN_STAT, 11),

                  // powerDataFromDB[key].device_data_id,
                ];
              });
            } else {
              myData = [];
            }
            if (myData.length > 0) {
              // console.log("last data ID", myData.at(-1));
              setLastDataFront(myData.at(-1));
              setFirstDataBack(myData.at(0));
              setendDate(
                `${startDateyear}-${startDatemonth}-${startDateday} ${startDateHour}:${startDateMinute}:${startDateSecond}`
              );
              // console.log("Graph Data!!!!!", myData);
              // myData
              setData(myData);
              setData1(myData1);
              setData2(myData2);
              setData3(myData3);
              setChartConfig({
                chart: {
                  id: "total-power-chart",
                  // type: "line",
                  // stacked: true,
                  height: 500,
                  foreColor: "#000000",
                  zoom: {
                    type: "x",
                    enabled: true,
                    autoScaleYaxis: true,
                  },
                  toolbar: {
                    autoSelected: "zoom",
                  },
                },
                tooltip: {
                  enabled: true,
                  color: "#000000",
                  style: {
                    fontSize: "16px",
                    // fontFamily: undefined
                  },
                  // shared: true,
                  x: {
                    format: "dd MMM yyyy hh:mm TT",
                  },
                  y: [
                    {
                      formatter: function (y) {
                        if (typeof y !== "undefined") {
                          return y.toFixed(2) + " " + localUnit;
                        }
                        return y;
                      },
                    },
                    {
                      formatter: function (y) {
                        if (typeof y !== "undefined") {
                          return y.toFixed(2) + " " + localUnit;
                        }
                        return y;
                      },
                    },
                    {
                      formatter: function (y) {
                        if (typeof y !== "undefined") {
                          return y.toFixed(2) + " " + localUnit;
                        }
                        return y;
                      },
                    },
                    {
                      formatter: function (y) {
                        if (typeof y !== "undefined") {
                          return y.toFixed(2) + " " + localUnit;
                        }
                        return y;
                      },
                    },
                  ],
                },
                dataLabels: {
                  enabled: false,
                },
                markers: {
                  size: 0,
                },
                xaxis: {
                  type: "datetime",
                  tickAmount: myData.length - 1,
                  tooltip: {
                    enabled: false,
                  },
                  labels: {
                    rotate: -90,
                    rotateAlways: true,
                    minHeight: 100,
                    maxHeight: 160,
                    show: true,
                    align: "left",
                    // trim: true,
                    // formatter: function (val, timestamp) {
                    //   if (TimeFormat === "hourly")
                    //     return moment(timestamp).format("HH:00");
                    //   if (TimeFormat === "daily")
                    //     return moment(timestamp).format("DD MMM");
                    //   if (TimeFormat === "monthly")
                    //     return moment(timestamp).format("MMM YYYY");
                    // },
                    style: {
                      colors: ["#000000"],
                      fontSize: "15px",
                      // fontFamily: 'Helvetica, Arial, sans-serif',
                      fontWeight: 600,
                    },
                  },
                },
                stroke: {
                  width: 2,
                },
                // colors: ["#64006C",  "#0054F1", "#00FF00", "#FFD93D"],
                colors: ["#64006C", "#0054F1", "#00FF00", "#FFD93D"],
                yaxis: {
                  opposite: false,
                  formatter: (value) => {
                    return value;
                  },
                  min: 0,
                  decimalsInFloat: 2,
                  forceNiceScale: true,
                  axisBorder: {
                    show: true,
                    color: "#78909C",
                    offsetX: 0,
                    offsetY: 0,
                  },
                  axisTicks: {
                    show: true,
                    borderType: "solid",
                    color: "#78909C",
                    width: 6,
                    offsetX: 0,
                    offsetY: 0,
                  },
                  labels: {
                    show: true,
                    align: "right",
                    minWidth: 0,
                    maxWidth: 160,
                    style: {
                      colors: [],
                      fontSize: "15px",
                      // fontFamily: 'Helvetica, Arial, sans-serif',
                      fontWeight: 600,
                    },
                    offsetX: 0,
                    offsetY: 0,
                    rotate: 0,
                    // formatter: (value) => { return val },
                  },
                  crosshairs: {
                    show: true,
                    position: "back",
                    stroke: {
                      color: "#b6b6b6",
                      width: 0.6,
                      dashArray: [0, 1, 2],
                    },
                  },
                  tooltip: {
                    enabled: false,
                    offsetX: 0,
                  },
                  title: {
                    text: "[" + localUnit + "]",
                    rotate: 0,
                    offsetX: 40,
                    offsetY: -170,
                    style: {
                      color: undefined,
                      fontSize: "15px",
                      // fontFamily: 'Helvetica, Arial, sans-serif',
                      fontWeight: 600,
                      // cssClass: 'apexcharts-yaxis-title',
                    },
                  },
                },
                legend: {
                  showForSingleSeries: true,
                  offsetX: 7,
                  //  offsetY:5,
                  position: "top",
                  fontSize: "18px",
                  //  width:1
                  //  fontWeight: 800,
                  markers: {
                    width: 12,
                    height: 12,
                    strokeWidth: 2,
                    strokeColor: "#fff",
                    fillColors: undefined,
                    radius: 12,
                    offsetX: -5,
                    // offsetY: 5
                  },
                },
              });
              // console.log(data);
            } else {
              //No Data
              setDataAvailable(false);
            }
            setisLoadingGraph(false);
          })
          .catch((err) => {
            setisLoadingGraph(false);
            setDataAvailable(false);
            // console.log(err)
          });
      }
    }
  }

  const getDashboardData = (deviceId) => {
    UserService.GetLatestDeviceStatsDataIelDc(deviceId)
      .then((res) => {
        console.log(
          "!!!!!!!!!!!!!!!!!!!!**********IEL DC:",
          res.data.data.deviceData[0]
        );
        // setPayload(res.data.data.deviceData[0]);
        const result = res.data.data.deviceData[0];
        setProcessData(result);
        setBat1({
          param1: result.BATT1_Voltage,
          param2: result.BATT1_Current,
          param3: result.BATT1_SOC,
        });

        setBat2({
          param1: result.BATT2_Voltage,
          param2: result.BATT2_Current,
          param3: result.BATT2_SOC,
        });
        setBat3({
          param1: result.BATT3_Voltage,
          param2: result.BATT3_Current,
          param3: result.BATT3_SOC,
        });

        setSolarPower({
          param1: result.SOLAR_Power,
          param2: result.SOLAR_Current,
          param3: result.SOLAR_Power,
        });

        setLoad({
          param1: result.MainBus_Voltage,
          param2: result.Load_Current,
          param3: (result.Load_Current * result.MainBus_Voltage) / 1000,
        });

        setDieselGenerator({
          DG_Power: result.DG_Power,
          DG_Status: result.DG_Status,
          Fuel_Percentage: result.Fuel_Percentage,
          DGBATT_Voltage: result.DGBATT_Voltage,
        });
        setIsGraphStatsLoading(0);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getDashboardData(device_id);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      console.log("Refsh 40 second");
      console.log("setInterval", Parameter);
      getDashboardData(device_id);

      if (Rt) getTodayData();
    }, 60 * 1000);
    return () => clearInterval(interval);
  }, [TimeFormat, Parameter, device_id, Rt]);

  useEffect(() => {
    var localUnit = "";
    var tooltipsArr = [];
    var TimeFormatDisplay = "dd MMM yyyy hh:mm TT";
    setData([]);
    setData1([]);
    setData2([]);
    setData3([]);
    getTodayData();
  }, [TimeFormat, Parameter]);

  useEffect(() => {
    setData([]);
    setData1([]);
    setData2([]);
    setData3([]);
    getTodayData();
    setParameter("power");
  }, [device_id]);

  useEffect(() => {
    setData([]);
    setData1([]);
    setData2([]);
    setData3([]);
    if (Rt) {
      getTodayData();
    }
    // console.log("Real time mode", Rt);
  }, [Rt, device_id]);

  function handleBackIClick() {
    setRt(false);
    if (Parameter === "power") {
      UserService.GetLinkedDeviceTemperatureData(
        device_id,
        "Load_Power",
        "daterange",
        formatDate(
          new Date(formatDate(FirstDataBack[0])).getTime() -
            7 * (3600 * 1000 * 24)
        ),
        formatDate(FirstDataBack[0])
      )
        .then((res) => {
          let powerDataFromDB = res.data.data.deviceData;
          let myData;
          let myData1;
          let myData2;
          let myData3;
          let myData4;
          if (typeof powerDataFromDB != "undefined" && !res.data.data.error) {
            myData = Object.keys(powerDataFromDB).map((key) => {
              return [
                powerDataFromDB[key].time,
                powerDataFromDB[key].Load_Power,
                powerDataFromDB[key].device_data_id,
              ];
            });

            myData1 = Object.keys(powerDataFromDB).map((key) => {
              return [
                powerDataFromDB[key].time,
                powerDataFromDB[key].Solar_Power,
                powerDataFromDB[key].device_data_id,
              ];
            });

            myData2 = Object.keys(powerDataFromDB).map((key) => {
              return [
                powerDataFromDB[key].time,
                powerDataFromDB[key].BATT_Power,
                powerDataFromDB[key].device_data_id,
              ];
            });

            // myData3 = Object.keys(powerDataFromDB).map((key) => {
            //   return [
            //     powerDataFromDB[key].time,
            //     powerDataFromDB[key].BATT_Power,
            //     powerDataFromDB[key].device_data_id,
            //   ];
            // });
          } else {
            myData = [];
          }
          if (myData.length > 0) {
            console.log("Last Data Start", myData.at(0));

            if (myData.at(0)[2] === FirstDataBack[2]) {
              console.log("same value repeated possible gap");
            } else {
              setFirstDataBack(myData.at(0));
              setData((previous) => {
                const uniqueThirdElements = new Set();
                return myData.concat(previous).filter((arr) => {
                  if (!uniqueThirdElements.has(arr[2])) {
                    uniqueThirdElements.add(arr[2]);
                    return true;
                  }
                  return false;
                });
              });
              setData1((previous) => {
                const uniqueThirdElements = new Set();
                return myData1.concat(previous).filter((arr) => {
                  if (!uniqueThirdElements.has(arr[2])) {
                    uniqueThirdElements.add(arr[2]);
                    return true;
                  }
                  return false;
                });
              });
              setData2((previous) => {
                const uniqueThirdElements = new Set();
                return myData2.concat(previous).filter((arr) => {
                  if (!uniqueThirdElements.has(arr[2])) {
                    uniqueThirdElements.add(arr[2]);
                    return true;
                  }
                  return false;
                });
              });
              // setData3((previous) => {
              //   const uniqueThirdElements = new Set();
              //   return myData3.concat(previous).filter((arr) => {
              //     if (!uniqueThirdElements.has(arr[2])) {
              //       uniqueThirdElements.add(arr[2]);
              //       return true;
              //     }
              //     return false;
              //   });
              // });
            }
          }
          setisLoadingGraph(false);
        })
        .catch((err) => {
          setisLoadingGraph(false);
          console.log(err);
        });
    } else if (Parameter === "energy") {
      let QueryStarDate;
      let QueryendDate;

      console.log(
        TimeFormat,
        " get data back",
        "LastDataFront",
        LastDataFront,
        "FirstDataBack",
        FirstDataBack
      );
      if (TimeFormat === "hourly") {
        //24hr
        QueryStarDate = moment(FirstDataBack[0])
          .subtract(1, "day")
          .format("YYYY-MM-DD  00:00:00");
        QueryendDate = moment(FirstDataBack[0])
          .subtract(1, "day")
          .format("YYYY-MM-DD  23:59:59");
        console.log("<<===", QueryStarDate, QueryendDate);
      } else if (TimeFormat === "daily") {
        QueryStarDate = moment(FirstDataBack[0])
          .subtract(1, "month")
          .format("YYYY-MM-DD  00:00:00");
        QueryendDate = moment(FirstDataBack[0])
          .subtract(1, "day")
          .format("YYYY-MM-DD  23:59:59");
        console.log("<<===", QueryStarDate, QueryendDate);
      } else {
        QueryStarDate = moment(FirstDataBack[0])
          .subtract(1, "year")
          .format("YYYY-MM-DD  00:00:00");
        QueryendDate = moment(FirstDataBack[0])
          .subtract(1, "year")
          .format("YYYY-MM-DD  23:59:59");
        console.log("<<===", QueryStarDate, QueryendDate);
      }

      UserService.GetLinkedDeviceData(
        device_id,
        "timerange",
        TimeFormat,
        QueryStarDate,
        QueryendDate
      )
        .then((res) => {
          let powerDataFromDB = res.data.data.deviceData;
          let myData;
          let myData1;
          let localUnit = "KWH";
          powerDataFromDB = calculateDifferences(
            Object.values(powerDataFromDB)
          );

          if (typeof powerDataFromDB != "undefined" && !res.data.data.error) {
            myData = powerDataFromDB.map((key) => {
              return [
                key.time2,
                key["LoadDiff"],
                // powerDataFromDB.device_data_id,
              ];
            });
            myData1 = powerDataFromDB.map((key) => {
              return [
                key.time2,
                key["SolarDiff"],
                // powerDataFromDB[key].device_data_id,
              ];
            });
          } else {
            myData = [];
          }

          myData = fillMissingGaps(myData, TimeFormat);
          myData1 = fillMissingGaps(myData1, TimeFormat);
          if (myData.length > 0) {
            console.log("Last Data Start", myData.at(0));
            setFirstDataBack(myData.at(0));
            setLastDataFront(myData.at(-1));
            // myData = fillMissingGaps(myData, TimeFormat);

            setData(myData);
            setData1(myData1);
            setChartConfig({
              chart: {
                id: "total-power-chart",
                // type: "line",
                // stacked: true,
                height: 500,
                foreColor: "#000000",
                zoom: {
                  type: "x",
                  enabled: true,
                  autoScaleYaxis: true,
                },
                toolbar: {
                  autoSelected: "zoom",
                },
              },
              tooltip: {
                enabled: true,
                color: "#000000",
                style: {
                  fontSize: "16px",
                  // fontFamily: undefined
                },
                // shared: true,
                x: {
                  format: "dd MMM yyyy hh:mm TT",
                },
                y: [
                  {
                    formatter: function (y) {
                      if (typeof y !== "undefined") {
                        return y.toFixed(2) + " " + localUnit;
                      }
                      return y;
                    },
                  },
                  {
                    formatter: function (y) {
                      if (typeof y !== "undefined") {
                        return y.toFixed(2) + " " + localUnit;
                      }
                      return y;
                    },
                  },
                  {
                    formatter: function (y) {
                      if (typeof y !== "undefined") {
                        return y.toFixed(2) + " " + localUnit;
                      }
                      return y;
                    },
                  },
                  {
                    formatter: function (y) {
                      if (typeof y !== "undefined") {
                        return y.toFixed(2) + " " + localUnit;
                      }
                      return y;
                    },
                  },
                ],
              },
              dataLabels: {
                enabled: false,
              },
              markers: {
                size: 0,
              },
              xaxis: {
                type: "datetime",
                tickAmount: myData.length - 1,
                tooltip: {
                  enabled: false,
                },
                labels: {
                  rotate: -90,
                  rotateAlways: true,
                  minHeight: 100,
                  maxHeight: 160,
                  show: true,
                  align: "left",
                  // trim: true,
                  formatter: function (val, timestamp) {
                    if (TimeFormat === "hourly")
                      return moment(timestamp).format("HH:00");
                    if (TimeFormat === "daily")
                      return moment(timestamp).format("DD MMM");
                    if (TimeFormat === "monthly")
                      return moment(timestamp).format("MMM YYYY");
                  },
                  style: {
                    colors: ["#000000"],
                    fontSize: "15px",
                    // fontFamily: 'Helvetica, Arial, sans-serif',
                    fontWeight: 600,
                  },
                },
              },
              stroke: {
                width: 2,
              },
              colors: ["#64006C", "#0054F1", "#00FF00", "#FFD93D"],
              yaxis: {
                opposite: false,
                formatter: (value) => {
                  return value;
                },
                min: 0,
                decimalsInFloat: 2,
                forceNiceScale: true,
                axisBorder: {
                  show: true,
                  color: "#78909C",
                  offsetX: 0,
                  offsetY: 0,
                },
                axisTicks: {
                  show: true,
                  borderType: "solid",
                  color: "#78909C",
                  width: 6,
                  offsetX: 0,
                  offsetY: 0,
                },
                labels: {
                  show: true,
                  align: "right",
                  minWidth: 0,
                  maxWidth: 160,
                  style: {
                    colors: [],
                    fontSize: "15px",
                    // fontFamily: 'Helvetica, Arial, sans-serif',
                    fontWeight: 600,
                  },
                  offsetX: 0,
                  offsetY: 0,
                  rotate: 0,
                  // formatter: (value) => { return val },
                },
                crosshairs: {
                  show: true,
                  position: "back",
                  stroke: {
                    color: "#b6b6b6",
                    width: 0.6,
                    dashArray: [0, 1, 2],
                  },
                },
                tooltip: {
                  enabled: false,
                  offsetX: 0,
                },
                title: {
                  text: "[" + localUnit + "]",
                  rotate: 0,
                  offsetX: 40,
                  offsetY: -170,
                  style: {
                    color: undefined,
                    fontSize: "15px",
                    // fontFamily: 'Helvetica, Arial, sans-serif',
                    fontWeight: 600,
                    // cssClass: 'apexcharts-yaxis-title',
                  },
                },
              },
              legend: {
                showForSingleSeries: true,
                offsetX: 7,
                //  offsetY:5,
                position: "top",
                fontSize: "18px",
                //  width:1
                //  fontWeight: 800,
                markers: {
                  width: 12,
                  height: 12,
                  strokeWidth: 2,
                  strokeColor: "#fff",
                  fillColors: undefined,
                  radius: 12,
                  offsetX: -5,
                  // offsetY: 5
                },
              },
            });
          }
          setisLoadingGraph(false);
        })
        .catch((err) => {
          setisLoadingGraph(false);
          console.log(err);
        });
    } else if (Parameter === "energy2") {
      console.log(" get data energy");
      let Param = TimeFormat === "daily" ? "T_Energy_Hr_A" : "T_Energy_D_A";
      UserService.GetLinkedDeviceData(
        device_id,
        "timerange",
        TimeFormat,
        formatDate(
          new Date(formatDate(FirstDataBack[0])).getTime() -
            7 * (3600 * 1000 * 24)
        ),
        formatDate(FirstDataBack[0])
      )
        .then((res) => {
          let powerDataFromDB = res.data.data.deviceData;
          let myData;
          let myData1;
          let myData2;
          let myData3;
          if (typeof powerDataFromDB != "undefined" && !res.data.data.error) {
            myData = Object.keys(powerDataFromDB).map((key) => {
              return [
                powerDataFromDB[key].time2,
                powerDataFromDB[key]["T_Energy_Acc"],
                // powerDataFromDB[key].device_data_id,
              ];
            });

            myData1 = Object.keys(powerDataFromDB).map((key) => {
              return [
                powerDataFromDB[key].time2,
                powerDataFromDB[key]["diff"],
                // powerDataFromDB[key].device_data_id,
              ];
            });
          } else {
            myData = [];
            myData1 = [];
          }
          if (myData.length > 0) {
            console.log("Last Data Start", myData.at(0));

            if (myData.at(0)[0] === FirstDataBack[0]) {
              console.log("same value repeated possible gap");
            } else {
              setFirstDataBack(myData.at(0));
              setData((previous) => {
                const uniqueThirdElements = new Set();
                return myData.concat(previous).filter((arr) => {
                  if (!uniqueThirdElements.has(arr[0])) {
                    uniqueThirdElements.add(arr[0]);
                    return true;
                  }
                  return false;
                });
              });
              setData1((previous) => {
                const uniqueThirdElements = new Set();
                return myData1.concat(previous).filter((arr) => {
                  if (!uniqueThirdElements.has(arr[0])) {
                    uniqueThirdElements.add(arr[0]);
                    return true;
                  }
                  return false;
                });
              });
              // setData1(myData1);
            }
          }
          setisLoadingGraph(false);
        })
        .catch((err) => {
          setisLoadingGraph(false);
          console.log(err);
        });
    } else if (Parameter === "temperature2") {
      UserService.GetLinkedDeviceTemperatureData(
        device_id,
        "temperature",
        "daterange",
        formatDate(
          new Date(formatDate(FirstDataBack[0])).getTime() -
            7 * (3600 * 1000 * 24)
        ),
        formatDate(FirstDataBack[0])
      )
        .then((res) => {
          let powerDataFromDB = res.data.data.deviceData;
          let myData;

          if (typeof powerDataFromDB != "undefined" && !res.data.data.error) {
            myData = Object.keys(powerDataFromDB).map((key) => {
              return [powerDataFromDB[key].time, powerDataFromDB[key].value];
            });
          } else {
            myData = [];
          }
          if (myData.length > 0) {
            console.log("Last Data Start", myData.at(0));

            if (myData.at(0)[2] === FirstDataBack[0]) {
              console.log("same value repeated possible gap");
            } else {
              setFirstDataBack(myData.at(0));
              setData((previous) => {
                const uniqueThirdElements = new Set();
                return myData.concat(previous).filter((arr) => {
                  if (!uniqueThirdElements.has(arr[0])) {
                    uniqueThirdElements.add(arr[0]);
                    return true;
                  }
                  return false;
                });
              });
            }
          }
          setisLoadingGraph(false);
        })
        .catch((err) => {
          setisLoadingGraph(false);
          console.log(err);
        });
    } else if (Parameter === "temperature") {
      UserService.GetLinkedDeviceTemperatureData(
        device_id,
        "Load_Power",
        "daterange",
        formatDate(
          new Date(formatDate(FirstDataBack[0])).getTime() -
            7 * (3600 * 1000 * 24)
        ),
        formatDate(FirstDataBack[0])
      )
        .then((res) => {
          let powerDataFromDB = res.data.data.deviceData;
          let myData;
          let myData1;
          let myData2;
          let myData3;
          let myData4;
          let lastValueNotNull = 0 ;
          if (typeof powerDataFromDB != "undefined" && !res.data.data.error) {
            myData = Object.keys(powerDataFromDB).map((key) => {
              lastValueNotNull = powerDataFromDB[key].Temp_Bat1 ; 
              return [
                powerDataFromDB[key].time,
                powerDataFromDB[key].Temp_Bat1 > 0
                  ? powerDataFromDB[key].Temp_Bat1
                  : lastValueNotNull,
                powerDataFromDB[key].device_data_id,
              ];
            });

            myData1 = Object.keys(powerDataFromDB).map((key) => {
              lastValueNotNull = powerDataFromDB[key].Temp_Bat2 ; 
              return [
                powerDataFromDB[key].time,
                powerDataFromDB[key].Temp_Bat2 > 0
                  ? powerDataFromDB[key].Temp_Bat2
                  : lastValueNotNull,
                powerDataFromDB[key].device_data_id,
              ];
            });

            myData2 = Object.keys(powerDataFromDB).map((key) => {
              lastValueNotNull = powerDataFromDB[key].Temp_Bat3 ; 
              return [
                powerDataFromDB[key].time,
                powerDataFromDB[key].Temp_Bat3 > 0
                  ? powerDataFromDB[key].Temp_Bat3
                  : lastValueNotNull,
                powerDataFromDB[key].device_data_id,
              ];
            });

            myData3 = Object.keys(powerDataFromDB).map((key) => {
              lastValueNotNull = powerDataFromDB[key].Temp_Cabinte ; 
              return [
                powerDataFromDB[key].time,
                powerDataFromDB[key].Temp_Cabinte > 0
                  ? powerDataFromDB[key].Temp_Cabinte
                  : lastValueNotNull,
                powerDataFromDB[key].device_data_id,
              ];
            });

            myData3 = Object.keys(powerDataFromDB).map((key) => {
              lastValueNotNull = powerDataFromDB[key].Temp_Outdoor ; 
              return [
                powerDataFromDB[key].time,
                powerDataFromDB[key].Temp_Outdoor >= 0
                  ? powerDataFromDB[key].Temp_Outdoor
                  : lastValueNotNull,
                powerDataFromDB[key].device_data_id,
              ];
            });
          } else {
            myData = [];
          }
          if (myData.length > 0) {
            console.log("Last Data Start", myData.at(0));

            if (myData.at(0)[2] === FirstDataBack[2]) {
              console.log("same value repeated possible gap");
            } else {
              setFirstDataBack(myData.at(0));
              setData((previous) => {
                const uniqueThirdElements = new Set();
                return myData.concat(previous).filter((arr) => {
                  if (!uniqueThirdElements.has(arr[2])) {
                    uniqueThirdElements.add(arr[2]);
                    return true;
                  }
                  return false;
                });
              });
              setData1((previous) => {
                const uniqueThirdElements = new Set();
                return myData1.concat(previous).filter((arr) => {
                  if (!uniqueThirdElements.has(arr[2])) {
                    uniqueThirdElements.add(arr[2]);
                    return true;
                  }
                  return false;
                });
              });
              setData2((previous) => {
                const uniqueThirdElements = new Set();
                return myData2.concat(previous).filter((arr) => {
                  if (!uniqueThirdElements.has(arr[2])) {
                    uniqueThirdElements.add(arr[2]);
                    return true;
                  }
                  return false;
                });
              });
              setData3((previous) => {
                const uniqueThirdElements = new Set();
                return myData3.concat(previous).filter((arr) => {
                  if (!uniqueThirdElements.has(arr[2])) {
                    uniqueThirdElements.add(arr[2]);
                    return true;
                  }
                  return false;
                });
              });

              setData4((previous) => {
                const uniqueThirdElements = new Set();
                return myData3.concat(previous).filter((arr) => {
                  if (!uniqueThirdElements.has(arr[2])) {
                    uniqueThirdElements.add(arr[2]);
                    return true;
                  }
                  return false;
                });
              });
            }
          }
          setisLoadingGraph(false);
        })
        .catch((err) => {
          setisLoadingGraph(false);
          console.log(err);
        });
    } else if (Parameter === "soc") {
      UserService.GetLinkedDeviceTemperatureData(
        device_id,
        "Load_Power",
        "daterange",
        formatDate(
          new Date(formatDate(FirstDataBack[0])).getTime() -
            7 * (3600 * 1000 * 24)
        ),
        formatDate(FirstDataBack[0])
      )
        .then((res) => {
          let powerDataFromDB = res.data.data.deviceData;
          let myData;
          let myData1;
          let myData2;
          let myData3;
          let myData4;
          if (typeof powerDataFromDB != "undefined" && !res.data.data.error) {
            myData = Object.keys(powerDataFromDB).map((key) => {
              return [
                powerDataFromDB[key].time,
                powerDataFromDB[key].BAT1_SoC > 0
                  ? powerDataFromDB[key].BAT1_SoC
                  : null,
                powerDataFromDB[key].device_data_id,
              ];
            });

            myData1 = Object.keys(powerDataFromDB).map((key) => {
              return [
                powerDataFromDB[key].time,
                powerDataFromDB[key].BAT2_SoC > 0
                  ? powerDataFromDB[key].BAT2_SoC
                  : null,
                powerDataFromDB[key].device_data_id,
              ];
            });

            myData2 = Object.keys(powerDataFromDB).map((key) => {
              return [
                powerDataFromDB[key].time,
                powerDataFromDB[key].BAT3_SoC > 0
                  ? powerDataFromDB[key].BAT3_SoC
                  : null,
                powerDataFromDB[key].device_data_id,
              ];
            });

            myData3 = Object.keys(powerDataFromDB).map((key) => {
              return [
                powerDataFromDB[key].time,
                powerDataFromDB[key].Temp_Cabinte > 0
                  ? powerDataFromDB[key].Temp_Cabinte
                  : null,
                powerDataFromDB[key].device_data_id,
              ];
            });

            myData3 = Object.keys(powerDataFromDB).map((key) => {
              return [
                powerDataFromDB[key].time,
                powerDataFromDB[key].Temp_Outdoor >= 0
                  ? powerDataFromDB[key].Temp_Outdoor
                  : null,
                powerDataFromDB[key].device_data_id,
              ];
            });
          } else {
            myData = [];
          }
          if (myData.length > 0) {
            console.log("Last Data Start", myData.at(0));

            if (myData.at(0)[2] === FirstDataBack[2]) {
              console.log("same value repeated possible gap");
            } else {
              setFirstDataBack(myData.at(0));
              setData((previous) => {
                const uniqueThirdElements = new Set();
                return myData.concat(previous).filter((arr) => {
                  if (!uniqueThirdElements.has(arr[2])) {
                    uniqueThirdElements.add(arr[2]);
                    return true;
                  }
                  return false;
                });
              });
              setData1((previous) => {
                const uniqueThirdElements = new Set();
                return myData1.concat(previous).filter((arr) => {
                  if (!uniqueThirdElements.has(arr[2])) {
                    uniqueThirdElements.add(arr[2]);
                    return true;
                  }
                  return false;
                });
              });
              setData2((previous) => {
                const uniqueThirdElements = new Set();
                return myData2.concat(previous).filter((arr) => {
                  if (!uniqueThirdElements.has(arr[2])) {
                    uniqueThirdElements.add(arr[2]);
                    return true;
                  }
                  return false;
                });
              });
              setData3((previous) => {
                const uniqueThirdElements = new Set();
                return myData3.concat(previous).filter((arr) => {
                  if (!uniqueThirdElements.has(arr[2])) {
                    uniqueThirdElements.add(arr[2]);
                    return true;
                  }
                  return false;
                });
              });

              setData4((previous) => {
                const uniqueThirdElements = new Set();
                return myData3.concat(previous).filter((arr) => {
                  if (!uniqueThirdElements.has(arr[2])) {
                    uniqueThirdElements.add(arr[2]);
                    return true;
                  }
                  return false;
                });
              });
            }
          }
          setisLoadingGraph(false);
        })
        .catch((err) => {
          setisLoadingGraph(false);
          console.log(err);
        });
    } else if (Parameter === "mainbus") {
      UserService.GetLinkedDeviceTemperatureData(
        device_id,
        "Load_Power",
        "daterange",
        formatDate(
          new Date(formatDate(FirstDataBack[0])).getTime() -
            7 * (3600 * 1000 * 24)
        ),
        formatDate(FirstDataBack[0])
      )
        .then((res) => {
          let powerDataFromDB = res.data.data.deviceData;
          let myData;
          let myData1;
          let myData2;
          let myData3;
          let myData4;
          if (typeof powerDataFromDB != "undefined" && !res.data.data.error) {
            myData = Object.keys(powerDataFromDB).map((key) => {
              return [
                powerDataFromDB[key].time,
                powerDataFromDB[key].MainBus_Voltage,
                powerDataFromDB[key].device_data_id,
              ];
            });

            // myData1 = Object.keys(powerDataFromDB).map((key) => {
            //   return [
            //     powerDataFromDB[key].time,
            //     powerDataFromDB[key].Solar_Power,
            //     powerDataFromDB[key].device_data_id,
            //   ];
            // });

            // myData2 = Object.keys(powerDataFromDB).map((key) => {
            //   return [
            //     powerDataFromDB[key].time,
            //     powerDataFromDB[key].BATT_Power,
            //     powerDataFromDB[key].device_data_id,
            //   ];
            // });

            // myData3 = Object.keys(powerDataFromDB).map((key) => {
            //   return [
            //     powerDataFromDB[key].time,
            //     powerDataFromDB[key].BATT_Power,
            //     powerDataFromDB[key].device_data_id,
            //   ];
            // });
          } else {
            myData = [];
          }
          if (myData.length > 0) {
            console.log("Last Data Start", myData.at(0));

            if (myData.at(0)[2] === FirstDataBack[2]) {
              console.log("same value repeated possible gap");
            } else {
              setFirstDataBack(myData.at(0));
              setData((previous) => {
                const uniqueThirdElements = new Set();
                return myData.concat(previous).filter((arr) => {
                  if (!uniqueThirdElements.has(arr[2])) {
                    uniqueThirdElements.add(arr[2]);
                    return true;
                  }
                  return false;
                });
              });
              // setData1((previous) => {
              //   const uniqueThirdElements = new Set();
              //   return myData1.concat(previous).filter((arr) => {
              //     if (!uniqueThirdElements.has(arr[2])) {
              //       uniqueThirdElements.add(arr[2]);
              //       return true;
              //     }
              //     return false;
              //   });
              // });
              // setData2((previous) => {
              //   const uniqueThirdElements = new Set();
              //   return myData2.concat(previous).filter((arr) => {
              //     if (!uniqueThirdElements.has(arr[2])) {
              //       uniqueThirdElements.add(arr[2]);
              //       return true;
              //     }
              //     return false;
              //   });
              // });
              // setData3((previous) => {
              //   const uniqueThirdElements = new Set();
              //   return myData3.concat(previous).filter((arr) => {
              //     if (!uniqueThirdElements.has(arr[2])) {
              //       uniqueThirdElements.add(arr[2]);
              //       return true;
              //     }
              //     return false;
              //   });
              // });
            }
          }
          setisLoadingGraph(false);
        })
        .catch((err) => {
          setisLoadingGraph(false);
          console.log(err);
        });
    } else if (Parameter === "genhours1") {
      UserService.GetLinkedDeviceTemperatureData(
        device_id,
        "Genset_Hours",
        "daterange",
        formatDate(
          new Date(formatDate(FirstDataBack[0])).getTime() -
            7 * (3600 * 1000 * 24)
        ),
        formatDate(FirstDataBack[0])
      )
        .then((res) => {
          let powerDataFromDB = res.data.data.deviceData;
          let myData;
          let myData1;
          let myData2;
          let myData3;
          let myData4;
          if (typeof powerDataFromDB != "undefined" && !res.data.data.error) {
            myData = Object.keys(powerDataFromDB).map((key) => {
              return [
                powerDataFromDB[key].time,
                powerDataFromDB[key].Genset_Hours,
                powerDataFromDB[key].device_data_id,
              ];
            });

            // myData1 = Object.keys(powerDataFromDB).map((key) => {
            //   return [
            //     powerDataFromDB[key].time,
            //     powerDataFromDB[key].Solar_Power,
            //     powerDataFromDB[key].device_data_id,
            //   ];
            // });

            // myData2 = Object.keys(powerDataFromDB).map((key) => {
            //   return [
            //     powerDataFromDB[key].time,
            //     powerDataFromDB[key].BATT_Power,
            //     powerDataFromDB[key].device_data_id,
            //   ];
            // });

            // myData3 = Object.keys(powerDataFromDB).map((key) => {
            //   return [
            //     powerDataFromDB[key].time,
            //     powerDataFromDB[key].BATT_Power,
            //     powerDataFromDB[key].device_data_id,
            //   ];
            // });
          } else {
            myData = [];
          }
          if (myData.length > 0) {
            console.log("Last Data Start", myData.at(0));

            if (myData.at(0)[2] === FirstDataBack[2]) {
              console.log("same value repeated possible gap");
            } else {
              setFirstDataBack(myData.at(0));
              setData((previous) => {
                const uniqueThirdElements = new Set();
                return myData.concat(previous).filter((arr) => {
                  if (!uniqueThirdElements.has(arr[2])) {
                    uniqueThirdElements.add(arr[2]);
                    return true;
                  }
                  return false;
                });
              });
              // setData1((previous) => {
              //   const uniqueThirdElements = new Set();
              //   return myData1.concat(previous).filter((arr) => {
              //     if (!uniqueThirdElements.has(arr[2])) {
              //       uniqueThirdElements.add(arr[2]);
              //       return true;
              //     }
              //     return false;
              //   });
              // });
              // setData2((previous) => {
              //   const uniqueThirdElements = new Set();
              //   return myData2.concat(previous).filter((arr) => {
              //     if (!uniqueThirdElements.has(arr[2])) {
              //       uniqueThirdElements.add(arr[2]);
              //       return true;
              //     }
              //     return false;
              //   });
              // });
              // setData3((previous) => {
              //   const uniqueThirdElements = new Set();
              //   return myData3.concat(previous).filter((arr) => {
              //     if (!uniqueThirdElements.has(arr[2])) {
              //       uniqueThirdElements.add(arr[2]);
              //       return true;
              //     }
              //     return false;
              //   });
              // });
            }
          }
          setisLoadingGraph(false);
        })
        .catch((err) => {
          setisLoadingGraph(false);
          console.log(err);
        });
    } else if (Parameter === "genhours") {
      let QueryStarDate;
      let QueryendDate;

      console.log(
        TimeFormat,
        " get data back",
        "LastDataFront",
        LastDataFront,
        "FirstDataBack",
        FirstDataBack
      );
      if (TimeFormat === "hourly") {
        //24hr
        QueryStarDate = moment(FirstDataBack[0])
          .subtract(1, "day")
          .format("YYYY-MM-DD  00:00:00");
        QueryendDate = moment(FirstDataBack[0])
          .subtract(1, "day")
          .format("YYYY-MM-DD  23:59:59");
        console.log("<<===", QueryStarDate, QueryendDate);
      } else if (TimeFormat === "daily") {
        QueryStarDate = moment(FirstDataBack[0])
          .subtract(1, "month")
          .format("YYYY-MM-DD  00:00:00");
        QueryendDate = moment(FirstDataBack[0])
          .subtract(1, "day")
          .format("YYYY-MM-DD  23:59:59");
        console.log("<<===", QueryStarDate, QueryendDate);
      } else {
        QueryStarDate = moment(FirstDataBack[0])
          .subtract(1, "year")
          .format("YYYY-MM-DD  00:00:00");
        QueryendDate = moment(FirstDataBack[0])
          .subtract(1, "year")
          .format("YYYY-MM-DD  23:59:59");
        console.log("<<===", QueryStarDate, QueryendDate);
      }

      UserService.GetLinkedDeviceData(
        device_id,
        "timerange",
        TimeFormat,
        QueryStarDate,
        QueryendDate
      )
        .then((res) => {
          let powerDataFromDB = res.data.data.deviceData;
          let myData;
          let myData1;
          let localUnit = "HR";

          if (typeof powerDataFromDB != "undefined" && !res.data.data.error) {
            myData = Object.keys(powerDataFromDB).map((key) => {
              return [
                key.time2,
                key["Genset_HoursDiff"],
                // powerDataFromDB.device_data_id,
              ];
            });
            myData1 = Object.keys(powerDataFromDB).map((key) => {
              return [
                key.time2,
                key["SolarDiff"],
                // powerDataFromDB.device_data_id,
              ];
            });
          } else {
            myData = [];
          }
          if (myData.length > 0) {
            console.log("Last Data Start", myData.at(0));
            setFirstDataBack(myData.at(0));
            setLastDataFront(myData.at(-1));
            // myData = fillMissingGaps(myData, TimeFormat);

            setData(myData);
            // setData1(myData1);
            setChartConfig({
              chart: {
                id: "total-power-chart",
                // type: "line",
                // stacked: true,
                height: 500,
                foreColor: "#000000",
                zoom: {
                  type: "x",
                  enabled: true,
                  autoScaleYaxis: true,
                },
                toolbar: {
                  autoSelected: "zoom",
                },
              },
              tooltip: {
                enabled: true,
                color: "#000000",
                style: {
                  fontSize: "16px",
                  // fontFamily: undefined
                },
                // shared: true,
                x: {
                  format: "dd MMM yyyy hh:mm TT",
                },
                y: [
                  {
                    formatter: function (y) {
                      if (typeof y !== "undefined") {
                        return y.toFixed(2) + " " + localUnit;
                      }
                      return y;
                    },
                  },
                  {
                    formatter: function (y) {
                      if (typeof y !== "undefined") {
                        return y.toFixed(2) + " " + localUnit;
                      }
                      return y;
                    },
                  },
                  {
                    formatter: function (y) {
                      if (typeof y !== "undefined") {
                        return y.toFixed(2) + " " + localUnit;
                      }
                      return y;
                    },
                  },
                  {
                    formatter: function (y) {
                      if (typeof y !== "undefined") {
                        return y.toFixed(2) + " " + localUnit;
                      }
                      return y;
                    },
                  },
                ],
              },
              dataLabels: {
                enabled: false,
              },
              markers: {
                size: 0,
              },
              xaxis: {
                type: "datetime",
                tickAmount: myData.length - 1,
                tooltip: {
                  enabled: false,
                },
                labels: {
                  rotate: -90,
                  rotateAlways: true,
                  minHeight: 100,
                  maxHeight: 160,
                  show: true,
                  align: "left",
                  // trim: true,
                  formatter: function (val, timestamp) {
                    if (TimeFormat === "hourly")
                      return moment(timestamp).format("HH:00");
                    if (TimeFormat === "daily")
                      return moment(timestamp).format("DD MMM");
                    if (TimeFormat === "monthly")
                      return moment(timestamp).format("MMM YYYY");
                  },
                  style: {
                    colors: ["#000000"],
                    fontSize: "15px",
                    // fontFamily: 'Helvetica, Arial, sans-serif',
                    fontWeight: 600,
                  },
                },
              },
              stroke: {
                width: 2,
              },
              colors: ["#64006C", "#0054F1", "#00FF00", "#FFD93D"],
              yaxis: {
                opposite: false,
                formatter: (value) => {
                  return value;
                },
                min: 0,
                decimalsInFloat: 2,
                forceNiceScale: true,
                axisBorder: {
                  show: true,
                  color: "#78909C",
                  offsetX: 0,
                  offsetY: 0,
                },
                axisTicks: {
                  show: true,
                  borderType: "solid",
                  color: "#78909C",
                  width: 6,
                  offsetX: 0,
                  offsetY: 0,
                },
                labels: {
                  show: true,
                  align: "right",
                  minWidth: 0,
                  maxWidth: 160,
                  style: {
                    colors: [],
                    fontSize: "15px",
                    // fontFamily: 'Helvetica, Arial, sans-serif',
                    fontWeight: 600,
                  },
                  offsetX: 0,
                  offsetY: 0,
                  rotate: 0,
                  // formatter: (value) => { return val },
                },
                crosshairs: {
                  show: true,
                  position: "back",
                  stroke: {
                    color: "#b6b6b6",
                    width: 0.6,
                    dashArray: [0, 1, 2],
                  },
                },
                tooltip: {
                  enabled: false,
                  offsetX: 0,
                },
                title: {
                  text: "[" + localUnit + "]",
                  rotate: 0,
                  offsetX: 40,
                  offsetY: -170,
                  style: {
                    color: undefined,
                    fontSize: "15px",
                    // fontFamily: 'Helvetica, Arial, sans-serif',
                    fontWeight: 600,
                    // cssClass: 'apexcharts-yaxis-title',
                  },
                },
              },
              legend: {
                showForSingleSeries: true,
                offsetX: 7,
                //  offsetY:5,
                position: "top",
                fontSize: "18px",
                //  width:1
                //  fontWeight: 800,
                markers: {
                  width: 12,
                  height: 12,
                  strokeWidth: 2,
                  strokeColor: "#fff",
                  fillColors: undefined,
                  radius: 12,
                  offsetX: -5,
                  // offsetY: 5
                },
              },
            });
          }
          setisLoadingGraph(false);
        })
        .catch((err) => {
          setisLoadingGraph(false);
          console.log(err);
        });
    } else if (Parameter === "battcon") {
      UserService.GetLinkedDeviceTemperatureData(
        device_id,
        "Load_Power",
        "daterange",
        formatDate(
          new Date(formatDate(FirstDataBack[0])).getTime() -
            7 * (3600 * 1000 * 24)
        ),
        formatDate(FirstDataBack[0])
      )
        .then((res) => {
          let powerDataFromDB = res.data.data.deviceData;
          let myData;
          let myData1;
          let myData2;
          let myData3;
          let myData4;
          if (typeof powerDataFromDB != "undefined" && !res.data.data.error) {
            myData = Object.keys(powerDataFromDB).map((key) => {
              return [
                powerDataFromDB[key].time,
                getBitValue(powerDataFromDB[key].GEN_STAT, 8),
                powerDataFromDB[key].device_data_id,
              ];
            });

            myData1 = Object.keys(powerDataFromDB).map((key) => {
              return [
                powerDataFromDB[key].time,
                getBitValue(powerDataFromDB[key].GEN_STAT, 9),
                powerDataFromDB[key].device_data_id,
              ];
            });

            myData2 = Object.keys(powerDataFromDB).map((key) => {
              return [
                powerDataFromDB[key].time,
                getBitValue(powerDataFromDB[key].GEN_STAT, 10),
                powerDataFromDB[key].device_data_id,
              ];
            });

            myData3 = Object.keys(powerDataFromDB).map((key) => {
              return [
                powerDataFromDB[key].time,
                getBitValue(powerDataFromDB[key].GEN_STAT, 11),
                powerDataFromDB[key].device_data_id,
              ];
            });
          } else {
            myData = [];
          }
          if (myData.length > 0) {
            console.log("Last Data Start", myData.at(0));

            if (myData.at(0)[2] === FirstDataBack[2]) {
              console.log("same value repeated possible gap");
            } else {
              setFirstDataBack(myData.at(0));
              setData((previous) => {
                const uniqueThirdElements = new Set();
                return myData.concat(previous).filter((arr) => {
                  if (!uniqueThirdElements.has(arr[2])) {
                    uniqueThirdElements.add(arr[2]);
                    return true;
                  }
                  return false;
                });
              });
              setData1((previous) => {
                const uniqueThirdElements = new Set();
                return myData1.concat(previous).filter((arr) => {
                  if (!uniqueThirdElements.has(arr[2])) {
                    uniqueThirdElements.add(arr[2]);
                    return true;
                  }
                  return false;
                });
              });
              setData2((previous) => {
                const uniqueThirdElements = new Set();
                return myData2.concat(previous).filter((arr) => {
                  if (!uniqueThirdElements.has(arr[2])) {
                    uniqueThirdElements.add(arr[2]);
                    return true;
                  }
                  return false;
                });
              });
              setData3((previous) => {
                const uniqueThirdElements = new Set();
                return myData3.concat(previous).filter((arr) => {
                  if (!uniqueThirdElements.has(arr[2])) {
                    uniqueThirdElements.add(arr[2]);
                    return true;
                  }
                  return false;
                });
              });
            }
          }
          setisLoadingGraph(false);
        })
        .catch((err) => {
          setisLoadingGraph(false);
          console.log(err);
        });
    }
  }
  function handleForwardClick() {
    setRt(false);
    console.log("handleForwardClick endDate", formatDate(LastDataFront[0]));
    let newEndtDate = new Date(
      new Date(formatDate(LastDataFront[0])).getTime() + 3600 * 1000 * 24
    );

    let newEndtDateday = newEndtDate.getDate();
    let newEndtDatemonth = newEndtDate.getMonth() + 1;
    let newEndtDateyear = newEndtDate.getFullYear();

    let newEndtDateHours = newEndtDate.getHours();
    let newEndtDateMinutes = newEndtDate.getMonth() + 1;
    let newEndtDateSeconds = newEndtDate.getFullYear();

    newEndtDate = `${newEndtDateyear}-${newEndtDatemonth}-${newEndtDateday}`;
    console.log(
      "handleForwardClick",
      formatDate(LastDataFront[0]),
      startDate,
      newEndtDate
    );
    // console.log("current data", data);

    if (Parameter === "power") {
      UserService.GetLinkedDeviceTemperatureData(
        device_id,
        "Load_Power",
        "daterange",
        formatDate(LastDataFront[0]),
        formatDate(
          new Date(formatDate(LastDataFront[0])).getTime() +
            3600 * 1000 * 24 * 3
        )
        // newEndtDate
        // startDate + " 00:00:00"
      )
        .then((res) => {
          console.log(
            "get device temperature data res--",
            res.data.data.deviceData
          );
          let powerDataFromDB = res.data.data.deviceData;
          let myData;
          let myData1;
          let myData2;
          let myData3;
          if (typeof powerDataFromDB != "undefined" && !res.data.data.error) {
            myData = Object.keys(powerDataFromDB).map((key) => {
              return [
                powerDataFromDB[key].time,
                powerDataFromDB[key].Load_Power,
                powerDataFromDB[key].device_data_id,
              ];
            });
            myData1 = Object.keys(powerDataFromDB).map((key) => {
              return [
                powerDataFromDB[key].time,
                powerDataFromDB[key].Solar_Power,
                powerDataFromDB[key].device_data_id,
              ];
            });
            myData2 = Object.keys(powerDataFromDB).map((key) => {
              return [
                powerDataFromDB[key].time,
                powerDataFromDB[key].BATT_Power,
                powerDataFromDB[key].device_data_id,
              ];
            });
            // myData3 = Object.keys(powerDataFromDB).map((key) => {
            //   return [
            //     powerDataFromDB[key].time,
            //     powerDataFromDB[key].L3_Power_A,
            //     powerDataFromDB[key].device_data_id,
            //   ];
            // });
          } else {
            myData = [];
          }
          if (myData.length > 0) {
            // console.log("Graph Data!!!!!", myData)
            // setData(myData);
            setLastDataFront(myData.at(-1));
            setData((previous) => {
              return previous.concat(myData);
            });

            setData1((previous) => {
              return previous.concat(myData1);
            });
            setData2((previous) => {
              return previous.concat(myData2);
            });
            // setData3((previous) => {
            //   return previous.concat(myData3);
            // });

            // console.log(data);
            setendDate(newEndtDate);
          }
          setisLoadingGraph(false);
        })
        .catch((err) => {
          setisLoadingGraph(false);
          console.log(err);
        });
    } else if (Parameter === "energy") {
      let QueryStarDate;
      let QueryendDate;
      let localUnit = "KWH";
      console.log(
        TimeFormat,
        " get data forward",
        "FirstDataBack",
        FirstDataBack,
        "LastDataFront",
        LastDataFront
      );
      console.log(
        "old ===>>",
        moment(LastDataFront[0]).format("YYYY-MM-DD  00:00:00"),
        moment(LastDataFront[0]).format("YYYY-MM-DD  23:59:59")
      );
      if (TimeFormat === "hourly") {
        //24hr
        QueryStarDate = moment(LastDataFront[0])
          .add(1, "day")
          .format("YYYY-MM-DD  00:00:00");
        QueryendDate = moment(LastDataFront[0])
          .add(1, "day")
          .format("YYYY-MM-DD  23:59:59");
        console.log("new ===>>", QueryStarDate, QueryendDate);
      } else if (TimeFormat === "daily") {
        QueryStarDate = moment(LastDataFront[0])
          .add(1, "day")
          .format("YYYY-MM-DD  00:00:00");
        QueryendDate = moment(LastDataFront[0])
          .add(1, "month")
          .format("YYYY-MM-DD  23:59:59");
        console.log("new ===>>", QueryStarDate, QueryendDate);
      } else {
        QueryStarDate = moment(LastDataFront[0])
          .add(1, "day")
          .format("YYYY-MM-DD  00:00:00");
        QueryendDate = moment(LastDataFront[0])
          .add(1, "year")
          .format("YYYY-MM-DD  23:59:59");
        console.log("new ===>>", QueryStarDate, QueryendDate);
      }
      UserService.GetLinkedDeviceData(
        device_id,
        "timerange",
        TimeFormat,
        QueryStarDate,
        QueryendDate
        // newEndtDate
        // startDate + " 00:00:00"
      )
        .then((res) => {
          console.log(
            "get device temperature data res--",
            res.data.data.deviceData
          );
          let powerDataFromDB = res.data.data.deviceData;
          let myData;
          let myData1;
          powerDataFromDB = calculateDifferences(
            Object.values(powerDataFromDB)
          );

          if (typeof powerDataFromDB != "undefined" && !res.data.data.error) {
            myData = powerDataFromDB.map((key) => {
              return [key.time2, key["LoadDiff"]];
            });
            myData1 = powerDataFromDB.map((key) => {
              return [key.time2, key["SolarDiff"]];
            });
          } else {
            myData = [];
          }

          myData = fillMissingGaps(myData, TimeFormat);
          myData1 = fillMissingGaps(myData1, TimeFormat);
          if (myData.length > 0) {
            // myData = fillMissingGaps(myData, TimeFormat);

            setData(myData);
            setData1(myData1);

            setendDate(newEndtDate);
            setFirstDataBack(myData.at(0));
            setLastDataFront(myData.at(-1));
            setChartConfig({
              chart: {
                id: "total-power-chart",
                // type: "line",
                // stacked: true,
                height: 500,
                foreColor: "#000000",
                zoom: {
                  type: "x",
                  enabled: true,
                  autoScaleYaxis: true,
                },
                toolbar: {
                  autoSelected: "zoom",
                },
              },
              tooltip: {
                enabled: true,
                color: "#000000",
                style: {
                  fontSize: "16px",
                  // fontFamily: undefined
                },
                // shared: true,
                x: {
                  format: "dd MMM yyyy hh:mm TT",
                },
                y: [
                  {
                    formatter: function (y) {
                      if (typeof y !== "undefined") {
                        return y.toFixed(2) + " " + localUnit;
                      }
                      return y;
                    },
                  },
                  {
                    formatter: function (y) {
                      if (typeof y !== "undefined") {
                        return y.toFixed(2) + " " + localUnit;
                      }
                      return y;
                    },
                  },
                  {
                    formatter: function (y) {
                      if (typeof y !== "undefined") {
                        return y.toFixed(2) + " " + localUnit;
                      }
                      return y;
                    },
                  },
                  {
                    formatter: function (y) {
                      if (typeof y !== "undefined") {
                        return y.toFixed(2) + " " + localUnit;
                      }
                      return y;
                    },
                  },
                ],
              },
              dataLabels: {
                enabled: false,
              },
              markers: {
                size: 0,
              },
              xaxis: {
                type: "datetime",
                tickAmount: myData.length - 1,
                tooltip: {
                  enabled: false,
                },
                labels: {
                  rotate: -90,
                  rotateAlways: true,
                  minHeight: 100,
                  maxHeight: 160,
                  show: true,
                  align: "left",
                  // trim: true,
                  formatter: function (val, timestamp) {
                    if (TimeFormat === "hourly")
                      return moment(timestamp).format("HH:00");
                    if (TimeFormat === "daily")
                      return moment(timestamp).format("DD MMM");
                    if (TimeFormat === "monthly")
                      return moment(timestamp).format("MMM YYYY");
                  },
                  style: {
                    colors: ["#000000"],
                    fontSize: "15px",
                    // fontFamily: 'Helvetica, Arial, sans-serif',
                    fontWeight: 600,
                  },
                },
              },
              stroke: {
                width: 2,
              },
              colors: ["#64006C", "#0054F1", "#00FF00", "#FFD93D"],
              yaxis: {
                opposite: false,
                formatter: (value) => {
                  return value;
                },
                min: 0,
                decimalsInFloat: 2,
                forceNiceScale: true,
                axisBorder: {
                  show: true,
                  color: "#78909C",
                  offsetX: 0,
                  offsetY: 0,
                },
                axisTicks: {
                  show: true,
                  borderType: "solid",
                  color: "#78909C",
                  width: 6,
                  offsetX: 0,
                  offsetY: 0,
                },
                labels: {
                  show: true,
                  align: "right",
                  minWidth: 0,
                  maxWidth: 160,
                  style: {
                    colors: [],
                    fontSize: "15px",
                    // fontFamily: 'Helvetica, Arial, sans-serif',
                    fontWeight: 600,
                  },
                  offsetX: 0,
                  offsetY: 0,
                  rotate: 0,
                  // formatter: (value) => { return val },
                },
                crosshairs: {
                  show: true,
                  position: "back",
                  stroke: {
                    color: "#b6b6b6",
                    width: 0.6,
                    dashArray: [0, 1, 2],
                  },
                },
                tooltip: {
                  enabled: false,
                  offsetX: 0,
                },
                title: {
                  text: "[" + localUnit + "]",
                  rotate: 0,
                  offsetX: 40,
                  offsetY: -170,
                  style: {
                    color: undefined,
                    fontSize: "15px",
                    // fontFamily: 'Helvetica, Arial, sans-serif',
                    fontWeight: 600,
                    // cssClass: 'apexcharts-yaxis-title',
                  },
                },
              },
              legend: {
                showForSingleSeries: true,
                offsetX: 7,
                //  offsetY:5,
                position: "top",
                fontSize: "18px",
                //  width:1
                //  fontWeight: 800,
                markers: {
                  width: 12,
                  height: 12,
                  strokeWidth: 2,
                  strokeColor: "#fff",
                  fillColors: undefined,
                  radius: 12,
                  offsetX: -5,
                  // offsetY: 5
                },
              },
            });
          }
          setisLoadingGraph(false);
        })
        .catch((err) => {
          setisLoadingGraph(false);
          console.log(err);
        });
    } else if (Parameter === "energy2") {
      console.log(" get data energy");
      let Param = TimeFormat === "daily" ? "T_Energy_Hr_A" : "T_Energy_D_A";
      UserService.GetLinkedDeviceData(
        device_id,
        "timerange",
        TimeFormat,
        formatDate(LastDataFront[0]),
        formatDate(
          new Date(formatDate(LastDataFront[0])).getTime() + 3600 * 1000 * 24
        )
        // newEndtDate
        // startDate + " 00:00:00"
      )
        .then((res) => {
          console.log(
            "get device temperature data res--",
            res.data.data.deviceData
          );
          let powerDataFromDB = res.data.data.deviceData;
          let myData;
          let myData1;
          let myData2;
          let myData3;
          if (typeof powerDataFromDB != "undefined" && !res.data.data.error) {
            myData = Object.keys(powerDataFromDB).map((key) => {
              return [
                powerDataFromDB[key].time2,
                powerDataFromDB[key]["T_Energy_Acc"],
                // powerDataFromDB[key].device_data_id,
              ];
            });

            myData1 = Object.keys(powerDataFromDB).map((key) => {
              return [
                powerDataFromDB[key].time2,
                powerDataFromDB[key]["diff"],
                // powerDataFromDB[key].device_data_id,
              ];
            });
          } else {
            myData = [];
            myData1 = [];
          }
          if (myData.length > 0) {
            // console.log("Graph Data!!!!!", myData)
            // setData(myData);
            setLastDataFront(myData.at(-1));
            setData((previous) => {
              return previous.concat(myData);
            });
            setData1((previous) => {
              return previous.concat(myData1);
            });

            // setData1(myData1);

            // console.log(data);
            setendDate(newEndtDate);
          }
          setisLoadingGraph(false);
        })
        .catch((err) => {
          setisLoadingGraph(false);
          console.log(err);
        });
    } else if (Parameter === "temperature2") {
      UserService.GetLinkedDeviceTemperatureData(
        device_id,
        "temperature",
        "daterange",
        formatDate(LastDataFront[0]),
        formatDate(
          new Date(formatDate(LastDataFront[0])).getTime() + 3600 * 1000 * 24
        )
        // newEndtDate
        // startDate + " 00:00:00"
      )
        .then((res) => {
          console.log(
            "get device temperature data res--",
            res.data.data.deviceData
          );
          let powerDataFromDB = res.data.data.deviceData;
          let myData;

          if (typeof powerDataFromDB != "undefined" && !res.data.data.error) {
            myData = Object.keys(powerDataFromDB).map((key) => {
              return [powerDataFromDB[key].time, powerDataFromDB[key].value];
            });
          } else {
            myData = [];
          }
          if (myData.length > 0) {
            // console.log("Graph Data!!!!!", myData)
            // setData(myData);
            setLastDataFront(myData.at(-1));
            setData((previous) => {
              return previous.concat(myData);
            });

            // console.log(data);
            setendDate(newEndtDate);
          }
          setisLoadingGraph(false);
        })
        .catch((err) => {
          setisLoadingGraph(false);
          console.log(err);
        });
    } else if (Parameter === "temperature") {
      UserService.GetLinkedDeviceTemperatureData(
        device_id,
        "Load_Power",
        "daterange",
        formatDate(LastDataFront[0]),
        formatDate(
          new Date(formatDate(LastDataFront[0])).getTime() + 3600 * 1000 * 24
        )
        // newEndtDate
        // startDate + " 00:00:00"
      )
        .then((res) => {
          console.log(
            "get device temperature data res--",
            res.data.data.deviceData
          );
          let powerDataFromDB = res.data.data.deviceData;
          let myData;
          let myData1;
          let myData2;
          let myData3;
          let myData4;
          let lastValueNotNull;
          if (typeof powerDataFromDB != "undefined" && !res.data.data.error) {
            myData = Object.keys(powerDataFromDB).map((key) => {
            lastValueNotNull = powerDataFromDB[key].Temp_Bat1 ;

              return [
                powerDataFromDB[key].time,
                powerDataFromDB[key].Temp_Bat1 > 0
                  ? powerDataFromDB[key].Temp_Bat1
                  : lastValueNotNull,
                powerDataFromDB[key].device_data_id,
              ];
            });
            myData1 = Object.keys(powerDataFromDB).map((key) => {
            lastValueNotNull = powerDataFromDB[key].Temp_Bat2 ;
            return [
                powerDataFromDB[key].time,
                powerDataFromDB[key].Temp_Bat2 > 0
                  ? powerDataFromDB[key].Temp_Bat2
                  : lastValueNotNull,
                powerDataFromDB[key].device_data_id,
              ];
            });
            myData2 = Object.keys(powerDataFromDB).map((key) => {
            lastValueNotNull = powerDataFromDB[key].Temp_Bat3 ;
            return [
                powerDataFromDB[key].time,
                powerDataFromDB[key].Temp_Bat3 > 0
                  ? powerDataFromDB[key].Temp_Bat3
                  : lastValueNotNull,
                powerDataFromDB[key].device_data_id,
              ];
            });
            myData3 = Object.keys(powerDataFromDB).map((key) => {
            lastValueNotNull = powerDataFromDB[key].Temp_Cabinte ;
            return [
                powerDataFromDB[key].time,
                powerDataFromDB[key].Temp_Cabinte > 0
                  ? powerDataFromDB[key].Temp_Cabinte
                  : lastValueNotNull,
                powerDataFromDB[key].device_data_id,
              ];
            });
            myData4 = Object.keys(powerDataFromDB).map((key) => {
            lastValueNotNull = powerDataFromDB[key].Temp_Outdoor ;
            return [
                powerDataFromDB[key].time,
                powerDataFromDB[key].Temp_Outdoor >= 0
                  ? powerDataFromDB[key].Temp_Outdoor
                  : lastValueNotNull,
                powerDataFromDB[key].device_data_id,
              ];
            });
          } else {
            myData = [];
          }
          if (myData.length > 0) {
            // console.log("Graph Data!!!!!", myData)
            // setData(myData);
            setLastDataFront(myData.at(-1));
            setData((previous) => {
              return previous.concat(myData);
            });

            setData1((previous) => {
              return previous.concat(myData1);
            });
            setData2((previous) => {
              return previous.concat(myData2);
            });
            // setData3((previous) => {
            //   return previous.concat(myData3);
            // });

            // console.log(data);
            setendDate(newEndtDate);
          }
          setisLoadingGraph(false);
        })
        .catch((err) => {
          setisLoadingGraph(false);
          console.log(err);
        });
    } else if (Parameter === "soc") {
      UserService.GetLinkedDeviceTemperatureData(
        device_id,
        "Load_Power",
        "daterange",
        formatDate(LastDataFront[0]),
        formatDate(
          new Date(formatDate(LastDataFront[0])).getTime() + 3600 * 1000 * 24
        )
        // newEndtDate
        // startDate + " 00:00:00"
      )
        .then((res) => {
          console.log(
            "get device temperature data res--",
            res.data.data.deviceData
          );
          let powerDataFromDB = res.data.data.deviceData;
          let myData;
          let myData1;
          let myData2;
          let myData3;
          let myData4;
          if (typeof powerDataFromDB != "undefined" && !res.data.data.error) {
            myData = Object.keys(powerDataFromDB).map((key) => {
              return [
                powerDataFromDB[key].time,
                powerDataFromDB[key].BAT1_SoC > 0
                  ? powerDataFromDB[key].BAT1_SoC
                  : null,
                powerDataFromDB[key].device_data_id,
              ];
            });
            myData1 = Object.keys(powerDataFromDB).map((key) => {
              return [
                powerDataFromDB[key].time,
                powerDataFromDB[key].BAT2_SoC > 0
                  ? powerDataFromDB[key].BAT2_SoC
                  : null,
                powerDataFromDB[key].device_data_id,
              ];
            });
            myData2 = Object.keys(powerDataFromDB).map((key) => {
              return [
                powerDataFromDB[key].time,
                powerDataFromDB[key].BAT3_SoC > 0
                  ? powerDataFromDB[key].BAT3_SoC
                  : null,
                powerDataFromDB[key].device_data_id,
              ];
            });
            myData3 = Object.keys(powerDataFromDB).map((key) => {
              return [
                powerDataFromDB[key].time,
                powerDataFromDB[key].Temp_Cabinte > 0
                  ? powerDataFromDB[key].Temp_Cabinte
                  : null,
                powerDataFromDB[key].device_data_id,
              ];
            });
            myData4 = Object.keys(powerDataFromDB).map((key) => {
              return [
                powerDataFromDB[key].time,
                powerDataFromDB[key].Temp_Outdoor >= 0
                  ? powerDataFromDB[key].Temp_Outdoor
                  : null,
                powerDataFromDB[key].device_data_id,
              ];
            });
          } else {
            myData = [];
          }
          if (myData.length > 0) {
            // console.log("Graph Data!!!!!", myData)
            // setData(myData);
            setLastDataFront(myData.at(-1));
            setData((previous) => {
              return previous.concat(myData);
            });

            setData1((previous) => {
              return previous.concat(myData1);
            });
            setData2((previous) => {
              return previous.concat(myData2);
            });
            // setData3((previous) => {
            //   return previous.concat(myData3);
            // });

            // console.log(data);
            setendDate(newEndtDate);
          }
          setisLoadingGraph(false);
        })
        .catch((err) => {
          setisLoadingGraph(false);
          console.log(err);
        });
    } else if (Parameter === "mainbus") {
      UserService.GetLinkedDeviceTemperatureData(
        device_id,
        "Load_Power",
        "daterange",
        formatDate(LastDataFront[0]),
        formatDate(
          new Date(formatDate(LastDataFront[0])).getTime() +
            3600 * 1000 * 24 * 3
        )
        // newEndtDate
        // startDate + " 00:00:00"
      )
        .then((res) => {
          console.log(
            "get device temperature data res--",
            res.data.data.deviceData
          );
          let powerDataFromDB = res.data.data.deviceData;
          let myData;
          let myData1;
          let myData2;
          let myData3;
          if (typeof powerDataFromDB != "undefined" && !res.data.data.error) {
            myData = Object.keys(powerDataFromDB).map((key) => {
              return [
                powerDataFromDB[key].time,
                powerDataFromDB[key].Load_Power,
                powerDataFromDB[key].device_data_id,
              ];
            });
            // myData1 = Object.keys(powerDataFromDB).map((key) => {
            //   return [
            //     powerDataFromDB[key].time,
            //     powerDataFromDB[key].Solar_Power,
            //     powerDataFromDB[key].device_data_id,
            //   ];
            // });
            // myData2 = Object.keys(powerDataFromDB).map((key) => {
            //   return [
            //     powerDataFromDB[key].time,
            //     powerDataFromDB[key].BATT_Power,
            //     powerDataFromDB[key].device_data_id,
            //   ];
            // });
            // myData3 = Object.keys(powerDataFromDB).map((key) => {
            //   return [
            //     powerDataFromDB[key].time,
            //     powerDataFromDB[key].L3_Power_A,
            //     powerDataFromDB[key].device_data_id,
            //   ];
            // });
          } else {
            myData = [];
          }
          if (myData.length > 0) {
            // console.log("Graph Data!!!!!", myData)
            // setData(myData);
            setLastDataFront(myData.at(-1));
            setData((previous) => {
              return previous.concat(myData);
            });

            // setData1((previous) => {
            //   return previous.concat(myData1);
            // });
            // setData2((previous) => {
            //   return previous.concat(myData2);
            // });
            // setData3((previous) => {
            //   return previous.concat(myData3);
            // });

            // console.log(data);
            setendDate(newEndtDate);
          }
          setisLoadingGraph(false);
        })
        .catch((err) => {
          setisLoadingGraph(false);
          console.log(err);
        });
    } else if (Parameter === "genhours1") {
      UserService.GetLinkedDeviceTemperatureData(
        device_id,
        "Genset_Hours",
        "daterange",
        formatDate(LastDataFront[0]),
        formatDate(
          new Date(formatDate(LastDataFront[0])).getTime() +
            3600 * 1000 * 24 * 3
        )
        // newEndtDate
        // startDate + " 00:00:00"
      )
        .then((res) => {
          console.log(
            "get device temperature data res--",
            res.data.data.deviceData
          );
          let powerDataFromDB = res.data.data.deviceData;
          let myData;
          let myData1;
          let myData2;
          let myData3;
          if (typeof powerDataFromDB != "undefined" && !res.data.data.error) {
            myData = Object.keys(powerDataFromDB).map((key) => {
              return [
                powerDataFromDB[key].time,
                powerDataFromDB[key].Genset_Hours,
                powerDataFromDB[key].device_data_id,
              ];
            });
            // myData1 = Object.keys(powerDataFromDB).map((key) => {
            //   return [
            //     powerDataFromDB[key].time,
            //     powerDataFromDB[key].Solar_Power,
            //     powerDataFromDB[key].device_data_id,
            //   ];
            // });
            // myData2 = Object.keys(powerDataFromDB).map((key) => {
            //   return [
            //     powerDataFromDB[key].time,
            //     powerDataFromDB[key].BATT_Power,
            //     powerDataFromDB[key].device_data_id,
            //   ];
            // });
            // myData3 = Object.keys(powerDataFromDB).map((key) => {
            //   return [
            //     powerDataFromDB[key].time,
            //     powerDataFromDB[key].L3_Power_A,
            //     powerDataFromDB[key].device_data_id,
            //   ];
            // });
          } else {
            myData = [];
          }
          if (myData.length > 0) {
            // console.log("Graph Data!!!!!", myData)
            // setData(myData);
            setLastDataFront(myData.at(-1));
            setData((previous) => {
              return previous.concat(myData);
            });

            // setData1((previous) => {
            //   return previous.concat(myData1);
            // });
            // setData2((previous) => {
            //   return previous.concat(myData2);
            // });
            // setData3((previous) => {
            //   return previous.concat(myData3);
            // });

            // console.log(data);
            setendDate(newEndtDate);
          }
          setisLoadingGraph(false);
        })
        .catch((err) => {
          setisLoadingGraph(false);
          console.log(err);
        });
    } else if (Parameter === "genhours") {
      let QueryStarDate;
      let QueryendDate;
      let localUnit = "HR";
      console.log(
        TimeFormat,
        " get data forward",
        "FirstDataBack",
        FirstDataBack,
        "LastDataFront",
        LastDataFront
      );
      console.log(
        "old ===>>",
        moment(LastDataFront[0]).format("YYYY-MM-DD  00:00:00"),
        moment(LastDataFront[0]).format("YYYY-MM-DD  23:59:59")
      );
      if (TimeFormat === "hourly") {
        //24hr
        QueryStarDate = moment(LastDataFront[0])
          .add(1, "day")
          .format("YYYY-MM-DD  00:00:00");
        QueryendDate = moment(LastDataFront[0])
          .add(1, "day")
          .format("YYYY-MM-DD  23:59:59");
        console.log("new ===>>", QueryStarDate, QueryendDate);
      } else if (TimeFormat === "daily") {
        QueryStarDate = moment(LastDataFront[0])
          .add(1, "day")
          .format("YYYY-MM-DD  00:00:00");
        QueryendDate = moment(LastDataFront[0])
          .add(1, "month")
          .format("YYYY-MM-DD  23:59:59");
        console.log("new ===>>", QueryStarDate, QueryendDate);
      } else {
        QueryStarDate = moment(LastDataFront[0])
          .add(1, "day")
          .format("YYYY-MM-DD  00:00:00");
        QueryendDate = moment(LastDataFront[0])
          .add(1, "year")
          .format("YYYY-MM-DD  23:59:59");
        console.log("new ===>>", QueryStarDate, QueryendDate);
      }
      UserService.GetLinkedDeviceData(
        device_id,
        "timerange",
        TimeFormat,
        QueryStarDate,
        QueryendDate
        // newEndtDate
        // startDate + " 00:00:00"
      )
        .then((res) => {
          console.log(
            "get device temperature data res--",
            res.data.data.deviceData
          );
          let powerDataFromDB = res.data.data.deviceData;
          let myData;
          let myData1;

          if (typeof powerDataFromDB != "undefined" && !res.data.data.error) {
            myData = Object.keys(powerDataFromDB).map((key) => {
              return [key.time2, key["Genset_HoursDiff"]];
            });
            myData1 = Object.keys(powerDataFromDB).map((key) => {
              return [key.time2, key["SolarDiff"]];
            });
          } else {
            myData = [];
          }
          if (myData.length > 0) {
            // myData = fillMissingGaps(myData, TimeFormat);

            setData(myData);
            // setData1(myData1);

            setendDate(newEndtDate);
            setFirstDataBack(myData.at(0));
            setLastDataFront(myData.at(-1));
            setChartConfig({
              chart: {
                id: "total-power-chart",
                // type: "line",
                // stacked: true,
                height: 500,
                foreColor: "#000000",
                zoom: {
                  type: "x",
                  enabled: true,
                  autoScaleYaxis: true,
                },
                toolbar: {
                  autoSelected: "zoom",
                },
              },
              tooltip: {
                enabled: true,
                color: "#000000",
                style: {
                  fontSize: "16px",
                  // fontFamily: undefined
                },
                // shared: true,
                x: {
                  format: "dd MMM yyyy hh:mm TT",
                },
                y: [
                  {
                    formatter: function (y) {
                      if (typeof y !== "undefined") {
                        return y.toFixed(2) + " " + localUnit;
                      }
                      return y;
                    },
                  },
                  {
                    formatter: function (y) {
                      if (typeof y !== "undefined") {
                        return y.toFixed(2) + " " + localUnit;
                      }
                      return y;
                    },
                  },
                  {
                    formatter: function (y) {
                      if (typeof y !== "undefined") {
                        return y.toFixed(2) + " " + localUnit;
                      }
                      return y;
                    },
                  },
                  {
                    formatter: function (y) {
                      if (typeof y !== "undefined") {
                        return y.toFixed(2) + " " + localUnit;
                      }
                      return y;
                    },
                  },
                ],
              },
              dataLabels: {
                enabled: false,
              },
              markers: {
                size: 0,
              },
              xaxis: {
                type: "datetime",
                tickAmount: myData.length - 1,
                tooltip: {
                  enabled: false,
                },
                labels: {
                  rotate: -90,
                  rotateAlways: true,
                  minHeight: 100,
                  maxHeight: 160,
                  show: true,
                  align: "left",
                  // trim: true,
                  formatter: function (val, timestamp) {
                    if (TimeFormat === "hourly")
                      return moment(timestamp).format("HH:00");
                    if (TimeFormat === "daily")
                      return moment(timestamp).format("DD MMM");
                    if (TimeFormat === "monthly")
                      return moment(timestamp).format("MMM YYYY");
                  },
                  style: {
                    colors: ["#000000"],
                    fontSize: "15px",
                    // fontFamily: 'Helvetica, Arial, sans-serif',
                    fontWeight: 600,
                  },
                },
              },
              stroke: {
                width: 2,
              },
              colors: ["#64006C", "#0054F1", "#00FF00", "#FFD93D"],
              yaxis: {
                opposite: false,
                formatter: (value) => {
                  return value;
                },
                min: 0,
                decimalsInFloat: 2,
                forceNiceScale: true,
                axisBorder: {
                  show: true,
                  color: "#78909C",
                  offsetX: 0,
                  offsetY: 0,
                },
                axisTicks: {
                  show: true,
                  borderType: "solid",
                  color: "#78909C",
                  width: 6,
                  offsetX: 0,
                  offsetY: 0,
                },
                labels: {
                  show: true,
                  align: "right",
                  minWidth: 0,
                  maxWidth: 160,
                  style: {
                    colors: [],
                    fontSize: "15px",
                    // fontFamily: 'Helvetica, Arial, sans-serif',
                    fontWeight: 600,
                  },
                  offsetX: 0,
                  offsetY: 0,
                  rotate: 0,
                  // formatter: (value) => { return val },
                },
                crosshairs: {
                  show: true,
                  position: "back",
                  stroke: {
                    color: "#b6b6b6",
                    width: 0.6,
                    dashArray: [0, 1, 2],
                  },
                },
                tooltip: {
                  enabled: false,
                  offsetX: 0,
                },
                title: {
                  text: "[" + localUnit + "]",
                  rotate: 0,
                  offsetX: 40,
                  offsetY: -170,
                  style: {
                    color: undefined,
                    fontSize: "15px",
                    // fontFamily: 'Helvetica, Arial, sans-serif',
                    fontWeight: 600,
                    // cssClass: 'apexcharts-yaxis-title',
                  },
                },
              },
              legend: {
                showForSingleSeries: true,
                offsetX: 7,
                //  offsetY:5,
                position: "top",
                fontSize: "18px",
                //  width:1
                //  fontWeight: 800,
                markers: {
                  width: 12,
                  height: 12,
                  strokeWidth: 2,
                  strokeColor: "#fff",
                  fillColors: undefined,
                  radius: 12,
                  offsetX: -5,
                  // offsetY: 5
                },
              },
            });
          }
          setisLoadingGraph(false);
        })
        .catch((err) => {
          setisLoadingGraph(false);
          console.log(err);
        });
    } else if (Parameter === "battcon") {
      UserService.GetLinkedDeviceTemperatureData(
        device_id,
        "Load_Power",
        "daterange",
        formatDate(LastDataFront[0]),
        formatDate(
          new Date(formatDate(LastDataFront[0])).getTime() +
            3600 * 1000 * 24 * 3
        )
        // newEndtDate
        // startDate + " 00:00:00"
      )
        .then((res) => {
          console.log(
            "get device temperature data res--",
            res.data.data.deviceData
          );
          let powerDataFromDB = res.data.data.deviceData;
          let myData;
          let myData1;
          let myData2;
          let myData3;
          if (typeof powerDataFromDB != "undefined" && !res.data.data.error) {
            myData = Object.keys(powerDataFromDB).map((key) => {
              return [
                powerDataFromDB[key].time,
                getBitValue(powerDataFromDB[key].GEN_STAT, 8),
                powerDataFromDB[key].device_data_id,
              ];
            });
            myData1 = Object.keys(powerDataFromDB).map((key) => {
              return [
                powerDataFromDB[key].time,
                getBitValue(powerDataFromDB[key].GEN_STAT, 9),
                powerDataFromDB[key].device_data_id,
              ];
            });
            myData2 = Object.keys(powerDataFromDB).map((key) => {
              return [
                powerDataFromDB[key].time,
                getBitValue(powerDataFromDB[key].GEN_STAT, 10),
                powerDataFromDB[key].device_data_id,
              ];
            });
            myData3 = Object.keys(powerDataFromDB).map((key) => {
              return [
                powerDataFromDB[key].time,
                getBitValue(powerDataFromDB[key].GEN_STAT, 11),
                powerDataFromDB[key].device_data_id,
              ];
            });
          } else {
            myData = [];
          }
          if (myData.length > 0) {
            // console.log("Graph Data!!!!!", myData)
            // setData(myData);
            setLastDataFront(myData.at(-1));
            setData((previous) => {
              return previous.concat(myData);
            });

            setData1((previous) => {
              return previous.concat(myData1);
            });
            setData2((previous) => {
              return previous.concat(myData2);
            });
            setData3((previous) => {
              return previous.concat(myData3);
            });

            // console.log(data);
            setendDate(newEndtDate);
          }
          setisLoadingGraph(false);
        })
        .catch((err) => {
          setisLoadingGraph(false);
          console.log(err);
        });
    }
  }

  return (
    <>
      <Grid
        container
        spacing={1}
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
      >
        <TwoBoxes
          isGraphStatsLoading={isGraphStatsLoading}
          data={SolarPower}
          color={"#0054F1"}
          name={"Solar Power"}
        />

        <ThreeBoxes
          isGraphStatsLoading={isGraphStatsLoading}
          data={Load}
          color={"purple"}
          name={"Load"}
        />

        <FourBoxes
          isGraphStatsLoading={isGraphStatsLoading}
          data={DieselGenerator}
          color={"#FF0000"}
          name={"Solar Power"}
        />

        <Grid spacing={1} item xs={12} sm={12} md={12}>
          <Card
            className="row"
            sx={{
              "border-radius": "1% ",

              boxShadow: 2,
              margin: "0 5px 10px",
              ":hover": {
                boxShadow: 5,
              },
              padding: "1%",
            }}
          >
            <div className="col-lg-2 col-sm-12">
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Trend</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={Parameter}
                  label="Format"
                  // onChange={handleChangeParameter}
                  onChange={(event) => {
                    setParameter(event.target.value);
                  }}
                >
                  <MenuItem value={"power"}>Power</MenuItem>
                  <MenuItem value={"energy"}>Energy</MenuItem>
                  <MenuItem value={"temperature"}>Temperature</MenuItem>
                  <MenuItem value={"mainbus"}>Main Bus Voltage</MenuItem>
                  <MenuItem value={"genhours"}>Genset Hours</MenuItem>
                  <MenuItem value={"battstate"}>Battery Banks</MenuItem>
                  {/* <MenuItem value={"battcon"}>Alarm History</MenuItem> */}
                  <MenuItem value={"soc"}>SoC</MenuItem>
                  <MenuItem value={"alarmstate"}>Alarms</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="col-lg-2 col-sm-12">
              {Parameter === "energy" || Parameter === "genhours" ? (
                <>
                  {" "}
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Format
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={TimeFormat}
                      label="Format"
                      onChange={handleChangeFormat}
                    >
                      <MenuItem value={"hourly"}>Hourly</MenuItem>
                      <MenuItem value={"daily"}>Daily</MenuItem>
                      <MenuItem value={"monthly"}>Monthly</MenuItem>
                    </Select>
                  </FormControl>
                </>
              ) : (
                <>
                  {Parameter === "battcon" ? (
                    <></>
                  ) : (
                    // <FormControl fullWidth>
                    //   <InputLabel id="demo-simple-select-label">
                    //     Select Alarm
                    //   </InputLabel>
                    //   <Select
                    //     labelId="demo-simple-select-label"
                    //     id="demo-simple-select"
                    //     value={AlarmSelection}
                    //     label="Format"
                    //     onChange={handleChangeFormat}
                    //   >
                    //     <MenuItem value={"0"}>Solar Low Battery Voltage Alarm</MenuItem>
                    //     <MenuItem value={"1"}>General Battery Shelter Alarm</MenuItem>
                    //     <MenuItem value={"2"}>General Solar Power Cabinet Alarm</MenuItem>
                    //     <MenuItem value={"3"}>EMMS Failure Alarm</MenuItem>
                    //   </Select>
                    // </FormControl>

                    <></>
                  )}
                </>
              )}
            </div>
            <div className="col-lg-2 col-sm-12">
              <Typography> Real-Time</Typography>
              <Switch
                checked={Rt}
                onChange={() => setRt(!Rt)}
                name="Real Time"
                // color="primary"
              />
            </div>
            <div
              className="col-lg-1 col-sm-12"
              style={{
                padding: "1%",
              }}
            >
              <IconButton
                color="primary"
                aria-label="ArrowBackIosIcon"
                onClick={handleBackIClick}
              >
                <ArrowBackIosIcon />
              </IconButton>
            </div>

            <div
              className="col-lg-4 col-sm-12"
              style={{
                padding: "1%",
              }}
            >
              <DateRangePicker onApply={handleApply}>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Select date range"
                  style={{
                    fontSize: 12,
                    border: "1px solid #46acad",
                    borderRadius: 25,
                  }}
                  value={FirstDataBack[0] + " " + LastDataFront[0]}
                />
              </DateRangePicker>
            </div>

            <div
              className="col-lg-1 col-sm-12"
              style={{
                padding: "1%",
              }}
            >
              <IconButton
                color="primary"
                aria-label="ArrowForwardIosIcon"
                onClick={handleForwardClick}
              >
                <ArrowForwardIosIcon />
              </IconButton>
            </div>
          </Card>
        </Grid>
        <Grid spacing={1} item xs={12} sm={12} md={12}>
          <>
            {isLoadingGraph ? (
              <>
                {Parameter === "alarmstate" ? (
                  <Alarams ProcessData={ProcessData} />
                ) : (
                  <>
                    {Parameter === "battstate" ? (
                      <Batteries ProcessData={ProcessData} />
                    ) : (
                      <>
                        <p style={{ textAlign: "center", padding: 108 }}>
                          Loading...
                        </p>
                      </>
                    )}
                  </>
                )}
              </>
            ) : (
              <>
                {Parameter === "energy" ? (
                  <ReactApexChart
                    height={450}
                    ref={chartRef}
                    options={chartConfig}
                    series={[
                      {
                        name: "Load Accumulated Energy",
                        // data: data[0].data
                        data: data,
                      },
                      {
                        name: "Solar Accumulated Energy",
                        // data: data[0].data
                        data: data1,
                      },
                    ]}
                    type="bar"
                    // type="line"
                  />
                ) : (
                  <>
                    {Parameter === "mainbus" ? (
                      <ReactApexChart
                        height={450}
                        ref={chartRef}
                        options={chartConfig}
                        series={[
                          {
                            name: "Main Bus Voltage",
                            // data: data[0].data
                            data: data,
                          },
                        ]}
                        type="line"
                      />
                    ) : (
                      <>
                        {Parameter === "genhours" ? (
                          <ReactApexChart
                            height={450}
                            ref={chartRef}
                            options={chartConfig}
                            series={[
                              {
                                name: "Gen set Hours",
                                // data: data[0].data
                                data: data,
                              },
                            ]}
                            type="bar"
                          />
                        ) : (
                          <>
                            {Parameter === "temperature" ? (
                              <ReactApexChart
                                height={450}
                                ref={chartRef}
                                options={chartConfig}
                                series={[
                                  { name: "Bat1", data: data },
                                  { name: "Bat2", data: data1 },
                                  { name: "Bat3", data: data2 },
                                  { name: "Cab", data: data3 },
                                  { name: "Out", data: data4 },
                                  // { name: "L3", data: data3 },
                                ]}
                                type="line"
                              />
                            ) : (
                              <>
                                {Parameter === "power" ? (
                                  <ReactApexChart
                                    height={450}
                                    ref={chartRef}
                                    options={chartConfig}
                                    series={[
                                      {
                                        name: "Load Power",
                                        // data: data[0].data
                                        data: data,
                                      },
                                      { name: "Solar Power", data: data1 },
                                      // { name: "Batt Power", data: data2 },
                                      // { name: "L3", data: data3 },
                                    ]}
                                    type="line"
                                  />
                                ) : (
                                  <>
                                    {Parameter == "battcon" ? (
                                      <ReactApexChart
                                        height={450}
                                        ref={chartRef}
                                        options={chartConfig}
                                        series={[
                                          {
                                            name: "Solar Low Battery Voltage Alarm",
                                            // data: data[0].data
                                            data: data,
                                          },
                                          { name: "General Battery Shelter Alarm", data: data1 },
                                          { name: "General Solar Power Cabinet Alarm", data: data2 },
                                          { name: "EMMS Failure Alarm", data: data3 },
                                        ]}
                                        type="line"
                                      />
                                    ) : (
                                      <ReactApexChart
                                        height={450}
                                        ref={chartRef}
                                        options={chartConfig}
                                        series={[
                                          { name: "Bat1", data: data },
                                          { name: "Bat2", data: data1 },
                                          { name: "Bat3", data: data2 },
                                          //   { name: "Cab", data: data3 },
                                          //  { name: "Out", data: data4 },
                                          // { name: "L3", data: data3 },
                                        ]}
                                        type="line"
                                      />
                                    )}
                                  </>
                                )}
                              </>
                            )}
                          </>
                        )}{" "}
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </>
        </Grid>

        <Grid
          container
          spacing={3}
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
        >
          <ThreeBoxes
            isGraphStatsLoading={isGraphStatsLoading}
            data={Bat1}
            color={"#00FF00"}
            name={"BAT 1"}
            type={1}
          />

          <ThreeBoxes
            isGraphStatsLoading={isGraphStatsLoading}
            data={Bat2}
            color={"#FFA500"}
            name={"BAT 2"}
            type={1}
          />

          <ThreeBoxes
            isGraphStatsLoading={isGraphStatsLoading}
            data={Bat3}
            color={"#FFD93D"}
            name={"BAT 3"}
            type={1}
          />
        </Grid>
      </Grid>
    </>
  );
}
