import React, { Fragment } from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const borderColor = "#90e5fc";
const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    borderBottomColor: "#bff0fd",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 24,
    fontStyle: "bold",
  },
  from: {
    width: "25%",
    textAlign: "center",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingLeft: 8,
  },
  to: {
    width: "25%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: "center",
    paddingRight: 8,
  },
  kwhr: {
    width: "25%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: "center",
    paddingRight: 8,
  },
  amount: {
    width: "25%",
    textAlign: "center",
    paddingRight: 8,
  },
});

function formatNumberWithCommas(inputNumber) {
  // Convert the number to a string
  let numberString = inputNumber.toString();

  // Check if the number has a decimal part
  if (numberString.includes(".")) {
    // Split the string into integer and decimal parts
    let parts = numberString.split(".");

    // Format the integer part with commas
    let formattedInteger = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    // Combine the formatted integer part with the decimal part
    return formattedInteger + "." + parts[1];
  } else {
    // Format the integer part with commas
    return numberString.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
}

const InvoiceTableRow = ({ items, TariffRate, statementType }) => {
  const rows = items.map((item) => (
    <View style={styles.row} key={item.sno.toString()}>
      <Text style={styles.from}>{item.from}</Text>
      <Text style={styles.to}>{item.to}</Text>
      {/* <Text style={styles.kwhr}>{item.kwhr.toFixed(2)}</Text> */}
      {/* {statementType == "statement" ? null : (
        <Text style={styles.amount}>{(item.kwhr * TariffRate).toFixed(2)}</Text>
      )} */}

      {statementType == "statement" ? (
        <Text
          style={{
            width: "50%",
            borderRightColor: borderColor,
            borderRightWidth: 1,
            textAlign: "center",
            paddingRight: 8,
          }}
        >
          {/* {item.kwhr.toFixed(2)} */}
          {formatNumberWithCommas((item.kwhr !== null ? item.kwhr : 0).toFixed(2))}
        </Text>
      ) : (
        <>
          <Text style={styles.kwhr}>
            {formatNumberWithCommas(item.kwhr.toFixed(2))}
          </Text>

          <Text style={styles.amount}>
            {/* {(item.kwhr * TariffRate).toFixed(2)} */}
            {/* {formatNumberWithCommas(
              (item.kwhr !== null ? item.kwhr : 0) * TariffRate.toFixed(2)
            ) + "$"} */}
            {formatNumberWithCommas((item.kwhr !== null ? (item.kwhr* TariffRate) : 0).toFixed(2)) + " $"}
          </Text>
        </>
      )}
    </View>
  ));
  return <Fragment>{rows}</Fragment>;
};

export default InvoiceTableRow;
