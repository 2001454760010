// src/hooks/useInactivityTimer.js

import { useEffect, useRef } from 'react';

function useInactivityTimer(timeoutDuration, onTimeout) {
  const timerId = useRef(null);

  useEffect(() => {
    // Function to reset the timer
    const resetTimer = () => {
      if (timerId.current) {
        clearTimeout(timerId.current);
      }
      timerId.current = setTimeout(onTimeout, timeoutDuration);
    };

    // Event listeners for user interactions
    const events = ['mousedown', 'mousemove', 'keydown', 'scroll', 'touchstart'];

    events.forEach((event) => {
      window.addEventListener(event, resetTimer);
    });

    // Set the initial timer
    resetTimer();

    // Cleanup function
    return () => {
      if (timerId.current) {
        clearTimeout(timerId.current);
      }
      events.forEach((event) => {
        window.removeEventListener(event, resetTimer);
      });
    };
  }, [timeoutDuration, onTimeout]);

  return null; // Return value can be adjusted based on your needs
}

export default useInactivityTimer;
