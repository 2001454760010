import UserService from "../services/user.service";
import React, { useState, useEffect, useRef } from "react";
import IconButton from "@mui/material/IconButton";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ReactApexChart from "react-apexcharts";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import Switch from "@mui/material/Switch";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
const tzone = "Asia/Amman";

function formatDate(date) {
  date = new Date(date);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

export default function App(props) {
  const DeviceId = props.DeviceId;
  const userID = props.userID;
  const type = props.type;
  const param = props.param;
  


  console.log(props);
  const chartRef = useRef();
  const [isLoadingGraph, setisLoadingGraph] = useState(false);
  const [data, setData] = useState([]);
  const [LastDataFront, setLastDataFront] = useState(0);
  const [FirstDataBack, setFirstDataBack] = useState(0);
  const [chartTitle, setChartTitle] = useState("chartTitle");
  const [childDevices, setChildDevices] = useState([]);
  const [Rt, setRt] = React.useState(true);
  const [format, setFormat] = React.useState("Monthly");
  const [parameter, setParameter] = React.useState(param);

  // switch (param) {
  //   case "power":
  //     setParameter("T_Power_A");
  //     break;
  //   case "energy":
  //     setParameter("T_Power_A");
  //     break;
  //   default:
  //     setParameter("T_Power_A");
  //     break;
  // }
  const handleChangeFormat = (event) => {
    setFormat(event.target.value);
    handleApply();
  };
  function getchildDevices(deviceId) {
    UserService.GetTreeViewData(userID).then(
      (response) => {
        const result = response.data.data.profile[0]
          .filter((x) => x.parent_id === DeviceId)
          .map((x) => x.device_id);
        // console.log("Child Devices", result);
        setChildDevices(result);
      },
      (error) => {}
    );
  }

  useEffect(() => {
    getchildDevices(DeviceId);
    console.log(childDevices);
  }, [DeviceId]);

  const [chartConfig] = useState({
    options: {
      chart: {
        id: "total-power-chart",
        type: "line",
        stacked: true,
        height: 350,
        zoom: {
          type: "x",
          enabled: true,
          autoScaleYaxis: true,
        },
        toolbar: {
          autoSelected: "zoom",
        },
      },
      dataLabels: {
        enabled: false,
      },
      markers: {
        size: 0,
      },
      xaxis: {
        type: "datetime",
      },
      yaxis: {
        opposite: false,
        // min: 0,
        decimalsInFloat: 2,
        axisBorder: {
          show: true,
          color: "#78909C",
          offsetX: 0,
          offsetY: 0,
        },
        axisTicks: {
          show: true,
          borderType: "solid",
          color: "#78909C",
          width: 6,
          offsetX: 0,
          offsetY: 0,
        },

        crosshairs: {
          show: true,
          position: "back",
          stroke: {
            color: "#b6b6b6",
            width: 0.6,
            dashArray: [0, 1, 2],
          },
        },
        tooltip: {
          enabled: false,
          offsetX: 10,
        },
      },
      tooltip: {
        enabled: true,
        shared: false,
        x: {
          format: "dd MMM yyyy hh:mm",
        },
      },
      stroke: {
        width: 2.5,
      },
      colors: [
        "#64006C",
        "#FF9800",
        "#66DA26",
        "#546E7A",
        "#2E93fA",
        "#E91E63",
      ],
    },
    noData: {
      text: "Loading...",
    },
    series: [
      {
        name: chartTitle,
        data: [
          // [1361487600000, 5],
          // [1461746800000, 10],
          // [1561833200000, 15],
          // [1671919600000, 20],
          // [1465487600000, 15],
          // [1463746800000, 30],
          // [1421833200000, 45],
          // [1661919600000, 50],
        ],
      },
    ],
  });

  function getTodayData() {
    let startDateObjDate = new Date();
    console.log("useEffect ", startDateObjDate);
    let startDateday = startDateObjDate.getDate();
    let startDatemonth = startDateObjDate.getMonth() + 1;
    let startDateyear = startDateObjDate.getFullYear();

    console.log(
      "useEffect ",
      `${startDateyear}-${startDatemonth}-${startDateday}`
    );

    if (childDevices) {
      setisLoadingGraph(true);

      UserService.getVIrtualDeviceData({
        type: parameter,
        format,
        startDate: `${startDateyear}-${startDatemonth}-${startDateday} 00:00:00`,
        endDate: `${startDateyear}-${startDatemonth}-${startDateday} 23:59:59`,
        deviceIds: childDevices,
      })

        .then((res) => {
          //console.log("power initial res:", res.data.data.deviceData)
          let powerDataFromDB = res.data.data.deviceData;
          console.log("res Data!!!!!!!!!", powerDataFromDB);
          let myData;
          if (typeof powerDataFromDB != "undefined" && !res.data.data.error) {
            myData = Object.keys(powerDataFromDB).map((key) => {
              return [powerDataFromDB[key].time, powerDataFromDB[key].value];
            });
          } else {
            myData = [];
          }
          if (myData.length > 0) {
            console.log("last data ID", myData.at(-1));
            setLastDataFront(myData.at(-1));
            setFirstDataBack(myData.at(0));

            // console.log("Graph Data!!!!!", myData);
            // myData
            setData(myData);

            // console.log(data);
          } else {
            //No Data
          }
          setisLoadingGraph(false);
        })
        .catch((err) => {
          setisLoadingGraph(false);
          // console.log(err)
        });
    }
  }

  const handleApply = (event, picker) => {
    setRt(false);
    
    let startDateObj = picker.startDate._d;
    let startDateObjDate = new Date(startDateObj);
    console.log("startDateObjDate", startDateObjDate);
    let startDateday = startDateObjDate.getDate();
    let startDatemonth = startDateObjDate.getMonth() + 1;
    let startDateyear = startDateObjDate.getFullYear();
    console.log(
      "final start date----",
      `${startDateyear}-${startDatemonth}-${startDateday}`
    );

    let endDateObj = picker.endDate._d;
    let endDateObjDate = new Date(endDateObj);
    console.log("endDateObjDate", endDateObjDate);

    let endDateday = endDateObjDate.getDate();
    let endDatemonth = endDateObjDate.getMonth() + 1;
    let endDateyear = endDateObjDate.getFullYear();
    let endDateHour = endDateObjDate.getHours();
    let endDateMinute = endDateObjDate.getMinutes();
    let endDateSecond = endDateObjDate.getMinutes();
    // let endDate = endDateyear + "-" + endDatemonth + "-" + endDateday;
    console.log(
      "final end date----",
      `${endDateyear}-${endDatemonth}-${endDateday} ${endDateHour}:${endDateMinute}:${endDateSecond}`
    );

    UserService.getVIrtualDeviceData({
      type: parameter,
      format,
      startDate: `${startDateyear}-${startDatemonth}-${startDateday} 00:00:00`,
      endDate: `${endDateyear}-${endDatemonth}-${endDateday} 23:59:59`,
      deviceIds: childDevices,
    })
      .then((res) => {
        let powerDataFromDB = res.data.data.deviceData;
        let myData;
        if (typeof powerDataFromDB != "undefined" && !res.data.data.error) {
          myData = Object.keys(powerDataFromDB).map((key) => {
            return [powerDataFromDB[key].time, powerDataFromDB[key].value];
          });

          console.log("last data ID", myData.at(-1));
          setLastDataFront(myData.at(-1));
          setFirstDataBack(myData.at(0));
        } else {
          myData = [];
        }
        if (myData.length > 0) {
          // console.log("Graph Data!!!!!", myData)
          setData(myData);
          console.log(data);
        }
        setisLoadingGraph(false);
      })
      .catch((err) => {
        setisLoadingGraph(false);
        console.log(err);
      });
  };

  function handleBackIClick() {
    setRt(false);
    UserService.getVIrtualDeviceData({
      type: parameter,
      format,
      startDate: formatDate(
        new Date(formatDate(FirstDataBack[0])).getTime() -
          7 * (3600 * 1000 * 24)
      ),
      endDate: formatDate(FirstDataBack[0]),
      deviceIds: childDevices,
    })
      .then((res) => {
        let powerDataFromDB = res.data.data.deviceData;
        let myData;
        if (typeof powerDataFromDB != "undefined" && !res.data.data.error) {
          myData = Object.keys(powerDataFromDB).map((key) => {
            return [powerDataFromDB[key].time, powerDataFromDB[key].value];
          });
        } else {
          myData = [];
        }
        if (myData.length > 0) {
          console.log("Last Data Start", myData);

          setFirstDataBack(myData.at(0));
          setData((previous) => {
            return myData.concat(previous);
          });
          // }
        }
        setisLoadingGraph(false);
      })
      .catch((err) => {
        setisLoadingGraph(false);
        console.log(err);
      });
  }
  function handleForwardClick() {
    setRt(false);

    UserService.getVIrtualDeviceData({
      type: parameter,
      format,
      startDate: formatDate(LastDataFront[0]),
      endDate: formatDate(
        new Date(formatDate(LastDataFront[0])).getTime() + 3600 * 1000 * 24
      ),
      deviceIds: childDevices,
    })
      .then((res) => {
        console.log(
          "get device temperature data res--",
          res.data.data.deviceData
        );
        let powerDataFromDB = res.data.data.deviceData;
        let myData;
        if (typeof powerDataFromDB != "undefined" && !res.data.data.error) {
          myData = Object.keys(powerDataFromDB).map((key) => {
            return [powerDataFromDB[key].time, powerDataFromDB[key].value];
          });
        } else {
          myData = [];
        }
        if (myData.length > 0) {
          // console.log("Graph Data!!!!!", myData)
          // setData(myData);
          setLastDataFront(myData.at(-1));
          setData((previous) => {
            return previous.concat(myData);
          });
        }
        setisLoadingGraph(false);
      })
      .catch((err) => {
        setisLoadingGraph(false);
        console.log(err);
      });
  }
  useEffect(() => {
    getTodayData();
  }, [childDevices]);

  useEffect(() => {
    getTodayData();
  }, [format]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (Rt) getTodayData();
    }, 60 * 5 * 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      {isLoadingGraph ? (
        <p style={{ textAlign: "center", padding: 108 }}>Loading...</p>
      ) : (
        <>
          <div className="row">
            {param === "energy" ? (
              <div className="col-lg-2 col-sm-12">
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Format</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={format}
                    label="Format"
                    onChange={handleChangeFormat}
                  >
                    <MenuItem value={"Daily"}>Daily</MenuItem>
                    <MenuItem value={"Monthly"}>Monthly</MenuItem>
                  </Select>
                </FormControl>
              </div>
            ) : null}

            <div className="col-lg-2 col-sm-12">
              <Typography> Real-Time</Typography>
            </div>
            <div className="col-lg-2 col-sm-12">
              {" "}
              <Switch
                checked={Rt}
                onChange={() => setRt(!Rt)}
                name="Real Time"
                // color="primary"
              />
            </div>

            <div className="col-lg-1 col-sm-12">
              <IconButton
                color="primary"
                aria-label="ArrowBackIosIcon"
                onClick={handleBackIClick}
              >
                <ArrowBackIosIcon />
              </IconButton>
            </div>
            <div className="col-lg-4 col-sm-12">
              <DateRangePicker
                // key={keyRef.current}
                // onCallback={handleCallback}
                onApply={handleApply}
                // initialSettings={isInitialDateData}
              >
                <input
                  // value={formatDate(FirstDataBack)}
                  type="text"
                  className="form-control"
                  placeholder="Select date range"
                  style={{
                    fontSize: 12,
                    border: "1px solid #46acad",
                    borderRadius: 25,
                  }}
                  value={FirstDataBack[0] + " " + LastDataFront[0]}
                />
              </DateRangePicker>
            </div>
            <div className="col-lg-1 col-sm-12">
              <IconButton
                color="primary"
                aria-label="ArrowForwardIosIcon"
                onClick={handleForwardClick}
              >
                <ArrowForwardIosIcon />
              </IconButton>
            </div>
          </div>

          <ReactApexChart
            height={350}
            ref={chartRef}
            options={chartConfig.options}
            series={[
              {
                name: chartTitle,

                data: data,
              },
            ]}
            type={type}
          />
        </>
      )}
    </>
  );
}
