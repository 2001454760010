import { useCustomCompareEffect } from "use-custom-compare";
import isEqual from "lodash/isEqual";
import UserService from "../services/user.service";
import React, { useState, useEffect, useRef } from "react";
import moment from "moment-timezone";
import ReactPaginate from "react-paginate";
import IconButton from "@mui/material/IconButton";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ReactApexChart from "react-apexcharts";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import Switch from "@mui/material/Switch";
import { Typography } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Card, CardContent, Grid } from "@mui/material";
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";
import Control from "./Control";
// import DatePicker from "react-datepicker";
import DatePicker, { CalendarContainer } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import inflect from "i";
import BillingView from "./Billing/BillingView";
const tzone = "Asia/Amman";

function subtract30Days(date) {
  // Subtract 30 days
  date.setDate(date.getDate() - 30);

  // Get the individual date components
  var year = date.getFullYear();
  var month = String(date.getMonth() + 1).padStart(2, "0");
  var day = String(date.getDate()).padStart(2, "0");
  var hours = String(date.getHours()).padStart(2, "0");
  var minutes = String(date.getMinutes()).padStart(2, "0");
  var seconds = String(date.getSeconds()).padStart(2, "0");

  // Format the date string
  var formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

  return formattedDate;
}
function formatDate(date) {
  date = new Date(date);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

function calculateDifferences(inputArray) {
  // Create a new array to store the results
  let resultArray = [];

  // Loop through the input array starting from the second element (index 1)
  for (let i = 1; i < inputArray.length; i++) {
    // Calculate the difference between T_Energy_Acc of current element and T_Energy_Acc of the previous element
    let diff = (inputArray[i].T_Energy_Acc - inputArray[i - 1].T_Energy_Acc).toFixed(2);

    // Create a new object with the 'diff' property and other properties from the current element
    let resultElement = { ...inputArray[i], diff };

    // Add the new object to the result array
    resultArray.push(resultElement);
  }

  // Return the result array
  return resultArray;
}
export default function App(props) {
  const {
    isGraphDataFromSocket,
    graphDataFromSocket,
    isFilterGraphData,
    graphDataFromFilter,
    device_id,
    phase,
    userID,
    isSharedDevice,
    isDeviceStatus,
    StatementName
  } = props;

  // console.log("props from Appex",props);
  const chartRef = useRef();
  const [gData, setGData] = useState([]);
  const [isLoadingGraph, setisLoadingGraph] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [data, setData] = useState([]);
  const [data1, setData1] = useState([]);
  const [data2, setData2] = useState([]);
  const [data3, setData3] = useState([]);
  const [startDate, setstartDate] = useState("");
  const [endDate, setendDate] = useState("");
  const [LastDataFront, setLastDataFront] = useState(0);
  const [FirstDataBack, setFirstDataBack] = useState(0);
  const [DataAvailable, setDataAvailable] = useState(false);

  const [Rt, setRt] = React.useState(true);
  const [TimeFormat, setTtimeFormat] = React.useState("daily");
  const [Parameter, setParameter] = React.useState("power");
  const format = "daterange-initial";

  const firstdate = moment().startOf("month").unix();
  //console.log("firstdate", firstdate);
  const lastdate = moment().endOf("month").unix();
  //console.log("lastdate", lastdate);
  const [chartConfig, setChartConfig] = useState({
    chart: {
      id: "total-power-chart",
      // type: "line",
      // stacked: true,
      height: 500,
      foreColor: "#000000",
      zoom: {
        type: "x",
        enabled: true,
        autoScaleYaxis: true,
      },
      // events: {
      //   zoomed: function (chartContext, { xaxis, yaxis }) {
      //     // ...
      //     console.log(chartContext, { xaxis, yaxis });
      //     console.log(xaxis.min, xaxis.max);
      //     console.log(chartRef.current.props.series[0].data);
      //     let result = chartRef.current.props.series[0].data
      //       .filter(
      //         (x) =>
      //           new Date(x[0]).getTime() > xaxis.min &&
      //           new Date(x[0]).getTime() <= xaxis.max
      //       )
      //       .map((x) => x[1])
      //       .sort(function (b, a) {
      //         return a - b;
      //       })[0];
      //     console.log(Math.floor(result) + 10);
      //     // setChartConfig((prevState) => {
      //     //   return {
      //     //     ...prevState,
      //     //     yaxis: {

      //     //       max:  result

      //     //     },
      //     //   };
      //     // });

      //     console.log(data);

      //     //get max
      //   },
      // },
      toolbar: {
        autoSelected: "zoom",
      },
    },
    dataLabels: {
      enabled: false,
    },
    markers: {
      size: 0,
    },
    xaxis: {
      type: "datetime",
      tooltip: {
        enabled: false,
      },
      labels: {
        rotate: -90,
        rotateAlways: true,
        minHeight: 100,
        maxHeight: 160,
        show: true,
        align: "left",
        trim: true,
        // minWidth: 0,
        // maxWidth: 1200,
        style: {
          colors: ["#000000"],
          fontSize: "15px",
          // fontFamily: 'Helvetica, Arial, sans-serif',
          fontWeight: 600,
        },
        // offsetX: 0,
        // offsetY: 0,
        // format: 'dd/MM',
        // format: "dd MMM yyyy hh:mm TT",
        // datetimeFormatter: {
        //   year: 'yyyy',
        //   month: 'MMM \'yy',
        //   day: 'dd MMM',
        //   hour: 'HH:mm'
        // }
        // formatter: function (value, timestamp) {
        //   return new Date(timestamp) // The formatter function overrides format property
        // },
      },
      // title: {
      //   text: "KW",
      //   rotate: 0,
      //   offsetX: 420, // 812 - 380 850
      //   offsetY: -75, //-50 au centre
      //   style: {
      //     color: '#000000',
      //     fontSize: "15px",
      //     // fontFamily: 'Helvetica, Arial, sans-serif',
      //     fontWeight: 600,
      //     // cssClass: 'apexcharts-yaxis-title',
      //   },
      // },
    },
    stroke: {
      width: 2,
    },
    colors: ["#64006C", "#F30202", "#0054F1", "#FFD93D"],
    yaxis: {
      opposite: true,
      // formatter: (value) => {
      //   return value ;
      // },
      // min: 0,
      decimalsInFloat: 2,
      forceNiceScale: true,
      axisBorder: {
        show: true,
        color: "#78909C",
        offsetX: 0,
        offsetY: 0,
      },
      axisTicks: {
        show: true,
        borderType: "solid",
        color: "#78909C",
        width: 6,
        offsetX: 0,
        offsetY: 0,
      },
      labels: {
        show: true,
        align: "right",
        minWidth: 0,
        maxWidth: 160,
        style: {
          colors: [],
          fontSize: "15px",
          // fontFamily: 'Helvetica, Arial, sans-serif',
          fontWeight: 600,
        },
        offsetX: 0,
        offsetY: 0,
        rotate: 0,
        // formatter: (value) => { return val },
      },
      crosshairs: {
        show: true,
        position: "back",
        stroke: {
          color: "#b6b6b6",
          width: 0.6,
          dashArray: [0, 1, 2],
        },
      },
      tooltip: {
        enabled: false,
        offsetX: 0,
      },
      title: {
        text: "[KW2]",
        rotate: 0,
        offsetX: 40,
        offsetY: -170,
        style: {
          color: undefined,
          fontSize: "15px",
          // fontFamily: 'Helvetica, Arial, sans-serif',
          fontWeight: 600,
          // cssClass: 'apexcharts-yaxis-title',
        },
      },
    },
    legend: {
      showForSingleSeries: true,
      offsetX: 7,
      //  offsetY:5,
      position: "top",
      fontSize: "18px",
      //  width:1
      //  fontWeight: 800,
      markers: {
        width: 12,
        height: 12,
        strokeWidth: 2,
        strokeColor: "#fff",
        fillColors: undefined,
        radius: 12,
        offsetX: -5,
        // offsetY: 5
      },
    },
  });
  const [startDate2, setStartDate2] = useState(new Date());
  const handleChangeFormat = (event) => {
    setTtimeFormat(event.target.value);
    console.log(event.target.value);

    // handleApply();
  };

  const handleApply = (event, picker) => {
    setRt(false);
    const format = "daterange";
    let startDateObj = picker.startDate._d;
    let startDateObjDate = new Date(startDateObj);
    console.log("startDateObjDate", startDateObjDate);
    let startDateday = startDateObjDate.getDate();
    let startDatemonth = startDateObjDate.getMonth() + 1;
    let startDateyear = startDateObjDate.getFullYear();
    setstartDate(`${startDateyear}-${startDatemonth}-${startDateday}`);
    console.log(
      "final start date----",
      `${startDateyear}-${startDatemonth}-${startDateday}`
    );

    let endDateObj = picker.endDate._d;
    let endDateObjDate = new Date(endDateObj);
    console.log("endDateObjDate", endDateObjDate);

    let endDateday = endDateObjDate.getDate();
    let endDatemonth = endDateObjDate.getMonth() + 1;
    let endDateyear = endDateObjDate.getFullYear();
    let endDateHour = endDateObjDate.getHours();
    let endDateMinute = endDateObjDate.getMinutes();
    let endDateSecond = endDateObjDate.getMinutes();
    // let endDate = endDateyear + "-" + endDatemonth + "-" + endDateday;
    setendDate(`${endDateyear}-${endDatemonth}-${endDateday}`);
    console.log(
      "final end date----",
      `${endDateyear}-${endDatemonth}-${endDateday} ${endDateHour}:${endDateMinute}:${endDateSecond}`
    );
    if (Parameter === "power") {
      UserService.GetLinkedDeviceTemperatureData(
        device_id,
        phase,
        "daterange",
        `${startDateyear}-${startDatemonth}-${startDateday} 00:00:00`,
        `${endDateyear}-${endDatemonth}-${endDateday} 23:59:59`
      )
        .then((res) => {
          let powerDataFromDB = res.data.data.deviceData;
          let myData;
          let myData1;
          let myData2;
          let myData3;
          if (typeof powerDataFromDB != "undefined" && !res.data.data.error) {
            myData = Object.keys(powerDataFromDB).map((key) => {
              return [
                powerDataFromDB[key].time,
                powerDataFromDB[key].T_Power_A,
                // powerDataFromDB[key].device_data_id,
              ];
            });

            myData1 = Object.keys(powerDataFromDB).map((key) => {
              return [
                powerDataFromDB[key].time,
                powerDataFromDB[key].L1_Power_A,
                // powerDataFromDB[key].device_data_id,
              ];
            });
            myData2 = Object.keys(powerDataFromDB).map((key) => {
              return [
                powerDataFromDB[key].time,
                powerDataFromDB[key].L2_Power_A,
                // powerDataFromDB[key].device_data_id,
              ];
            });
            myData3 = Object.keys(powerDataFromDB).map((key) => {
              return [
                powerDataFromDB[key].time,
                powerDataFromDB[key].L3_Power_A,
                // powerDataFromDB[key].device_data_id,
              ];
            });

            console.log("last data ID", myData.at(-1));
            setLastDataFront(myData.at(-1));
            setFirstDataBack(myData.at(0));
          } else {
            myData = [];
          }
          if (myData.length > 0) {
            // console.log("Graph Data!!!!!", myData)
            setData(myData);
            setData1(myData1);
            setData2(myData2);
            setData3(myData3);
            console.log(data);
          }
          setisLoadingGraph(false);
        })
        .catch((err) => {
          setisLoadingGraph(false);
          console.log(err);
        });
    } else if (Parameter === "energy") {
      console.log(" get data energy");
      let QueryStarDate;
      let QueryendDate;

      if (TimeFormat === "hourly") {
        //24hr
        QueryStarDate = moment(picker.startDate._d)
          .format("YYYY-MM-DD  00:00:00");
        QueryendDate = moment(picker.startDate._d)
          .format("YYYY-MM-DD  23:59:59");
        console.log("<<===", QueryStarDate, QueryendDate);
      } else if (TimeFormat === "daily") {
        QueryStarDate = moment(picker.startDate._d)
          .startOf("month")
          .format("YYYY-MM-DD  00:00:00");
        QueryendDate = moment(picker.startDate._d).endOf("month").format("YYYY-MM-DD  23:59:59");
        console.log("<<===", QueryStarDate, QueryendDate);
      } else {
        QueryStarDate = moment(picker.startDate._d)
          .subtract(1, "year")
          .format("YYYY-MM-DD  00:00:00");
        QueryendDate = moment(picker.startDate._d)
          .subtract(1, "year")
          .format("YYYY-MM-DD  23:59:59");
        console.log("<<===", QueryStarDate, QueryendDate);
      }

      let Param = TimeFormat === "hourly" ? "T_Energy_Hr_A" : "T_Energy_D_A";
      console.log(TimeFormat, Param);

      UserService.GetLinkedDeviceData(
        device_id,
        "timerange",
        TimeFormat,
        QueryStarDate,
        QueryendDate,
      )
        .then((res) => {
          let localUnit = "KWH";
          let powerDataFromDB = res.data.data.deviceData;
          let myData;

          //
          powerDataFromDB=calculateDifferences(Object.values(powerDataFromDB));
console.log("res Data!!!!!!!!!", powerDataFromDB);

          //
          if (typeof powerDataFromDB != "undefined" && !res.data.data.error) {
            myData = powerDataFromDB.map((i) => {
              return [
                i.time2,
                i.diff,
                // powerDataFromDB[key].device_data_id,
              ];
            });

            
            console.log("last data ID", myData.at(-1));
            setLastDataFront(myData.at(-1));
            setFirstDataBack(myData.at(0));
          } else {
            myData = [];
          }
          if (myData.length > 0) {
            // console.log("Graph Data!!!!!", myData)
            myData = fillMissingGaps(myData,TimeFormat);

            setData(myData);
            setChartConfig({
              chart: {
                id: "total-power-chart",
                // type: "line",
                // stacked: true,
                height: 500,
                foreColor: "#000000",
                zoom: {
                  type: "x",
                  enabled: true,
                  autoScaleYaxis: true,
                },
                toolbar: {
                  autoSelected: "zoom",
                },
              },
              tooltip: {
                enabled: true,
                color: "#000000",
                style: {
                  fontSize: "16px",
                  // fontFamily: undefined
                },
                // shared: true,
                x: {
                  format: "dd MMM yyyy hh:mm TT",
                },
                y: [
                  {
                    formatter: function (y) {
                      if (typeof y !== "undefined") {
                        return y.toFixed(2) + " " + localUnit;
                      }
                      return y;
                    },
                  },
                  {
                    formatter: function (y) {
                      if (typeof y !== "undefined") {
                        return y.toFixed(2) + " " + localUnit;
                      }
                      return y;
                    },
                  },
                  {
                    formatter: function (y) {
                      if (typeof y !== "undefined") {
                        return y.toFixed(2) + " " + localUnit;
                      }
                      return y;
                    },
                  },
                  {
                    formatter: function (y) {
                      if (typeof y !== "undefined") {
                        return y.toFixed(2) + " " + localUnit;
                      }
                      return y;
                    },
                  },
                ],
              },
              dataLabels: {
                enabled: false,
              },
              markers: {
                size: 0,
              },
              xaxis: {
                type: "datetime",
                tickAmount: myData.length - 1,
                tooltip: {
                  enabled: false,
                },
                labels: {
                  rotate: -90,
                  rotateAlways: true,
                  minHeight: 100,
                  maxHeight: 160,
                  show: true,
                  align: "left",
                  // trim: true,
                  formatter: function (val, timestamp) {
                    if (TimeFormat === "hourly")
                      return moment(timestamp).format("HH:00");
                    if (TimeFormat === "daily")
                      return moment(timestamp).format("DD MMM");
                    if (TimeFormat === "monthly")
                      return moment(timestamp).format("MMM YYYY");
                  },
                  style: {
                    colors: ["#000000"],
                    fontSize: "15px",
                    // fontFamily: 'Helvetica, Arial, sans-serif',
                    fontWeight: 600,
                  },
                },
              },
              stroke: {
                width: 2,
              },
              colors: ["#64006C", "#F30202", "#0054F1", "#FFD93D"],
              yaxis: {
                opposite: false,
                formatter: (value) => {
                  return value;
                },
                // min: 0,
                decimalsInFloat: 2,
                forceNiceScale: true,
                axisBorder: {
                  show: true,
                  color: "#78909C",
                  offsetX: 0,
                  offsetY: 0,
                },
                axisTicks: {
                  show: true,
                  borderType: "solid",
                  color: "#78909C",
                  width: 6,
                  offsetX: 0,
                  offsetY: 0,
                },
                labels: {
                  show: true,
                  align: "right",
                  minWidth: 0,
                  maxWidth: 160,
                  style: {
                    colors: [],
                    fontSize: "15px",
                    // fontFamily: 'Helvetica, Arial, sans-serif',
                    fontWeight: 600,
                  },
                  offsetX: 0,
                  offsetY: 0,
                  rotate: 0,
                  // formatter: (value) => { return val },
                },
                crosshairs: {
                  show: true,
                  position: "back",
                  stroke: {
                    color: "#b6b6b6",
                    width: 0.6,
                    dashArray: [0, 1, 2],
                  },
                },
                tooltip: {
                  enabled: false,
                  offsetX: 0,
                },
                title: {
                  text: "[" + localUnit + "]",
                  rotate: 0,
                  offsetX: 40,
                  offsetY: -170,
                  style: {
                    color: undefined,
                    fontSize: "15px",
                    // fontFamily: 'Helvetica, Arial, sans-serif',
                    fontWeight: 600,
                    // cssClass: 'apexcharts-yaxis-title',
                  },
                },
              },
              legend: {
                showForSingleSeries: true,
                offsetX: 7,
                //  offsetY:5,
                position: "top",
                fontSize: "18px",
                //  width:1
                //  fontWeight: 800,
                markers: {
                  width: 12,
                  height: 12,
                  strokeWidth: 2,
                  strokeColor: "#fff",
                  fillColors: undefined,
                  radius: 12,
                  offsetX: -5,
                  // offsetY: 5
                },
              },
            });
          }
          setisLoadingGraph(false);
        })
        .catch((err) => {
          setisLoadingGraph(false);
          console.log(err);
        });
    } else if (Parameter === "energy2") {
      console.log(" get data energy");
      let Param = TimeFormat === "daily" ? "T_Energy_Hr_A" : "T_Energy_D_A";
      UserService.GetLinkedDeviceData(
        device_id,
        "timerange",
        TimeFormat,
        `${startDateyear}-${startDatemonth}-${startDateday} 00:00:00`,
        `${endDateyear}-${endDatemonth}-${endDateday} 23:59:59`
      )
        .then((res) => {
          let powerDataFromDB = res.data.data.deviceData;
          let myData;
          let myData1;
          let myData2;
          let myData3;
          if (typeof powerDataFromDB != "undefined" && !res.data.data.error) {
            myData = Object.keys(powerDataFromDB).map((key) => {
              return [
                powerDataFromDB[key].time2,
                powerDataFromDB[key]["T_Energy_Acc"],
                // powerDataFromDB[key].device_data_id,
              ];
            });

            myData1 = Object.keys(powerDataFromDB).map((key) => {
              return [
                powerDataFromDB[key].time2,
                powerDataFromDB[key]["diff"],
                // powerDataFromDB[key].device_data_id,
              ];
            });
            console.log("last data ID", myData.at(-1));
            setLastDataFront(myData.at(-1));
            setFirstDataBack(myData.at(0));
          } else {
            myData = [];
            myData1 = [];
          }
          if (myData.length > 0) {
            // console.log("Graph Data!!!!!", myData)
            setData(myData);
            setData1(myData1);
            // setData2(myData2);
            // setData3(myData3);
            // console.log(data);
          }
          setisLoadingGraph(false);
        })
        .catch((err) => {
          setisLoadingGraph(false);
          console.log(err);
        });
    } else if (Parameter === "temperature") {
      UserService.GetLinkedDeviceTemperatureData(
        device_id,
        "temperature",
        "daterange",
        `${startDateyear}-${startDatemonth}-${startDateday} 00:00:00`,
        `${endDateyear}-${endDatemonth}-${endDateday} 23:59:59`
      )
        .then((res) => {
          let powerDataFromDB = res.data.data.deviceData;
          let myData;

          if (typeof powerDataFromDB != "undefined" && !res.data.data.error) {
            myData = Object.keys(powerDataFromDB).map((key) => {
              return [
                powerDataFromDB[key].time,
                powerDataFromDB[key].value,
                // powerDataFromDB[key].device_data_id,
              ];
            });

            console.log("last data ID", myData.at(-1));
            setLastDataFront(myData.at(-1));
            setFirstDataBack(myData.at(0));
          } else {
            myData = [];
          }
          if (myData.length > 0) {
            // console.log("Graph Data!!!!!", myData)
            setData(myData);
            console.log(data);
          }
          setisLoadingGraph(false);
        })
        .catch((err) => {
          setisLoadingGraph(false);
          console.log(err);
        });
    }
  };

  function fillMissingGaps(data, type) {
    let result = [];
    const template = {};
    const template2 = {};
  
    const startOfMonth = parseInt(
      moment(data[0][0]).startOf("month").format("DD")
    );
    const endOfMonth = parseInt(moment(data[0][0]).endOf("month").format("DD"));
    const Day = parseInt(moment(data[0][0]).format("DD"));
    const Month = parseInt(moment(data[0][0]).endOf("month").format("MM"));
    const Year = parseInt(moment(data[0][0]).endOf("month").format("YYYY"));
  
    if (type == "hourly") {
      data.forEach((x) => {
        template2[moment(x[0]).format("HH")] = x;
      });
      for (let num = 0; num <= 23; num++) {
        if (num < 10) {
          template["0" + num] = [
            moment(`${Year}-${Month}-${Day}`).format(`YYYY-MM-DD 0${num}:00:00`),
            null,
          ];
        } else {
          template["" + num] = [
            moment(`${Year}-${Month}-${Day}`).format(`YYYY-MM-DD ${num}:00:00`),
            null,
          ];
        }
      }
      // console.log("+++", template);
      //console.log(template2);
  
      result = Object.values({ ...template, ...template2 }).sort((a, b) =>
        a[0].localeCompare(b[0], "en", { numeric: true })
      );
      return result;
    } else if (type == "daily") {
      data.forEach((x) => {
        template2[moment(x[0]).format("DD")] = x;
      });
  
      for (let num = startOfMonth; num <= endOfMonth; num++) {
        if (num < 10) {
          template["0" + num] = [
            moment(`${Year}-${Month}-${num}`).format("YYYY-MM-DD  00:00:00"),
            null,
          ];
        } else {
          template["" + num] = [
            moment(`${Year}-${Month}-${num}`).format("YYYY-MM-DD  00:00:00"),
            null,
          ];
        }
      }
  
      result = Object.values({ ...template, ...template2 }).sort((a, b) =>
        a[0].localeCompare(b[0], "en", { numeric: true })
      );
      // console.log("***", result);
      // console.log("+++", template);
      // console.log("+++", template2);
      // console.log("*/*/", result);
      return result;
    } else if (type == "monthly") {
      data.forEach((x) => {
        template2[moment(x[0]).format("MM")] = x;
      });
  
      for (let num = 1; num <= 12; num++) {
        if (num < 10) {
          template["0" + num] = [
            moment(`${Year}-0${num}-02`).format("YYYY-MM-DD  00:00:00"),
            null,
          ];
        } else {
          template["" + num] = [
            moment(`${Year}-${num}-02`).format("YYYY-MM-DD  00:00:00"),
            null,
          ];
        }
      }
      console.log("+++", template2);
      result = Object.values({ ...template, ...template2 }).sort((a, b) =>
        a[0].localeCompare(b[0], "en", { numeric: true })
      );
      // console.log("***", result);
      // console.log("+++", template2);
      // console.log("*/*/", result);
      return result;
    }
  }

  function getTodayData() {
    // console.log("getTodayData", Parameter);
    const endDateObjDate = new Date(Date.now());
    const startDateObjDate = new Date(Date.now() - 3600 * 1000 * 24);
    // console.log("useEffect ", startDateObjDate);
    let startDateday = startDateObjDate.getDate();
    let startDatemonth = startDateObjDate.getMonth() + 1;
    let startDateyear = startDateObjDate.getFullYear();

    let endDateday = endDateObjDate.getDate();
    let endDatemonth = endDateObjDate.getMonth() + 1;
    let endDateyear = endDateObjDate.getFullYear();

    let startDateHour = startDateObjDate.getHours();
    let startDateMinute = startDateObjDate.getMinutes();
    let startDateSecond = startDateObjDate.getMinutes();

    // setstartDate(`${startDateyear}-${startDatemonth}-${startDateday}`);
    // console.log(
    //   "useEffect ",
    //   `${startDateyear}-${startDatemonth}-${startDateday}`
    // );

    // get initial data from API
    if (device_id) {
      // console.log("!!!!!call power initial use effect!!!!!!!!!!")
      setisLoadingGraph(true);
      // UserService.GetLinkedDeviceData(
      //   device_id,
      //   phase,
      //   "daterange",
      //   `${startDateyear}-${startDatemonth}-${startDateday}`,
      //   `${startDateyear}-${startDatemonth}-${startDateday}`
      // )
      let localUnit;
      if (Parameter === "power") {
        // console.log(" get data power");
        localUnit = "KW";
        UserService.GetLinkedDeviceTemperatureData(
          device_id,
          phase,
          "daterange",
          `${startDateyear}-${startDatemonth}-${startDateday} ${startDateHour}:${startDateMinute}:${startDateSecond}`,
          `${endDateyear}-${endDatemonth}-${endDateday} 23:59:59`
          // startDate + " 00:00:00"
        )
          .then((res) => {
            //console.log("power initial res:", res.data.data.deviceData)
            let powerDataFromDB = res.data.data.deviceData;
            // console.log("res Data!!!!!!!!!", powerDataFromDB);
            let myData;
            let myData1;
            let myData2;
            let myData3;
            if (typeof powerDataFromDB != "undefined" && !res.data.data.error) {
              myData = Object.keys(powerDataFromDB).map((key) => {
                return [
                  powerDataFromDB[key].time,
                  powerDataFromDB[key].T_Power_A,
                  // powerDataFromDB[key].device_data_id,
                ];
              });
              myData1 = Object.keys(powerDataFromDB).map((key) => {
                return [
                  powerDataFromDB[key].time,
                  powerDataFromDB[key].L1_Power_A,
                  // powerDataFromDB[key].device_data_id,
                ];
              });
              myData2 = Object.keys(powerDataFromDB).map((key) => {
                return [
                  powerDataFromDB[key].time,
                  powerDataFromDB[key].L2_Power_A,
                  // powerDataFromDB[key].device_data_id,
                ];
              });
              myData3 = Object.keys(powerDataFromDB).map((key) => {
                return [
                  powerDataFromDB[key].time,
                  powerDataFromDB[key].L3_Power_A,
                  // powerDataFromDB[key].device_data_id,
                ];
              });
            } else {
              myData = [];
            }
            if (myData.length > 0) {
              // console.log("last data ID", myData.at(-1));
              setLastDataFront(myData.at(-1));
              setFirstDataBack(myData.at(0));
              setendDate(
                `${startDateyear}-${startDatemonth}-${startDateday} ${startDateHour}:${startDateMinute}:${startDateSecond}`
              );
              // console.log("Graph Data!!!!!", myData);
              // myData
              setData(myData);
              setData1(myData1);
              setData2(myData2);
              setData3(myData3);
              setChartConfig({
                chart: {
                  id: "total-power-chart",
                  // type: "line",
                  // stacked: true,
                  height: 500,
                  foreColor: "#000000",
                  zoom: {
                    type: "x",
                    enabled: true,
                    autoScaleYaxis: true,
                  },
                  toolbar: {
                    autoSelected: "zoom",
                  },
                },
                tooltip: {
                  enabled: true,
                  color: "#000000",
                  style: {
                    fontSize: "16px",
                    // fontFamily: undefined
                  },
                  // shared: true,
                  x: {
                    format: "dd MMM yyyy hh:mm TT",
                  },
                  y: [
                    {
                      formatter: function (y) {
                        if (typeof y !== "undefined") {
                          return y.toFixed(2) + " " + localUnit;
                        }
                        return y;
                      },
                    },
                    {
                      formatter: function (y) {
                        if (typeof y !== "undefined") {
                          return y.toFixed(2) + " " + localUnit;
                        }
                        return y;
                      },
                    },
                    {
                      formatter: function (y) {
                        if (typeof y !== "undefined") {
                          return y.toFixed(2) + " " + localUnit;
                        }
                        return y;
                      },
                    },
                    {
                      formatter: function (y) {
                        if (typeof y !== "undefined") {
                          return y.toFixed(2) + " " + localUnit;
                        }
                        return y;
                      },
                    },
                  ],
                },
                dataLabels: {
                  enabled: false,
                },
                markers: {
                  size: 0,
                },
                xaxis: {
                  type: "datetime",
                  tickAmount: myData.length - 1,
                  tooltip: {
                    enabled: false,
                  },
                  labels: {
                    rotate: -90,
                    rotateAlways: true,
                    minHeight: 100,
                    maxHeight: 160,
                    show: true,
                    align: "left",
                    // trim: true,
                    // formatter: function (val, timestamp) {
                    //   if (TimeFormat === "hourly")
                    //     return moment(timestamp).format("HH:00");
                    //   if (TimeFormat === "daily")
                    //     return moment(timestamp).format("DD MMM");
                    //   if (TimeFormat === "monthly")
                    //     return moment(timestamp).format("MMM YYYY");
                    // },
                    style: {
                      colors: ["#000000"],
                      fontSize: "15px",
                      // fontFamily: 'Helvetica, Arial, sans-serif',
                      fontWeight: 600,
                    },
                  },
                },
                stroke: {
                  width: 2,
                },
                colors: ["#64006C", "#F30202", "#0054F1", "#FFD93D"],
                yaxis: {
                  opposite: false,
                  formatter: (value) => {
                    return value;
                  },
                  // min: 0,
                  decimalsInFloat: 2,
                  forceNiceScale: true,
                  axisBorder: {
                    show: true,
                    color: "#78909C",
                    offsetX: 0,
                    offsetY: 0,
                  },
                  axisTicks: {
                    show: true,
                    borderType: "solid",
                    color: "#78909C",
                    width: 6,
                    offsetX: 0,
                    offsetY: 0,
                  },
                  labels: {
                    show: true,
                    align: "right",
                    minWidth: 0,
                    maxWidth: 160,
                    style: {
                      colors: [],
                      fontSize: "15px",
                      // fontFamily: 'Helvetica, Arial, sans-serif',
                      fontWeight: 600,
                    },
                    offsetX: 0,
                    offsetY: 0,
                    rotate: 0,
                    // formatter: (value) => { return val },
                  },
                  crosshairs: {
                    show: true,
                    position: "back",
                    stroke: {
                      color: "#b6b6b6",
                      width: 0.6,
                      dashArray: [0, 1, 2],
                    },
                  },
                  tooltip: {
                    enabled: false,
                    offsetX: 0,
                  },
                  title: {
                    text: "[" + localUnit + "]",
                    rotate: 0,
                    offsetX: 40,
                    offsetY: -170,
                    style: {
                      color: undefined,
                      fontSize: "15px",
                      // fontFamily: 'Helvetica, Arial, sans-serif',
                      fontWeight: 600,
                      // cssClass: 'apexcharts-yaxis-title',
                    },
                  },
                },
                legend: {
                  showForSingleSeries: true,
                  offsetX: 7,
                  //  offsetY:5,
                  position: "top",
                  fontSize: "18px",
                  //  width:1
                  //  fontWeight: 800,
                  markers: {
                    width: 12,
                    height: 12,
                    strokeWidth: 2,
                    strokeColor: "#fff",
                    fillColors: undefined,
                    radius: 12,
                    offsetX: -5,
                    // offsetY: 5
                  },
                },
              });
              // console.log(data);
            } else {
              //No Data
              setDataAvailable(false);
            }
            setisLoadingGraph(false);
          })
          .catch((err) => {
            setisLoadingGraph(false);
            setDataAvailable(false);
            // console.log(err)
          });
      } else if (Parameter === "energy") {
        localUnit = "KWH";
        let QueryStarDate;
        let QueryendDate;
      //  console.log(" get data energy");
        let Param = TimeFormat === "hourly" ? "T_Energy_Hr_A" : "T_Energy_D_A";

        // if (TimeFormat === "daily" || TimeFormat === "hourly")
        //   QueryendDate = `${startDateyear}-${startDatemonth}-${startDateday} ${startDateHour}:${startDateMinute}:${startDateSecond}`;
        // else QueryendDate = subtract30Days(new Date());

        if (TimeFormat === "hourly") {
          //24hr
          QueryStarDate = moment().format("YYYY-MM-DD  00:00:00");
          QueryendDate = moment().format("YYYY-MM-DD  23:59:59");
        } else if (TimeFormat === "daily") {
          QueryendDate = moment().format("YYYY-MM-DD  00:00:00");
          QueryStarDate = moment()
            .startOf("month")
            .format("YYYY-MM-DD  00:00:00");
        } else {
          QueryendDate = moment().format("YYYY-MM-DD  00:00:00");
          QueryStarDate = moment()
            .subtract(12, "month")
            .format("YYYY-MM-DD  23:59:59");
        }

        console.log("new current ===>>", QueryStarDate, QueryendDate);

        UserService.GetLinkedDeviceData(
          device_id,
          "timerange",
          TimeFormat,
          QueryStarDate,
          QueryendDate
        ) //need to be refrshed
          .then((res) => {
            //console.log("power initial res:", res.data.data.deviceData)
            let powerDataFromDB = res.data.data.deviceData;
            powerDataFromDB=calculateDifferences(Object.values(powerDataFromDB));
            console.log("res Data!!!!!!!!!", powerDataFromDB);
            let myData;
            if (typeof powerDataFromDB != "undefined" && !res.data.data.error) {
              myData = powerDataFromDB.map((i) => {
                return [
                  i.time2,
                  i.diff,
                  // powerDataFromDB[key].device_data_id,
                ];
              });
            } else {
              myData = [];
            }
            if (myData.length > 0) {
              console.log("last data ID", myData.at(-1));
              setLastDataFront(myData.at(-1));
              setFirstDataBack(myData.at(0));
              setendDate(
                `${startDateyear}-${startDatemonth}-${startDateday} ${startDateHour}:${startDateMinute}:${startDateSecond}`
              );
              myData = fillMissingGaps(myData,TimeFormat);
              
              setData(myData);

              setChartConfig({
                chart: {
                  id: "total-power-chart",
                  // type: "line",
                  // stacked: true,
                  height: 500,
                  foreColor: "#000000",
                  zoom: {
                    type: "x",
                    enabled: true,
                    autoScaleYaxis: true,
                  },
                  toolbar: {
                    autoSelected: "zoom",
                  },
                },
                tooltip: {
                  enabled: true,
                  color: "#000000",
                  style: {
                    fontSize: "16px",
                    // fontFamily: undefined
                  },
                  // shared: true,
                  x: {
                    format: "dd MMM yyyy hh:mm TT",
                  },
                  y: [
                    {
                      formatter: function (y) {
                        if (typeof y !== "undefined") {
                          return y.toFixed(2) + " " + localUnit;
                        }
                        return y;
                      },
                    },
                    {
                      formatter: function (y) {
                        if (typeof y !== "undefined") {
                          return y.toFixed(2) + " " + localUnit;
                        }
                        return y;
                      },
                    },
                    {
                      formatter: function (y) {
                        if (typeof y !== "undefined") {
                          return y.toFixed(2) + " " + localUnit;
                        }
                        return y;
                      },
                    },
                    {
                      formatter: function (y) {
                        if (typeof y !== "undefined") {
                          return y.toFixed(2) + " " + localUnit;
                        }
                        return y;
                      },
                    },
                  ],
                },
                dataLabels: {
                  enabled: false,
                },
                markers: {
                  size: 0,
                },
                xaxis: {
                  type: "datetime",
                  tickAmount: myData.length - 1,
                  tooltip: {
                    enabled: false,
                  },
                  labels: {
                    rotate: -90,
                    rotateAlways: true,
                    minHeight: 100,
                    maxHeight: 160,
                    show: true,
                    align: "left",
                    // trim: true,
                    formatter: function (val, timestamp) {
                      if (TimeFormat === "hourly")
                        return moment(timestamp).format("HH:00");
                      if (TimeFormat === "daily")
                        return moment(timestamp).format("DD MMM");
                      if (TimeFormat === "monthly")
                        return moment(timestamp).format("MMM YYYY");
                    },
                    style: {
                      colors: ["#000000"],
                      fontSize: "15px",
                      // fontFamily: 'Helvetica, Arial, sans-serif',
                      fontWeight: 600,
                    },
                  },
                },
                stroke: {
                  width: 2,
                },
                colors: ["#64006C", "#F30202", "#0054F1", "#FFD93D"],
                yaxis: {
                  opposite: false,
                  formatter: (value) => {
                    return value;
                  },
                  // min: 0,
                  decimalsInFloat: 2,
                  forceNiceScale: true,
                  axisBorder: {
                    show: true,
                    color: "#78909C",
                    offsetX: 0,
                    offsetY: 0,
                  },
                  axisTicks: {
                    show: true,
                    borderType: "solid",
                    color: "#78909C",
                    width: 6,
                    offsetX: 0,
                    offsetY: 0,
                  },
                  labels: {
                    show: true,
                    align: "right",
                    minWidth: 0,
                    maxWidth: 160,
                    style: {
                      colors: [],
                      fontSize: "15px",
                      // fontFamily: 'Helvetica, Arial, sans-serif',
                      fontWeight: 600,
                    },
                    offsetX: 0,
                    offsetY: 0,
                    rotate: 0,
                    // formatter: (value) => { return val },
                  },
                  crosshairs: {
                    show: true,
                    position: "back",
                    stroke: {
                      color: "#b6b6b6",
                      width: 0.6,
                      dashArray: [0, 1, 2],
                    },
                  },
                  tooltip: {
                    enabled: false,
                    offsetX: 0,
                  },
                  title: {
                    text: "[" + localUnit + "]",
                    rotate: 0,
                    offsetX: 40,
                    offsetY: -170,
                    style: {
                      color: undefined,
                      fontSize: "15px",
                      // fontFamily: 'Helvetica, Arial, sans-serif',
                      fontWeight: 600,
                      // cssClass: 'apexcharts-yaxis-title',
                    },
                  },
                },
                legend: {
                  showForSingleSeries: true,
                  offsetX: 7,
                  //  offsetY:5,
                  position: "top",
                  fontSize: "18px",
                  //  width:1
                  //  fontWeight: 800,
                  markers: {
                    width: 12,
                    height: 12,
                    strokeWidth: 2,
                    strokeColor: "#fff",
                    fillColors: undefined,
                    radius: 12,
                    offsetX: -5,
                    // offsetY: 5
                  },
                },
              });
            } else {
              //No Data
              setDataAvailable(false);
            }
            setisLoadingGraph(false);
          })
          .catch((err) => {
            setisLoadingGraph(false);
            setDataAvailable(false);
            // console.log(err)
          });
      } else if (Parameter === "energy2") {
        let QueryendDate;

        console.log(" get data energy");
        let Param = TimeFormat === "daily" ? "T_Energy_Hr_A" : "T_Energy_D_A";
        if (TimeFormat === "daily" || TimeFormat === "hourly")
          QueryendDate = `${startDateyear}-${startDatemonth}-${startDateday} ${startDateHour}:${startDateMinute}:${startDateSecond}`;
        else QueryendDate = subtract30Days(new Date());
        console.log(QueryendDate);
        UserService.GetLinkedDeviceData(
          device_id,
          "timerange",
          TimeFormat,
          QueryendDate,
          `${endDateyear}-${endDatemonth}-${endDateday} 23:59:59`
        ) //need to be refrshed
          .then((res) => {
            //console.log("power initial res:", res.data.data.deviceData)
            let powerDataFromDB = res.data.data.deviceData;
            console.log("res Data!!!!!!!!!", powerDataFromDB);
            let myData;
            let myData1;
            let myData2;
            let myData3;
            if (typeof powerDataFromDB != "undefined" && !res.data.data.error) {
              myData = Object.keys(powerDataFromDB).map((key) => {
                return [
                  powerDataFromDB[key].time2,
                  powerDataFromDB[key]["T_Energy_Acc"],
                  // powerDataFromDB[key].device_data_id,
                ];
              });
              myData1 = Object.keys(powerDataFromDB).map((key) => {
                return [
                  powerDataFromDB[key].time2,
                  powerDataFromDB[key]["diff"],
                  // powerDataFromDB[key].device_data_id,
                ];
              });
            } else {
              myData = [];
              myData1 = [];
            }
            if (myData.length > 0) {
              console.log("last data ID", myData.at(-1));
              setLastDataFront(myData.at(-1));
              setFirstDataBack(myData.at(0));
              setendDate(
                `${startDateyear}-${startDatemonth}-${startDateday} ${startDateHour}:${startDateMinute}:${startDateSecond}`
              );
              // console.log("Graph Data!!!!!", myData);
              // myData
              setData(myData);
              setData1(myData1);
              // setData2(myData2);
              // setData3(myData3);

              // console.log(data);
            } else {
              //No Data
              setDataAvailable(false);
            }
            setisLoadingGraph(false);
          })
          .catch((err) => {
            setisLoadingGraph(false);
            setDataAvailable(false);
            // console.log(err)
          });
      } else if (Parameter === "temperature") {
        localUnit = "°C";
        console.log(" get data temperature");
        UserService.GetLinkedDeviceTemperatureData(
          device_id,
          "temperature",
          "daterange",
          `${startDateyear}-${startDatemonth}-${startDateday} ${startDateHour}:${startDateMinute}:${startDateSecond}`,
          `${endDateyear}-${endDatemonth}-${endDateday} 23:59:59`
          // startDate + " 00:00:00"
        )
          .then((res) => {
            //console.log("power initial res:", res.data.data.deviceData)
            let powerDataFromDB = res.data.data.deviceData;
            console.log("res Data!!!!!!!!!", powerDataFromDB);
            let myData;

            if (typeof powerDataFromDB != "undefined" && !res.data.data.error) {
              myData = Object.keys(powerDataFromDB).map((key) => {
                return [
                  powerDataFromDB[key].time,
                  powerDataFromDB[key].value,
                  // powerDataFromDB[key].device_data_id,
                ];
              });
            } else {
              myData = [];
            }
            if (myData.length > 0) {
              console.log("last data ID", myData.at(-1));
              setLastDataFront(myData.at(-1));
              setFirstDataBack(myData.at(0));
              setendDate(
                `${startDateyear}-${startDatemonth}-${startDateday} ${startDateHour}:${startDateMinute}:${startDateSecond}`
              );
              // console.log("Graph Data!!!!!", myData);
              // myData
              setData(myData);
              setChartConfig({
                chart: {
                  id: "total-power-chart",
                  // type: "line",
                  // stacked: true,
                  height: 500,
                  foreColor: "#000000",
                  zoom: {
                    type: "x",
                    enabled: true,
                    autoScaleYaxis: true,
                  },
                  toolbar: {
                    autoSelected: "zoom",
                  },
                },
                tooltip: {
                  enabled: true,
                  color: "#000000",
                  style: {
                    fontSize: "16px",
                    // fontFamily: undefined
                  },
                  // shared: true,
                  x: {
                    format: "dd MMM yyyy hh:mm TT",
                  },
                  y: [
                    {
                      formatter: function (y) {
                        if (typeof y !== "undefined") {
                          return y.toFixed(2) + " " + localUnit;
                        }
                        return y;
                      },
                    },
                    {
                      formatter: function (y) {
                        if (typeof y !== "undefined") {
                          return y.toFixed(2) + " " + localUnit;
                        }
                        return y;
                      },
                    },
                    {
                      formatter: function (y) {
                        if (typeof y !== "undefined") {
                          return y.toFixed(2) + " " + localUnit;
                        }
                        return y;
                      },
                    },
                    {
                      formatter: function (y) {
                        if (typeof y !== "undefined") {
                          return y.toFixed(2) + " " + localUnit;
                        }
                        return y;
                      },
                    },
                  ],
                },
                dataLabels: {
                  enabled: false,
                },
                markers: {
                  size: 0,
                },
                xaxis: {
                  type: "datetime",
                  tickAmount: myData.length - 1,
                  tooltip: {
                    enabled: false,
                  },
                  labels: {
                    rotate: -90,
                    rotateAlways: true,
                    minHeight: 100,
                    maxHeight: 160,
                    show: true,
                    align: "left",
                    // trim: true,
                    // formatter: function (val, timestamp) {
                    //   if (TimeFormat === "hourly")
                    //     return moment(timestamp).format("HH:00");
                    //   if (TimeFormat === "daily")
                    //     return moment(timestamp).format("DD MMM");
                    //   if (TimeFormat === "monthly")
                    //     return moment(timestamp).format("MMM YYYY");
                    // },
                    style: {
                      colors: ["#000000"],
                      fontSize: "15px",
                      // fontFamily: 'Helvetica, Arial, sans-serif',
                      fontWeight: 600,
                    },
                  },
                },
                stroke: {
                  width: 2,
                },
                colors: ["#64006C", "#F30202", "#0054F1", "#FFD93D"],
                yaxis: {
                  opposite: false,
                  formatter: (value) => {
                    return value;
                  },
                  // min: 0,
                  decimalsInFloat: 2,
                  forceNiceScale: true,
                  axisBorder: {
                    show: true,
                    color: "#78909C",
                    offsetX: 0,
                    offsetY: 0,
                  },
                  axisTicks: {
                    show: true,
                    borderType: "solid",
                    color: "#78909C",
                    width: 6,
                    offsetX: 0,
                    offsetY: 0,
                  },
                  labels: {
                    show: true,
                    align: "right",
                    minWidth: 0,
                    maxWidth: 160,
                    style: {
                      colors: [],
                      fontSize: "15px",
                      // fontFamily: 'Helvetica, Arial, sans-serif',
                      fontWeight: 600,
                    },
                    offsetX: 0,
                    offsetY: 0,
                    rotate: 0,
                    // formatter: (value) => { return val },
                  },
                  crosshairs: {
                    show: true,
                    position: "back",
                    stroke: {
                      color: "#b6b6b6",
                      width: 0.6,
                      dashArray: [0, 1, 2],
                    },
                  },
                  tooltip: {
                    enabled: false,
                    offsetX: 0,
                  },
                  title: {
                    text: "[" + localUnit + "]",
                    rotate: 0,
                    offsetX: 40,
                    offsetY: -170,
                    style: {
                      color: undefined,
                      fontSize: "15px",
                      // fontFamily: 'Helvetica, Arial, sans-serif',
                      fontWeight: 600,
                      // cssClass: 'apexcharts-yaxis-title',
                    },
                  },
                },
                legend: {
                  showForSingleSeries: true,
                  offsetX: 7,
                  //  offsetY:5,
                  position: "top",
                  fontSize: "18px",
                  //  width:1
                  //  fontWeight: 800,
                  markers: {
                    width: 12,
                    height: 12,
                    strokeWidth: 2,
                    strokeColor: "#fff",
                    fillColors: undefined,
                    radius: 12,
                    offsetX: -5,
                    // offsetY: 5
                  },
                },
              });

              // console.log(data);
            } else {
              //No Data
              setDataAvailable(false);
            }
            setisLoadingGraph(false);
          })
          .catch((err) => {
            setisLoadingGraph(false);
            setDataAvailable(false);
            // console.log(err)
          });
      }
    }
  }

  useEffect(() => {
    const interval = setInterval(() => {
      // console.log("Refsh 40 second");
      // console.log("setInterval", Parameter);

      if (Rt) getTodayData();
    }, 60 * 1000);
    return () => clearInterval(interval);
  }, [TimeFormat, Parameter, device_id, Rt]);

  useEffect(() => {
    var localUnit = "";
    var tooltipsArr = [];
    var TimeFormatDisplay = "dd MMM yyyy hh:mm TT";
    setData([]);
    setData1([]);
    setData2([]);
    setData3([]);
    getTodayData();

    // if (Parameter === "power") {
    //   localUnit = "KW";
    //   tooltipsArr = [
    //     {
    //       formatter: function (y) {
    //         if (typeof y !== "undefined") {
    //           return y.toFixed(2) + " " + localUnit;
    //         }
    //         return y;
    //       },
    //     },
    //     {
    //       formatter: function (y) {
    //         if (typeof y !== "undefined") {
    //           return y.toFixed(2) + " " + localUnit;
    //         }
    //         return y;
    //       },
    //     },
    //     {
    //       formatter: function (y) {
    //         if (typeof y !== "undefined") {
    //           return y.toFixed(2) + " " + localUnit;
    //         }
    //         return y;
    //       },
    //     },
    //     {
    //       formatter: function (y) {
    //         if (typeof y !== "undefined") {
    //           return y.toFixed(2) + " " + localUnit;
    //         }
    //         return y;
    //       },
    //     },
    //   ];
    // } else if (Parameter === "energy" || Parameter === "energy2") {
    //   localUnit = "KWH";
    //   tooltipsArr = [
    //     {
    //       formatter: function (y) {
    //         if (typeof y !== "undefined") {
    //           return y.toFixed(2) + " " + localUnit;
    //         }
    //         return y;
    //       },
    //     },
    //   ];
    // } else if (Parameter === "temperature") {
    //   localUnit = "°C";
    //   tooltipsArr = [
    //     {
    //       formatter: function (y) {
    //         if (typeof y !== "undefined") {
    //           return y.toFixed(2) + " " + localUnit;
    //         }
    //         return y;
    //       },
    //     },
    //   ];
    // }

    // setChartConfig((prevState) => {
    //   return {
    //     ...prevState,
    //     tooltip: {
    //       enabled: true,
    //       color: "#000000",
    //       style: {
    //         fontSize: "16px",
    //         // fontFamily: undefined
    //       },
    //       // shared: true,
    //       x: {
    //         format: "dd MMM yyyy hh:mm TT",
    //       },
    //       y: tooltipsArr,
    //       // custom: function ({ series, seriesIndex, dataPointIndex, w }) {
    //       //   var data =
    //       //     w.globals.initialSeries[seriesIndex].data[dataPointIndex];
    //       //   const time = new Date(w.globals.seriesX[0][dataPointIndex]);
    //       //   // console.log(series,  dataPointIndex,w);

    //       //   const options = {
    //       //     day: '2-digit',
    //       //     month: 'short',
    //       //     year: 'numeric',
    //       //     hour: '2-digit',
    //       //     minute: '2-digit',
    //       //     hour12: true
    //       //   };
    //       //   const formattedDate = time.toLocaleString('en-US', options);
    //       //   const render = series.map((v, i) => {
    //       //     const name = w.globals.seriesNames[i];
    //       //     const color = w.globals.colors[i];
    //       //     const value = v[dataPointIndex];
    //       //     console.log(i, name, color, value);
    //       //     return `<li><h4><span style="background: ${color};"></span><b>${name}:</b> ${value}</h4></li>`;
    //       //   }).join(' ');
    //       //   console.log(render);

    //       //   return (
    //       //     "<ul>" +
    //       //     "<li><h4><b>Time</b>: " +
    //       //     formattedDate +
    //       //     "</h4></li>" +
    //       //     render+
    //       //     "</ul>"
    //       //   );
    //       // },
    //     },
    //     yaxis: {
    //       opposite: false,
    //       formatter: (value) => {
    //         return value;
    //       },
    //       // min: 0,
    //       decimalsInFloat: 2,
    //       forceNiceScale: true,
    //       axisBorder: {
    //         show: true,
    //         color: "#78909C",
    //         offsetX: 0,
    //         offsetY: 0,
    //       },
    //       axisTicks: {
    //         show: true,
    //         borderType: "solid",
    //         color: "#78909C",
    //         width: 6,
    //         offsetX: 0,
    //         offsetY: 0,
    //       },
    //       labels: {
    //         show: true,
    //         align: "right",
    //         minWidth: 0,
    //         maxWidth: 160,
    //         style: {
    //           colors: [],
    //           fontSize: "15px",
    //           // fontFamily: 'Helvetica, Arial, sans-serif',
    //           fontWeight: 600,
    //         },
    //         offsetX: 0,
    //         offsetY: 0,
    //         rotate: 0,
    //         // formatter: (value) => { return val },
    //       },
    //       crosshairs: {
    //         show: true,
    //         position: "back",
    //         stroke: {
    //           color: "#b6b6b6",
    //           width: 0.6,
    //           dashArray: [0, 1, 2],
    //         },
    //       },
    //       tooltip: {
    //         enabled: false,
    //         offsetX: 0,
    //       },
    //       title: {
    //         text: "[" + localUnit + "]",
    //         rotate: 0,
    //         offsetX: 40,
    //         offsetY: -170,
    //         style: {
    //           color: undefined,
    //           fontSize: "15px",
    //           // fontFamily: 'Helvetica, Arial, sans-serif',
    //           fontWeight: 600,
    //           // cssClass: 'apexcharts-yaxis-title',
    //         },
    //       },
    //     },
    //   };
    // });
  }, [TimeFormat, Parameter]);

  useEffect(() => {
    setData([]);
    setData1([]);
    setData2([]);
    setData3([]);
    getTodayData();
    setParameter("power");
  }, [device_id]);

  useEffect(() => {
    setData([]);
    setData1([]);
    setData2([]);
    setData3([]);
    if (Rt) {
      getTodayData();
    }
    // console.log("Real time mode", Rt);
  }, [Rt, device_id]);

  // useCustomCompareEffect(
  //   () => {
  //     if (isGraphDataFromSocket && Rt) {
  //       // load last 24hr data and append new one received
  //       console.log(
  //         "!!!!!!!!!!!!!!!!!!!!!!!!! Graph data from socket:",
  //         graphDataFromSocket
  //       );
  //       if (graphDataFromSocket.length > 0) {
  //         let myData = graphDataFromSocket.map((item) => {
  //           return [item.time, item.value];
  //         });
  //         if (chartRef && chartRef.current != null && Rt) {
  //           chartRef.current.chart.ctx.appendData([
  //             {
  //               data: myData,
  //             },
  //           ]);
  //         }
  //         console.log("socket g data!!!!!!!", myData);
  //       }
  //     }
  //   },
  //   [
  //     isFilterGraphData,
  //     graphDataFromFilter,
  //     isGraphDataFromSocket,
  //     graphDataFromSocket,
  //   ],
  //   (prevDeps, nextDeps) => isEqual(prevDeps, nextDeps)
  // );

  function handleBackIClick() {
    setRt(false);
    if (Parameter === "power") {
      UserService.GetLinkedDeviceTemperatureData(
        device_id,
        phase,
        "daterange",
        formatDate(
          new Date(formatDate(FirstDataBack[0])).getTime() -
            7 * (3600 * 1000 * 24)
        ),
        formatDate(FirstDataBack[0])
      )
        .then((res) => {
          let powerDataFromDB = res.data.data.deviceData;
          let myData;
          let myData1;
          let myData2;
          let myData3;
          if (typeof powerDataFromDB != "undefined" && !res.data.data.error) {
            myData = Object.keys(powerDataFromDB).map((key) => {
              return [
                powerDataFromDB[key].time,
                powerDataFromDB[key].T_Power_A,
                powerDataFromDB[key].device_data_id,
              ];
            });

            myData1 = Object.keys(powerDataFromDB).map((key) => {
              return [
                powerDataFromDB[key].time,
                powerDataFromDB[key].L1_Power_A,
                powerDataFromDB[key].device_data_id,
              ];
            });

            myData2 = Object.keys(powerDataFromDB).map((key) => {
              return [
                powerDataFromDB[key].time,
                powerDataFromDB[key].L2_Power_A,
                powerDataFromDB[key].device_data_id,
              ];
            });

            myData3 = Object.keys(powerDataFromDB).map((key) => {
              return [
                powerDataFromDB[key].time,
                powerDataFromDB[key].L3_Power_A,
                powerDataFromDB[key].device_data_id,
              ];
            });
          } else {
            myData = [];
          }
          if (myData.length > 0) {
            console.log("Last Data Start", myData.at(0));

            if (myData.at(0)[2] === FirstDataBack[2]) {
              console.log("same value repeated possible gap");
            } else {
              setFirstDataBack(myData.at(0));
              setData((previous) => {
                const uniqueThirdElements = new Set();
                return myData.concat(previous).filter((arr) => {
                  if (!uniqueThirdElements.has(arr[2])) {
                    uniqueThirdElements.add(arr[2]);
                    return true;
                  }
                  return false;
                });
              });
              setData1((previous) => {
                const uniqueThirdElements = new Set();
                return myData1.concat(previous).filter((arr) => {
                  if (!uniqueThirdElements.has(arr[2])) {
                    uniqueThirdElements.add(arr[2]);
                    return true;
                  }
                  return false;
                });
              });
              setData2((previous) => {
                const uniqueThirdElements = new Set();
                return myData2.concat(previous).filter((arr) => {
                  if (!uniqueThirdElements.has(arr[2])) {
                    uniqueThirdElements.add(arr[2]);
                    return true;
                  }
                  return false;
                });
              });
              setData3((previous) => {
                const uniqueThirdElements = new Set();
                return myData3.concat(previous).filter((arr) => {
                  if (!uniqueThirdElements.has(arr[2])) {
                    uniqueThirdElements.add(arr[2]);
                    return true;
                  }
                  return false;
                });
              });
            }
          }
          setisLoadingGraph(false);
        })
        .catch((err) => {
          setisLoadingGraph(false);
          console.log(err);
        });
    } else if (Parameter === "energy") {
      let QueryStarDate;
      let QueryendDate;

      console.log(
        TimeFormat,
        " get data back",
        "LastDataFront",
        LastDataFront,
        "FirstDataBack",
        FirstDataBack
      );
      if (TimeFormat === "hourly") {
        //24hr
        QueryStarDate = moment(FirstDataBack[0])
          .subtract(1, "day")
          .format("YYYY-MM-DD  00:00:00");
        QueryendDate = moment(FirstDataBack[0])
          .subtract(1, "day")
          .format("YYYY-MM-DD  23:59:59");
        console.log("<<===", QueryStarDate, QueryendDate);
      } else if (TimeFormat === "daily") {
        QueryStarDate = moment(FirstDataBack[0])
          .subtract(1, "month")
          .format("YYYY-MM-DD  00:00:00");
        QueryendDate = moment(FirstDataBack[0]).subtract(1, "day").format("YYYY-MM-DD  23:59:59");
        console.log("<<===", QueryStarDate, QueryendDate);
      } else {
        QueryStarDate = moment(FirstDataBack[0])
          .subtract(1, "year")
          .format("YYYY-MM-DD  00:00:00");
        QueryendDate = moment(FirstDataBack[0])
          .subtract(1, "year")
          .format("YYYY-MM-DD  23:59:59");
        console.log("<<===", QueryStarDate, QueryendDate);
      }

      UserService.GetLinkedDeviceData(
        device_id,
        "timerange",
        TimeFormat,
        QueryStarDate,
        QueryendDate
      )
        .then((res) => {
          let powerDataFromDB = res.data.data.deviceData;
          let myData;
          let localUnit = "KWH";


//
powerDataFromDB=calculateDifferences(Object.values(powerDataFromDB));
console.log("res Data!!!!!!!!!", powerDataFromDB);
if (typeof powerDataFromDB != "undefined" && !res.data.data.error) {
  myData = powerDataFromDB.map((i) => {
    return [
      i.time2,
      i.diff,
      // powerDataFromDB[key].device_data_id,
    ];
  });
} else {
  myData = [];
}
//



          if (myData.length > 0) {
            console.log("Last Data Start", myData.at(0));
            setFirstDataBack(myData.at(0));
            setLastDataFront(myData.at(-1));
            myData = fillMissingGaps(myData,TimeFormat);

            setData(myData);
            setChartConfig({
              chart: {
                id: "total-power-chart",
                // type: "line",
                // stacked: true,
                height: 500,
                foreColor: "#000000",
                zoom: {
                  type: "x",
                  enabled: true,
                  autoScaleYaxis: true,
                },
                toolbar: {
                  autoSelected: "zoom",
                },
              },
              tooltip: {
                enabled: true,
                color: "#000000",
                style: {
                  fontSize: "16px",
                  // fontFamily: undefined
                },
                // shared: true,
                x: {
                  format: "dd MMM yyyy hh:mm TT",
                },
                y: [
                  {
                    formatter: function (y) {
                      if (typeof y !== "undefined") {
                        return y.toFixed(2) + " " + localUnit;
                      }
                      return y;
                    },
                  },
                  {
                    formatter: function (y) {
                      if (typeof y !== "undefined") {
                        return y.toFixed(2) + " " + localUnit;
                      }
                      return y;
                    },
                  },
                  {
                    formatter: function (y) {
                      if (typeof y !== "undefined") {
                        return y.toFixed(2) + " " + localUnit;
                      }
                      return y;
                    },
                  },
                  {
                    formatter: function (y) {
                      if (typeof y !== "undefined") {
                        return y.toFixed(2) + " " + localUnit;
                      }
                      return y;
                    },
                  },
                ],
              },
              dataLabels: {
                enabled: false,
              },
              markers: {
                size: 0,
              },
              xaxis: {
                type: "datetime",
                tickAmount: myData.length - 1,
                tooltip: {
                  enabled: false,
                },
                labels: {
                  rotate: -90,
                  rotateAlways: true,
                  minHeight: 100,
                  maxHeight: 160,
                  show: true,
                  align: "left",
                  // trim: true,
                  formatter: function (val, timestamp) {
                    if (TimeFormat === "hourly")
                      return moment(timestamp).format("HH:00");
                    if (TimeFormat === "daily")
                      return moment(timestamp).format("DD MMM");
                    if (TimeFormat === "monthly")
                      return moment(timestamp).format("MMM YYYY");
                  },
                  style: {
                    colors: ["#000000"],
                    fontSize: "15px",
                    // fontFamily: 'Helvetica, Arial, sans-serif',
                    fontWeight: 600,
                  },
                },
              },
              stroke: {
                width: 2,
              },
              colors: ["#64006C", "#F30202", "#0054F1", "#FFD93D"],
              yaxis: {
                opposite: false,
                formatter: (value) => {
                  return value;
                },
                // min: 0,
                decimalsInFloat: 2,
                forceNiceScale: true,
                axisBorder: {
                  show: true,
                  color: "#78909C",
                  offsetX: 0,
                  offsetY: 0,
                },
                axisTicks: {
                  show: true,
                  borderType: "solid",
                  color: "#78909C",
                  width: 6,
                  offsetX: 0,
                  offsetY: 0,
                },
                labels: {
                  show: true,
                  align: "right",
                  minWidth: 0,
                  maxWidth: 160,
                  style: {
                    colors: [],
                    fontSize: "15px",
                    // fontFamily: 'Helvetica, Arial, sans-serif',
                    fontWeight: 600,
                  },
                  offsetX: 0,
                  offsetY: 0,
                  rotate: 0,
                  // formatter: (value) => { return val },
                },
                crosshairs: {
                  show: true,
                  position: "back",
                  stroke: {
                    color: "#b6b6b6",
                    width: 0.6,
                    dashArray: [0, 1, 2],
                  },
                },
                tooltip: {
                  enabled: false,
                  offsetX: 0,
                },
                title: {
                  text: "[" + localUnit + "]",
                  rotate: 0,
                  offsetX: 40,
                  offsetY: -170,
                  style: {
                    color: undefined,
                    fontSize: "15px",
                    // fontFamily: 'Helvetica, Arial, sans-serif',
                    fontWeight: 600,
                    // cssClass: 'apexcharts-yaxis-title',
                  },
                },
              },
              legend: {
                showForSingleSeries: true,
                offsetX: 7,
                //  offsetY:5,
                position: "top",
                fontSize: "18px",
                //  width:1
                //  fontWeight: 800,
                markers: {
                  width: 12,
                  height: 12,
                  strokeWidth: 2,
                  strokeColor: "#fff",
                  fillColors: undefined,
                  radius: 12,
                  offsetX: -5,
                  // offsetY: 5
                },
              },
            });
          }
          setisLoadingGraph(false);
        })
        .catch((err) => {
          setisLoadingGraph(false);
          console.log(err);
        });
    } else if (Parameter === "energy2") {
      console.log(" get data energy");
      let Param = TimeFormat === "daily" ? "T_Energy_Hr_A" : "T_Energy_D_A";
      UserService.GetLinkedDeviceData(
        device_id,
        "timerange",
        TimeFormat,
        formatDate(
          new Date(formatDate(FirstDataBack[0])).getTime() -
            7 * (3600 * 1000 * 24)
        ),
        formatDate(FirstDataBack[0])
      )
        .then((res) => {
          let powerDataFromDB = res.data.data.deviceData;
          let myData;
          let myData1;
          let myData2;
          let myData3;
          if (typeof powerDataFromDB != "undefined" && !res.data.data.error) {
            myData = Object.keys(powerDataFromDB).map((key) => {
              return [
                powerDataFromDB[key].time2,
                powerDataFromDB[key]["T_Energy_Acc"],
                // powerDataFromDB[key].device_data_id,
              ];
            });

            myData1 = Object.keys(powerDataFromDB).map((key) => {
              return [
                powerDataFromDB[key].time2,
                powerDataFromDB[key]["diff"],
                // powerDataFromDB[key].device_data_id,
              ];
            });
          } else {
            myData = [];
            myData1 = [];
          }
          if (myData.length > 0) {
            console.log("Last Data Start", myData.at(0));

            if (myData.at(0)[0] === FirstDataBack[0]) {
              console.log("same value repeated possible gap");
            } else {
              setFirstDataBack(myData.at(0));
              setData((previous) => {
                const uniqueThirdElements = new Set();
                return myData.concat(previous).filter((arr) => {
                  if (!uniqueThirdElements.has(arr[0])) {
                    uniqueThirdElements.add(arr[0]);
                    return true;
                  }
                  return false;
                });
              });
              setData1((previous) => {
                const uniqueThirdElements = new Set();
                return myData1.concat(previous).filter((arr) => {
                  if (!uniqueThirdElements.has(arr[0])) {
                    uniqueThirdElements.add(arr[0]);
                    return true;
                  }
                  return false;
                });
              });
              // setData1(myData1);
            }
          }
          setisLoadingGraph(false);
        })
        .catch((err) => {
          setisLoadingGraph(false);
          console.log(err);
        });
    } else if (Parameter === "temperature") {
      UserService.GetLinkedDeviceTemperatureData(
        device_id,
        "temperature",
        "daterange",
        formatDate(
          new Date(formatDate(FirstDataBack[0])).getTime() -
            7 * (3600 * 1000 * 24)
        ),
        formatDate(FirstDataBack[0])
      )
        .then((res) => {
          let powerDataFromDB = res.data.data.deviceData;
          let myData;

          if (typeof powerDataFromDB != "undefined" && !res.data.data.error) {
            myData = Object.keys(powerDataFromDB).map((key) => {
              return [powerDataFromDB[key].time, powerDataFromDB[key].value];
            });
          } else {
            myData = [];
          }
          if (myData.length > 0) {
            console.log("Last Data Start", myData.at(0));

            if (myData.at(0)[2] === FirstDataBack[0]) {
              console.log("same value repeated possible gap");
            } else {
              setFirstDataBack(myData.at(0));
              setData((previous) => {
                const uniqueThirdElements = new Set();
                return myData.concat(previous).filter((arr) => {
                  if (!uniqueThirdElements.has(arr[0])) {
                    uniqueThirdElements.add(arr[0]);
                    return true;
                  }
                  return false;
                });
              });
            }
          }
          setisLoadingGraph(false);
        })
        .catch((err) => {
          setisLoadingGraph(false);
          console.log(err);
        });
    }
  }
  function handleForwardClick() {
    setRt(false);
    console.log("handleForwardClick endDate", formatDate(LastDataFront[0]));
    let newEndtDate = new Date(
      new Date(formatDate(LastDataFront[0])).getTime() + 3600 * 1000 * 24
    );

    let newEndtDateday = newEndtDate.getDate();
    let newEndtDatemonth = newEndtDate.getMonth() + 1;
    let newEndtDateyear = newEndtDate.getFullYear();

    let newEndtDateHours = newEndtDate.getHours();
    let newEndtDateMinutes = newEndtDate.getMonth() + 1;
    let newEndtDateSeconds = newEndtDate.getFullYear();

    newEndtDate = `${newEndtDateyear}-${newEndtDatemonth}-${newEndtDateday}`;
    console.log(
      "handleForwardClick",
      formatDate(LastDataFront[0]),
      startDate,
      newEndtDate
    );
    // console.log("current data", data);

    if (Parameter === "power") {
      UserService.GetLinkedDeviceTemperatureData(
        device_id,
        phase,
        "daterange",
        formatDate(LastDataFront[0]),
        formatDate(
          new Date(formatDate(LastDataFront[0])).getTime() + 3600 * 1000 * 24
        )
        // newEndtDate
        // startDate + " 00:00:00"
      )
        .then((res) => {
          console.log(
            "get device temperature data res--",
            res.data.data.deviceData
          );
          let powerDataFromDB = res.data.data.deviceData;
          let myData;
          let myData1;
          let myData2;
          let myData3;
          if (typeof powerDataFromDB != "undefined" && !res.data.data.error) {
            myData = Object.keys(powerDataFromDB).map((key) => {
              return [
                powerDataFromDB[key].time,
                powerDataFromDB[key].T_Power_A,
                powerDataFromDB[key].device_data_id,
              ];
            });
            myData1 = Object.keys(powerDataFromDB).map((key) => {
              return [
                powerDataFromDB[key].time,
                powerDataFromDB[key].L1_Power_A,
                powerDataFromDB[key].device_data_id,
              ];
            });
            myData2 = Object.keys(powerDataFromDB).map((key) => {
              return [
                powerDataFromDB[key].time,
                powerDataFromDB[key].L2_Power_A,
                powerDataFromDB[key].device_data_id,
              ];
            });
            myData3 = Object.keys(powerDataFromDB).map((key) => {
              return [
                powerDataFromDB[key].time,
                powerDataFromDB[key].L3_Power_A,
                powerDataFromDB[key].device_data_id,
              ];
            });
          } else {
            myData = [];
          }
          if (myData.length > 0) {
            // console.log("Graph Data!!!!!", myData)
            // setData(myData);
            setLastDataFront(myData.at(-1));
            setData((previous) => {
              return previous.concat(myData);
            });

            setData1((previous) => {
              return previous.concat(myData1);
            });
            setData2((previous) => {
              return previous.concat(myData2);
            });
            setData3((previous) => {
              return previous.concat(myData3);
            });

            // console.log(data);
            setendDate(newEndtDate);
          }
          setisLoadingGraph(false);
        })
        .catch((err) => {
          setisLoadingGraph(false);
          console.log(err);
        });
    } else if (Parameter === "energy") {
      let QueryStarDate;
      let QueryendDate;
      let localUnit = "KWH";
      console.log(
        TimeFormat,
        " get data forward",
        "FirstDataBack",
        FirstDataBack,
        "LastDataFront",
        LastDataFront
      );
      console.log(
        "old ===>>",
        moment(LastDataFront[0]).format("YYYY-MM-DD  00:00:00"),
        moment(LastDataFront[0]).format("YYYY-MM-DD  23:59:59")
      );
      if (TimeFormat === "hourly") {
        //24hr
        QueryStarDate = moment(LastDataFront[0])
          .add(1, "day")
          .format("YYYY-MM-DD  00:00:00");
        QueryendDate = moment(LastDataFront[0])
          .add(1, "day")
          .format("YYYY-MM-DD  23:59:59");
        console.log("new ===>>", QueryStarDate, QueryendDate);
      } else if (TimeFormat === "daily") {
        QueryStarDate = moment(LastDataFront[0])
          .add(1, "day")
          .format("YYYY-MM-DD  00:00:00");
        QueryendDate = moment(LastDataFront[0])
          .add(1, "month")
          .format("YYYY-MM-DD  23:59:59");
        console.log("new ===>>", QueryStarDate, QueryendDate);
      } else {
        QueryStarDate = moment(LastDataFront[0])
          .add(1, "day")
          .format("YYYY-MM-DD  00:00:00");
        QueryendDate = moment(LastDataFront[0])
          .add(1, "year")
          .format("YYYY-MM-DD  23:59:59");
        console.log("new ===>>", QueryStarDate, QueryendDate);
      }
      UserService.GetLinkedDeviceData(
        device_id,
        "timerange",
        TimeFormat,
        QueryStarDate,
        QueryendDate
        // newEndtDate
        // startDate + " 00:00:00"
      )
        .then((res) => {
          console.log(
            "get device temperature data res--",
            res.data.data.deviceData
          );
          let powerDataFromDB = res.data.data.deviceData;
          let myData;

          powerDataFromDB=calculateDifferences(Object.values(powerDataFromDB));
console.log("res Data!!!!!!!!!", powerDataFromDB);
if (typeof powerDataFromDB != "undefined" && !res.data.data.error) {
  myData = powerDataFromDB.map((i) => {
    return [
      i.time2,
      i.diff,
      // powerDataFromDB[key].device_data_id,
    ];
  });
} else {
  myData = [];
}

          if (myData.length > 0) {
            myData = fillMissingGaps(myData,TimeFormat);

            setData(myData);

            setendDate(newEndtDate);
            setFirstDataBack(myData.at(0));
            setLastDataFront(myData.at(-1));
            setChartConfig({
              chart: {
                id: "total-power-chart",
                // type: "line",
                // stacked: true,
                height: 500,
                foreColor: "#000000",
                zoom: {
                  type: "x",
                  enabled: true,
                  autoScaleYaxis: true,
                },
                toolbar: {
                  autoSelected: "zoom",
                },
              },
              tooltip: {
                enabled: true,
                color: "#000000",
                style: {
                  fontSize: "16px",
                  // fontFamily: undefined
                },
                // shared: true,
                x: {
                  format: "dd MMM yyyy hh:mm TT",
                },
                y: [
                  {
                    formatter: function (y) {
                      if (typeof y !== "undefined") {
                        return y.toFixed(2) + " " + localUnit;
                      }
                      return y;
                    },
                  },
                  {
                    formatter: function (y) {
                      if (typeof y !== "undefined") {
                        return y.toFixed(2) + " " + localUnit;
                      }
                      return y;
                    },
                  },
                  {
                    formatter: function (y) {
                      if (typeof y !== "undefined") {
                        return y.toFixed(2) + " " + localUnit;
                      }
                      return y;
                    },
                  },
                  {
                    formatter: function (y) {
                      if (typeof y !== "undefined") {
                        return y.toFixed(2) + " " + localUnit;
                      }
                      return y;
                    },
                  },
                ],
              },
              dataLabels: {
                enabled: false,
              },
              markers: {
                size: 0,
              },
              xaxis: {
                type: "datetime",
                tickAmount: myData.length - 1,
                tooltip: {
                  enabled: false,
                },
                labels: {
                  rotate: -90,
                  rotateAlways: true,
                  minHeight: 100,
                  maxHeight: 160,
                  show: true,
                  align: "left",
                  // trim: true,
                  formatter: function (val, timestamp) {
                    if (TimeFormat === "hourly")
                      return moment(timestamp).format("HH:00");
                    if (TimeFormat === "daily")
                      return moment(timestamp).format("DD MMM");
                    if (TimeFormat === "monthly")
                      return moment(timestamp).format("MMM YYYY");
                  },
                  style: {
                    colors: ["#000000"],
                    fontSize: "15px",
                    // fontFamily: 'Helvetica, Arial, sans-serif',
                    fontWeight: 600,
                  },
                },
              },
              stroke: {
                width: 2,
              },
              colors: ["#64006C", "#F30202", "#0054F1", "#FFD93D"],
              yaxis: {
                opposite: false,
                formatter: (value) => {
                  return value;
                },
                // min: 0,
                decimalsInFloat: 2,
                forceNiceScale: true,
                axisBorder: {
                  show: true,
                  color: "#78909C",
                  offsetX: 0,
                  offsetY: 0,
                },
                axisTicks: {
                  show: true,
                  borderType: "solid",
                  color: "#78909C",
                  width: 6,
                  offsetX: 0,
                  offsetY: 0,
                },
                labels: {
                  show: true,
                  align: "right",
                  minWidth: 0,
                  maxWidth: 160,
                  style: {
                    colors: [],
                    fontSize: "15px",
                    // fontFamily: 'Helvetica, Arial, sans-serif',
                    fontWeight: 600,
                  },
                  offsetX: 0,
                  offsetY: 0,
                  rotate: 0,
                  // formatter: (value) => { return val },
                },
                crosshairs: {
                  show: true,
                  position: "back",
                  stroke: {
                    color: "#b6b6b6",
                    width: 0.6,
                    dashArray: [0, 1, 2],
                  },
                },
                tooltip: {
                  enabled: false,
                  offsetX: 0,
                },
                title: {
                  text: "[" + localUnit + "]",
                  rotate: 0,
                  offsetX: 40,
                  offsetY: -170,
                  style: {
                    color: undefined,
                    fontSize: "15px",
                    // fontFamily: 'Helvetica, Arial, sans-serif',
                    fontWeight: 600,
                    // cssClass: 'apexcharts-yaxis-title',
                  },
                },
              },
              legend: {
                showForSingleSeries: true,
                offsetX: 7,
                //  offsetY:5,
                position: "top",
                fontSize: "18px",
                //  width:1
                //  fontWeight: 800,
                markers: {
                  width: 12,
                  height: 12,
                  strokeWidth: 2,
                  strokeColor: "#fff",
                  fillColors: undefined,
                  radius: 12,
                  offsetX: -5,
                  // offsetY: 5
                },
              },
            });
          }
          setisLoadingGraph(false);
        })
        .catch((err) => {
          setisLoadingGraph(false);
          console.log(err);
        });
    } else if (Parameter === "energy2") {
      console.log(" get data energy");
      let Param = TimeFormat === "daily" ? "T_Energy_Hr_A" : "T_Energy_D_A";
      UserService.GetLinkedDeviceData(
        device_id,
        "timerange",
        TimeFormat,
        formatDate(LastDataFront[0]),
        formatDate(
          new Date(formatDate(LastDataFront[0])).getTime() + 3600 * 1000 * 24
        )
        // newEndtDate
        // startDate + " 00:00:00"
      )
        .then((res) => {
          console.log(
            "get device temperature data res--",
            res.data.data.deviceData
          );
          let powerDataFromDB = res.data.data.deviceData;
          let myData;
          let myData1;
          let myData2;
          let myData3;
          if (typeof powerDataFromDB != "undefined" && !res.data.data.error) {
            myData = Object.keys(powerDataFromDB).map((key) => {
              return [
                powerDataFromDB[key].time2,
                powerDataFromDB[key]["T_Energy_Acc"],
                // powerDataFromDB[key].device_data_id,
              ];
            });

            myData1 = Object.keys(powerDataFromDB).map((key) => {
              return [
                powerDataFromDB[key].time2,
                powerDataFromDB[key]["diff"],
                // powerDataFromDB[key].device_data_id,
              ];
            });
          } else {
            myData = [];
            myData1 = [];
          }
          if (myData.length > 0) {
            // console.log("Graph Data!!!!!", myData)
            // setData(myData);
            setLastDataFront(myData.at(-1));
            setData((previous) => {
              return previous.concat(myData);
            });
            setData1((previous) => {
              return previous.concat(myData1);
            });

            // setData1(myData1);

            // console.log(data);
            setendDate(newEndtDate);
          }
          setisLoadingGraph(false);
        })
        .catch((err) => {
          setisLoadingGraph(false);
          console.log(err);
        });
    } else if (Parameter === "temperature") {
      UserService.GetLinkedDeviceTemperatureData(
        device_id,
        "temperature",
        "daterange",
        formatDate(LastDataFront[0]),
        formatDate(
          new Date(formatDate(LastDataFront[0])).getTime() + 3600 * 1000 * 24
        )
        // newEndtDate
        // startDate + " 00:00:00"
      )
        .then((res) => {
          console.log(
            "get device temperature data res--",
            res.data.data.deviceData
          );
          let powerDataFromDB = res.data.data.deviceData;
          let myData;

          if (typeof powerDataFromDB != "undefined" && !res.data.data.error) {
            myData = Object.keys(powerDataFromDB).map((key) => {
              return [powerDataFromDB[key].time, powerDataFromDB[key].value];
            });
          } else {
            myData = [];
          }
          if (myData.length > 0) {
            // console.log("Graph Data!!!!!", myData)
            // setData(myData);
            setLastDataFront(myData.at(-1));
            setData((previous) => {
              return previous.concat(myData);
            });

            // console.log(data);
            setendDate(newEndtDate);
          }
          setisLoadingGraph(false);
        })
        .catch((err) => {
          setisLoadingGraph(false);
          console.log(err);
        });
    }
  }

  return (
    <>
      <Card
        className="row"
        sx={{
          "border-radius": "1% ",

          boxShadow: 2,
          margin: "0 5px 10px",
          ":hover": {
            boxShadow: 5,
          },
          padding: "1%",
        }}
      >
        <div className="col-lg-2 col-sm-12">
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Trend</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={Parameter}
              label="Format"
              // onChange={handleChangeParameter}
              onChange={(event) => {
                setParameter(event.target.value);
              }}
            >
              <MenuItem value={"power"}>Power</MenuItem>
              <MenuItem value={"energy"}>Energy</MenuItem>
              <MenuItem value={"temperature"}>Temperature</MenuItem>
              <MenuItem value={"control"}>Control</MenuItem>
              <MenuItem value={"bill"}>Bill</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="col-lg-2 col-sm-12">
        {Parameter === "energy" ? (<>              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Format</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={TimeFormat}
                  label="Format"
                  onChange={handleChangeFormat}
                >
                  <MenuItem value={"hourly"}>Hourly</MenuItem>
                  <MenuItem value={"daily"}>Daily</MenuItem>
                  <MenuItem value={"monthly"}>Monthly</MenuItem>
                </Select>
              </FormControl></>):(<></>)}

        </div>
        <div className="col-lg-2 col-sm-12">
        {Parameter != "control" &&   Parameter != "bill" ?(<>      
             <Typography> Real-Time</Typography>
          <Switch
            checked={Rt}
            onChange={() => setRt(!Rt)}
            name="Real Time"
            // color="primary"
          />
          </>):(<></>)}

        </div>
            
        <div
          className="col-lg-1 col-sm-12"
          style={{
            padding: "1%",
          }}
        > {Parameter != "control" &&   Parameter != "bill" ?(<>    
          <IconButton
            color="primary"
            aria-label="ArrowBackIosIcon"
            onClick={handleBackIClick}
          >
            <ArrowBackIosIcon />
          </IconButton>
        </>):(<></>)}
        </div>

          <div
            className="col-lg-4 col-sm-12"
            style={{
              padding: "1%",
            }}
          >
                    {Parameter != "control" &&   Parameter != "bill" ?(<> 

            <DateRangePicker onApply={handleApply}>
              <input
                type="text"
                className="form-control"
                placeholder="Select date range"
                style={{
                  fontSize: 12,
                  border: "1px solid #46acad",
                  borderRadius: 25,
                }}
                value={FirstDataBack[0] + " " + LastDataFront[0]}
              />
            </DateRangePicker>
            </>):(<></>)}

          </div>
        
        <div
          className="col-lg-1 col-sm-12"
          style={{
            padding: "1%",
          }}
        >
        {Parameter != "control" &&   Parameter != "bill" ?(<> 

          <IconButton
            color="primary"
            aria-label="ArrowForwardIosIcon"
            onClick={handleForwardClick}
          >
            <ArrowForwardIosIcon />
          </IconButton>
        </>):(<></>)}

        </div>

      </Card>

      {Parameter === "control" || Parameter === "bill" ? (
        <>
      {Parameter === "control" ? (   <Control
            device_id={device_id}
            userID={userID}
            isSharedDevice={isSharedDevice}
            isDeviceStatus={isDeviceStatus}
          />):(
         <BillingView device_id={device_id} 
         userID={userID}
         StatementName = {StatementName}
         ></BillingView>
          )}

       
        </>
      ) : (
        <>
          {isLoadingGraph ? (
            <p style={{ textAlign: "center", padding: 108 }}>Loading...</p>
          ) : (
            <>
              {Parameter === "energy" ? (
                <ReactApexChart
                  height={450}
                  ref={chartRef}
                  options={chartConfig}
                  series={[
                    {
                      name: "Total Energy",
                      // data: data[0].data
                      data: data,
                    },
                  ]}
                  type="bar"
                  // type="line"
                />
              ) : (
                <>
                  {Parameter === "energy2" ? (
                    <ReactApexChart
                      height={450}
                      ref={chartRef}
                      options={chartConfig}
                      series={[
                        {
                          name: "Total Energy",
                          // data: data[0].data
                          data: data,
                        },
                        {
                          name: "Total Energy -1 ",
                          // data: data[0].data
                          data: data1,
                        },
                        // {
                        //   name: "T-1",
                        //   data: data.map((v, i) => {
                        //     let diff;
                        //     if (i != 0) diff = v[1] - data[i - 1][1];
                        //     else diff = v[1];

                        //     return [v[0], diff < 0 ? 0 : diff];
                        //   }),
                        // },
                      ]}
                      type="bar"
                      // type="line"
                    />
                  ) : (
                    // <h1>test</h1>
                    <ReactApexChart
                      height={450}
                      ref={chartRef}
                      options={chartConfig}
                      series={[
                        {
                          name: "Total",
                          // data: data[0].data
                          data: data,
                        },
                        { name: "L1", data: data1 },
                        { name: "L2", data: data2 },
                        { name: "L3", data: data3 },
                      ]}
                      type="line"
                      // type="line"
                    />
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
    </>
  );
}
