import { useCustomCompareEffect } from "use-custom-compare";
import isEqual from "lodash/isEqual";
import UserService from "../../services/user.service";
import React, { useState, useEffect, useRef } from "react";
import moment from "moment-timezone";
import ReactPaginate from "react-paginate";
import IconButton from "@mui/material/IconButton";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ReactApexChart from "react-apexcharts";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import Switch from "@mui/material/Switch";
import { Typography } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Card, CardContent, Grid } from "@mui/material";
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";
import Skeleton from "react-loading-skeleton";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import InboxIcon from "@mui/icons-material/Inbox";
import DraftsIcon from "@mui/icons-material/Drafts";
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import GppGoodIcon from '@mui/icons-material/GppGood';
let AlarmKeys = [
  0,
  "Solar Low Battery Voltage Alarm",
  "General Battery Shelter Alarm",
  "General Solar Power Cabinet Alarm",
  "EMMS Failure Alarm",
];
function findChangedElements(data) {
  let changes = [];

  for (let i = 1; i < data.length - 1; i++) {
    let subarrayChanges = [];

    for (let j = 1; j < data[i].length - 1; j++) {
      if (data[i - 1][j] !== data[i][j]) {
        subarrayChanges.push({ index: j, value: data[i][j] });
      }
    }

    if (subarrayChanges.length > 0) {
      changes.push({ lineIndex: i, changes: subarrayChanges });
    }
  }

  return changes;
}

function getBitValue(value, index) {
  // Vérifier que l'indice est valide
  if (index < 0 || index >= 32) {
    console.error("L'indice doit être compris entre 0 et 31.");
    return null;
  }

  // Utiliser l'opérateur de décalage pour obtenir la valeur du bit à l'indice donné
  return (value >> index) & 1;
}

function AlaramHistory(props) {
  const { device_id } = props;
  const [JournalTable, setJournalTable] = useState([]);

  function DisplayChanges(data, changes) {
    console.log(changes);
    let Journal = [];
    if (changes.length === 0) {
      console.log("No changes detected.");
    } else {
      console.log("Changes detected:");
      changes.forEach((change) => {
        // console.log(
        //   `Changes in line at index ${change.lineIndex} ${
        //     data[change.lineIndex][0]
        //   }:`
        // );
        change.changes.forEach((subarrayChange) => {
          // console.log(
          //   `${data[change.lineIndex][0]} ${AlarmKeys[subarrayChange.index]} is ${
          //     !subarrayChange.value ? "ON" : "OFF"
          //   }`
          // );
          Journal.push(
            // [`${data[change.lineIndex][0]} ${
            [` ${moment(data[change.lineIndex][0]).format("DD MM YYYY, hh:mm:ss")} ${

              AlarmKeys[subarrayChange.index]
            } is ${!subarrayChange.value ? "ON" : "OFF"}`,!subarrayChange.value]
          );
        });
      });
    }
    console.log(Journal);
    setJournalTable(Journal.reverse());
  }

  console.log(
    device_id,
    moment().subtract(100, "days").format("DD MM YYYY, 00:00:00"),
    moment().format("DD MM YYYY, 23:59:59")
  );

  useEffect(() => {
    getFirstTableData();
  }, [device_id]);

  useEffect(() => {
    // Use setTimeout to update the message after 2000 milliseconds (2 seconds)
    const timeoutId = setTimeout(() => {
      getFirstTableData();
    }, 60 * 1000);

    // Cleanup function to clear the timeout if the component unmounts
    return () => clearTimeout(timeoutId);
  }, []); // Empty dependency array ensures the effect runs only once

  function getFirstTableData() {
    UserService.GetLinkedDeviceTemperatureData(
      device_id,
      "Load_Power",
      "daterange",
      moment().subtract(10, "days").format("YYYY-MM-DD 00:00:00"),
      moment().format("YYYY-MM-DD 23:59:59")
    )
      .then((res) => {
        let powerDataFromDB = res.data.data.deviceData;
        // console.log(powerDataFromDB);
        let myData;
        let myData1;
        let myData2;
        let myData3;
        if (typeof powerDataFromDB != "undefined" && !res.data.data.error) {
          myData = Object.keys(powerDataFromDB).map((key) => {
            return [
              powerDataFromDB[key].time,
              getBitValue(powerDataFromDB[key].GEN_STAT, 8),
              getBitValue(powerDataFromDB[key].GEN_STAT, 9),
              getBitValue(powerDataFromDB[key].GEN_STAT, 10),
              getBitValue(powerDataFromDB[key].GEN_STAT, 11),
              powerDataFromDB[key].device_data_id,
              // powerDataFromDB[key].device_data_id,
            ];
          });

          // console.table(myData);
          // console.log( myData);

          DisplayChanges(myData, findChangedElements(myData));

          console.log("last data ID", myData.at(-1));
          // setLastDataFront(myData.at(-1));
          // setFirstDataBack(myData.at(0));
        } else {
          myData = [];
        }
        if (myData.length > 0) {
          // console.log("Graph Data!!!!!", myData)
          // setData(myData);
          // setData1(myData1);
          // setData2(myData2);
          // setData3(myData3);
          // console.log(data);
        }
        // setisLoadingGraph(false);
      })
      .catch((err) => {
        // setisLoadingGraph(false);
        console.log(err);
      });
  }

  return (
    <Grid
      sx={{ marginBottom: "10px" }}
      spacing={1}
      item
      xs={12}
      sm={12}
      md={12}
    >
      <Card sx={{ marginBottom: "10px", border: `solid 1px black` }}>
        <Typography
          variant="h5"
          component="div"
          sx={{
            textAlign: "center",
            // color: "red",
            // backgroundColor: "red",
            // border: `solid 3px red`,
          }}
        >
          History
        </Typography>
      </Card>
      <Card
        sx={{
          boxShadow: 2,
          ":hover": {
            boxShadow: 5,
          },

          border: `solid 1px black`,
        }}
      >
        <CardContent>
          <Grid
            container
            spacing={1}
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
          >
              <nav aria-label="main mailbox folders">
                <List>
                  {JournalTable.map((x) => {
                    console.log(x);
                    return <ListItem disablePadding>
                    <ListItemButton>
                      <ListItemIcon>
                       { x[1]?<ReportProblemIcon sx={{color: 'red'}} />:<GppGoodIcon sx={{color: 'green'}}/>}
                      </ListItemIcon>
                      <ListItemText sx={{color: x[1]?'red':'green'}} primary={x[0]} />
                    </ListItemButton>
                  </ListItem>;
                  })}
                  
                </List>
              </nav>
              <Divider />

          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
}

function AlaramLog(props) {
  const { isGraphStatsLoading, data, color, name } = props;

  //   const isGraphStatsLoading = 0;
  const labeslFontSize = 8;
  const dataFontSize = 18;
  const unitFontSize = 16;
  return (
    <Grid
      sx={{ marginBottom: "10px" }}
      spacing={1}
      item
      xs={12}
      sm={12}
      md={12}
    >
      <Card sx={{ marginBottom: "10px", border: `solid 1px black` }}>
        <Typography
          variant="h5"
          component="div"
          sx={{
            textAlign: "center",
            // color: "red",
            // backgroundColor: "red",
            // border: `solid 3px red`,
          }}
        >
          {name}
        </Typography>
      </Card>
      <Card
        sx={{
          boxShadow: 2,
          ":hover": {
            boxShadow: 5,
          },

          border: `solid 1px black`,
        }}
      >
        <CardContent>
          <Grid
            container
            spacing={1}
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
          >
            <Grid spacing={1} item xs={3} sm={3} md={3}>
              <Card
                className=""
                sx={{
                  boxShadow: 2,
                  ":hover": {
                    boxShadow: 5,
                  },
                }}
              >
                <CardContent
                  style={{
                    //   background: "green"
                    background: getBitValue(data, 8) ? "green" : "red",
                  }}

                  // style={{
                  //   //   background: "green"
                  //   background: 1 ? 'green': 'red'
                  //   }}
                >
                  <Typography
                    variant="h5"
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      color: "white",
                      "padding-left": 5,
                      "padding-right": 5,
                      fontSize: unitFontSize,
                    }}
                  >
                    {/* Solar Failure Alarm */}
                    Solar Low Battery Voltage
                  </Typography>
                </CardContent>
              </Card>
            </Grid>

            <Grid spacing={1} item xs={3} sm={3} md={3}>
              <Card
                className=""
                sx={{
                  boxShadow: 2,
                  ":hover": {
                    boxShadow: 5,
                  },
                }}
              >
                <CardContent
                  style={{
                    //   background: "green"
                    background: getBitValue(data, 9) ? "green" : "red",
                  }}
                >
                  <Typography
                    variant="h5"
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      color: "white",
                      "padding-left": 5,
                      "padding-right": 5,
                      fontSize: unitFontSize,
                    }}
                  >
                    {/* Temperature Alarm */}
                    General Battery Shelter
                  </Typography>
                </CardContent>
              </Card>
            </Grid>

            <Grid spacing={1} item xs={3} sm={3} md={3}>
              <Card
                className=""
                sx={{
                  boxShadow: 2,
                  ":hover": {
                    boxShadow: 5,
                  },
                }}
              >
                <CardContent
                  style={{
                    //   background: "green"
                    background: getBitValue(data, 10) ? "green" : "red",
                  }}

                  // style={{
                  //   //   background: "green"
                  //   background: 1 ? 'green': 'red'
                  //   }}
                >
                  <Typography
                    variant="h5"
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      color: "white",
                      "padding-left": 5,
                      "padding-right": 5,
                      fontSize: unitFontSize,
                    }}
                  >
                    {/* Door Alarm */}
                    General Solar Power
                  </Typography>
                </CardContent>
              </Card>
            </Grid>

            <Grid spacing={1} item xs={3} sm={3} md={3}>
              <Card
                className=""
                sx={{
                  boxShadow: 2,
                  ":hover": {
                    boxShadow: 5,
                    height:'100px'
                  },
                }}
              >
                <CardContent
                  style={{
                    //   background: "green"
                    background: getBitValue(data, 11) ? "green" : "red",
                  }}

                  // style={{
                  //   //   background: "green"
                  //   background: 1 ? 'green': 'red'
                  //   }}
                >
                  <Typography
                    variant="h5"
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      color: "white",
                      "padding-left": 5,
                      "padding-right": 5,
                      fontSize: unitFontSize,
                    }}
                  >
                    {/* Low Voltage Alarm */}
                    EMMS Failure
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
}

export default function App(props) {
  const { ProcessData } = props;
  console.log(ProcessData);
  return (
    <Grid
      container
      spacing={1}
      direction="row"
      justify="flex-start"
      alignItems="flex-start"
    >
      <AlaramLog
        isGraphStatsLoading={0}
        data={ProcessData.GEN_STAT}
        startfrom={3}
        name="Alarm Log"
      ></AlaramLog>

      <AlaramHistory device_id={ProcessData.device_id}></AlaramHistory>
    </Grid>
  );
}
