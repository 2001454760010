// Start Date 19 April 2023
// TODO get virtual device child devices
// Get process data of thsi devices sum it and display it each one seconds
// Update the device list in Devicescontrol board
// TODO Update Boxes
// TODO Energy Charts

import React, { useState, useEffect, useRef, MouseEvent } from "react";
import DeviceStats from "../components/DeviceStats";
import VirtualDevicePowerChart from "./VirtualDevicePowerChart";
import DeviceSelector from "./DeviceSelector";
import UserService from "../services/user.service";

export default function App(props) {
  console.log(props);
  const userID = props.userID;
  const DeviceId = props.DeviceObject.id;
  const DeviceName = props.DeviceName;
  const [isPower, setIsPower] = useState(true);
  const [isEnergy, setIsEnergy] = useState(false);
  const [childDevices, setChildDevices] = useState([]);
  const [isControlDevices, setIsControlDevices] = useState(false);

  function getchildDevices(deviceId) {
    UserService.GetTreeViewData(userID).then(
      (response) => {
        const result = response.data.data.profile[0]
          .filter((x) => x.parent_id === DeviceId)
          .map((x) => x.device_id);
        // console.log("Child Devices", result);
        setChildDevices(result);
      },
      (error) => {}
    );
  }

  function handlePowerClick() {
    console.log("Power Click");
    setIsPower(true);
    setIsControlDevices(false);
    setIsEnergy(false);
  }
  function handleEnergyClick() {
    console.log("Energy Click");
    setIsEnergy(true);
    setIsPower(false);
    setIsControlDevices(false);
  }
  function handleControlDevicesClick() {
    console.log("Devices Click");
    setIsControlDevices(true);
    setIsPower(false);
    setIsEnergy(false);
  }

  useEffect(() => {
    getchildDevices(DeviceId);
    console.log(childDevices);
  }, [DeviceId]);

  return (
    <>
      <div className="grpah_table">
        <div className="col-lg-12 box_graph device_name">
          <div className="widget_categories right-widget top_heding ">
            <h4>
              {DeviceName}
              {/* <span style={{ background: `${isDeviceStatus}` }} /> */}
            </h4>
          </div>
        </div>
        <div className="col-lg-12 box_graph">
          <div className="widget_categories right-widget top_heding">
            <div className="tags top_tag">
              {/* <div className="tags bottom_tag"> */}
              <a
                onClick={handlePowerClick}
                className={`tag-cloud-link ${isPower ? "bg_green" : null} `}
                style={{ cursor: "pointer" }}
              >
                Power
              </a>

              <a
                onClick={handleEnergyClick}
                className={`tag-cloud-link ${isEnergy ? "bg_green" : null} `}
                style={{ cursor: "pointer" }}
              >
                Energy
              </a>

              <a
                onClick={handleControlDevicesClick}
                className={`tag-cloud-link ${
                  isControlDevices ? "bg_green" : null
                } `}
                style={{ cursor: "pointer" }}
              >
                Devices
              </a>

              <a
                onClick={handleControlDevicesClick}
                className={"tag-cloud-link"}
                style={{ cursor: "pointer" }}
              >
                TBA
              </a>
            </div>
          </div>

          <DeviceStats
            isStaticTxtValue1={"TxtValue1"}
            isStaticTxtValue2={"TxtValue2"}
            isStaticTxtValue3={"TxtValue3"}
            isStaticTxtValue4={"TxtValue4"}
            isStaticValue1={1}
            isStaticValue2={2}
            isStaticValue3={3}
            isStaticValue4={4}
          />
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
            {isPower ? (
              <VirtualDevicePowerChart
                DeviceId={DeviceId}
                userID={userID}
                param="power"
                type="line"
              />
            ) : null}
            {isEnergy ? (
              <VirtualDevicePowerChart
                DeviceId={DeviceId}
                userID={userID}
                param="energy"
                type="bar"
              />
            ) : null}
            {isControlDevices ? (
              <DeviceSelector DeviceId={DeviceId} userID={userID} />
            ) : null}
          </div>
        </div>
      </div>
      ;
    </>
  );
}
