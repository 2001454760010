
const Access_profiles =[
    // "Agile": 
    { Power_Load: 1, Power_Total_IEL_A: 1, Zero_Total_Power: 1, Accumulated_Energy_IEL: 1, Accumulated_Energy_Solis: 1, Power_Load: 1 },//2
    // "ODASCO": 
    { Power_Load: 1, Power_Total_IEL_A: 1, Zero_Total_Power: 1, Accumulated_Energy_IEL: 1, Accumulated_Energy_Solis: 1, Power_Load: 1 },//2
    // "Orange": 
    { Power_Load: 0, Power_Total_IEL_A: 0, Zero_Total_Power: 0, Accumulated_Energy_IEL: 0, Accumulated_Energy_Solis: 0, Power_Load: 0 },//1

]
export default Access_profiles;

// Pofile Management:
// 1. Orange(Client) Profile
// 2. ODASCO(Contractor) Profile
// 3. Agile Profile
//     - Dashboard(for all Profiles)

//  - Trends
// 1. Power Load Trend(Keep the same) ⇒ Profile 2 and 3 ONLY
// 2. Power Solar + Power Trend to be combined to one trend. ok
//              ⇒ Solis Power for all profiles. 
//              ⇒ IEL and zero total power for profile 2 and 3 ONLY. 
//     3. Energy + Energy Solar to be combined to one trend. 
//              ⇒ Solis Energy for all profiles. 
//              ⇒ IEL and energy grid for profile 2 and 3 ONLY.

//     Note: Energy bar to be in format of Hourly / daily / monthly.
    
// - Billing
//         - Finalize Billing with laith(pdf file)
//         - Alarms
//         - Change the names of the bills
// 1. Solar PV Intrusion
// 2. Solar Inverter Intrusion