import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  titleContainer: {
    flexDirection: "row",
    marginTop: 12,
  },
  reportTitle: {
    fontSize: 12,
    textAlign: "center",
    // textTransform: 'uppercase',
  },
  footer: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
});

const InvoiceThankYouMsg = () => (
  <View style={styles.footer}>
    <Text style={styles.reportTitle}>
      Capital 100 000 MAD – ICE 003291689000065 – RC 583457 – IF 53778541
    </Text>
    <Text>
      Adresse : Sis 2 et 4 rue Abou Dhabi 3éme étage Bureau N° 18 Casablanca
    </Text>
  </View>
);

export default InvoiceThankYouMsg;
