import React from "react";
import { Typography, Box } from "@mui/material";
const styles = {
  myapp: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
    backgroundColor: '#f0f0f0',
    gap: '20px'
  },
  row: {
    display: 'flex',
    justifyContent: 'center',
    gap: '50px'
  },
  boxSet: {
    display: 'flex',
    flexDirection: 'column',
    width: '300px'
  },
  titleBox: {
    width: '100%',
    padding: '10px',
    borderRadius: '10px',
    border: '2px solid #007bff',
    backgroundColor: '#e7f3ff',
    textAlign: 'center',
    marginBottom: '10px'
  },
  box: {
    width: '100%',
    padding: '10px',
    borderRadius: '10px',
    border: '2px solid #333',
    backgroundColor: '#fff',
    textAlign: 'center'
  },
  staticText: {
    fontWeight: 'bold'
  }};

const TitleBox = ({ title }) => {
  return (
    <Box
      variant="outlined"
      elevation={3}
      // className="title-box"
      style={styles.titleBox}>
     
    
      <Typography variant="h6" align="center">
        {title}
      </Typography>
    </Box>
  );
};

export default TitleBox
