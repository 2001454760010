import React from "react";
import { Card, CardContent, Grid, Typography } from "@mui/material";
import Skeleton from "react-loading-skeleton";
import { useState, useEffect } from "react";
import UserService from "../services/user.service";
import { FreeBreakfast } from "@mui/icons-material";

const DeviceStats = React.memo((props) => {
  const {
    isStaticTxtValue1,
    isStaticTxtValue2,
    isStaticTxtValue3,
    isStaticTxtValue4,
    isStaticTxtValue5,
    isStaticValue1,
    isStaticValue2,
    isStaticValue3,
    isStaticValue4,
    isStaticValue5,
    isDeviceID,
    // isGraphStatsLoading,
  } = props;
  const [isGraphStatsLoading, setisGraphStatsLoading] = useState(true);
  const [energyLoading, seteEnrgyLoading] = useState(true);

  const [TotalPower, SetTotalPower] = useState({});
  const [TotalEnergy, SetTotalEnergy] = useState({});
  const [Frequency, SetFrequency] = useState({});
  const [PowerFactor, SetPowerFactor] = useState({});
  const [Temperature, SetTemperature] = useState({});

  useEffect(() => {
    // console.log("This will run every second!");
    UserService.GetLatestDeviceStatsData(isDeviceID)
      .then((res) => {
        // console.log(
        //   "!!!!!!!!!!!!!!!!!!!!**********888on change api call:",
        //   res.data.data.deviceData
        // );
        setTimeout(() => {
          setisGraphStatsLoading(false);
        }, 1000);
        if (res.data.data.error) {
          SetTotalPower("0.00");
          SetTotalEnergy("0.00");
          SetFrequency("0.00");
          SetPowerFactor("0.00");
          SetTemperature("0.00");
        } else {
          // console.log("!!!!!!!!!!!!process data", res.data.data.deviceData[0]);

          const { T_voltage, T_current, T_power, T_energy, temperature } =
            res.data.data.deviceData[0];

          SetTotalPower(T_power);
          SetFrequency(T_voltage);
          SetPowerFactor(T_current);
          SetTemperature(temperature);
        }
      })
      .catch((err) => {
        console.log(err);
        setisGraphStatsLoading(false);
      });

    UserService.GetLatestDeviceStatsDataMonthly(isDeviceID)
      .then((res) => {
        // console.log(
        //   "!!!!!!!!!!!!!!!!!!!!**********888on change api call:",
        //   res.data.data.deviceData
        // );
        setTimeout(() => {
          setisGraphStatsLoading(false);
          seteEnrgyLoading(false);
        }, 1000);
        if (res.data.data.error) {
          SetTotalEnergy("0.00");
        } else {
          //console.log("!!!!!!!!!!!!process data", res.data.data.deviceData[0]);
          const { T_energy } = res.data.data.deviceData[0];
          // SetTotalEnergy(T_energy);
          if (T_energy === null) SetTotalEnergy("0.00");
          else SetTotalEnergy(T_energy);
        }
      })
      .catch((err) => {
        console.log(err);
        setisGraphStatsLoading(false);
        seteEnrgyLoading(true);
      });
  }, [isDeviceID]);

  useEffect(() => {
    const interval = setInterval(() => {
      // console.log("This will run every 15 second! box 1 ",isDeviceID);
      UserService.GetLatestDeviceStatsData(isDeviceID)
        .then((res) => {
          // console.log(
          //   "!!!!!!!!!!!!!!!!!!!!**********888on change api call:",
          //   res.data.data.deviceData
          // );
          setTimeout(() => {
            setisGraphStatsLoading(false);
          }, 1000);
          if (res.data.data.error) {
            SetTotalPower("0.00");
            SetFrequency("0.00");
            SetPowerFactor("0.00");
            SetTemperature("0.00");
          } else {
            // console.log(
            //   "!!!!!!!!!!!!process data",
            //   res.data.data.deviceData[0]
            // );

            const { T_voltage, T_current, T_power, temperature } =
              res.data.data.deviceData[0];

            SetTotalPower(T_power);
            // SetTotalEnergy(55);
            SetFrequency(T_voltage);
            SetPowerFactor(T_current);
            SetTemperature(temperature);
          }
        })
        .catch((err) => {
          console.log(err);
          setisGraphStatsLoading(false);
        });
      UserService.GetLatestDeviceStatsDataMonthly(isDeviceID)
        .then((res) => {
          // console.log(
          //   "!!!!!!!!!!!!!!!!!!!!**********888on change api call:",
          //   res.data.data.deviceData
          // );
          setTimeout(() => {
            setisGraphStatsLoading(false);
            seteEnrgyLoading(false);
          }, 1000);
          if (res.data.data.error) {
            SetTotalEnergy("0.00");
          } else {
            const { T_energy } = res.data.data.deviceData[0];
            seteEnrgyLoading(true);
            if (T_energy === null) SetTotalEnergy("0.00");
            else SetTotalEnergy(T_energy);
          }
        })
        .catch((err) => {
          console.log(err);
          setisGraphStatsLoading(false);
          seteEnrgyLoading(false);
        });
    }, 15 * 1000);
    return () => clearInterval(interval);
  }, [isDeviceID]);

  const labeslFontSize = 18;
  const dataFontSize = labeslFontSize + 16;
  const unitFontSize = labeslFontSize + 3;
  return (
    <Grid
      container
      spacing={1}
      direction="row"
      justify="flex-start"
      alignItems="flex-start"
    >
      <Grid spacing={1} item xs={12} sm={6} md={2}>
        <Card
          className=""
          sx={{
            "border-radius": "4% ",

            maxWidth: 345,
            boxShadow: 2,
            margin: "0 5px 10px",
            ":hover": {
              boxShadow: 5,
            },
            "border-bottom": `solid 5px #64006C`,
          }}
        >
          <CardContent
            style={{
              textAlign: "center",
              fontWeight: "bold",
              "padding-left": 5,
              "padding-right": 5,
              fontSize: dataFontSize,
            }}
          >
            <Typography
              variant="h5"
              component="div"
              style={{
                textAlign: "center",
                fontWeight: "bold",
                fontSize: labeslFontSize,
              }}
            >
              Total Power
            </Typography>
            <Typography
              variant="h5"
              component="div"
              style={{
                textAlign: "center",
                fontWeight: "bold",
                padding: 0,
                fontSize: dataFontSize,
              }}
            >
              {isGraphStatsLoading ? (
                <Skeleton height={15} width={100} />
              ) : (
                <span>
                  <span>
                    <span>{parseFloat(TotalPower).toFixed(2)}</span>
                  </span>
                </span>
              )}
            </Typography>

            <Typography
              variant="h5"
              component="div"
              style={{
                textAlign: "right",
                fontWeight: "bold",
                padding: 0,
                fontSize: unitFontSize,
                margin: 0,
                "padding-top": "-10%",
              }}
            >
              {isGraphStatsLoading ? (
                <Skeleton height={15} width={100} />
              ) : (
                <span>KW</span>
              )}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid spacing={1} item xs={12} sm={6} md={3}>
        <Card
          className=""
          sx={{
            "border-radius": "4% ",
            maxWidth: 345,
            boxShadow: 2,
            margin: "0 5px 10px",
            ":hover": {
              boxShadow: 5,
            },
            "border-bottom": `solid 5px #64006C`,
          }}
        >
          <CardContent
            style={{
              textAlign: "center",
              fontWeight: "bold",
              "padding-left": 5,
              "padding-right": 5,
              fontSize: dataFontSize,
            }}
          >
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              style={{
                textAlign: "center",
                fontWeight: "bold",
                margin: 0,
                fontSize: labeslFontSize,
              }}
            >
              Total Energy
            </Typography>
            <Typography
              variant="h5"
              component="div"
              style={{
                textAlign: "center",
                fontWeight: "bold",
                padding: 0,
                fontSize: dataFontSize,
              }}
            >
              {energyLoading ? (
                <Skeleton height={15} width={100} />
              ) : (
                <span>
                  <span>{TotalEnergy}</span>
                </span>
              )}
            </Typography>
            <Typography
              variant="h5"
              component="div"
              style={{
                textAlign: "right",
                fontWeight: "bold",
                padding: 0,
                fontSize: unitFontSize,
              }}
            >
              {isGraphStatsLoading ? (
                <Skeleton height={15} width={100} />
              ) : (
                <span>KWH</span>
              )}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid spacing={1} item xs={12} sm={6} md={2}>
        <Card
          className=""
          sx={{
            "border-radius": "4% ",

            maxWidth: 345,
            boxShadow: 2,
            margin: "0 5px 10px",
            ":hover": {
              boxShadow: 5,
            },
            "border-bottom": `solid 5px #64006C`,
          }}
        >
          <CardContent>
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              style={{
                textAlign: "center",
                fontWeight: "bold",
                margin: 0,
                fontSize: labeslFontSize,
              }}
            >
              Frequency
            </Typography>
            <Typography
              variant="h5"
              component="div"
              style={{
                textAlign: "center",
                fontWeight: "bold",
                padding: 0,
                fontSize: dataFontSize,
                gutterBottom: 0,
              }}
            >
              {isGraphStatsLoading ? (
                <Skeleton height={15} width={100} />
              ) : (
                <span>
                  <span>{parseFloat(Frequency).toFixed(2)}</span>
                </span>
              )}
            </Typography>
            <Typography
              variant="h5"
              component="div"
              style={{
                textAlign: "right",
                fontWeight: "bold",
                padding: 0,
                fontSize: unitFontSize,
              }}
            >
              {isGraphStatsLoading ? (
                <Skeleton height={15} width={100} />
              ) : (
                <span>HZ</span>
              )}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid spacing={1} item xs={12} sm={6} md={3}>
        <Card
          className=""
          sx={{
            "border-radius": "4% ",

            maxWidth: 345,
            boxShadow: 2,
            margin: "0 5px 10px",
            ":hover": {
              boxShadow: 5,
            },
            "border-bottom": `solid 5px #64006C`,
          }}
        >
          <CardContent>
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              style={{
                textAlign: "center",
                fontWeight: "bold",
                margin: 0,
                fontSize: labeslFontSize,
              }}
            >
              Power Factor
            </Typography>
            <Typography
              variant="h5"
              component="div"
              style={{
                textAlign: "center",
                fontWeight: "bold",
                padding: 0,
                fontSize: dataFontSize,
              }}
            >
              {isGraphStatsLoading ? (
                <Skeleton height={15} width={100} />
              ) : (
                <span>
                  <span>{PowerFactor}</span>
                </span>
              )}
            </Typography>
            <Typography
              variant="h5"
              component="div"
              style={{
                textAlign: "right",
                fontWeight: "bold",
                padding: 0,
                fontSize: unitFontSize,
              }}
            >
              {isGraphStatsLoading ? (
                <Skeleton height={15} width={100} />
              ) : (
                <span>&nbsp;</span>
              )}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid spacing={1} item xs={12} sm={6} md={2}>
        <Card
          className=""
          sx={{
            "border-radius": "4% ",

            maxWidth: 345,
            boxShadow: 2,
            margin: "0 5px 10px",
            ":hover": {
              boxShadow: 5,
            },
            "border-bottom": `solid 5px #64006C`,
          }}
        >
          <CardContent>
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              style={{
                textAlign: "center",
                fontWeight: "bold",
                margin: 0,
                fontSize: labeslFontSize,
              }}
            >
              Temperature
            </Typography>
            <Typography
              variant="h5"
              component="div"
              style={{
                textAlign: "center",
                fontWeight: "bold",
                padding: 0,
                fontSize: dataFontSize,
              }}
            >
              {isGraphStatsLoading ? (
                <Skeleton height={15} width={100} />
              ) : (
                <span>
                  <span>{Temperature}</span>
                </span>
              )}
            </Typography>
            <Typography
              variant="h5"
              component="div"
              style={{
                textAlign: "right",
                fontWeight: "bold",
                padding: 0,
                fontSize: unitFontSize,
              }}
            >
              {isGraphStatsLoading ? (
                <Skeleton height={15} width={100} />
              ) : (
                <span>℃</span>
              )}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
});

export default DeviceStats;
