import React, { useState } from "react";
import { Card, CardContent, Grid, Typography } from "@mui/material";
import Skeleton from "react-loading-skeleton";
import { useEffect } from "react";
import UserService from "../services/user.service";
import { green } from "@mui/material/colors";

const labeslFontSize = 8;
const dataFontSize = 18;
const unitFontSize = 16;
var l1_voltage,
  l2_voltage,
  l3_voltage,
  l1_current,
  l2_current,
  l3_current,
  T_voltage,
  T_current,
  T_Energy_l1,
  T_Energy_l2,
  T_Energy_l3;

const PhaseBox = (props) => {
  const { isGraphStatsLoading, voltage, current, power, color } = props;
  // console.log(isGraphStatsLoading, voltage, current, power, color);
  // console.log(voltage);

  return (
    <Grid spacing={1} item xs={12} sm={6} md={4}>
      {" "}
      <Grid
        container
        spacing={1}
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
      >
        <Grid spacing={1} item xs={12} sm={6} md={4}>
          <Card
            className=""
            sx={{
              // maxWidth: 345,
              boxShadow: 2,
              margin: "0 5px 10px",
              ":hover": {
                boxShadow: 5,
              },

              "border-top": `solid 3px ${color}`,
            }}
          >
            <CardContent>
              <Typography
                variant="h5"
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  "padding-left": 5,
                  "padding-right": 5,
                  fontSize: unitFontSize - 2,
                }}
              >
                Voltage
              </Typography>
              <Typography
                variant="h5"
                component="div"
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  padding: 0,
                  fontSize: dataFontSize,
                }}
              >
                {isGraphStatsLoading ? (
                  <Skeleton height={15} width={100} />
                ) : (
                  <span>
                    <span>
                      <span>{parseFloat(voltage).toFixed(2)}</span>
                    </span>
                  </span>
                )}
              </Typography>

              <Typography
                variant="h5"
                component="div"
                style={{
                  textAlign: "right",
                  fontWeight: "bold",
                  padding: 0,
                  fontSize: unitFontSize,
                  margin: 0,
                  "padding-top": "-10%",
                }}
              >
                {isGraphStatsLoading ? (
                  <Skeleton height={15} width={100} />
                ) : (
                  <span>V</span>
                )}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid spacing={1} item xs={12} sm={6} md={4}>
          <Card
            className=""
            sx={{
              // maxWidth: 345,
              boxShadow: 2,
              margin: "0 5px 10px",
              ":hover": {
                boxShadow: 5,
              },
              "border-top": `solid 3px ${color}`,
            }}
          >
            <CardContent>
              <Typography
                variant="h5"
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  "padding-left": 5,
                  "padding-right": 5,
                  fontSize: unitFontSize - 2,
                }}
              >
                Current
              </Typography>
              <Typography
                variant="h5"
                component="div"
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  padding: 0,
                  fontSize: dataFontSize,
                }}
              >
                {isGraphStatsLoading ? (
                  <Skeleton height={15} width={100} />
                ) : (
                  <span>
                    <span>{current}</span>
                  </span>
                )}
              </Typography>
              <Typography
                variant="h5"
                component="div"
                style={{
                  textAlign: "right",
                  fontWeight: "bold",
                  padding: 0,
                  fontSize: unitFontSize,
                }}
              >
                {isGraphStatsLoading ? (
                  <Skeleton height={15} width={100} />
                ) : (
                  <span>A</span>
                )}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid spacing={1} item xs={12} sm={6} md={4}>
          <Card
            className=""
            sx={{
              // maxWidth: 345,
              boxShadow: 2,
              margin: "0 5px 10px",
              ":hover": {
                boxShadow: 5,
              },
              "border-top": `solid 3px ${color}`,
            }}
          >
            <CardContent>
              <Typography
                gutterBottom
                variant="h5"
                component="div"
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  margin: 0,
                  fontSize: unitFontSize - 2,
                }}
              >
                Power
              </Typography>
              <Typography
                variant="h5"
                component="div"
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  padding: 0,
                  fontSize: dataFontSize,
                }}
              >
                {isGraphStatsLoading ? (
                  <Skeleton height={15} width={100} />
                ) : (
                  <span>
                    <span>{parseFloat(power).toFixed(2)}</span>
                  </span>
                )}
              </Typography>
              <Typography
                variant="h5"
                component="div"
                style={{
                  textAlign: "right",
                  fontWeight: "bold",
                  padding: 0,
                  fontSize: unitFontSize,
                }}
              >
                {isGraphStatsLoading ? (
                  <Skeleton height={15} width={100} />
                ) : (
                  <span>KW</span>
                )}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        {/* <Grid spacing={1} item xs={12} sm={12} md={12}>
          <Card
            className=""
            sx={{
              // maxWidth: 345,
              boxShadow: 2,
              margin: "0 5px 10px",
              ":hover": {
                boxShadow: 5,
              },
              "border-top": `solid 3px ${color}`,
            }}
          >
            <CardContent>
              <Typography
                variant="h5"
                style={{
                  textAlign: "center",
                  fontWeight: "bold",

                  fontSize: labeslFontSize,
                }}
              >
                Phase 3
              </Typography>
            </CardContent>
          </Card>
        </Grid> */}
      </Grid>
    </Grid>
  );
};

const DeviceStats = React.memo((props) => {
  const {
    isStaticTxtValue1,
    isStaticTxtValue2,
    isStaticTxtValue3,
    isStaticTxtValue4,
    isStaticValue1,
    isStaticValue2,
    isStaticValue3,
    isStaticValue4,
    // isGraphStatsLoading,
    isDeviceID,
  } = props;
  // console.log("\tisDeviceID=>", isDeviceID);

  const [StatsLoading, SetStatsLoading] = useState(false);
  const [StatsData, SetStatsData] = useState({});
  // const l1_voltage = "L1 Voltage";
  // const l1_current = "L1 Current";
  // const l1_enrgy = "L1 Current";
  // useEffect(() => {
  //   UserService.GetLatestDeviceStatsData(isDeviceID)
  //     .then((res) => {
  //       l1_voltage = res.data.data.deviceData[0].l1_voltage;
  //       l2_voltage = res.data.data.deviceData[0].l2_voltage;
  //       l3_voltage = res.data.data.deviceData[0].l3_voltage;
  //       l1_current = res.data.data.deviceData[0].l1_current;
  //       l2_current = res.data.data.deviceData[0].l2_current;
  //       l3_current = res.data.data.deviceData[0].l3_current;
  //       T_voltage = res.data.data.deviceData[0].T_voltage;
  //       T_current = res.data.data.deviceData[0].T_current;
  //       T_Energy_l1 = res.data.data.deviceData[0].T_Energy_L1;
  //       T_Energy_l2 = res.data.data.deviceData[0].T_Energy_L2;
  //       T_Energy_l3 = res.data.data.deviceData[0].T_Energy_L3;
  //       SetStatsLoading(true);
  //       SetStatsData(res.data.data.deviceData[0]);
  //       console.log("\t=>", res);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }, [isDeviceID]);

  const [isGraphStatsLoading, setisGraphStatsLoading] = useState(true);

  const [L1Voltage, setL1Voltage] = useState();
  const [L2Voltage, setL2Voltage] = useState();
  const [L3Voltage, setL3Voltage] = useState();
  const [L1Current, setL1Current] = useState();
  const [L2Current, setL2Current] = useState();
  const [L3Current, setL3Current] = useState();
  const [TVoltage, setTVoltage] = useState();
  const [TCurrent, setTCurrent] = useState();
  const [L1TEnergy, setL1TEnergy] = useState();
  const [L2TEnergy, setL2TEnergy] = useState();
  const [L3TEnergy, setL3TEnergy] = useState();

  useEffect(() => {
    // console.log("This will run every second!");
    // console.log("This will run every 15 second! box 2 ",isDeviceID);

    UserService.GetLatestDeviceStatsData(isDeviceID)
      .then((res) => {
        // console.log(
        //   "!!!!!!!!!!!!!!!!!!!!**********888on change api call:",
        //   res.data.data.deviceData
        // );
        setTimeout(() => {
          setisGraphStatsLoading(false);
        }, 1000);
        if (res.data.data.error) {
          setL1Voltage(0);
          setL2Voltage(0);
          setL3Voltage(0);
          setL1Current(0);
          setL2Current(0);
          setL3Current(0);
          setTVoltage(0);
          setTCurrent(0);
          setL1TEnergy(0);
          setL2TEnergy(0);
          setL3TEnergy(0);
        } else {
          // console.log("!!!!!!!!!!!!process data", res.data.data.deviceData[0]);

          const { T_voltage, T_current, T_power, T_energy, temperature } =
            res.data.data.deviceData[0];

          setL1Voltage(res.data.data.deviceData[0].l1_voltage);
          setL2Voltage(res.data.data.deviceData[0].l2_voltage);
          setL3Voltage(res.data.data.deviceData[0].l3_voltage);
          setL1Current(res.data.data.deviceData[0].l1_current);
          setL2Current(res.data.data.deviceData[0].l2_current);
          setL3Current(res.data.data.deviceData[0].l3_current);
          setTVoltage(res.data.data.deviceData[0].T_voltage);
          setTCurrent(res.data.data.deviceData[0].T_current);
          setL1TEnergy(res.data.data.deviceData[0].AP_power_l1);
          setL2TEnergy(res.data.data.deviceData[0].AP_power_l2);
          setL3TEnergy(res.data.data.deviceData[0].AP_power_l3);
        }
      })
      .catch((err) => {
        console.log(err);
        setisGraphStatsLoading(false);
      });
  }, [isDeviceID]);

  useEffect(() => {
    const interval = setInterval(() => {
      // console.log("This will run every second!");
    // console.log("This will run every 15 second! box 2 ",isDeviceID);

      UserService.GetLatestDeviceStatsData(isDeviceID)
        .then((res) => {
          // console.log(
          //   "!!!!!!!!!!!!!!!!!!!!**********888on change api call:",
          //   res.data.data.deviceData
          // );
          setTimeout(() => {
            setisGraphStatsLoading(false);
          }, 1000);
          if (res.data.data.error) {
            setL1Voltage(0);
            setL2Voltage(0);
            setL3Voltage(0);
            setL1Current(0);
            setL2Current(0);
            setL3Current(0);
            setTVoltage(0);
            setTCurrent(0);
            setL1TEnergy(0);
            setL2TEnergy(0);
            setL3TEnergy(0);
          } else {
            // console.log(
            //   "!!!!!!!!!!!!process data",
            //   res.data.data.deviceData[0]
            // );

            const { T_voltage, T_current, T_power, T_energy, temperature } =
              res.data.data.deviceData[0];

            setL1Voltage(res.data.data.deviceData[0].l1_voltage);
            setL2Voltage(res.data.data.deviceData[0].l2_voltage);
            setL3Voltage(res.data.data.deviceData[0].l3_voltage);
            setL1Current(res.data.data.deviceData[0].l1_current);
            setL2Current(res.data.data.deviceData[0].l2_current);
            setL3Current(res.data.data.deviceData[0].l3_current);
            setTVoltage(res.data.data.deviceData[0].T_voltage);
            setTCurrent(res.data.data.deviceData[0].T_current);
            setL1TEnergy(res.data.data.deviceData[0].AP_power_l1);
            setL2TEnergy(res.data.data.deviceData[0].AP_power_l2);
            setL3TEnergy(res.data.data.deviceData[0].AP_power_l3);
          }
        })
        .catch((err) => {
          console.log(err);
          setisGraphStatsLoading(false);
        });
    }, 15 * 1000);
    return () => clearInterval(interval);
  }, [isDeviceID]);

  return (
    <>
      <Grid
        container
        spacing={3}
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
      >
        {/* <Grid spacing={1} item xs={12} sm={6} md={4}>
          {" "}
          <Grid
            container
            spacing={1}
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
          >
            <Grid spacing={1} item xs={12} sm={6} md={4}>
              <Card
                className=""
                sx={{
                  // maxWidth: 345,
                  boxShadow: 2,
                  margin: "0 5px 10px",
                  ":hover": {
                    boxShadow: 5,
                  },
                }}
              >
                <CardContent>
                  <Typography
                    variant="h5"
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",

                      fontSize: labeslFontSize,
                    }}
                  >
                    Voltage
                  </Typography>
                  <Typography
                    variant="h5"
                    component="div"
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      padding: 0,
                      fontSize: dataFontSize,
                    }}
                  >
                    {isGraphStatsLoading ? (
                      <Skeleton height={15} width={100} />
                    ) : (
                      <span>
                        <span>
                          <span>{parseFloat(l1_voltage).toFixed(2)}</span>
                        </span>
                      </span>
                    )}
                  </Typography>

                  <Typography
                    variant="h5"
                    component="div"
                    style={{
                      textAlign: "right",
                      fontWeight: "bold",
                      padding: 0,
                      fontSize: unitFontSize,
                      margin: 0,
                      "padding-top": "-10%",
                    }}
                  >
                    {isGraphStatsLoading ? (
                      <Skeleton height={15} width={100} />
                    ) : (
                      <span>V</span>
                    )}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid spacing={1} item xs={12} sm={6} md={4}>
              <Card
                className=""
                sx={{
                  // maxWidth: 345,
                  boxShadow: 2,
                  margin: "0 5px 10px",
                  ":hover": {
                    boxShadow: 5,
                  },
                }}
              >
                <CardContent>
                  <Typography
                    gutterBottom
                    variant="h5"
                    // component="div"
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: labeslFontSize,
                    }}
                  >
                    Current
                  </Typography>
                  <Typography
                    variant="h5"
                    component="div"
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      padding: 0,
                      fontSize: dataFontSize,
                    }}
                  >
                    {isGraphStatsLoading ? (
                      <Skeleton height={15} width={100} />
                    ) : (
                      <span>
                        <span>{l1_current}</span>
                      </span>
                    )}
                  </Typography>
                  <Typography
                    variant="h5"
                    component="div"
                    style={{
                      textAlign: "right",
                      fontWeight: "bold",
                      padding: 0,
                      fontSize: unitFontSize,
                    }}
                  >
                    {isGraphStatsLoading ? (
                      <Skeleton height={15} width={100} />
                    ) : (
                      <span>A</span>
                    )}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid spacing={1} item xs={12} sm={6} md={4}>
              <Card
                className=""
                sx={{
                  // maxWidth: 345,
                  boxShadow: 2,
                  margin: "0 5px 10px",
                  ":hover": {
                    boxShadow: 5,
                  },
                }}
              >
                <CardContent>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      margin: 0,
                      fontSize: labeslFontSize,
                    }}
                  >
                    Power
                  </Typography>
                  <Typography
                    variant="h5"
                    component="div"
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      padding: 0,
                      fontSize: dataFontSize,
                    }}
                  >
                    {isGraphStatsLoading ? (
                      <Skeleton height={15} width={100} />
                    ) : (
                      <span>
                        <span>{parseFloat(T_Energy_l1).toFixed(2)}</span>
                      </span>
                    )}
                  </Typography>
                  <Typography
                    variant="h5"
                    component="div"
                    style={{
                      textAlign: "right",
                      fontWeight: "bold",
                      padding: 0,
                      fontSize: unitFontSize,
                    }}
                  >
                    {isGraphStatsLoading ? (
                      <Skeleton height={15} width={100} />
                    ) : (
                      <span>KW</span>
                    )}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid spacing={1} item xs={12} sm={12} md={12}>
              <Card
                className=""
                sx={{
                  // maxWidth: 345,
                  boxShadow: 2,
                  margin: "0 5px 10px",
                  ":hover": {
                    boxShadow: 5,
                  },
                }}
              >
                <CardContent>
                  <Typography
                    variant="h5"
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",

                      fontSize: dataFontSize + 1,
                      color: "rgba(25,230,160)",
                    }}
                  >
                    Phase 1
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>

        <Grid spacing={1} item xs={12} sm={6} md={4}>
          {" "}
          <Grid
            container
            spacing={1}
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
          >
            <Grid spacing={1} item xs={12} sm={6} md={4}>
              <Card
                className=""
                sx={{
                  // maxWidth: 345,
                  boxShadow: 2,
                  margin: "0 5px 10px",
                  ":hover": {
                    boxShadow: 5,
                  },
                }}
              >
                <CardContent>
                  <Typography
                    variant="h5"
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",

                      fontSize: labeslFontSize,
                    }}
                  >
                    Voltage
                  </Typography>
                  <Typography
                    variant="h5"
                    component="div"
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      padding: 0,
                      fontSize: dataFontSize,
                    }}
                  >
                    {isGraphStatsLoading ? (
                      <Skeleton height={15} width={100} />
                    ) : (
                      <span>
                        <span>
                          <span>{parseFloat(l2_voltage).toFixed(2)}</span>
                        </span>
                      </span>
                    )}
                  </Typography>

                  <Typography
                    variant="h5"
                    component="div"
                    style={{
                      textAlign: "right",
                      fontWeight: "bold",
                      padding: 0,
                      fontSize: unitFontSize,
                      margin: 0,
                      "padding-top": "-10%",
                    }}
                  >
                    {isGraphStatsLoading ? (
                      <Skeleton height={15} width={100} />
                    ) : (
                      <span>V</span>
                    )}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid spacing={1} item xs={12} sm={6} md={4}>
              <Card
                className=""
                sx={{
                  // maxWidth: 345,
                  boxShadow: 2,
                  margin: "0 5px 10px",
                  ":hover": {
                    boxShadow: 5,
                  },
                }}
              >
                <CardContent>
                  <Typography
                    gutterBottom
                    variant="h5"
                    // component="div"
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: labeslFontSize,
                    }}
                  >
                    Current
                  </Typography>
                  <Typography
                    variant="h5"
                    component="div"
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      padding: 0,
                      fontSize: dataFontSize,
                    }}
                  >
                    {isGraphStatsLoading ? (
                      <Skeleton height={15} width={100} />
                    ) : (
                      <span>
                        <span>{l2_current}</span>
                      </span>
                    )}
                  </Typography>
                  <Typography
                    variant="h5"
                    component="div"
                    style={{
                      textAlign: "right",
                      fontWeight: "bold",
                      padding: 0,
                      fontSize: unitFontSize,
                    }}
                  >
                    {isGraphStatsLoading ? (
                      <Skeleton height={15} width={100} />
                    ) : (
                      <span>A</span>
                    )}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid spacing={1} item xs={12} sm={6} md={4}>
              <Card
                className=""
                sx={{
                  // maxWidth: 345,
                  boxShadow: 2,
                  margin: "0 5px 10px",
                  ":hover": {
                    boxShadow: 5,
                  },
                }}
              >
                <CardContent>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      margin: 0,
                      fontSize: labeslFontSize,
                    }}
                  >
                    Power
                  </Typography>
                  <Typography
                    variant="h5"
                    component="div"
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      padding: 0,
                      fontSize: dataFontSize,
                    }}
                  >
                    {isGraphStatsLoading ? (
                      <Skeleton height={15} width={100} />
                    ) : (
                      <span>
                        <span>{parseFloat(T_Energy_l2).toFixed(2)}</span>
                      </span>
                    )}
                  </Typography>
                  <Typography
                    variant="h5"
                    component="div"
                    style={{
                      textAlign: "right",
                      fontWeight: "bold",
                      padding: 0,
                      fontSize: unitFontSize,
                    }}
                  >
                    {isGraphStatsLoading ? (
                      <Skeleton height={15} width={100} />
                    ) : (
                      <span>KW</span>
                    )}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid spacing={1} item xs={12} sm={12} md={12}>
              <Card
                className=""
                sx={{
                  // maxWidth: 345,
                  boxShadow: 2,
                  margin: "0 5px 10px",
                  ":hover": {
                    boxShadow: 5,
                  },
                }}
              >
                <CardContent>
                  <Typography
                    variant="h5"
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",

                      fontSize: dataFontSize + 1,
                      color: "rgba(254,184,48)",
                    }}
                  >
                    Phase 2
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>

        <Grid spacing={1} item xs={12} sm={6} md={4}>
          {" "}
          <Grid
            container
            spacing={1}
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
          >
            <Grid spacing={1} item xs={12} sm={6} md={4}>
              <Card
                className=""
                sx={{
                  // maxWidth: 345,
                  boxShadow: 2,
                  margin: "0 5px 10px",
                  ":hover": {
                    boxShadow: 5,
                  },
                }}
              >
                <CardContent>
                  <Typography
                    variant="h5"
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",

                      fontSize: labeslFontSize,
                    }}
                  >
                    Voltage
                  </Typography>
                  <Typography
                    variant="h5"
                    component="div"
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      padding: 0,
                      fontSize: dataFontSize,
                    }}
                  >
                    {isGraphStatsLoading ? (
                      <Skeleton height={15} width={100} />
                    ) : (
                      <span>
                        <span>
                          <span>{parseFloat(l3_voltage).toFixed(2)}</span>
                        </span>
                      </span>
                    )}
                  </Typography>

                  <Typography
                    variant="h5"
                    component="div"
                    style={{
                      textAlign: "right",
                      fontWeight: "bold",
                      padding: 0,
                      fontSize: unitFontSize,
                      margin: 0,
                      "padding-top": "-10%",
                    }}
                  >
                    {isGraphStatsLoading ? (
                      <Skeleton height={15} width={100} />
                    ) : (
                      <span>V</span>
                    )}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid spacing={1} item xs={12} sm={6} md={4}>
              <Card
                className=""
                sx={{
                  // maxWidth: 345,
                  boxShadow: 2,
                  margin: "0 5px 10px",
                  ":hover": {
                    boxShadow: 5,
                  },
                }}
              >
                <CardContent>
                  <Typography
                    gutterBottom
                    variant="h5"
                    // component="div"
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: labeslFontSize,
                    }}
                  >
                    Current
                  </Typography>
                  <Typography
                    variant="h5"
                    component="div"
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      padding: 0,
                      fontSize: dataFontSize,
                    }}
                  >
                    {isGraphStatsLoading ? (
                      <Skeleton height={15} width={100} />
                    ) : (
                      <span>
                        <span>{l3_current}</span>
                      </span>
                    )}
                  </Typography>
                  <Typography
                    variant="h5"
                    component="div"
                    style={{
                      textAlign: "right",
                      fontWeight: "bold",
                      padding: 0,
                      fontSize: unitFontSize,
                    }}
                  >
                    {isGraphStatsLoading ? (
                      <Skeleton height={15} width={100} />
                    ) : (
                      <span>A</span>
                    )}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid spacing={1} item xs={12} sm={6} md={4}>
              <Card
                className=""
                sx={{
                  // maxWidth: 345,
                  boxShadow: 2,
                  margin: "0 5px 10px",
                  ":hover": {
                    boxShadow: 5,
                  },
                }}
              >
                <CardContent>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      margin: 0,
                      fontSize: labeslFontSize,
                    }}
                  >
                    Power
                  </Typography>
                  <Typography
                    variant="h5"
                    component="div"
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      padding: 0,
                      fontSize: dataFontSize,
                    }}
                  >
                    {isGraphStatsLoading ? (
                      <Skeleton height={15} width={100} />
                    ) : (
                      <span>
                        <span>{parseFloat(T_Energy_l3).toFixed(2)}</span>
                      </span>
                    )}
                  </Typography>
                  <Typography
                    variant="h5"
                    component="div"
                    style={{
                      textAlign: "right",
                      fontWeight: "bold",
                      padding: 0,
                      fontSize: unitFontSize,
                    }}
                  >
                    {isGraphStatsLoading ? (
                      <Skeleton height={15} width={100} />
                    ) : (
                      <span>KW</span>
                    )}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid spacing={1} item xs={12} sm={12} md={12}>
              <Card
                className=""
                sx={{
                  // maxWidth: 345,
                  boxShadow: 2,
                  margin: "0 5px 10px",
                  ":hover": {
                    boxShadow: 5,
                  },
                }}
              >
                <CardContent>
                  <Typography
                    variant="h5"
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",

                      fontSize: dataFontSize + 1,
                      color: "rgba(255,92,115)",
                    }}
                  >
                    Phase 3
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid> */}

        <PhaseBox
          isGraphStatsLoading={0}
          voltage={L1Voltage}
          current={L1Current}
          power={L1TEnergy}
          color={"#F30202"}
        />
        <PhaseBox
          isGraphStatsLoading={0}
          voltage={L2Voltage}
          current={L2Current}
          power={L2TEnergy}
          color={'#0054F1'}
        />

        <PhaseBox
          isGraphStatsLoading={0}
          voltage={L3Voltage}
          current={L3Current}
          power={L3TEnergy}
          color={'#FFD93D'}
        />
      </Grid>
    </>
  );
});

export default DeviceStats;
