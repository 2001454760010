import React from "react";
import { Page, Document, Image, StyleSheet } from "@react-pdf/renderer";
import InvoiceItemsTable from "./InvoiceItemsTable";
import InvoiceThankYouMsg from "./InvoiceThankYouMsg";
import logo from "./logo.jpg";
import ClientDetail from "./ClientDetail";

const styles = StyleSheet.create({
  page: {
    fontFamily: "Helvetica",
    fontSize: 11,
    paddingTop: 30,
    paddingLeft: 60,
    paddingRight: 60,
    lineHeight: 1.5,
    flexDirection: "column",
  },
  logo: {
    // width: 74,
    // height: 66,
    marginLeft: "auto",
    marginRight: "auto",
  },
});


const Invoice = ({invoice,statementType}) => (
  <Document title="test">
    <Page size="A4" style={styles.page} >
      {/* <Image style={styles.logo} src={logo} />
        <InvoiceTitle title='Energy Statement Report'/> */}
      {/* <BillTo invoice={invoice} /> */}
      <ClientDetail  invoice={invoice} statementType={statementType}></ClientDetail>
      <InvoiceItemsTable invoice={invoice}  statementType={statementType}/>
      <InvoiceThankYouMsg />
    </Page>
  </Document>
);

export default Invoice;
