import { useCustomCompareEffect } from "use-custom-compare";
import isEqual from "lodash/isEqual";
import UserService from "../../services/user.service";
import React, { useState, useEffect, useRef } from "react";
import moment from "moment-timezone";
import ReactPaginate from "react-paginate";
import IconButton from "@mui/material/IconButton";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ReactApexChart from "react-apexcharts";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import Switch from "@mui/material/Switch";
import { Typography } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Card, CardContent, Grid } from "@mui/material";
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";
import Skeleton from "react-loading-skeleton";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import InboxIcon from "@mui/icons-material/Inbox";
import DraftsIcon from "@mui/icons-material/Drafts";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import GppGoodIcon from "@mui/icons-material/GppGood";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { object } from "yup";
import { map } from "highcharts";

const tdStyle = {
  padding: "5px",
  // border: "1px solid black",
};

let placeholder = [
  { name: "abcd", value: "1234", type: "tba" },
  { name: "abcd", value: "1234", type: "tba" },
  { name: "abcd", value: "1234", type: "tba" },
  { name: "abcd", value: "1234", type: "tba" },
];

export default function App(props) {
  const { device_id,edit } = props;
  console.log("props",device_id,edit);

  const [data, setData] = useState({});
  const [modifiedData, setModifiedData] = useState();

  //   const isGraphStatsLoading = 0;
  const labeslFontSize = 8;
  const dataFontSize = 18;
  const unitFontSize = 16;

  function UpdateData() {
    console.log("UpdateData", data);
    let data2 = { state: "state updated from react" };
    UserService.UpdateDevice(device_id, data)
      .then((res) => {
        let DataFromDB = res;
        console.log("DataFromDB", DataFromDB);
        // update()
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function getData() {
    console.log("get process");

    UserService.checkDeviceID(device_id)
      .then((res) => {
        let DataFromDB = res.data.data[0];
        const keysToKeep = [
          "site_id",
          "sim_card_details",
          "no_of_phases",
          "azimuth",
          "capacity_per_panel",
          "location",
          "deployment_date",
          "inverter_type",
          "title",
          "state",
          "no_of_pv_panels",
          "typical_site_load",
        ];

        DataFromDB = Object.keys(DataFromDB).reduce((acc, key) => {
          if (keysToKeep.includes(key)) {
            acc[key] = DataFromDB[key];
          }
          return acc;
        }, {});
        let myData;
        console.log("DataFromDB", DataFromDB);
        setData(DataFromDB);
        setModifiedData(DataFromDB);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <Grid
      container
      spacing={1}
      direction="row"
      justify="flex-start"
      alignItems="flex-start"
    >
      <Grid
        sx={{ marginBottom: "10px" }}
        spacing={1}
        item
        xs={12}
        sm={12}
        md={12}
      >
      

        <Card sx={{ marginBottom: "10px",              margin: "0 5px 10px",
            ":hover": {
              boxShadow: 5,
            },
            padding: "1%" }}>
          <Grid container style={{ textAlign: "center" }}>
            <Grid item xs={6} sm={6} md={6}>
              <table
                style={{
                  width: "100%",
                  // border: "1px solid black",
                  textAlign: "left",
                  margin: "5px",
                }}
              >
                <tr>
                  <td style={tdStyle}>
                    <h5> SIte ID</h5>
                  </td>
                  <td style={tdStyle}>
                    {/* <TextField
                      value={data.site_id}
                      sx={{ background: "lightgray" }}
                    /> */}
                    <TextField
                    disabled = {!edit}
                      value={data.site_id}
                      sx={{ background: "lightgray" }}
                      onChange={(event) => {
                        console.log(event.target.value);
                        setData((prevState) => ({
                          ...prevState,
                          site_id: event.target.value,
                        }));
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td style={tdStyle}>
                    {" "}
                    <h5>State</h5>
                  </td>
                  <td style={tdStyle}>
                    <TextField
                    disabled = {!edit}
                      value={data.state}
                      sx={{ background: "lightgray" }}
                      onChange={(event) => {
                        setData((prevState) => ({
                          ...prevState,
                          state: event.target.value,
                        }));
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td style={tdStyle}>
                    {" "}
                    <h5>No Of PV Panels</h5>
                  </td>
                  <td style={tdStyle}>
                    <TextField
                    disabled = {!edit}
                      value={data.no_of_pv_panels}
                      sx={{ background: "lightgray" }}
                      onChange={(event) => {
                        setData((prevState) => ({
                          ...prevState,
                          no_of_pv_panels: event.target.value,
                        }));
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td style={tdStyle}>
                    <h5> Tilt</h5>
                  </td>
                  <td style={tdStyle}>
                    <TextField
                    disabled = {!edit}
                      value={data.title}
                      sx={{ background: "lightgray" }}
                      onChange={(event) => {
                        setData((prevState) => ({
                          ...prevState,
                          title: event.target.value,
                        }));
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td style={tdStyle}>
                    <h5> Inverter Type</h5>
                  </td>
                  <td style={tdStyle}>
                    <TextField
                    disabled = {!edit}
                      value={data.inverter_type}
                      sx={{ background: "lightgray" }}
                      onChange={(event) => {
                        setData((prevState) => ({
                          ...prevState,
                          inverter_type: event.target.value,
                        }));
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td style={tdStyle}>
                    <h5> Typical Site Load</h5>
                  </td>
                  <td style={tdStyle}>
                    <TextField
                    disabled = {!edit}
                      value={data.typical_site_load}
                      sx={{ background: "lightgray" }}
                      onChange={(event) => {
                        setData((prevState) => ({
                          ...prevState,
                          typical_site_load: event.target.value,
                        }));
                      }}
                    />
                  </td>
                </tr>
              </table>
            </Grid>

            <Grid item xs={6} sm={6} md={6}>
              <table
                style={{
                  width: "100%",
                  // border: "1px solid black",
                  textAlign: "left",
                  margin: "5px",
                }}
              >
                <tr>
                  <td style={tdStyle}>
                    <h5> Deployment Date</h5>
                  </td>
                  <td style={tdStyle}>
                    <TextField
                    disabled = {!edit}
                      value={data.deployment_date}
                      sx={{ background: "lightgray" }}
                      onChange={(event) => {
                        setData((prevState) => ({
                          ...prevState,
                          deployment_date: event.target.value,
                        }));
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td style={tdStyle}>
                    <h5> Theoritical Production</h5>
                  </td>
                  <td style={tdStyle}>
                    <TextField
                    disabled = {!edit}
                      value={data.location}
                      sx={{ background: "lightgray" }}
                      onChange={(event) => {
                        setData((prevState) => ({
                          ...prevState,
                          location: event.target.value,
                        }));
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td style={tdStyle}>
                    <h5> Site Capacity</h5>
                  </td>
                  <td style={tdStyle}>
                    <TextField
                    disabled = {!edit}
                      value={data.capacity_per_panel}
                      sx={{ background: "lightgray" }}
                      onChange={(event) => {
                        setData((prevState) => ({
                          ...prevState,
                          capacity_per_panel: event.target.value,
                        }));
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td style={tdStyle}>
                    <h5>Azimuth</h5>
                  </td>
                  <td style={tdStyle}>
                    <TextField
                    disabled = {!edit}
                      value={data.azimuth}
                      sx={{ background: "lightgray" }}
                      onChange={(event) => {
                        setData((prevState) => ({
                          ...prevState,
                          azimuth: event.target.value,
                        }));
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td style={tdStyle}>
                    <h5> No of Phases</h5>
                  </td>
                  <td style={tdStyle}>
                    <TextField
                    disabled = {!edit}
                      value={data.no_of_phases}
                      sx={{ background: "lightgray" }}
                      onChange={(event) => {
                        setData((prevState) => ({
                          ...prevState,
                          no_of_phases: event.target.value,
                        }));
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td style={tdStyle}>
                    <h5> Sim Card Details</h5>
                  </td>
                  <td style={tdStyle}>
                    <TextField
                    disabled = {!edit}
                      value={data.sim_card_details}
                      sx={{ background: "lightgray" }}
                      onChange={(event) => {
                        setData((prevState) => ({
                          ...prevState,
                          sim_card_details: event.target.value,
                        }));
                      }}
                    />
                  </td>
                </tr>
              </table>
              {edit?(<div style={{
              textAlign: "right",
              margin:"10px"
            }}>
              <Button variant="contained" onClick={UpdateData} >
                Update
              </Button>
              </div>):(null)}
              
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
}
