import React from "react";
import TitleBox from "./TitleBox";
import Box from "./Box";
import { Height } from "@mui/icons-material";



const BoxSet = ({ title, values,row }) => {
  const styles = {
    boxSetContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center", // Center horizontally
      width: "85%", // Adjusted width to 100%
      maxWidth: "500px", // Added max-width to limit the width
      minWidth: "250px", // Added max-width to limit the width
      borderRadius: "10px",
      padding: "10px",
      marginBottom: "10px",
      backgroundColor: "#C4D9EE", // white with 80% opacity
      "margin-top": "4px",
      "margin-bottom": "4px",
      "margin-left": row !=2 ?"4px":"12px",
      "margin-right":  row !=2 ?"4px":"12px",
      "min-height":"374px"
    },
    contentContainer: {
      width: "100%",
    },
    boxRow: {
      display: "flex",
      alignItems: "center", // Center vertically
      width: "100%",
      //marginBottom: "30px",
      justifyContent: "center", // Center horizontally
    },
    borderedRow: {
      display: "flex",
      alignItems: "bottom", // Center vertically
      width: "100%",
      margin: "2px",
      border: "2px solid white", // Border around the row
      borderRadius: "5px", // Border radius
      // padding: "0px", // Padding inside the border
      justifyContent: "center",
      height: "30px",
    },
    staticText: {
      fontWeight: "bold",
      width: "150px", // Adjusted width of static text
      textAlign: "left", // Align static text to the center
      fontSize: "12px", // Decreased font size for the keys.
      color: "black",
      // marginRight: "5px", // Added margin to create space between text and box
      //marginBottom: "10px",
    },
  };
  return (
    // <div class="col-3">
    <div 
    style={styles.boxSetContainer}
    >
      <TitleBox title={title} />
      <div style={styles.contentContainer}>
        {values.map(({ name, dynamic }) => (
          <div key={name} style={styles.borderedRow}>
            <span style={styles.staticText}>{name}</span>
            <Box name={name} dynamic={dynamic} />
          </div>
        ))}
      </div>
    </div>
    // </div>
  );
};

export default BoxSet;
