import { useCustomCompareEffect } from "use-custom-compare";
import isEqual from "lodash/isEqual";
import UserService from "../../services/user.service";
import React, { useState, useEffect, useRef } from "react";
import moment from "moment-timezone";
import ReactPaginate from "react-paginate";
import IconButton from "@mui/material/IconButton";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ReactApexChart from "react-apexcharts";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import Switch from "@mui/material/Switch";
import { Typography } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Card, CardContent, Grid } from "@mui/material";
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";
import Skeleton from "react-loading-skeleton";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import InboxIcon from "@mui/icons-material/Inbox";
import DraftsIcon from "@mui/icons-material/Drafts";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import GppGoodIcon from "@mui/icons-material/GppGood";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { object } from "yup";
import { map } from "highcharts";
let AlarmKeys = [
  0,
  "Solar1_Intrusion",
  "Solar2_Intrusion",
  "General Solar Power Cabinet Alarm",
  "EMMS Failure Alarm",
];
let AlarmKeys_for_select = AlarmKeys.map((x) => {
  return { title: x };
});

function findChangedElements(data) {
  let changes = [];

  for (let i = 1; i < data.length - 1; i++) {
    let subarrayChanges = [];

    for (let j = 1; j < data[i].length - 1; j++) {
      if (data[i - 1][j] !== data[i][j]) {
        subarrayChanges.push({ index: j, value: data[i][j] });
      }
    }

    if (subarrayChanges.length > 0) {
      changes.push({ lineIndex: i, changes: subarrayChanges });
    }
  }

  return changes;
}

function getBitValue(value, index) {
  // Vérifier que l'indice est valide
  if (index < 0 || index >= 32) {
    console.error("L'indice doit être compris entre 0 et 31.");
    return null;
  }

  // Utiliser l'opérateur de décalage pour obtenir la valeur du bit à l'indice donné
  return (value >> index) & 1;
}

function AlaramHistory(props) {
  const { device_id } = props;
  const [JournalTable, setJournalTable] = useState([]);
  const [data, setData] = useState([]);
  const [LastDataFront, setLastDataFront] = useState(0);
  const [FirstDataBack, setFirstDataBack] = useState(0);
  const [Rt, setRt] = React.useState(true);

  function getData(start, end) {
    console.log("get process");

    UserService.GetStandardData(
      "IEL_MOR_ARCHIVED",
      device_id, //device_id,
      "Load_Power",
      "daterange",
      start,
      end
    )
      .then((res) => {
        let DataFromDB = res.data.data.deviceData;
        let myData;
        console.log("DataFromDB", DataFromDB);

        if (typeof DataFromDB != "undefined" && !res.data.data.error) {
          myData = Object.keys(DataFromDB).map((key) => {
            return [
              DataFromDB[key].date_time_A,
              DataFromDB[key].Solar1_Intrusion,
              DataFromDB[key].Solar2_Intrusion,
              DataFromDB[key].device_data_id,
              // powerDataFromDB[key].device_data_id,
            ];
          });
          DisplayChanges(myData, findChangedElements(myData));
          // console.table(myData);
          // console.log( myData);

          // DisplayChanges(myData, findChangedElements(myData));

          console.log("last data ID", myData.at(-1));
          // setLastDataFront(myData.at(-1));
          // setFirstDataBack(myData.at(0));
        } else {
          myData = [];
        }

        // setData(Object.values(DataFromDB));
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function DisplayChanges(data, changes) {
    // console.log(changes);
    let Journal = [];
    if (changes.length === 0) {
      console.log("No changes detected.");
    } else {
      // console.log("Changes detected:");
      changes.forEach((change) => {
        // console.log(
        //   `Changes in line at index ${change.lineIndex} ${
        //     data[change.lineIndex][0]
        //   }:`
        // );
        change.changes.forEach((subarrayChange) => {
          // console.log(
          //   `${data[change.lineIndex][0]} ${AlarmKeys[subarrayChange.index]} is ${
          //     !subarrayChange.value ? "ON" : "OFF"
          //   }`
          // );
          Journal.push(
            // [`${data[change.lineIndex][0]} ${
            [
              `${data[change.lineIndex][3]} ${moment(data[change.lineIndex][0])
                .subtract({ hours: 1 })
                .format("DD MM YYYY, hh:mm:ss")} ${
                AlarmKeys[subarrayChange.index]
              } is ${!subarrayChange.value ? "ON" : "OFF"}`,
              !subarrayChange.value,
            ]
          );
        });
      });
    }
    console.log(Journal);
    setJournalTable(Journal.reverse());
  }

  const handleApply = (event, picker) => {
    console.log("handle Apply");
    let startDateObj = picker.startDate._d;
    let endDateObj = picker.endDate._d;
    let startTime = moment(startDateObj).format("YYYY-MM-DD HH:mm:ss");
    let endTime = moment(endDateObj).format("YYYY-MM-DD 23:59:59");
    console.log("startDateObjDate", startTime);
    console.log("endDateObjDate", endTime);

    getData(startTime, endTime);
  };
  useEffect(() => {
    // getFirstTableData();
    let endTime = moment().format("YYYY-MM-DD HH:mm:ss");
    let startTime = moment().subtract(1, "days").format("YYYY-MM-DD HH:mm:ss");
    getData(startTime, endTime);
  }, [device_id]);

  useEffect(() => {
    // Use setTimeout to update the message after 2000 milliseconds (2 seconds)
    const timeoutId = setTimeout(() => {
      // getFirstTableData();
      let endTime = moment().format("YYYY-MM-DD HH:mm:ss");
      let startTime = moment()
        .subtract(5, "days")
        .format("YYYY-MM-DD HH:mm:ss");
      getData(startTime, endTime);
    }, 60 * 1000);

    // Cleanup function to clear the timeout if the component unmounts
    return () => clearTimeout(timeoutId);
  }, []); // Empty dependency array ensures the effect runs only once

  function getFirstTableData() {
    UserService.GetLinkedDeviceTemperatureData(
      device_id,
      "Load_Power",
      "daterange",
      moment().subtract(10, "days").format("YYYY-MM-DD 00:00:00"),
      moment().format("YYYY-MM-DD 23:59:59")
    )
      .then((res) => {
        let powerDataFromDB = res.data.data.deviceData;
        // console.log(powerDataFromDB);
        let myData;
        if (typeof powerDataFromDB != "undefined" && !res.data.data.error) {
          myData = Object.keys(powerDataFromDB).map((key) => {
            return [
              powerDataFromDB[key].time,
              getBitValue(powerDataFromDB[key].GEN_STAT, 8),
              getBitValue(powerDataFromDB[key].GEN_STAT, 9),
              getBitValue(powerDataFromDB[key].GEN_STAT, 10),
              getBitValue(powerDataFromDB[key].GEN_STAT, 11),
              powerDataFromDB[key].device_data_id,
              // powerDataFromDB[key].device_data_id,
            ];
          });
          DisplayChanges(myData, findChangedElements(myData));

          console.log("last data ID", myData.at(-1));
        } else {
          myData = [];
        }
        if (myData.length > 0) {
          // console.log("Graph Data!!!!!", myData)
          // setData(myData);
          // setData1(myData1);
          // setData2(myData2);
          // setData3(myData3);
          // console.log(data);
        }
        // setisLoadingGraph(false);
      })
      .catch((err) => {
        // setisLoadingGraph(false);
        console.log(err);
      });
  }

  return (
    <Grid
      sx={{ marginBottom: "10px" }}
      spacing={1}
      item
      xs={12}
      sm={12}
      md={12}
    >
      <Card sx={{ marginBottom: "10px", border: `solid 1px black` ,             margin: "0 5px 10px",
            ":hover": {
              boxShadow: 5,
            },
            padding: "1%" }}>
        <div className="row">
          <div className="col-lg-2 col-sm-12">
            {" "}
            <Typography
              variant="h5"
              component="div"
              sx={{
                textAlign: "center",
                // color: "red",
                // backgroundColor: "red",
                // border: `solid 3px red`,
              }}
            >
              History
            </Typography>
          </div>
          {/* <div className="col-lg-5 col-sm-12">
            <Autocomplete
              multiple
              id="tags-outlined"
              options={AlarmKeys_for_select}
              getOptionLabel={(option) => option.title}
              defaultValue={[AlarmKeys_for_select[1]]}
              filterSelectedOptions
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="filterSelectedOptions"
                  // placeholder="Favorites"
                />
              )}
            />
          </div> */}
          <div className="col-lg-2 col-sm-12">
            <Typography> Real-Time</Typography>
            <Switch
              checked={Rt}
              onChange={() => setRt(!Rt)}
              name="Real Time"
              // color="primary"
            />
          </div>
          <div
            className="col-lg-4 col-sm-12"
            style={{
              padding: "1%",
            }}
          >
            <DateRangePicker onApply={handleApply}>
              <input
                type="text"
                className="form-control"
                placeholder="Select date range"
                style={{
                  fontSize: 12,
                  border: "1px solid #46acad",
                  borderRadius: 25,
                }}
                // value={FirstDataBack[0] + " " + LastDataFront[0]}
              />
            </DateRangePicker>
          </div>
        </div>
      </Card>
      <Card sx={{ marginBottom: "10px", border: `solid 1px black` ,             margin: "0 5px 10px",
            ":hover": {
              boxShadow: 5,
            },
            padding: "1%" }}>
        <CardContent sx={{ height: "400px", overflow: "auto" }}>
          <Grid
            container
            spacing={1}
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
          >
            <nav aria-label="main mailbox folders">
              <List>
                {JournalTable.map((x) => {
                  // console.log(x);
                  return (
                    <ListItem disablePadding>
                      <ListItemButton>
                        <ListItemIcon>
                          {x[1] ? (
                            <ReportProblemIcon sx={{ color: "red" }} />
                          ) : (
                            <GppGoodIcon sx={{ color: "green" }} />
                          )}
                        </ListItemIcon>
                        <ListItemText
                          sx={{ color: x[1] ? "red" : "green" }}
                          primary={x[0]}
                        />
                      </ListItemButton>
                    </ListItem>
                  );
                })}
              </List>
            </nav>
            <Divider />
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
}

function AlaramLog(props) {
  const { isGraphStatsLoading, Value1, Value2, color, name } = props;
  console.log({ isGraphStatsLoading, Value1, Value2, color, name });
  //   const isGraphStatsLoading = 0;
  const labeslFontSize = 8;
  const dataFontSize = 18;
  const unitFontSize = 16;
  return (
    <Grid
      sx={{ marginBottom: "10px" }}
      spacing={1}
      item
      xs={12}
      sm={12}
      md={12}
    >
      <Card sx={{ marginBottom: "10px", border: `solid 1px black` ,             margin: "0 5px 10px",
            ":hover": {
              boxShadow: 5,
            },
            padding: "1%" }}>
        <Typography
          variant="h5"
          component="div"
          sx={{
            textAlign: "center",
            // color: "red",
            // backgroundColor: "red",
            // border: `solid 3px red`,
          }}
        >
          {name}
        </Typography>
      </Card>
      <Card sx={{ marginBottom: "10px", border: `solid 1px black` ,             margin: "0 5px 10px",
            ":hover": {
              boxShadow: 5,
            },
            padding: "1%" }}>
        <CardContent>
          <Grid
            container
            spacing={1}
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
          >
            <Grid spacing={1} item xs={6} sm={6} md={6}>
              <Card
                className=""
                sx={{
                  boxShadow: 2,
                  ":hover": {
                    boxShadow: 5,
                  },
                }}
              >
                <CardContent
                  style={{
                    //   background: "green"
                    background: Value1 ? "green" : "red",
                  }}

                  // style={{
                  //   //   background: "green"
                  //   background: 1 ? 'green': 'red'
                  //   }}
                >
                  <Typography
                    variant="h5"
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      color: "white",
                      "padding-left": 5,
                      "padding-right": 5,
                      fontSize: unitFontSize,
                    }}
                  >
                    {/* Solar Failure Alarm */}
                    Solar PV Intrusion
                  </Typography>
                </CardContent>
              </Card>
            </Grid>

            <Grid spacing={1} item xs={6} sm={6} md={6}>
              <Card
                className=""
                sx={{
                  boxShadow: 2,
                  ":hover": {
                    boxShadow: 5,
                  },
                }}
              >
                <CardContent
                  style={{
                    //   background: "green"
                    background: Value2 ? "green" : "red",
                  }}
                >
                  <Typography
                    variant="h5"
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      color: "white",
                      "padding-left": 5,
                      "padding-right": 5,
                      fontSize: unitFontSize,
                    }}
                  >
                    {/* Temperature Alarm */}
                    Solar Inverter Intrusion
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
}

export default function App(props) {
  const { device_id } = props;
  console.log(device_id);

  const [staticValue1, setStaticValue1] = useState(0);
  const [staticValue2, setStaticValue2] = useState(0);
  const [data, setData] = useState([]);

  function getData(start, end) {
    console.log("get process");

    UserService.GetStandardData(
      "IEL_MOR_ARCHIVED",
      device_id, //device_id,
      "Load_Power",
      "daterange",
      start,
      end
    )
      .then((res) => {
        let DataFromDB = res.data.data.deviceData;
        console.log("DataFromDB", DataFromDB);
        setData(Object.values(DataFromDB));
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function getProcessData(start, end) {
    console.log("get process");

    UserService.GetStandardData(
      "IEL_MOR_ARCHIVED",
      device_id, //device_id,
      "Load_Power",
      "daterange",
      start,
      end,
      1
    )
      .then((res) => {
        let powerDataFromDB = res.data.data.deviceData[0];
        console.log("Archived2 res Data!!!!!!!!!", res.data.data.deviceData);
        console.log("Solar1_Intrusion", powerDataFromDB.Solar1_Intrusion);
        console.log("Solar2_Intrusion", powerDataFromDB.Solar2_Intrusion);
        setStaticValue1(powerDataFromDB.Solar1_Intrusion);
        setStaticValue2(powerDataFromDB.Solar2_Intrusion);

        // setStaticValue1(last_data.Solar1_Intrusion); //p29
        // setStaticValue2(last_data.Solar2_Intrusion); //a10
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    console.log(" getProcessData");
    let endTime = moment().format("YYYY-MM-DD HH:mm:ss");
    let startTime = moment().subtract(5, "days").format("YYYY-MM-DD HH:mm:ss");
    let result = getProcessData("getProcessData:", startTime, endTime);

    // getData();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      console.log("Refsh 40 second");
      let endTime = moment().format("YYYY-MM-DD HH:mm:ss");
      let startTime = moment()
        .subtract(5, "days")
        .format("YYYY-MM-DD HH:mm:ss");
      // console
      let result = getProcessData(startTime, endTime);
    }, 60 * 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <Grid
      container
      spacing={1}
      direction="row"
      justify="flex-start"
      alignItems="flex-start"
    >
      <AlaramLog
        isGraphStatsLoading={0}
        data={5}
        Value1={staticValue1}
        Value2={staticValue2}
        startfrom={3}
        name="Alarm Log"
      ></AlaramLog>

      <AlaramHistory device_id={device_id}></AlaramHistory>
    </Grid>
  );
}
