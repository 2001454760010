import { useCustomCompareEffect } from "use-custom-compare";
import isEqual from "lodash/isEqual";
import UserService from "../../services/user.service";
import React, { useState, useEffect, useRef } from "react";
import moment from "moment-timezone";
import ReactPaginate from "react-paginate";
import IconButton from "@mui/material/IconButton";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ReactApexChart from "react-apexcharts";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import Switch from "@mui/material/Switch";
import { Typography } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Card, CardContent, Grid } from "@mui/material";
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";
import Skeleton from "react-loading-skeleton";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import InboxIcon from "@mui/icons-material/Inbox";
import DraftsIcon from "@mui/icons-material/Drafts";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import GppGoodIcon from "@mui/icons-material/GppGood";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { object } from "yup";
import { map } from "highcharts";

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

function fillMissingGaps(data, type) {
  let result = [];
  const template = {};
  const template2 = {};

  const startOfMonth = parseInt(
    moment(data[0][0]).startOf("month").format("DD")
  );
  const endOfMonth = parseInt(moment(data[0][0]).endOf("month").format("DD"));
  const Day = parseInt(moment(data[0][0]).format("DD"));
  const Month = parseInt(moment(data[0][0]).endOf("month").format("MM"));
  const Year = parseInt(moment(data[0][0]).endOf("month").format("YYYY"));

  if (type == "daily") {
    data.forEach((x) => {
      template2[moment(x[0]).format("DD")] = [
        moment(x[0]).format("YYYY-MM-DD  00:00:00"),
        x[1],
      ];
    });
    // console.log(template2);
    for (let num = startOfMonth; num <= endOfMonth; num++) {
      if (num < 10) {
        template["0" + num] = [
          moment(`${Year}-${Month}-${num}`).format("YYYY-MM-DD  00:00:00"),
          null,
        ];
      } else {
        template["" + num] = [
          moment(`${Year}-${Month}-${num}`).format("YYYY-MM-DD  00:00:00"),
          null,
        ];
      }
    }
    // console.log(template);
    result = Object.values({ ...template, ...template2 }).sort((a, b) =>
      a[0].localeCompare(b[0], "en", { numeric: true })
    );
    // console.log("***", result);
    // console.log("+++", template);
    // console.log("+++", template2);
    // console.log("*/*/", result);
    return result.map((x) => [new Date(x[0]), x[1]]);
  } else if (type == "monthly") {
    data.forEach((x) => {
      template2[moment(x[0]).format("MM")] = x;
    });

    for (let num = 1; num <= 12; num++) {
      if (num < 10) {
        template["0" + num] = [
          moment(`${Year}-0${num}-02`).format("YYYY-MM-DD  00:00:00"),
          null,
        ];
      } else {
        template["" + num] = [
          moment(`${Year}-${num}-02`).format("YYYY-MM-DD  00:00:00"),
          null,
        ];
      }
    }
    console.log("+++", template2);
    result = Object.values({ ...template, ...template2 }).sort((a, b) =>
      a[0].localeCompare(b[0], "en", { numeric: true })
    );
    // console.log("***", result);
    // console.log("+++", template2);
    // console.log("*/*/", result);
    return result;
  }
}

export default function App(props) {
  const { device_id, edit, rerAccess } = props;
  const [data0, setData0] = useState([]);
  const [data1, setData1] = useState([]);
  const [data2, setData2] = useState([]);
  const [data3, setData3] = useState([]);
  const [data4, setData4] = useState([]);
  const [ratio, setRatio] = useState(0);
  const [dataNotAvailable, setDataNotAvailable] = useState(false);
  const chartRef = useRef();

  const [chartConfig, setChartConfig] = useState({
    chart: {
      id: "total-power-chart",
      // type: "line",
      // stacked: true,
      height: 500,
      foreColor: "#000000",
      zoom: {
        type: "x",
        enabled: true,
        autoScaleYaxis: true,
      },

      toolbar: {
        autoSelected: "zoom",
      },
    },
    dataLabels: {
      enabled: false,
      // offsetY: +60,
      style: {
        fontSize: "15px",
        colors: ["#000000"],
      },
    },
    markers: {
      size: 0,
    },
    xaxis: {
      type: "datetime",
      tooltip: {
        enabled: false,
      },
      labels: {
        rotate: -90,
        rotateAlways: true,
        minHeight: 100,
        maxHeight: 160,
        show: true,
        align: "left",
        trim: true,
        // minWidth: 0,
        // maxWidth: 1200,
        style: {
          colors: ["#000000"],
          fontSize: "15px",
          // fontFamily: 'Helvetica, Arial, sans-serif',
          fontWeight: 600,
        },
      },
    },
    stroke: {
      width: 2,
    },
    colors: ["#0054F1", "#0054F1", "#0054F1", "#0054F1"],
    yaxis: {
      opposite: true,
      // formatter: (value) => {
      //   return value ;
      // },
      min: 0,
      decimalsInFloat: 2,
      forceNiceScale: true,
      axisBorder: {
        show: true,
        color: "#78909C",
        offsetX: 0,
        offsetY: 0,
      },
      axisTicks: {
        show: true,
        borderType: "solid",
        color: "#78909C",
        width: 6,
        offsetX: 0,
        offsetY: 0,
      },
      labels: {
        show: true,
        align: "right",
        minWidth: 0,
        maxWidth: 160,
        style: {
          colors: [],
          fontSize: "15px",
          // fontFamily: 'Helvetica, Arial, sans-serif',
          fontWeight: 600,
        },
        offsetX: 0,
        offsetY: 0,
        rotate: 0,
        // formatter: (value) => { return val },
      },
      crosshairs: {
        show: true,
        position: "back",
        stroke: {
          color: "#b6b6b6",
          width: 0.6,
          dashArray: [0, 1, 2],
        },
      },
      tooltip: {
        enabled: false,
        offsetX: 0,
      },
      title: {
        text: "[KW2]",
        rotate: 0,
        offsetX: 40,
        offsetY: -170,
        style: {
          color: undefined,
          fontSize: "15px",
          // fontFamily: 'Helvetica, Arial, sans-serif',
          fontWeight: 600,
          // cssClass: 'apexcharts-yaxis-title',
        },
      },
    },
    legend: {
      showForSingleSeries: true,
      offsetX: 7,
      //  offsetY:5,
      position: "top",
      fontSize: "18px",
      //  width:1
      //  fontWeight: 800,
      markers: {
        width: 12,
        height: 12,
        strokeWidth: 2,
        strokeColor: "#fff",
        fillColors: undefined,
        radius: 12,
        offsetX: -5,
        // offsetY: 5
      },
    },
  });
  console.log("props", props);

  function calculateDifferences(inputArray) {
    // Create a new array to store the results
    let resultArray = [];
    if (inputArray.length == 1) {
      return inputArray;
    }
    console.log(inputArray);
    // resultArray.push(inputArray[0]);
    // Loop through the input array starting from the second element (index 1)
    for (let i = 1; i < inputArray.length; i++) {
      // Calculate the difference between T_Energy_Acc of current element and T_Energy_Acc of the previous element
      let diff = inputArray[i][1] - inputArray[i - 1][1];
      diff = diff % 1 !== 0 ? parseFloat(diff.toFixed(2)) : diff;

      // Create a new object with the 'diff' property and other properties from the current element
      let resultElement = [inputArray[i][0], diff >= 0 ? diff : 0];
      // console.log(resultElement);
      // Add the new object to the result array
      resultArray.push(resultElement);
    }

    resultArray.unshift(inputArray[0][1] >= 0 ? inputArray[0] : 0);
    console.log(resultArray);
    // Return the result array
    return resultArray;
  }

  function getChartData(powerDataFromDB, field) {
    let dNotNull = 0;
    return Object.values(powerDataFromDB).map((x) => {
      let t, d;
      t = x.date_time_A;
      d = x[field];
      if (d != null && d != 0 && d != -127) dNotNull = d;
      d = d === null ? dNotNull : d;
      d = d === -127 ? dNotNull : d;
      d = d === 0 && field === "Temp_IEL" ? dNotNull : d;
      d = field === "Power_Solis_AC_T_A" ? d / 1000 : d;
      // d = field === "Zero_Total_Power" ? d / 1000 : d;

      return [t, d];
    });
  }

  const formatDate = (year, month, day) => {
    const formattedMonth = String(month).padStart(2, '0'); // Add leading zero if necessary
    const formattedDay = String(day).padStart(2, '0');     // Add leading zero if necessary
    return `${parseInt(year)+2000}-${formattedMonth}-${formattedDay}`;
  };

  function getData(nrg_type, field) {
    console.log("get process");
    let type;
    if (props.TimeFormat == "daily") type = "day";
    else if (props.TimeFormat == "hour") type = "hour";
    else type = "month";
    // const TimeFormat = props.TimeFormat;
    const payload = {
      deviceId: props.device_id,
      type,
      year: props.EnergyYear,
      month: props.EnergyMonth,
      day: props.EnergyDay,
      EnergyType: nrg_type,
      TimeFormat: props.TimeFormat,
    };
    if (type != "hour") {
      UserService.PostEnergyData(payload)
        .then((resData) => {
          if (resData.data.type === "success") {
            let res = Object.values(resData.data.data.deviceData);
            // console.table(res);

            // let filteredArray = res.filter(
            //   (item) => item.energy_type === nrg_type
            // );
            let filteredArray = res;
            // console.table(filteredArray);
            filteredArray = filteredArray.map((v, index) => {
              // console.log(v, index);
              // if (payload.type == "day") return [index + 1, v.value];
              // else return [months[index], v.value];
              if (payload.type == "day")
                return [
                  new Date(2000 + v.year_index, v.month_index - 1, v.day_index),
                  v.value,
                ];
              else
                return [
                  new Date(2000 + v.year_index, v.month_index - 1, 15),
                  v.value,
                ];
            });
            const localUnit = "KWH";
            if (filteredArray.length) {
              // setDataNotAvailable(false);

              if (field == 1) setData1(filteredArray);
              if (field == 2) setData2(filteredArray);
              if (field == 3) setData3(filteredArray);
              if (field == 4) setData4(filteredArray);
              // console.table(filteredArray);
              // console.log(filteredArray);
              // console.log(fillMissingGaps(filteredArray, "daily"));
              //  setData0(Object.values(fillMissingGaps(filteredArray,"daily")));
              const localUnit = "KWH";

              setChartConfig({
                chart: {
                  id: "total-power-chart",
                  // type: "line",
                  // stacked: true,
                  height: 500,
                  foreColor: "#000000",
                  zoom: {
                    type: "x",
                    enabled: true,
                    autoScaleYaxis: true,
                  },
                  toolbar: {
                    autoSelected: "zoom",
                  },
                },
                tooltip: {
                  enabled: true,
                  color: "#000000",
                  style: {
                    fontSize: "16px",
                    // fontFamily: undefined
                  },
                  // shared: true,
                  x: {
                    formatter: function (timestamp) {
                      // console.log(val)
                      if (payload.TimeFormat === "hourly")
                        return moment(timestamp).format("DD MMMM YYYY HH:00");
                      if (payload.TimeFormat === "daily")
                        return moment(timestamp).format("DD MMMM YYYY");
                      if (payload.TimeFormat === "monthly")
                        return moment(timestamp).format("YYYY MMMM");
                    },
                  },
                  y: [
                    {
                      formatter: function (y) {
                        if (typeof y !== "undefined") {
                          return y.toFixed(2) + " " + "[KW]";
                        }
                        return y;
                      },
                    },
                    {
                      formatter: function (y) {
                        if (typeof y !== "undefined") {
                          return y.toFixed(2) + " " + "[KW]";
                        }
                        return y;
                      },
                    },
                    {
                      formatter: function (y) {
                        if (typeof y !== "undefined") {
                          return y.toFixed(2) + " " + "[KW]";
                        }
                        return y;
                      },
                    },
                    {
                      formatter: function (y) {
                        if (typeof y !== "undefined") {
                          return y.toFixed(2) + " " + localUnit;
                        }
                        return y;
                      },
                    },
                  ],
                },
                dataLabels: {
                  enabled: false,
                },
                markers: {
                  size: 0,
                },
                xaxis: {
                  type: "datetime",
                  tickAmount: filteredArray.length - 1,
                  tooltip: {
                    enabled: false,
                  },
                  labels: {
                    rotate: -90,
                    rotateAlways: true,
                    minHeight: 100,
                    maxHeight: 250,
                    show: true,
                    align: "left",
                    trim: false,
                    formatter: function (val, timestamp) {
                      if (payload.TimeFormat === "daily")
                        return moment(timestamp).format("DD MMM");
                      if (payload.TimeFormat === "monthly")
                        return moment(timestamp).format("MMMM");
                    },
                    style: {
                      colors: ["#000000"],
                      fontSize: "15px",
                      // fontFamily: 'Helvetica, Arial, sans-serif',
                      fontWeight: 600,
                    },
                  },
                },
                stroke: {
                  width: 2,
                },
                colors: ["#64006C", "#F30202", "#0054F1", "#FFD93D"],
                yaxis: {
                  opposite: false,
                  formatter: (value) => {
                    return value;
                  },
                  // min: 0,
                  decimalsInFloat: 2,
                  forceNiceScale: true,
                  axisBorder: {
                    show: true,
                    color: "#78909C",
                    offsetX: 0,
                    offsetY: 0,
                  },
                  axisTicks: {
                    show: true,
                    borderType: "solid",
                    color: "#78909C",
                    width: 6,
                    offsetX: 0,
                    offsetY: 0,
                  },
                  labels: {
                    show: true,
                    align: "right",
                    minWidth: 0,
                    maxWidth: 160,
                    style: {
                      colors: [],
                      fontSize: "15px",
                      // fontFamily: 'Helvetica, Arial, sans-serif',
                      fontWeight: 600,
                    },
                    offsetX: 0,
                    offsetY: 0,
                    rotate: 0,
                    // formatter: (value) => { return val },
                  },
                  crosshairs: {
                    show: true,
                    position: "back",
                    stroke: {
                      color: "#b6b6b6",
                      width: 0.6,
                      dashArray: [0, 1, 2],
                    },
                  },
                  tooltip: {
                    enabled: false,
                    offsetX: 0,
                  },
                  title: {
                    text: "[" + localUnit + "]",
                    rotate: 0,
                    offsetX: 40,
                    offsetY: -170,
                    style: {
                      color: undefined,
                      fontSize: "15px",
                      // fontFamily: 'Helvetica, Arial, sans-serif',
                      fontWeight: 600,
                      // cssClass: 'apexcharts-yaxis-title',
                    },
                  },
                },
                legend: {
                  showForSingleSeries: true,
                  offsetX: 7,
                  //  offsetY:5,
                  position: "top",
                  fontSize: "18px",
                  //  width:1
                  //  fontWeight: 800,
                  markers: {
                    width: 12,
                    height: 12,
                    strokeWidth: 2,
                    strokeColor: "#fff",
                    fillColors: undefined,
                    radius: 12,
                    offsetX: -5,
                    // offsetY: 5
                  },
                },
              });
            } else {
              console.log("datano available request data");
              // setData0([]);
              if (field == 1) setData1([]);
              if (field == 2) setData2([]);
              if (field == 3) setData3([]);
              if (field == 4) setData4([]);
              // setDataNotAvailable(true);
            }
          } else {
            // toast.error("Device not created.", { toastId: 2345353643 });
            console.log("error");
          }
        })
        .catch((error) => {
          {
            console.log("error", error);
          }
        });
    } else {
      let fields = ["Accumulated_Energy_Solis", "Energy_Grid_SM_T_A"];

      UserService.GetStandardData(
        "IEL_MOR_ARCHIVED",
        payload.deviceId, //device_id,
        "Load_Power",
        "daterange",
        `${payload.year}-${payload.month}-${payload.day} 00:00:00 `,
        `${payload.year}-${payload.month}-${payload.day} 23:59:59 `,
        0,
        "hourly"
      )
        .then((res) => {
          let powerDataFromDB = res.data.data.deviceData;
          console.log("GEt Data!!!!!!!!!", powerDataFromDB);
          let myData1;
          let myData2;
          if (typeof powerDataFromDB != "undefined" && !res.data.data.error) {
            fields.forEach((element) => {
              console.log(fields);
            });
            myData1 = getChartData(powerDataFromDB, fields[0]);
            myData2 = getChartData(powerDataFromDB, fields[1]);
            // console.log("f1", myData1);
            // console.log("f2", myData2);

            // myData1 = fillMissingGaps(
            //   calculateDifferences(getChartData(powerDataFromDB, fields[0])),
            //   "hourly"
            // );
            // myData2 = fillMissingGaps(
            //   calculateDifferences(getChartData(powerDataFromDB, fields[1])),
            //   "hourly"
            // );
            myData1 = calculateDifferences(
              getChartData(powerDataFromDB, fields[0])
            );
            myData2 = calculateDifferences(
              getChartData(powerDataFromDB, fields[1])
            );
            myData1.shift();
            myData2.shift();
            // console.log(myData);
            console.log("f1", myData1);
            console.log("f2", myData2);
            // console.log(myData3);
          } else {
            myData1 = [];
          }
          if (myData1.length > 0) {
            // Define the target day
            let targetDay =  formatDate(payload.year,payload.month,payload.day);
            console.log(targetDay)
            // Filter the array


            setData1(myData1.filter((entry) =>
              entry[0].startsWith(targetDay)
            ));
            setData4(myData2.filter((entry) =>
              entry[0].startsWith(targetDay)
            ));

            console.log("f1", myData1.filter((entry) =>
              entry[0].startsWith(targetDay)
            ));
            console.log("f2", myData2.filter((entry) =>
              entry[0].startsWith(targetDay)
            ));

            const localUnit = "KWH";

            setChartConfig({
              chart: {
                id: "total-power-chart",
                // type: "line",
                // stacked: true,
                height: 500,
                foreColor: "#000000",
                zoom: {
                  type: "x",
                  enabled: true,
                  autoScaleYaxis: true,
                },
                toolbar: {
                  autoSelected: "zoom",
                },
              },
              tooltip: {
                enabled: true,
                color: "#000000",
                style: {
                  fontSize: "16px",
                  // fontFamily: undefined
                },
                // shared: true,
                x: {
                  formatter: function (timestamp) {
                    return moment(timestamp).format("DD MMMM YYYY HH:00");
                  },
                },
                y: [
                  {
                    formatter: function (y) {
                      if (typeof y !== "undefined") {
                        return y.toFixed(2) + " " + "[KW]";
                      }
                      return y;
                    },
                  },
                  {
                    formatter: function (y) {
                      if (typeof y !== "undefined") {
                        return y.toFixed(2) + " " + "[KW]";
                      }
                      return y;
                    },
                  },
                  {
                    formatter: function (y) {
                      if (typeof y !== "undefined") {
                        return y.toFixed(2) + " " + "[KW]";
                      }
                      return y;
                    },
                  },
                  {
                    formatter: function (y) {
                      if (typeof y !== "undefined") {
                        return y.toFixed(2) + " " + localUnit;
                      }
                      return y;
                    },
                  },
                ],
              },
              dataLabels: {
                enabled: false,
              },
              markers: {
                size: 0,
              },
              xaxis: {
                type: "datetime",
                tickAmount: data1.length - 1,
                tooltip: {
                  enabled: false,
                },
                labels: {
                  rotate: -90,
                  rotateAlways: true,
                  minHeight: 100,
                  maxHeight: 250,
                  show: true,
                  align: "left",
                  trim: false,
                  formatter: function (val, timestamp) {
                    return moment(timestamp).format("HH:00");
                  },
                  style: {
                    colors: ["#000000"],
                    fontSize: "15px",
                    // fontFamily: 'Helvetica, Arial, sans-serif',
                    fontWeight: 600,
                  },
                },
              },
              stroke: {
                width: 2,
              },
              colors: ["#64006C", "#F30202", "#0054F1", "#FFD93D"],
              yaxis: {
                opposite: false,
                formatter: (value) => {
                  return value;
                },
                // min: 0,
                decimalsInFloat: 2,
                forceNiceScale: true,
                axisBorder: {
                  show: true,
                  color: "#78909C",
                  offsetX: 0,
                  offsetY: 0,
                },
                axisTicks: {
                  show: true,
                  borderType: "solid",
                  color: "#78909C",
                  width: 6,
                  offsetX: 0,
                  offsetY: 0,
                },
                labels: {
                  show: true,
                  align: "right",
                  minWidth: 0,
                  maxWidth: 160,
                  style: {
                    colors: [],
                    fontSize: "15px",
                    // fontFamily: 'Helvetica, Arial, sans-serif',
                    fontWeight: 600,
                  },
                  offsetX: 0,
                  offsetY: 0,
                  rotate: 0,
                  // formatter: (value) => { return val },
                },
                crosshairs: {
                  show: true,
                  position: "back",
                  stroke: {
                    color: "#b6b6b6",
                    width: 0.6,
                    dashArray: [0, 1, 2],
                  },
                },
                tooltip: {
                  enabled: false,
                  offsetX: 0,
                },
                title: {
                  text: "[" + localUnit + "]",
                  rotate: 0,
                  offsetX: 40,
                  offsetY: -170,
                  style: {
                    color: undefined,
                    fontSize: "15px",
                    // fontFamily: 'Helvetica, Arial, sans-serif',
                    fontWeight: 600,
                    // cssClass: 'apexcharts-yaxis-title',
                  },
                },
              },
              legend: {
                showForSingleSeries: true,
                offsetX: 7,
                //  offsetY:5,
                position: "top",
                fontSize: "18px",
                //  width:1
                //  fontWeight: 800,
                markers: {
                  width: 12,
                  height: 12,
                  strokeWidth: 2,
                  strokeColor: "#fff",
                  fillColors: undefined,
                  radius: 12,
                  offsetX: -5,
                  // offsetY: 5
                },
              },
            });
          } else {
          }
        })
        .catch((err) => {
          // setisLoadingGraph(false);
          // setDataAvailable(false);
        });
    }
  }
  function filterArraysByCommonDates(array1, array2) {
    try {
      // Step 1: Extract dates from both arrays
      const datesArray1 = array1.map((item) => item[0].getTime()); // Convert Date to timestamp
      const datesArray2 = array2.map((item) => item[0].getTime()); // Convert Date to timestamp

      // Step 2: Find common dates (by comparing timestamps)
      const commonDates = datesArray1.filter((date) =>
        datesArray2.includes(date)
      );

      // Step 3: Filter both arrays based on common dates
      const filteredArray1 = array1.filter((item) =>
        commonDates.includes(item[0].getTime())
      );
      const filteredArray2 = array2.filter((item) =>
        commonDates.includes(item[0].getTime())
      );
      return { filteredArray1, filteredArray2 };
    } catch (error) {
      return { filteredArray1: [], filteredArray2: [] };
    }
    // Return both filtered arrays
  }

  function gettRatio() {
    let totalLoad = 0;
    let totalSolar = 0;
    console.log("data 1", data1);
    console.log("data 4", data4);
    let result = filterArraysByCommonDates(data1, data4);
    let filteredArray1 = result.filteredArray1;
    let filteredArray2 = result.filteredArray2;
    console.log(result);

    for (let index = 0; index < filteredArray2.length; index++) {
      if (
        filteredArray1[index][1] !== null &&
        filteredArray2[index][1] !== null
      ) {
        totalLoad = totalLoad + filteredArray2[index][1];
        totalSolar = totalSolar + filteredArray1[index][1];
        // console.log(
        //   "solar :",
        //   filteredArray1[index][1],
        //   "total solar:",
        //   totalSolar,
        //   "load:",
        //   filteredArray2[index][1],
        //   "total load:",
        //   totalLoad
        // );
        // console.log("solar :", data1[index][1], "load:", data4[index][1]);
      }
      // console.log("solar :", data1[index][1], "load:", data4[index][1]);
      // console.log(index,data1[index][1],data4[index][1]);
    }
    setRatio(((totalSolar / totalLoad) * 100).toFixed(2));
  }
  useEffect(() => {
    getData(1, 1);
    getData(4, 4);
    if (props.TimeFormat != "hour") gettRatio();
  }, [props]);

  useEffect(() => {
    if (props.TimeFormat != "hour") gettRatio();
  }, [data1, data4]);

  // useEffect(() => {
  //   gettRatio();
  // }, [data1, data4]);

  return (
    <>
      {!dataNotAvailable ? (
        <>
          {rerAccess == 1 && props.TimeFormat != "hour" ? (
            <div>
              <h3
                style={{
                  color: "#0054F1",
                }}
              >
                Renewable Energy Ratio: {ratio}%
              </h3>
            </div>
          ) : null}

          <ReactApexChart
            height={450}
            ref={chartRef}
            options={chartConfig}
            series={[
              { name: "Solar", data: data1 },
              { name: "Load", data: data4 },
            ]}
            type="bar"
          />
        </>
      ) : (
        <div
          style={{
            "text-align": "center",
            padding: "100px",
          }}
        >
          <h3>Data is not available</h3>
        </div>
      )}
    </>
  );
}
