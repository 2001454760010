import React from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
   
    titleContainer:{
        flexDirection: 'row',
        marginTop: 12
    },
    reportTitle:{
        fontSize: 12,
        textAlign: 'center',
        // textTransform: 'uppercase',
    }
    ,
    footer:{
        position: 'absolute',
        fontSize: 12,
        bottom: 30,
        left: 0,
        right: 0,
        textAlign: 'center',
        color: 'grey',
    }
  });


  const InvoiceThankYouMsg = () => (
    <View style={styles.footer}>
        <Text style={styles.reportTitle}> &copy; Agile - Sync Portal https://agile-portal.com</Text>
    </View>
  );
  
  export default InvoiceThankYouMsg