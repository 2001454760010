import { useCustomCompareEffect } from "use-custom-compare";
import isEqual from "lodash/isEqual";
import UserService from "../../services/user.service";
import React, { useState, useEffect, useRef } from "react";
import moment from "moment-timezone";
import ReactPaginate from "react-paginate";
import IconButton from "@mui/material/IconButton";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ReactApexChart from "react-apexcharts";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import Switch from "@mui/material/Switch";
import { Typography } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Card, CardContent, Grid } from "@mui/material";
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";
import Skeleton from "react-loading-skeleton";

function getBitValue(value, index) {
  // Vérifier que l'indice est valide
  if (index < 0 || index >= 32) {
    console.error("L'indice doit être compris entre 0 et 31.");
    return null;
  }

  // Utiliser l'opérateur de décalage pour obtenir la valeur du bit à l'indice donné
  return (value >> index) & 1;
}


function Battery(props) {
  const { isGraphStatsLoading, data, startfrom,color, name } = props;

  //   const isGraphStatsLoading = 0;
  const labeslFontSize = 8;
  const dataFontSize = 18;
  const unitFontSize = 16;
  return (
    <Grid       sx={{marginBottom:"10px",

    
    }} spacing={1} item xs={12} sm={4} md={4} >
      <Card 
      sx={{marginBottom:"10px",
      border: `solid 1px black`,
      
      }}>
        <Typography
          variant="h5"
          component="div"
          sx={{
            textAlign: "center",
            color: "black",
            // backgroundColor: "red",
          // border: `solid 3px black`,

          }}
        >
          {name}
        </Typography>
      </Card>
      <Card
        sx={{
          boxShadow: 2,
          ":hover": {
            boxShadow: 5,
          },

          border: `solid 1px black`,
        }}
      >
        <CardContent>
          <Grid
            container
            spacing={1}
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
          >
            {[...Array(7).keys()].map((index) => (
              <Grid spacing={1} item xs={12} sm={12} md={12}>
                <Card
                  className=""
                  sx={{
                    boxShadow: 2,
                    ":hover": {
                      boxShadow: 5,
                    },
                  }}
                >
                  <CardContent
                    // style={{
                    // //   background: "green"
                    // background: Math.floor(Math.random() * 3) >= 1 ? 'green': 'red'
                    // }}

                    style={{
                      //   background: "green"
                      background: getBitValue(data,index+8) ? 'green': 'red'
                      }}
                  >
                    <Typography
                      variant="h5"
                      style={{
                        textAlign: "center",
                        fontWeight: "bold",
                        color: "white",
                        "padding-left": 5,
                        "padding-right": 5,
                        fontSize: unitFontSize,
                      }}
                    >
                      Battery {index + startfrom}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
}

export default function App(props) {
  const {ProcessData} = props;
  console.log(ProcessData);
  return (
    <Grid
      container
      spacing={1}
      direction="row"
      justify="flex-start"
      alignItems="flex-start"
    >

      <Battery isGraphStatsLoading={0} data= {ProcessData.BATT1_STAT} startfrom = {1} name="Cabinet 1"></Battery>
      <Battery isGraphStatsLoading={0} data= {ProcessData.BATT2_STAT} startfrom = {8} name="Cabinet 2"></Battery>
      <Battery isGraphStatsLoading={0} data= {ProcessData.BATT3_STAT} startfrom = {15} name="Cabinet 3"></Battery>


    </Grid>
  );
}
