// Start Date 22 April 2023
// Done Retreive linked devices
// Done filter to remove virtual devices and areas
// Done set checked list and unchekced list
// TODO Apply detecet the new  Device and remove
// TODO delete the removed records
// TODO Create New records

import React, { useState, useEffect, useRef, MouseEvent } from "react";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import { CardContent, Typography } from "@mui/material";
import UserService from "../../../services/user.service";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
  return [...a, ...not(b, a)];
}

function compareArrays(array1, array2) {
  const toAdd = array2.filter((item) => !array1.includes(item));
  const toRemove = array1.filter((item) => !array2.includes(item));
  return { toAdd, toRemove };
}

export default function DeviceSelector(props) {
  // console.log("Device Selector Props", props);
  //   const CheckedDevices = props.CheckedDevices;
  //   const UncheckedDevices = props.UncheckedDevices;
  const DeviceId = props.DeviceId;
  const userID = props.userID;

  const [checked, setChecked] = React.useState([]);
  const [left, setLeft] = React.useState([]);
  const [right, setRight] = React.useState([]);

  const [CheckedDevices, setCheckedDevices] = useState([]);
  const [UncheckedDevices, setUncheckedDevices] = useState([]);
  const [AreaList, setAreaList] = useState([]);
  const [Area, setArea] = React.useState(0);

  useEffect(() => {
    UserService.GetTreeViewData(userID).then(
      (response) => {
        console.log(
          DeviceId,
          "tree view data0000000000000000000000",
          response.data.data.profile
        );
        setAreaList(
          response.data.data.profile[0]
            .filter((x) => x.is_type === "area")
            .map((x) => {
              return { id: x.id, label: x.label };
            })
        );
        // console.log("areas", AreaList);

        setCheckedDevices(() => {
          setUncheckedDevices(
            response.data.data.profile[0]
              .filter((x) => x.is_type === "device")
              .filter((x) => x.device_id.includes("MOR"))
              // .filter((x) => x.parent_id === Area)
              .filter((x) => (Area !== 0 ? x.parent_id === Area : true))
              .filter(
                (item1) =>
                  !response.data.data.profile[0]
                    .filter((x) => x.parent_id === DeviceId)
                    .some((item2) => item2.label === item1.label)
              )
            // .filter((x) => x.is_type === "device")
          );
          return response.data.data.profile[0].filter(
            (x) => x.parent_id === DeviceId
          );
        });
        console.log(
          "selected",
          response.data.data.profile[0].filter((x) => x.parent_id === DeviceId)
        );
      },
      (error) => {}
    );
  }, [DeviceId, Area]);

  useEffect(() => {
    setChecked(CheckedDevices.map((x) => x.label));
    setLeft(UncheckedDevices.map((x) => x.label));
    setRight(CheckedDevices.map((x) => x.label));
  }, [DeviceId, CheckedDevices, UncheckedDevices]);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items) => intersection(checked, items).length;

  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const handleApply = () => {
    console.log("final List:", right);
    console.log("old List:", CheckedDevices);
    console.log(
      "old List names:",
      CheckedDevices.map((x) => x.label)
    );

    const { toAdd, toRemove } = compareArrays(
      CheckedDevices.map((x) => x.label),
      right
    );
    console.log(`Elements to add: ${toAdd}`);
    console.log(`Elements to remove: ${toRemove}`);
    // add devices
    //   prarrent_id = device_id
    console.log("Start Adding");
    toAdd.forEach((device) => {
      const result = UncheckedDevices.filter((x) => x.label === device)[0];
      const obj = {
        device_name: result.label,
        parent_id: DeviceId,
        // is_type: "virtual_child_device",
        device_id: result.device_id,
      };
      console.log(userID, "insertion object", obj);
      UserService.createVIrtualChildDevice(userID, obj)
        .then((resData) => {
          if (resData.data.type === "success") {
            console.log(resData);
          } else {
            // toast.error("Device not created.", { toastId: 2345353643 });
            console.log("error");
          }
        })
        .catch((error) => {
          {
            console.log("error", error);
          }
        });
    });
    console.log("Start removing");
    toRemove.forEach((device) => {
      const result = CheckedDevices.filter((x) => x.label === device)[0];

      console.log("deletyion id", result.id);
      UserService.removeIdFromTree(result.id).then(
        (response) => {
          console.log("response", response);
        },
        (error) => {}
      );
      //
    });
  };
  const customList = (title, items) => (
    <Card>
      <CardHeader
        sx={{ px: 2, py: 1 }}
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={
              numberOfChecked(items) === items.length && items.length !== 0
            }
            indeterminate={
              numberOfChecked(items) !== items.length &&
              numberOfChecked(items) !== 0
            }
            disabled={items.length === 0}
            inputProps={{
              "aria-label": "all items selected",
            }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} selected`}
      />
      <Divider />
      <List
        sx={{
          width: 200,
          height: 230,
          bgcolor: "background.paper",
          overflow: "auto",
        }}
        dense
        component="div"
        role="list"
      >
        {items.map((value) => {
          const labelId = `transfer-list-all-item-${value}-label`;

          return (
            <ListItem
              key={value}
              role="listitem"
              button
              onClick={handleToggle(value)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    "aria-labelledby": labelId,
                  }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={`${value}`} />
            </ListItem>
          );
        })}
      </List>
    </Card>
  );

  return (
    <>
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Card
          sx={{
            boxShadow: 2,
            margin: "2% 5px 10px",
            ":hover": {
              boxShadow: 5,
            },
          }}
        >
          <CardContent>
            <Grid item>{customList("Devices", left)}</Grid>
          </CardContent>
        </Card>
        <Card
          sx={{
            boxShadow: 2,
            margin: "2% 5px 10px",
            ":hover": {
              boxShadow: 5,
            },
          }}
        >
          <CardContent>
            <Grid item>
              <Grid container direction="column" alignItems="center">
                <InputLabel>Area</InputLabel>

                <Select
                  value={Area}
                  label="Area"
                  onChange={(event) => {
                    setArea(event.target.value);
                  }}
                >
                  <MenuItem value={0}>All</MenuItem>

                  {AreaList.map((x) => (
                    <MenuItem value={x.id}>{x.label}</MenuItem>
                  ))}
                </Select>
                <Button
                  sx={{ my: 0.5 }}
                  variant="outlined"
                  size="small"
                  onClick={handleCheckedRight}
                  disabled={leftChecked.length === 0}
                  aria-label="move selected right"
                >
                  &gt;
                </Button>
                <Button
                  sx={{ my: 0.5 }}
                  variant="outlined"
                  size="small"
                  onClick={handleCheckedLeft}
                  disabled={rightChecked.length === 0}
                  aria-label="move selected left"
                >
                  &lt;
                </Button>
                <Button
                  sx={{ my: 0.5 }}
                  variant="contained"
                  color="success"
                  size="large"
                  onClick={handleApply}
                  disabled={right.length === 0}
                  aria-label="move selected left"
                >
                  Apply
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Card
          sx={{
            boxShadow: 2,
            margin: "2% 5px 10px",
            ":hover": {
              boxShadow: 5,
            },
          }}
        >
          <CardContent>
            <Grid item>{customList("Selected", right)}</Grid>
          </CardContent>
        </Card>
      </Grid>
    </>
  );
}
