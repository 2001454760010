import { useCustomCompareEffect } from "use-custom-compare";
import isEqual from "lodash/isEqual";
import UserService from "../../services/user.service";
import React, { useState, useEffect, useRef } from "react";
import moment from "moment-timezone";
import ReactPaginate from "react-paginate";
import IconButton from "@mui/material/IconButton";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ReactApexChart from "react-apexcharts";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import Switch from "@mui/material/Switch";
import { Typography } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Card, CardContent, Grid } from "@mui/material";
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";
import Skeleton from "react-loading-skeleton";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import InboxIcon from "@mui/icons-material/Inbox";
import DraftsIcon from "@mui/icons-material/Drafts";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import GppGoodIcon from "@mui/icons-material/GppGood";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { object } from "yup";
import { map } from "highcharts";


function getNameById(id, id2) {

  const idNameMap = {
    4100: "No Grid",
    4117: "No Grid",
    4112: "Over grid Voltage",
    4113: "Under grid voltage",
    4114: "Over grid frequency",
    4115: "Under grid frequency",
    4128: "DC Over Voltage",
    4129: "DC bus Over Voltage",
    4131: "DC bus Under voltage",
    4166: "Grid interference",
    4120: "Grid Over Current",
    4168: "IGBT Over Current",
    4135: "DC input Over current",
    4121: "Grid current tracking fail",
    4167: "Grid current sampling fail",
    4145: "Initialization system fault",
    4150: "Communication Failure between main and slave DSP",
    4152: "12V power supply fault",
    4146: "Over temperature",
    4158: "Over temperature",
    4147: "PV isolation protection",
    4148: "Leakage current protection",
    4149: "Relay check fail",
    4151: "High DC injection current",
    4160: "AFCI module self-detect fault",
    4161: "ARC Fault" ,
    99991 : "Solar1 Intrusion",
    99992 : "Solar2 Intrusion",
    99993 : "Temperature"
  };

  return idNameMap[id] || 0;
}

function AlarmsOverview(props) {
  const { isGraphStatsLoading, Value1, Value2, Value3, Value4, Value5, alarm_name, color, name } = props;
  console.log({ isGraphStatsLoading, Value1, Value2, Value3, Value4, alarm_name, color, name });
  console.log(Value5);
  //   const isGraphStatsLoading = 0;
  const labeslFontSize = 8;
  const dataFontSize = 18;
  const unitFontSize = 16;
  return (
    <Grid
      sx={{ marginBottom: "10px" }}
      spacing={1}
      item
      xs={6}
      sm={6}
      md={6}
    >
      <Card sx={{
        marginBottom: "10px", border: `solid 1px black`, margin: "0 5px 10px",
        ":hover": {
          boxShadow: 5,
        },
        padding: "1%"
      }}>
        <Typography
          variant="h5"
          component="div"
          sx={{
            textAlign: "center",
            // color: "red",
            // backgroundColor: "red",
            // border: `solid 3px red`,
          }}
        >
          {name}
        </Typography>
      </Card>
      <Card sx={{
        marginBottom: "10px", border: `solid 1px black`, margin: "0 5px 10px",
        ":hover": {
          boxShadow: 5,
        },
        padding: "1%"
      }}>
        <CardContent>
          <Grid
            container
            spacing={1}
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
          >
            {!Value1 ? (<Grid spacing={1} item xs={12} sm={12} md={12}>
              <Card
                className=""
                sx={{
                  boxShadow: 2,
                  ":hover": {
                    boxShadow: 5,
                  },
                }}
              >
                <CardContent
                  style={{
                    //   background: "green"
                    background: "red",
                  }}

                // style={{
                //   //   background: "green"
                //   background: 1 ? 'green': 'red'
                //   }}
                >
                  <Typography
                    variant="h5"
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      color: "white",
                      "padding-left": 5,
                      "padding-right": 5,
                      fontSize: unitFontSize,
                    }}
                  >
                    {/* Solar Failure Alarm */}
                    Solar PV Intrusion
                  </Typography>
                </CardContent>
              </Card>
            </Grid>) : null}


            {!Value2 ? (<Grid spacing={1} item xs={12} sm={12} md={12}>
              <Card
                className=""
                sx={{
                  boxShadow: 2,
                  ":hover": {
                    boxShadow: 5,
                  },
                }}
              >
                <CardContent
                  style={{
                    //   background: "green"
                    background: "red",
                  }}
                >
                  <Typography
                    variant="h5"
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      color: "white",
                      "padding-left": 5,
                      "padding-right": 5,
                      fontSize: unitFontSize,
                    }}
                  >
                    {/* Temperature Alarm */}
                    Solar Inverter Intrusion
                  </Typography>
                </CardContent>
              </Card>
            </Grid>) : null}

            {/* New Alarms */}
            {Value3 > 45 ? (<Grid spacing={1} item xs={12} sm={12} md={12}>
              <Card
                className=""
                sx={{
                  boxShadow: 2,
                  ":hover": {
                    boxShadow: 5,
                  },
                }}
              >
                <CardContent
                  style={{
                    //   background: "green"
                    background: "red",
                  }}

                // style={{
                //   //   background: "green"
                //   background: 1 ? 'green': 'red'
                //   }}
                >
                  <Typography
                    variant="h5"
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      color: "white",
                      "padding-left": 5,
                      "padding-right": 5,
                      fontSize: unitFontSize,
                    }}
                  >
                    High Temperature Alarm ({Value3} C)
                  </Typography>
                </CardContent>
              </Card>
            </Grid>) : null}

            {Value4 != "green" ? (<Grid spacing={1} item xs={12} sm={12} md={12}>
              <Card
                className=""
                sx={{
                  boxShadow: 2,
                  ":hover": {
                    boxShadow: 5,
                  },
                }}
              >
                <CardContent
                  style={{
                    //   background: "green"
                    background: "red",
                  }}
                >
                  <Typography
                    variant="h5"
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      color: "white",
                      "padding-left": 5,
                      "padding-right": 5,
                      fontSize: unitFontSize,
                    }}
                  >
                    {/* Temperature Alarm */}
                    Connectivity Lost Alarm
                  </Typography>
                </CardContent>
              </Card>
            </Grid>) : null}


            {!Value5 ? (<Grid spacing={1} item xs={12} sm={12} md={12}>
              <Card
                className=""
                sx={{
                  boxShadow: 2,
                  ":hover": {
                    boxShadow: 5,
                  },
                }}
              >
                <CardContent
                  style={{
                    //   background: "green"
                    background: "red",
                  }}
                >
                  <Typography
                    variant="h5"
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      color: "white",
                      "padding-left": 5,
                      "padding-right": 5,
                      fontSize: unitFontSize,
                    }}
                  >
                    {/* Temperature Alarm */}
                    {alarm_name}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>) : null}

          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
}

function History(props) {
  const { JournalTable, name } = props;

  // const [JournalTable, setJournalTable] = useState([["message", 0]]);



  return (
    <Grid
      sx={{ marginBottom: "10px" }}
      spacing={1}
      item
      xs={6}
      sm={6}
      md={6}
    >
      <Card sx={{
        marginBottom: "10px", border: `solid 1px black`, margin: "0 5px 10px",
        ":hover": {
          boxShadow: 5,
        },
        padding: "1%"
      }}>
        <Typography
          variant="h5"
          component="div"
          sx={{
            textAlign: "center",
            // color: "red",
            // backgroundColor: "red",
            // border: `solid 3px red`,
          }}
        >
          {name}
        </Typography>
      </Card>
      <Card sx={{
        marginBottom: "10px", border: `solid 1px black`, margin: "0 5px 10px",
        ":hover": {
          boxShadow: 5,
        },
        padding: "1%"
      }}>
        <CardContent sx={{ height: "400px", overflow: "auto" }}>
          <Grid
            container
            spacing={1}
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
          >
            <nav aria-label="main mailbox folders">
              <List>
                {JournalTable.length > 0 && JournalTable.filter(innerArray => innerArray[1] !== 0).map((x) => {
                  // console.log(x);
                  return (
                    <ListItem disablePadding>
                      <ListItemButton>
                        <ListItemIcon>
                          <ReportProblemIcon sx={{ color: "red" }} />
                        </ListItemIcon>
                        <ListItemText
                          primary={x[0] + " " + x[1]}
                        />
                      </ListItemButton>
                    </ListItem>
                  );
                })}
              </List>
            </nav>
            <Divider />
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
}
export default function App(props) {
  const { device_id, isDeviceStatus, setAlarm } = props;
  console.log(props);

  const [staticValue1, setStaticValue1] = useState(0);
  const [staticValue2, setStaticValue2] = useState(0);
  const [staticValue3, setStaticValue3] = useState(0);
  const [staticValue4, setStaticValue4] = useState(0);
  const [staticValue5, setStaticValue5] = useState(1);
  const [alarmName, setAlarmName] = useState("");
  const [JournalTable, setJournalTable] = useState([]);
  const [data, setData] = useState([]);



  function getData(start, end) {
    console.log("get process");

    UserService.GetStandardData(
      "IEL_MOR_ALARMS",
      device_id, //device_id,
      "Load_Power",
      "daterange",
      start,
      end
    )
      .then((res) => {
        let DataFromDB = res.data.data.deviceData;
        let myData;
        console.log("DataFromDB", DataFromDB);

        if (typeof DataFromDB != "undefined" && !res.data.data.error) {
          myData = Object.keys(DataFromDB).map((key) => {
            return [
              moment(DataFromDB[key].date_time_A).format("YYYY-MM-DD HH:mm:ss"),
              getNameById(DataFromDB[key].Inv_Status, DataFromDB[key].Inverter_Alarm_Code),
              DataFromDB[key].Inverter_Alarm_Code,
              DataFromDB[key].Inv_Status
            ];
          }).slice().reverse();

          // setJournalTable(myData.filter(innerArray => innerArray[1] !== 0))
          if (myData[0][1] !== 0 ) {
            
            console.log(myData[0])
            setStaticValue5(0);
            setAlarmName(myData[0][1]);
            if (myData[0][3] !== 99991 || myData[0][3] !== 99992 || myData[0][3] !== 99993 ) 
              setStaticValue5(1);
          }
          else setStaticValue5(1);
          setJournalTable(myData);
          console.log("data", myData);
          console.log("data filtred", myData.filter(innerArray => innerArray[1] !== 0));
          console.log("last data ID", myData.at(-1));
        } else {
          myData = [];
        }

        // setData(Object.values(DataFromDB));
      })
      .catch((err) => {
        console.log(err);
      });
  }

  // let endTime = moment().format("YYYY-MM-DD HH:mm:ss");
  // let startTime = moment().subtract(5, "days").format("YYYY-MM-DD HH:mm:ss");
  // getData(startTime, endTime);
  function getProcessData(start, end) {
    console.log("get process");

    UserService.GetStandardData(
      "IEL_MOR_ARCHIVED",
      device_id, //device_id,
      "Load_Power",
      "daterange",
      start,
      end,
      1
    )
      .then((res) => {
        let powerDataFromDB = res.data.data.deviceData[0];
        console.log("Archived2 res Data!!!!!!!!!", res.data.data.deviceData);
        console.log("Solar1_Intrusion", powerDataFromDB.Solar1_Intrusion);
        console.log("Solar2_Intrusion", powerDataFromDB.Solar2_Intrusion);
        setStaticValue1(powerDataFromDB.Solar1_Intrusion);
        setStaticValue2(powerDataFromDB.Solar2_Intrusion);
        setStaticValue3(powerDataFromDB.Temp_IEL);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    console.log(" getProcessData");
    let endTime = moment().format("YYYY-MM-DD 23:59:59");
    let startTime = moment().subtract(90, "days").format("YYYY-MM-DD HH:mm:ss");
    let result = getProcessData("getProcessData:", startTime, endTime);
    getData(startTime, endTime);

    // setStaticValue1(powerDataFromDB.Solar1_Intrusion);
    // setStaticValue2(powerDataFromDB.Solar2_Intrusion);
    // setStaticValue3(powerDataFromDB.Temp_IEL);
    // setStaticValue5
    //wahda fihoum true tiger alarm
    if (
      staticValue1 == 0 ||
      staticValue2 == 0 ||
      staticValue3 > 45 ||
      isDeviceStatus != 'green' ||
      staticValue5 == 0
    ) { setAlarm(1) }

  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      console.log("Refsh 40 second");
      let endTime = moment().format("YYYY-MM-DD 23:59:59");
      let startTime = moment()
        .subtract(60, "days")
        .format("YYYY-MM-DD HH:mm:ss");
      // console
      let result = getProcessData(startTime, endTime);
    }, 60 * 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <Grid
      container
      spacing={1}
      direction="row"
      justify="flex-start"
      alignItems="flex-start"
    >
      <AlarmsOverview
        isGraphStatsLoading={0}
        data={5}
        Value1={staticValue1}
        Value2={staticValue2}
        Value3={staticValue3}
        Value4={isDeviceStatus}
        Value5={staticValue5}
        alarm_name={alarmName}
        startfrom={3}
        name="Alarm Log"
      ></AlarmsOverview>

      <History JournalTable={JournalTable} name="Alarm History" ></History>

    </Grid>
  );
}
