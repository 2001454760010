import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Footer } from "../components/includes/Footer";
import { Header } from "../components/includes/Header";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  login,
  register as signUp,
} from "../redux/features/AuthenticationSlice";
import { clearMessage } from "../redux/features/Message";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import UserService from "../services/user.service";
import ReCAPTCHA from "react-google-recaptcha";
const Portal = () => {
  const [switchForm, setswitchForm] = useState(true);
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const [recaptchaValue, setRecaptchaValue] = useState('');
  // const [recaptchaValue, setRecaptchaValue] = useState('03AFcWeA4hoY6EaGHYF2F9g9U7SrmRjZt4v-jlxHRR2LyoUzUd58ML5LAV13sXcO2t2Q8XWIBjg6qOAwhgLtPoW1_lCPGrsFuFxAIVpxgBlS8_ip_0u09aABrTZgR3DXjvw3ecmnfo39Lcmb4WBls_8I0twSjkK2CEGjQVTsfnaf12LVWQpkQegJQTWEjD48SiZbvslRXUlRmwQzm8568-9FNPDl2Gh3Gl2O5wUmytQ5mMWLtpBbcg_2VFtRFzawovVGbOZTzOrLuVjCfXZ_P6rkZFU4qXYIk67d4mzCFSaxa37H35tayi0cr_t4ujECOfYl0n5ooL5wQFrmn3hi58KLnD2euRYMJKSuGPMa-K5qwmwgnj71BUx8PiqEaz6t2nrzKt_a1aP0fAplJtsjjAVfVJMELG83Dp5wRHSMwXmdPXikqsoe1YnLaJy2YRWzDLw8DBngeDrGYD1UyPtdugO5fctJlcKM2IUcEY9OouQ5W3nztyDIwq1YvVCKMoe51FmioZKRGxMaGNp71oC3bB0DmI7_DRJDd1QKkUngA0w3k0q1gnYhH8SGcOR0MbryRRV1bUi4z9dXzFySPitm6xfdQ_N-W6zb32tDCDg-t3VUSKf6wYl1sILG7_B9JZi4UJrWyMrIlYbaZdxEFhsdqGuAkeqanlMpT0a8Ci7tGi7CzLLxv_DyWwzVtsSYDOp0pgqvzXHo6y21V4CtQzQUSdSYp0V5axJPwYKck32_qwv0GW_ou-MGVEMsKPqgPLFKICrGNs6Xn4ksF1B-upVtLo5I-H22rlG4EGpOqt0BBtOhR1PJmeE-X7y_d7FHkQAwP2L8Gr_67UDdZgOVrK_RFQaVovFrPRqsWUqDZ93NSnz9XJks3xGYJzTeiL_veVnsDSaxDu8ZFdHEVPo16uxLU-hsmj4s0fyM9xFzN4hx-UiPxvik4gd1Jnmc9SUizFAEblKfp8J6uv0jO8uK9U87eQcmxLgwe4e4cWYuG9Bfo0LKNJN9KGixwo7kDdYX4faDLD2Euj9kVzu-fNYIGhB_sZKGwbkBq-KRqFmZGmsOp3_rQfaA5heP1kg8mj0yn0wZrUub_iKLXdPsIEzPzAz-ng2c174YBOLU7HjWa3DMulAlKit1tcHZA6qNKn9OmW--5Ep8i51ocN50gFWjLTT3CpRFrDHQbbkImt1hoNtq-9Ay60tln1Wy5rz0M6Xk-ZpusBNkSUhGv-4VHHrbit3ftX75e6o1JOmt4mSnOamz1MoPaquxJLq6Kx9TMzzJp4FzETvs8pm7sUxgUKMz7l5AFL-sxc4v2UPwcLA5oezDzH6dG8ArrfV_bOXyzEWUfLy2Uj3Uq4T0RFGgMS1v3o0gD1X9eju0UyXKsJyoYqyq6zORNzHFpV9MjOBZP9Yg2wJ9RW8vHmDirPn7Bho5l5f0pu49HOn5pWv1lTLYskMG39j1m4Btltmah08rTIYRsf2UZCFyUrHpHKfmTHd76oBsotg6jnfhq21A8jxEjxpsk2nsyxym3YdVoR-CoF02K8PEElQfHmhuJoa6q2rN7BDhXfOaMGWCmjDBaRjE_G52eNid9t0TUSBPqG95ub46-hoBT0Z1TykOtARwzhWZpbWLrccUX__lFLlwzyJI_uns_Uacl3YSNhtM0dsxbP0FanbBjhWjcqE_qB_O0agvsjhoa1vWmZQdjbthoI3mcS7Zq65v5Kvt3ug1EhVNBKFPZYoKnQ-jKxYcTYQ9jzZ1386NDz0kO37svKfS6fQA1aofzyWrKmdrAbv0yKNubbEB4Ty7gEWUx7VAXq0jc9Db2QSl8TDHwhg3s_VcP5nEtUxHeCajAuh-sErqoxbg_JHjHi_e5vCddbYlLDDVY_aeX9TDT2XI_rWF4HjnyBLdN5Xg-Yhxi62t2O0KIPfNs');

  const handleCaptchaChange = (token) => {
    console.log("Captcha value:", token);
    setRecaptchaValue(token);
  };
  const switchFormHandle = () => {
    setswitchForm(!switchForm);
  };
  const switchFormHandleForgotPass = () => {
    setIsForgotPassword(false);
    setswitchForm(true);
  };
  const [isLoading, setisLoading] = useState(false);
  //const { isLoggedIn } = useSelector((state) => state.auth);
  const { message } = useSelector((state) => state.message);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearMessage());
  }, [dispatch]);

  const loginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Enter valid email id.")
      .required("Email id is required."),
    password: Yup.string()
      .required("Password is required.")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case (!@#$%^&*) Character"
      ),
  });
  const signupSchema = Yup.object().shape({
    firstName: Yup.string().required("First name is required."),
    lastName: Yup.string().required("Last name is required."),
    email: Yup.string()
      .email("Enter valid email id.")
      .required("Email id is required."),
    password: Yup.string()
      .required("Password is required.")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case (!@#$%^&*) Character"
      ),
    confirmPwd: Yup.string()
      .required("Password is required.")
      .oneOf([Yup.ref("password")], "Passwords does not match."),
  });
  const forgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email("Enter valid email id.")
      .required("Email id is required."),
  });
  //validation schema end
  const formOptionsLogin = { resolver: yupResolver(loginSchema) };
  const formOptions = { resolver: yupResolver(signupSchema) };
  const forgotOptions = { resolver: yupResolver(forgotPasswordSchema) };
  const {
    register,
    formState: { errors, isSubmitting },
    handleSubmit,
  } = useForm(formOptionsLogin);
  const {
    register: register2,
    formState: { errors: errors2 },
    handleSubmit: handleSubmit2,
  } = useForm(formOptions);
  const {
    register: register3,
    formState: { errors: errors3 },
    handleSubmit: handleSubmit3,
  } = useForm(forgotOptions);
  //login submit handler
  const onSubmit = (formValue) => {
   if (!recaptchaValue) {
     alert("Please complete the reCAPTCHA");
     return;
   }
    setisLoading(true);
    formValue.captcha = recaptchaValue;
    console.log(JSON.stringify(formValue))
    dispatch(login(formValue))
      .unwrap()
      .then(() => {
        setisLoading(false);
        dispatch(clearMessage());
      })
      .catch((error) => {
        setisLoading(false);
        dispatch(clearMessage());
      });
  };

  const onSubmitSignup = (formValue) => {
    setisLoading(true);
    //console.log(JSON.stringify(formValue));//print form data to console
    dispatch(signUp(formValue))
      .unwrap()
      .then((res) => {
        let userID = res.data.profile.id;
        let user_name = `${res.data.profile.first_name} ${res.data.profile.last_name}`;
        UserService.AddRootUserAfterSignUp(userID, { user_name }).then(
          (response) => {
            if (response.data.type == "success") {
              console.log("added");
            } else {
              console.log("added already");
            }
          },
          (error) => {
            //{ error && toast.error(error.response.data.message, { toastId: 2603453643 }) }
            const _content =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
          }
        );
        setisLoading(false);
        setswitchForm(true);
        dispatch(clearMessage());
      })
      .catch(() => {
        setisLoading(false);
        dispatch(clearMessage());
      });
  };
  const onSubmitForgotPassword = (formValue) => {
    setisLoading(true);
    console.log(JSON.stringify(formValue));
    UserService.forgotPasword(formValue.email)
      .then((res) => {
        setisLoading(false);
        if (res.data.error) {
          //error
          toast.error(
            "The email address isn't recongnized, Please try again or register for a new account.",
            { toastId: 23 }
          );
        } else {
          //success
          toast.success("Password reset link successfully sent!", {
            toastId: 23,
          });
        }
      })
      .catch((err) => {
        setisLoading(false);
        console.log(err);
      });
  };

  {
    message &&
      toast.info(message, {
        toastId: 23453643,
      });
  }
  //Handle forgot password
  const handleForgotPassword = () => {
    setIsForgotPassword(true);
  };
  return (
    <>
      <div>
        <Header />
        {/* MAin Navigation END    */}
        <section className="main-slider">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 col-sm-12">
                <div
                  id="carouselExampleSlidesOnly"
                  className="carousel slide"
                  data-ride="carousel"
                >
                  <div className="carousel-inner">
                    <div className="carousel-item active">
                      <img src="assets/images/slider/slider-4.jpeg" />
                    </div>
                    <div className="carousel-item">
                      <img src="assets/images/slider/slider-5.jpeg" />
                    </div>
                    <div className="carousel-item">
                      <img src="assets/images/slider/slider-6.jpeg" />
                    </div>

                    <a className="carousel-control-prev" href="#carouselExampleSlidesOnly" role="button" data-slide="prev">
            <span className="fa fa-long-arrow-left" aria-hidden="true" />
          </a>
          <a className="carousel-control-next" href="#carouselExampleSlidesOnly" role="button" data-slide="next">
            <span className="fa fa-long-arrow-right" aria-hidden="true" />
          </a>
                  </div>
                </div>
              </div>
              {isForgotPassword ? (
                <div className="col-lg-4 col-sm-12">
                  <div className="contact-form2 login_wraper">
                    <h4 className="text-uppercase">Forgot Password</h4>
                    <form onSubmit={handleSubmit3(onSubmitForgotPassword)}>
                      <div className="form-group">
                        <input
                          type="text"
                          {...register3("email")}
                          placeholder="Email address"
                          className={`form-control ${
                            errors3.email ? "is-invalid" : ""
                          }`}
                        />
                        <span style={{ color: "red" }}>
                          {errors3.email?.message}
                        </span>
                      </div>

                      {isLoading ? (
                        <button
                          style={{ width: "100%" }}
                          className="btn btn-primary"
                        >
                          Submit...{" "}
                          <div
                            className="spinner-border"
                            style={{ width: "1rem", height: "1rem" }}
                          />
                        </button>
                      ) : (
                        <button
                          style={{ width: "100%" }}
                          type="submit"
                          href="#"
                          className="btn btn-primary"
                        >
                          Submit
                        </button>
                      )}

                      <br />
                      <br />
                      <p>
                        <a href>
                          Already have account?{" "}
                          <Link
                            to="#"
                            onClick={() => switchFormHandleForgotPass()}
                            style={{ cursor: "pointer" }}
                          >
                            SignIn
                          </Link>
                        </a>
                      </p>
                    </form>
                  </div>
                </div>
              ) : switchForm ? (
                <div className="col-lg-4 col-sm-12">
                  <div className="contact-form2 login_wraper">
                    <h4 className="text-uppercase">Login to your Account</h4>
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="form-group">
                        <input
                          type="text"
                          {...register("email")}
                          placeholder="Email address"
                          className={`form-control ${
                            errors.email ? "is-invalid" : ""
                          }`}
                        />
                        <span style={{ color: "red" }}>
                          {errors.email?.message}
                        </span>
                      </div>
                      <div className="form-group">
                        <input
                          type="password"
                          {...register("password")}
                          placeholder="Password"
                          className={`form-control ${
                            errors.password ? "is-invalid" : ""
                          }`}
                        />
                        <span style={{ color: "red" }}>
                          {errors.password?.message}
                        </span>
                      </div>
                      {isLoading ? (
                        <button
                          style={{ width: "100%" }}
                          className="btn btn-primary"
                        >
                          Sign In...{" "}
                          <div
                            className="spinner-border"
                            style={{ width: "1rem", height: "1rem" }}
                          />
                        </button>
                      ) : (
                        <button
                          style={{ width: "100%" }}
                          type="submit"
                          className="btn btn-primary"
                        >
                          Sign In
                        </button>
                      )}

                      <div
                        className="forgot"
                        style={{ cursor: "pointer" }}
                        onClick={handleForgotPassword}
                      >
                        Forgot Password?
                      </div>
                      <ReCAPTCHA
                      sitekey={process.env.REACT_APP_SITE_KEY}
                      onChange={handleCaptchaChange}
                    />
                      <p>
                        Don't have account yet?{" "}
                        <Link
                          to="#"
                          onClick={() => switchFormHandle()}
                          style={{ cursor: "pointer" }}
                        >
                          Register
                        </Link>
                      </p>
                    </form>
                  </div>
                </div>
              ) : (
                <div className="col-lg-4 col-sm-12">
                  <div className="contact-form2 login_wraper">
                    <h4 className="text-uppercase">Create an account</h4>
                    <form onSubmit={handleSubmit2(onSubmitSignup)}>
                      <div className="form-group">
                        <input
                          type="text"
                          {...register2("firstName")}
                          placeholder="First name"
                          className={`form-control ${
                            errors2.firstName ? "is-invalid" : ""
                          }`}
                        />
                        <span style={{ color: "red" }}>
                          {errors2.firstName?.message}
                        </span>
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          {...register2("lastName")}
                          placeholder="Last name"
                          className={`form-control ${
                            errors2.lastName ? "is-invalid" : ""
                          }`}
                        />
                        <span style={{ color: "red" }}>
                          {errors2.lastName?.message}
                        </span>
                      </div>

                      <div className="form-group">
                        <input
                          type="text"
                          {...register2("email")}
                          placeholder="Email address"
                          className={`form-control ${
                            errors2.email ? "is-invalid" : ""
                          }`}
                        />
                        <span style={{ color: "red" }}>
                          {errors2.email?.message}
                        </span>
                      </div>
                      <div className="form-group">
                        <input
                          type="password"
                          {...register2("password")}
                          placeholder="Password"
                          className={`form-control ${
                            errors2.password ? "is-invalid" : ""
                          }`}
                        />
                        <span style={{ color: "red" }}>
                          {errors2.password?.message}
                        </span>
                      </div>
                      <div className="form-group">
                        <input
                          type="password"
                          {...register2("confirmPwd")}
                          placeholder="Confirm Password"
                          className={`form-control ${
                            errors2.confirmPwd ? "is-invalid" : ""
                          }`}
                        />
                        <span style={{ color: "red" }}>
                          {errors2.confirmPwd?.message}
                        </span>
                      </div>
                      {isLoading ? (
                        <button
                          style={{ width: "100%" }}
                          className="btn btn-primary"
                        >
                          Sign Up...{" "}
                          <div
                            className="spinner-border"
                            style={{ width: "1rem", height: "1rem" }}
                          />
                        </button>
                      ) : (
                        <button
                          style={{ width: "100%" }}
                          type="submit"
                          href="#"
                          className="btn btn-primary"
                        >
                          Sign Up
                        </button>
                      )}

                      <br />
                      <br />
                      <p>
                        <a href>
                          Already have account?{" "}
                          <Link
                            to="#"
                            onClick={() => switchFormHandle()}
                            style={{ cursor: "pointer" }}
                          >
                            SignIn
                          </Link>
                        </a>
                      </p>
                    </form>
                  </div>
                </div>
              )}
            </div>
          </div>
        </section>
        {/* Footer STYLES  */}
        <Footer />
        {/* FOOTER STYLES END */}
      </div>
    </>
  );
};

export default Portal;
