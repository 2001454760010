import UserService from "../../../services/user.service";
import React, { useState, useEffect, useRef } from "react";
import IconButton from "@mui/material/IconButton";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ReactApexChart from "react-apexcharts";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import Switch from "@mui/material/Switch";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import moment, { months } from "moment-timezone";
import { Card, CardContent, Grid } from "@mui/material";

const tzone = "Asia/Amman";

function formatDate(date) {
  date = new Date(date);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

export default function App(props) {
  const DeviceId = props.DeviceId;
  const userID = props.userID;
  const type = "line"; //props.type;
  const param = props.param;

  // console.log(props);
  const chartRef = useRef();
  const [isLoadingGraph, setisLoadingGraph] = useState(false);
  const [data, setData] = useState([]);
  const [data1, setData1] = useState([]);
  const [data2, setData2] = useState([]);
  const [data3, setData3] = useState([]);
  const [data4, setData4] = useState([]);
  const [data5, setData5] = useState([]);
  const [data6, setData6] = useState([]);
  const [DataAvailable, setDataAvailable] = useState(false);
  const [endDate, setendDate] = useState("");
  const [LastDataFront, setLastDataFront] = useState(0);
  const [FirstDataBack, setFirstDataBack] = useState(0);
  const [chartTitle, setChartTitle] = useState("chartTitle");
  const [childDevices, setChildDevices] = useState([]);
  const [Rt, setRt] = React.useState(true);
  const [format, setFormat] = React.useState("Monthly");
  const [parameter, setParameter] = React.useState(param);
  const [TimeFormat, setTtimeFormat] = React.useState("daily");

  const [chartConfig, setChartConfig] = useState({});

  function groupAndSumByFiveMinutes(data) {
    const groups = {};
    console.log("in groupAndSumByFiveMinutes");
    data = Object.values(data);
    data.forEach((item) => {
      const date = new Date(item.date_time_A);

      // Round down to the nearest 5-minute interval
      const minutes = date.getUTCMinutes();
      const roundedMinutes = Math.floor(minutes / 5) * 5;

      // Create a new Date object with rounded minutes
      const roundedDate = new Date(
        Date.UTC(
          date.getUTCFullYear(),
          date.getUTCMonth(),
          date.getUTCDate(),
          date.getUTCHours(),
          roundedMinutes
        )
      );

      const key = roundedDate.toISOString();

      // Initialize the group if it doesn't exist
      if (!groups[key]) {
        groups[key] = 0;
      }

      // Add the Power_Total_IEL_A value to the totalPower
      groups[key] += item.Power_Total_IEL_A;
    });

    // Convert the groups object to an array of objects with the required format
    const result = Object.keys(groups).map((key) => ({
      date_time_A: key,
      Power_Total_IEL_A: parseFloat(groups[key].toFixed(2)),
    }));

    return result;
  }

  function sumEnergyByDate2(data) {
    data = Object.values(data);
    // console.log("xx", data);

    // Step 1: Group the data by date_time_A
    const groupedData = data.reduce((acc, obj) => {
      const key = obj.date_time_A;
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(obj);
      return acc;
    }, {});

    // Step 2: Sum the Accumulated_Energy_Solis for each date
    const summedData = Object.keys(groupedData).map((date) => {
      const totalEnergy = groupedData[date].reduce(
        (sum, item) => sum + item.Accumulated_Energy_Solis,
        0
      );
      return { date_time_A: date, Accumulated_Energy_Solis: totalEnergy };
    });

    // Step 3: Calculate the difference from the previous day
    summedData.sort(
      (a, b) => new Date(a.date_time_A) - new Date(b.date_time_A)
    ); // Sort dates chronologically

    let previousTotal = 0;
    const result = summedData.map((entry, index) => {
      const difference = entry.Accumulated_Energy_Solis - previousTotal;
      previousTotal = entry.Accumulated_Energy_Solis;
      return {
        date_time_A: entry.date_time_A,
        Accumulated_Energy_Solis: entry.Accumulated_Energy_Solis,
        diff: index === 0 ? 0 : difference, // No difference for the first day
      };
    });

    // console.log("xx", result);
    return result;
  }
  function sumEnergyByDate(data) {
    data = Object.values(data);
    console.log("xx", data);

    // if(data.length == 1) return {...data,diff:data.Accumulated_Energy_Solis}
    // Step 1: Create a map to group objects by date_time_A
    const groupedByDate = data.reduce((acc, obj) => {
      if (!acc[obj.date_time_A]) {
        acc[obj.date_time_A] = [];
      }
      acc[obj.date_time_A].push(obj);
      return acc;
    }, {});

    console.log("groupedByDate", groupedByDate);
    // Step 2: Collect all unique id222 values
    const allIds = [...new Set(data.map((obj) => obj.id222))];

    // Step 3: Get an array of sorted dates
    const sortedDates = Object.keys(groupedByDate).sort(
      (a, b) => new Date(a) - new Date(b)
    );

    // Step 4: Ensure each group has all id222 values, look for the most recent value for missing id222
    const ensureAllIdsPresent = (groupedByDate, allIds, sortedDates) => {
      for (let i = 1; i < sortedDates.length; i++) {
        const currentDate = sortedDates[i];
        const previousDate = sortedDates[i - 1];
        const currentGroup = groupedByDate[currentDate];
        const previousGroup = groupedByDate[previousDate];

        const existingIds = currentGroup.map((obj) => obj.id222);
        const missingIds = allIds.filter((id) => !existingIds.includes(id));

        missingIds.forEach((missingId) => {
          // Find the most recent value of the missing id222 from the previous group
          const previousObject = previousGroup.find(
            (obj) => obj.id222 === missingId
          );
          if (previousObject) {
            const newObject = {
              id222: missingId,
              time: currentGroup[0].time,
              date_time_A: currentDate,
              Accumulated_Energy_Solis: previousObject.Accumulated_Energy_Solis,
            };
            currentGroup.push(newObject);
          }
        });
      }
    };

    ensureAllIdsPresent(groupedByDate, allIds, sortedDates);

    // Step 2: Sum the Accumulated_Energy_Solis for each date
    const summedData = Object.keys(groupedByDate).map((date) => {
      const totalEnergy = groupedByDate[date].reduce(
        (sum, item) => sum + item.Accumulated_Energy_Solis,
        0
      );
      return { date_time_A: date, Accumulated_Energy_Solis: totalEnergy };
    });

    // Step 3: Calculate the difference from the previous day
    summedData.sort(
      (a, b) => new Date(a.date_time_A) - new Date(b.date_time_A)
    ); // Sort dates chronologically

    let previousTotal = 0;
    const result = summedData.map((entry, index) => {
      const difference = entry.Accumulated_Energy_Solis - previousTotal;
      previousTotal = entry.Accumulated_Energy_Solis;
      return {
        date_time_A: entry.date_time_A,
        Accumulated_Energy_Solis: entry.Accumulated_Energy_Solis,
        diff: index === 0 ? entry.Accumulated_Energy_Solis : difference, // No difference for the first day
      };
    });

    // console.log("xxr", result);
    if (result.length === 1) return result;
    else return result.slice(1);
    // return result.slice(1);
  }
  function getChartData(powerDataFromDB, field) {
    let dNotNull = 0;
    return Object.values(powerDataFromDB).map((x) => {
      let t, d;
      t = x.date_time_A;
      d = x[field];
      if (d != null && d != 0 && d != -127) dNotNull = d;
      d = d === null ? dNotNull : d;
      d = d === -127 ? dNotNull : d;
      d = d === 0 ? dNotNull : d;
      return [t, d];
    });
  }

  const handleChangeFormat = (event) => {
    setTtimeFormat(event.target.value);
    console.log(event.target.value);
    // handleApply();
  };

  function getData(DeviceId, startTime, endTime) {
    let fields;
    if (TimeFormat == "monthly") {
      const diffInMonths = moment(endTime).diff(moment(startTime), "months");

      console.log(
        `The difference between the two dates is ${diffInMonths} months.`
      );
      if (diffInMonths < 4)
        startTime = moment(startTime)
          .subtract("2", "months")
          .format("YYYY-MM-DD HH:mm:ss");
    }
    fields = ["diff"];

    setisLoadingGraph(true);
    let localUnit;
    console.log(" get data power");
    localUnit = "KW";
    UserService.getVIrtualDeviceMoroccoData({
      id: DeviceId,
      type: "Energy", // process_process , power , energy
      format: TimeFormat,
      startDate: startTime, //`2024-05-15 00:00:00`,
      endDate: endTime, //`2024-05-16 00:00:00`,
    })
      .then((res) => {
        let powerDataFromDB = res.data.data.deviceData;
        console.log("GEt Data!!!!!!!!!", powerDataFromDB);
        let myData;
        let myData1;
        let myData2;
        let myData3;
        let myData4;
        let myData5;
        let myData6;
        if (typeof powerDataFromDB != "undefined" && !res.data.data.error) {
          myData = getChartData(sumEnergyByDate(powerDataFromDB), fields[0]);
          myData1 = getChartData(sumEnergyByDate(powerDataFromDB), fields[1]);
          myData2 = getChartData(sumEnergyByDate(powerDataFromDB), fields[2]);
          myData3 = getChartData(sumEnergyByDate(powerDataFromDB), fields[3]);
          myData4 = getChartData(sumEnergyByDate(powerDataFromDB), fields[4]);
          myData4 = getChartData(sumEnergyByDate(powerDataFromDB), fields[5]);
          myData4 = getChartData(sumEnergyByDate(powerDataFromDB), fields[6]);
          console.log(myData);
        } else {
          myData = [];
        }
        if (myData.length > 0) {
          console.log(myData);
          setLastDataFront(myData.at(-1));
          setFirstDataBack(myData.at(0));
          setendDate(endTime);

          setData(myData);
          setData1(myData1);
          setData2(myData2);
          setData3(myData3);
          setData4(myData4);

          setChartConfig({
            chart: {
              id: "total-power-chart",
              // type: "line",
              // stacked: true,
              height: 500,
              foreColor: "#000000",
              zoom: {
                type: "x",
                enabled: true,
                autoScaleYaxis: true,
              },
              toolbar: {
                autoSelected: "zoom",
              },
            },
            tooltip: {
              enabled: true,
              color: "#000000",
              style: {
                fontSize: "16px",
                // fontFamily: undefined
              },
              // shared: true,
              x: {
                format: "dd MMM yyyy hh:mm TT",
              },
              y: [
                {
                  formatter: function (y) {
                    if (typeof y !== "undefined") {
                      return y.toFixed(2) + " " + localUnit;
                    }
                    return y;
                  },
                },
                {
                  formatter: function (y) {
                    if (typeof y !== "undefined") {
                      return y.toFixed(2) + " " + localUnit;
                    }
                    return y;
                  },
                },
                {
                  formatter: function (y) {
                    if (typeof y !== "undefined") {
                      return y.toFixed(2) + " " + localUnit;
                    }
                    return y;
                  },
                },
                {
                  formatter: function (y) {
                    if (typeof y !== "undefined") {
                      return y.toFixed(2) + " " + localUnit;
                    }
                    return y;
                  },
                },
              ],
            },
            dataLabels: {
              enabled: false,
            },
            markers: {
              size: 0,
            },
            xaxis: {
              type: "datetime",
              tickAmount: myData.length - 1,
              tooltip: {
                enabled: false,
              },
              labels: {
                rotate: -90,
                rotateAlways: true,
                minHeight: 100,
                maxHeight: 160,
                show: true,
                align: "left",
                // trim: true,
                formatter: function (val, timestamp) {
                  if (TimeFormat === "hourly")
                    return moment(timestamp).format("HH:00");
                  if (TimeFormat === "daily")
                    return moment(timestamp).format("DD MMM");
                  if (TimeFormat === "monthly")
                    return moment(timestamp).format("MMM YYYY");
                },
                style: {
                  colors: ["#000000"],
                  fontSize: "15px",
                  // fontFamily: 'Helvetica, Arial, sans-serif',
                  fontWeight: 600,
                },
              },
            },
            stroke: {
              width: 2,
            },
            colors: ["#64006C", "#0054F1", "#00FF00", "#FFD93D"],
            yaxis: {
              opposite: false,
              formatter: (value) => {
                return value;
              },
              min: 0,
              decimalsInFloat: 2,
              forceNiceScale: true,
              axisBorder: {
                show: true,
                color: "#78909C",
                offsetX: 0,
                offsetY: 0,
              },
              axisTicks: {
                show: true,
                borderType: "solid",
                color: "#78909C",
                width: 6,
                offsetX: 0,
                offsetY: 0,
              },
              labels: {
                show: true,
                align: "right",
                minWidth: 0,
                maxWidth: 160,
                style: {
                  colors: [],
                  fontSize: "15px",
                  // fontFamily: 'Helvetica, Arial, sans-serif',
                  fontWeight: 600,
                },
                offsetX: 0,
                offsetY: 0,
                rotate: 0,
                // formatter: (value) => { return val },
              },
              crosshairs: {
                show: true,
                position: "back",
                stroke: {
                  color: "#b6b6b6",
                  width: 0.6,
                  dashArray: [0, 1, 2],
                },
              },
              tooltip: {
                enabled: false,
                offsetX: 0,
              },
              title: {
                text: "[" + localUnit + "]",
                rotate: 0,
                offsetX: 40,
                offsetY: -170,
                style: {
                  color: undefined,
                  fontSize: "15px",
                  // fontFamily: 'Helvetica, Arial, sans-serif',
                  fontWeight: 600,
                  // cssClass: 'apexcharts-yaxis-title',
                },
              },
            },
            legend: {
              showForSingleSeries: true,
              offsetX: 7,
              //  offsetY:5,
              position: "top",
              fontSize: "18px",
              //  width:1
              //  fontWeight: 800,
              markers: {
                width: 12,
                height: 12,
                strokeWidth: 2,
                strokeColor: "#fff",
                fillColors: undefined,
                radius: 12,
                offsetX: -5,
                // offsetY: 5
              },
            },
          });

        } else {
          setDataAvailable(false);
        }
        setisLoadingGraph(false);
      })
      .catch((err) => {
        setisLoadingGraph(false);
        setDataAvailable(false);
      });
  }

  const handleApply = (event, picker) => {
    setRt(false);

    console.log("handle Apply");
    let startDateObj = picker.startDate._d;
    let endDateObj = picker.endDate._d;
    let startTime = moment(startDateObj).format("YYYY-MM-DD HH:mm:ss");
    let endTime = moment(endDateObj).format("YYYY-MM-DD 23:59:59");
    console.log("startDateObjDate", startTime);
    console.log("endDateObjDate", endTime);

    getData(DeviceId, startTime, endTime);
  };

  function handleBackIClick() {
    setRt(false);
    setRt(false);
    console.log("back click");
    getDataBack(5);
  }
  function handleForwardClick() {
    setRt(false);
    console.log("back click");
    getDataFront(5);
  }

  function getDataBack(interval) {
    let fields = ["Power_Total_IEL_A"];

    let startTime = moment(FirstDataBack[0])
      .subtract(interval, "days")
      .format("YYYY-MM-DD HH:mm:ss");
    let endTime = FirstDataBack[0];
    console.log(startTime, endTime);

    setisLoadingGraph(true);
    let localUnit;
    // console.log(" get data power");
    localUnit = "KW";
    UserService.getVIrtualDeviceMoroccoData({
      id: DeviceId,
      type: "Power", // process_process , power , energy
      format: TimeFormat,
      startDate: startTime, //`2024-05-15 00:00:00`,
      endDate: endTime, //`2024-05-16 00:00:00`,
    })
      .then((res) => {
        let powerDataFromDB = res.data.data.deviceData;
        // console.log("Data Back!!!!!!!!!", powerDataFromDB);
        let myData;
        let myData1;
        let myData2;
        let myData3;
        let myData4;
        let myData5;
        let myData6;
        if (typeof powerDataFromDB != "undefined" && !res.data.data.error) {
          myData = getChartData(sumEnergyByDate(powerDataFromDB), fields[0]);
          myData1 = getChartData(sumEnergyByDate(powerDataFromDB), fields[1]);
          myData2 = getChartData(sumEnergyByDate(powerDataFromDB), fields[2]);
          myData3 = getChartData(sumEnergyByDate(powerDataFromDB), fields[3]);
          myData4 = getChartData(sumEnergyByDate(powerDataFromDB), fields[4]);
          myData5 = getChartData(sumEnergyByDate(powerDataFromDB), fields[5]);
          myData6 = getChartData(sumEnergyByDate(powerDataFromDB), fields[6]);
        } else {
          myData = [];
          console.log("????");
        }
        if (myData.length > 0) {
          // console.log(myData);

          setData((data) => myData.concat(data));
          setData1((data) => myData1.concat(data));
          setData2((data) => myData2.concat(data));
          setData3((data) => myData3.concat(data));
          setData4((data) => myData4.concat(data));
          setData5((data) => myData5.concat(data));
          setData6((data) => myData6.concat(data));

          setLastDataFront(myData.at(-1));
          setFirstDataBack(myData.at(0));
          setendDate(endTime);
          // setData1(myData1);
          // setData2(myData2);
        } else {
          setDataAvailable(false);
        }
        setisLoadingGraph(false);
      })
      .catch((err) => {
        setisLoadingGraph(false);
        setDataAvailable(false);
      });
  }

  function getDataFront(interval) {
    let fields = ["Power_Total_IEL_A"];

    console.log(LastDataFront[0], FirstDataBack[0]);
    let endTime = moment(LastDataFront[0])
      .add(interval, "days")
      .format("YYYY-MM-DD HH:mm:ss");
    let startTime = FirstDataBack[0];

    console.log(startTime, endTime);

    setisLoadingGraph(true);
    let localUnit;

    // console.log(" get data power");
    localUnit = "KW";
    UserService.getVIrtualDeviceMoroccoData({
      id: DeviceId,
      type: "Power", // process_process , power , energy
      format: TimeFormat,
      startDate: startTime, //`2024-05-15 00:00:00`,
      endDate: endTime, //`2024-05-16 00:00:00`,
    })
      .then((res) => {
        let powerDataFromDB = res.data.data.deviceData;
        // console.log("Data Back!!!!!!!!!", powerDataFromDB);
        let myData;
        let myData1;
        let myData2;
        let myData3;
        let myData4;
        let myData5;
        let myData6;
        if (typeof powerDataFromDB != "undefined" && !res.data.data.error) {
          myData = getChartData(sumEnergyByDate(powerDataFromDB), fields[0]);
          myData1 = getChartData(sumEnergyByDate(powerDataFromDB), fields[1]);
          myData2 = getChartData(sumEnergyByDate(powerDataFromDB), fields[2]);
          myData3 = getChartData(sumEnergyByDate(powerDataFromDB), fields[3]);
          myData4 = getChartData(sumEnergyByDate(powerDataFromDB), fields[4]);
          myData5 = getChartData(sumEnergyByDate(powerDataFromDB), fields[5]);
          myData6 = getChartData(sumEnergyByDate(powerDataFromDB), fields[6]);
        } else {
          myData = [];
          console.log("????");
        }
        if (myData.length > 0) {
          // console.log(myData);

          setData((data) => data.concat(myData));
          setData1((data) => data.concat(myData1));
          setData2((data) => data.concat(myData2));

          setLastDataFront(myData.at(-1));
          setFirstDataBack(myData.at(0));
          setendDate(endTime);
          // setData1(myData1);
          // setData2(myData2);

                      setChartConfig({
              chart: {
                id: "total-power-chart",
                // type: "line",
                // stacked: true,
                height: 500,
                foreColor: "#000000",
                zoom: {
                  type: "x",
                  enabled: true,
                  autoScaleYaxis: true,
                },
                toolbar: {
                  autoSelected: "zoom",
                },
              },
              tooltip: {
                enabled: true,
                color: "#000000",
                style: {
                  fontSize: "16px",
                  // fontFamily: undefined
                },
                // shared: true,
                x: {
                  format: "dd MMM yyyy hh:mm TT",
                },
                y: [
                  {
                    formatter: function (y) {
                      if (typeof y !== "undefined") {
                        return y.toFixed(2) + " " + localUnit;
                      }
                      return y;
                    },
                  },
                  {
                    formatter: function (y) {
                      if (typeof y !== "undefined") {
                        return y.toFixed(2) + " " + localUnit;
                      }
                      return y;
                    },
                  },
                  {
                    formatter: function (y) {
                      if (typeof y !== "undefined") {
                        return y.toFixed(2) + " " + localUnit;
                      }
                      return y;
                    },
                  },
                  {
                    formatter: function (y) {
                      if (typeof y !== "undefined") {
                        return y.toFixed(2) + " " + localUnit;
                      }
                      return y;
                    },
                  },
                ],
              },
              dataLabels: {
                enabled: false,
              },
              markers: {
                size: 0,
              },
              xaxis: {
                type: "datetime",
                tickAmount: myData.length - 1,
                tooltip: {
                  enabled: false,
                },
                labels: {
                  rotate: -90,
                  rotateAlways: true,
                  minHeight: 100,
                  maxHeight: 160,
                  show: true,
                  align: "left",
                  // trim: true,
                  formatter: function (val, timestamp) {
                    if (TimeFormat === "hourly")
                      return moment(timestamp).format("HH:00");
                    if (TimeFormat === "daily")
                      return moment(timestamp).format("DD MMM");
                    if (TimeFormat === "monthly")
                      return moment(timestamp).format("MMM YYYY");
                  },
                  style: {
                    colors: ["#000000"],
                    fontSize: "15px",
                    // fontFamily: 'Helvetica, Arial, sans-serif',
                    fontWeight: 600,
                  },
                },
              },
              stroke: {
                width: 2,
              },
              colors: ["#64006C", "#0054F1", "#00FF00", "#FFD93D"],
              yaxis: {
                opposite: false,
                formatter: (value) => {
                  return value;
                },
                min: 0,
                decimalsInFloat: 2,
                forceNiceScale: true,
                axisBorder: {
                  show: true,
                  color: "#78909C",
                  offsetX: 0,
                  offsetY: 0,
                },
                axisTicks: {
                  show: true,
                  borderType: "solid",
                  color: "#78909C",
                  width: 6,
                  offsetX: 0,
                  offsetY: 0,
                },
                labels: {
                  show: true,
                  align: "right",
                  minWidth: 0,
                  maxWidth: 160,
                  style: {
                    colors: [],
                    fontSize: "15px",
                    // fontFamily: 'Helvetica, Arial, sans-serif',
                    fontWeight: 600,
                  },
                  offsetX: 0,
                  offsetY: 0,
                  rotate: 0,
                  // formatter: (value) => { return val },
                },
                crosshairs: {
                  show: true,
                  position: "back",
                  stroke: {
                    color: "#b6b6b6",
                    width: 0.6,
                    dashArray: [0, 1, 2],
                  },
                },
                tooltip: {
                  enabled: false,
                  offsetX: 0,
                },
                title: {
                  text: "[" + localUnit + "]",
                  rotate: 0,
                  offsetX: 40,
                  offsetY: -170,
                  style: {
                    color: undefined,
                    fontSize: "15px",
                    // fontFamily: 'Helvetica, Arial, sans-serif',
                    fontWeight: 600,
                    // cssClass: 'apexcharts-yaxis-title',
                  },
                },
              },
              legend: {
                showForSingleSeries: true,
                offsetX: 7,
                //  offsetY:5,
                position: "top",
                fontSize: "18px",
                //  width:1
                //  fontWeight: 800,
                markers: {
                  width: 12,
                  height: 12,
                  strokeWidth: 2,
                  strokeColor: "#fff",
                  fillColors: undefined,
                  radius: 12,
                  offsetX: -5,
                  // offsetY: 5
                },
              },
            });
        } else {
          setDataAvailable(false);
        }
        setisLoadingGraph(false);
      })
      .catch((err) => {
        setisLoadingGraph(false);
        setDataAvailable(false);
      });
  }

  useEffect(() => {
    let endTime = moment().format("YYYY-MM-DD HH:mm:ss");
    let startTime = moment().subtract(5, "days").format("YYYY-MM-DD HH:mm:ss");
    console.log("loading power compo");
    getData(DeviceId, startTime, endTime);
  }, [DeviceId, TimeFormat]);

  useEffect(() => {
    const interval = setInterval(() => {
      console.log("Refsh 30 second");
      let endTime = moment().format("YYYY-MM-DD HH:mm:ss");
      let startTime = moment()
        .subtract(5, "days")
        .format("YYYY-MM-DD HH:mm:ss");
      if (Rt) getData(DeviceId, startTime, endTime);
    }, 30 * 1000);
    return () => clearInterval(interval);
  }, [DeviceId, Rt, TimeFormat]);

  return (
    <>
      <div className="col-lg-12 col-sm-12">
        <Card
          className="row"
          sx={{
            "border-radius": "1% ",

            boxShadow: 2,
            margin: "",
            ":hover": {
              boxShadow: 5,
            },
            padding: "1%",
          }}
        >
          <div className="col-lg-2 col-sm-12">
            <Typography> Real-Time</Typography>
          </div>
          <div className="col-lg-2 col-sm-12">
            {" "}
            <Switch
              checked={Rt}
              onChange={() => setRt(!Rt)}
              name="Real Time"
              // color="primary"
            />
          </div>
          <div className="col-lg-2 col-sm-12">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Format</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={TimeFormat}
                label="Format"
                onChange={handleChangeFormat}
              >
                <MenuItem value={"daily"}>Daily</MenuItem>
                <MenuItem value={"monthly"}>Monthly</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-lg-1 col-sm-12">
            <IconButton
              color="primary"
              aria-label="ArrowBackIosIcon"
              onClick={handleBackIClick}
            >
              <ArrowBackIosIcon />
            </IconButton>
          </div>
          <div className="col-lg-4 col-sm-12">
            <DateRangePicker
              // key={keyRef.current}
              // onCallback={handleCallback}
              onApply={handleApply}
              // initialSettings={isInitialDateData}
            >
              <input
                // value={formatDate(FirstDataBack)}
                type="text"
                className="form-control"
                placeholder="Select date range"
                style={{
                  fontSize: 12,
                  border: "1px solid #46acad",
                  borderRadius: 25,
                }}
                value={FirstDataBack[0] + " " + LastDataFront[0]}
              />
            </DateRangePicker>
          </div>
          <div className="col-lg-1 col-sm-12">
            <IconButton
              color="primary"
              aria-label="ArrowForwardIosIcon"
              onClick={handleForwardClick}
            >
              <ArrowForwardIosIcon />
            </IconButton>
          </div>
          <div className="col-lg-12 col-sm-12"><br></br></div>
          <div className="col-lg-12 col-sm-12">
            <ReactApexChart
              height={450}
              ref={chartRef}
              options={chartConfig}
              series={[{ name: "Power_Total_IEL_A", data: data }]}
              type="bar"
            />
          </div>
        </Card>
      </div>
    </>
  );
}
