import React, { Fragment } from "react";
import { Text, View, StyleSheet, Image } from "@react-pdf/renderer";
import moment from "moment-timezone";
import logo from "./logo.png";

const borderColor = "#90e5fc";
const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    alignItems: "center",
    height: 24,
    fontStyle: "bold",
  },
  description: {
    width: "25%",
    textAlign: "left",
    // paddingLeft: 8,
    fontWeight: "normal",
  },
  qty: {
    width: "25%",
    textAlign: "left",
    // paddingRight: 8,
  },
  rate: {
    width: "25%",
    textAlign: "left",
    // paddingRight: 8,
    fontWeight: "extrabold",
  },
  start: {
    width: "8%",
    textAlign: "left",
    // paddingRight: 8,
  },
  end: {
    width: "17%",
    textAlign: "left",
    // paddingRight: 8,
  },
  // reportTitle: {
  //   width: "50%",
  //   textAlign: "left",
  //   // paddingRight: 8,
  // },
  logo: {
    width: 180,
    height: 39,
    // marginLeft: "auto",
    // marginRight: "auto",
    // maxWidth: "100%",
    // maxHeight: "100%",
    // width: "50%",
    alignItems: "right",
    alignContent: "right",
  },
  reportTitle: {
    // color: '#61dafb',
    // letterSpacing: 4,
    fontSize: 15,
    textAlign: "left",
    textTransform: "uppercase",
    width: "50%",
    background: "red",
    borderRadius: "50%",
    // backgroundColor: '#61dafb',
    // backgroundColor :"blue"
  },
});

const ClientDetail = ({ invoice, statementType }) => {
  return (
    <Fragment>
      <View style={styles.row}>
        {statementType == "statement" ? (
          <Text style={styles.reportTitle}>Energy Statement Report</Text>
        ) : (
          <Text style={styles.reportTitle}>Bill Statement</Text>
        )}
        <Text
          style={{
            fontSize: 15,
            textAlign: "left",
            textTransform: "uppercase",
            width: "10%",
          }}
        >
          {" "}
        </Text>

        <Image style={styles.logo} src={logo} />
      </View>

      <View style={styles.row}>
        <Text style={styles.reportTitle}> </Text>
        <Text style={styles.reportTitle}> </Text>
        <Text style={styles.reportTitle}> </Text>
      </View>

      <View style={styles.row}>
        <Text style={styles.reportTitle}></Text>
        {/* <Image style={styles.logo} src={logo} /> */}
      </View>
      <View style={styles.row}>
        <Text style={styles.description}>Account Number: </Text>
        <Text style={styles.qty}>{invoice.device_id}</Text>
        <Text style={styles.rate}>Statement Date:</Text>
        <Text style={styles.amount}>{moment().format("DD-MMM-YYYY")}</Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.description}>Customer:</Text>
        <Text style={styles.qty}>{invoice.Customer} </Text>
        <Text style={styles.start}>Start:</Text>
        <Text style={styles.end}>{invoice.start}</Text>
        <Text style={styles.start}>End:</Text>
        <Text style={styles.end}>{invoice.end}</Text>
      </View>
      {statementType == "statement" ? null : (
        <View style={styles.row}>
          {" "}
          <Text style={styles.description}>Tarrif Rate:</Text>
          <Text style={styles.qty}>{invoice.TariffRate} $ </Text>
        </View>
      )}
    </Fragment>
  );
};

export default ClientDetail;
