import React, { useState, useEffect, Fragment } from "react";
import IconButton from "@mui/material/IconButton";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import ToggleButton from "@mui/material/ToggleButton";
import Button from "@mui/material/Button";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment-timezone";
import { useSelector } from "react-redux";
import UserService from "../../services/user.service";
import { PDFDownloadLink, pdf } from "@react-pdf/renderer";
import { PDFViewer } from "@react-pdf/renderer";
import { Modal } from "react-responsive-modal";
import TextField from "@mui/material/TextField";
// import "bootstrap-daterangepicker/daterangepicker.css";
import DatePicker from "react-datepicker";
import Invoice from "./Invoice/Invoice";
import "../../App.css";
import "react-datepicker/dist/react-datepicker.min.css";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import Input from "@mui/material/Input";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { saveAs } from "file-saver";
const LabelStyle = { color: "black", fontWeight: "bold", textAlign: "left" };
const DataLabelStyle = {
  color: "blue",
  "font-weight": "bold",
  textAlign: "left",
};

function BillingView(props) {
  const { device_id, userID, StatementName, devicename } = props;
  const { user } = useSelector((state) => state.auth);
  console.log(user.data.profile);
  console.log(props);

  const [statementType, SetStatementType] = useState("report");
  const [actionType, SetActionType] = useState("print");
  const [startDate, setstartDate] = useState(moment());
  const [startDate2, setstartDate2] = useState(new Date());
  const [endDate, setendDate] = useState(moment());
  const [endDate2, setendDate2] = useState(new Date());
  const [docName, setDocName] = useState("");
  const [open, setOpen] = useState(false);
  const [name, setName] = React.useState(StatementName);
  const [TariffRate, setTariffRate] = useState(0.99);
  const [Quarter, setQuarter] = React.useState("Q1");
  const [Year, setYear] = React.useState("2024");
  const [invoice, setInvoice] = useState({
    id: "/",
    invoice_no: "/",
    balance: "/",
    company: "/",
    email: "/",
    phone: "/",
    address: "/",
    trans_date: "/",
    due_date: "/",
    items: [],
  });

  const handleChangeQuarter = (event) => {
    setQuarter(event.target.value);
    console.log(event.target.value);
    // handleApply();
  };

  const handleChangeYear = (event) => {
    setYear(event.target.value);
    console.log(event.target.value);
    // handleApply();
  };

  function handlePdfClick() {
    console.log("Selected :", Quarter, Year);
    if (Quarter == "Q1") {
      console.log("QUarter 1");
      var start = moment(Year + "-01-01T00:00:00").format(
        "YYYY-MM-DD  00:00:00"
      );
      console.log("startDate", start);

      var end = moment(Year + "-03-31T00:00:00").format("YYYY-MM-DD  23:59:59");
      console.log("endDate", end);
    }

    if (Quarter == "Q2") {
      console.log("QUarter 2");
      var start = moment(Year + "-04-01T00:00:00").format(
        "YYYY-MM-DD  00:00:00"
      );
      console.log("startDate", start);

      var end = moment(Year + "-06-30T00:00:00").format("YYYY-MM-DD  23:59:59");
      console.log("endDate", end);
    }

    if (Quarter == "Q3") {
      console.log("QUarter 3");
      var start = moment(Year + "-07-01T00:00:00").format(
        "YYYY-MM-DD  00:00:00"
      );
      console.log("startDate", start);

      var end = moment(Year + "-09-30T00:00:00").format("YYYY-MM-DD  23:59:59");
      console.log("endDate", end);
    }

    if (Quarter == "Q4") {
      console.log("QUarter 4");
      var start = moment(Year + "-01-01T00:00:00").format(
        "YYYY-MM-DD  00:00:00"
      );
      console.log("startDate", start);

      var end = moment(Year + "-10-01T00:00:00").format("YYYY-MM-DD  23:59:59");
      console.log("endDate", end);
    }
    setstartDate(start);
    setendDate(end);
    // start = moment(startDate2)
    //   .startOf("month")
    //   .format("YYYY-MM-DD  00:00:00");
    // console.log("startDate", start);

    // end = moment(endDate2).endOf("month").format("YYYY-MM-DD  23:59:59");
    // console.log("endDate", end);

    //  Start
    const data = {
      statement_name: name,
    };

    // UserService.GetAccessProfileById(3)
    // UserService.EditAccessProfile(2,{      "Zero_Total_Power":1  })
    UserService.CreateAccessProfile({      "Power_Load":1  })
      .then((res) => {
        console.log("GetAllAccessProfile : ",res);
      })
      .catch((error) => {
        console.log(error);
      });

    UserService.updateStatementName(userID, data)
      .then((res) => {
        ///localStorage.setItem("user", JSON.stringify(updateUserData));
        // toast.success()
        // toast.success("Statement Name Updated", { toastId: 23453643 })
        // console.log(res)
      })
      .catch((error) => {
        // setisLoading(false)
        // { error && toast.info(error.response.data.message, { toastId: 234536467686787 }) }
        // console.log(error)
      });

    //  End
    getData(start, end, device_id);
  }

  const onCloseModal = () => setOpen(false);

  const handleChangeStatementType = (event, type) => {
    console.log(type);
    SetStatementType(type);
  };
  const handleChangeActionType = (event, action) => {
    SetActionType(action);
  };

  const handleChangeName = (event) => {
    setName(event.target.value);
    //Update DB with new Statement Name
    const data = {
      StatementName: event.target.value,
      email: user.data.profile.email,
    };
    console.log(userID, data);
  };

  function getData(QueryStarDate, QueryendDate, device_id) {
    console.log("getData", QueryStarDate, QueryendDate, device_id);
    UserService.GetStandardData(
      "IEL_MOR_ARCHIVED",
      device_id, //device_id,
      "Load_Power",
      "daterange",
      QueryStarDate,
      QueryendDate,
      0,
      "monthly"
    )
      .then((res) => {
        let powerDataFromDB = res.data.data.deviceData;
        console.log("billing", powerDataFromDB);
        let myData;
        if (typeof powerDataFromDB != "undefined" && !res.data.data.error) {
          myData = Object.keys(powerDataFromDB).map((key) => {
            return [
              powerDataFromDB[key].time2,
              powerDataFromDB[key]["Accumulated_Energy_Solis"],
            ];
          });
        } else {
          myData = [];
        }
        if (myData.length > 0) {
          console.log(myData);
          let TableData = myData.map((x) => [
            moment(x[0]).format("01-MMM-YYYY"),
            moment(x[0]).endOf("month").format("DD-MMM-YYYY"),
            x[1],
          ]);
          console.log(TableData);
          // setDocTable(TableData);
          TableData = TableData.map((x, i) => {
            return {
              sno: i,
              from: x[0],
              to: x[1],
              kwhr: x[2],
            };
          });
          console.table(TableData);

          setInvoice({
            id: "5df3180a09ea16dc4b95f910",
            invoice_no: "201906-28",
            balance: "$2,283.74",
            company: "MANTRIX",
            email: "susanafuentes@mantrix.com",
            phone: "+1 (872) 588-3809",
            address: "922 Campus Road, Drytown, Wisconsin, 1986",
            trans_date: "2019-09-12",
            due_date: "2019-10-12",
            start: QueryStarDate,
            end: QueryendDate,
            TariffRate: TariffRate,
            device_id,
            devicename,
            items: TableData,
            Customer: name,
            startDate,
            endDate,
          });

          {
            statementType == "statement"
              ? setDocName(
                  "Energy Statement Report  " +
                    device_id +
                    "_" +
                    moment().format("DD-MM-YY")
                )
              : setDocName(
                  "Bill Statement  " +
                    device_id +
                    "_" +
                    moment().format("DD-MM-YY")
                );
          }
          // console.log(invoice);
          setOpen(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    console.log("do Action", actionType);
    // handlePdfClick();
  }, [statementType]);

  const saveFile = () => {
    // This does the trick!
    pdf(<Invoice invoice={invoice} statementType={statementType} />)
      .toBlob()
      .then((blob) => saveAs(blob, docName + ".pdf"));
  };

  return (
    <Fragment>
      <div className="container">
        <h2 style={{ textAlign: "center", margin: 20, color: "#5a5757" }}>
          Energy Statement Report
        </h2>
        <div
          className="row control-row"
          style={{
            marginTop: 13,
          }}
        >
          <div className="col-12">
            <div className="row justify-content-start">
              <div className="col-md-3" style={LabelStyle}>
                Account Number:
              </div>
              <div className="col-md-3" style={DataLabelStyle}>
                {devicename}
              </div>
              <div className="col-md-3" style={LabelStyle}>
                Statement Date:
              </div>
              <div className="col-md-3" style={DataLabelStyle}>
                {moment().format("DD/MM/YY")}
              </div>
            </div>
          </div>

          {/* <div className="col-12">
            <div className="row justify-content-start align-items-center">
              <div className="col-md-3 " style={LabelStyle}>
                Customer:
              </div>
              <div className="col-md-3 " style={DataLabelStyle}>

                <TextField value={name} onChange={handleChangeName} />
              </div>
            </div>
          </div> */}

          <div className="col-12">
            <div className="row justify-content-start align-items-center">
              <div className="col-md-1" style={LabelStyle}>
                Quarter:
              </div>
              <div className="col-md-4" style={{ DataLabelStyle }}>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={Quarter}
                  label="Format"
                  onChange={handleChangeQuarter}
                  style={{ width: "80%" }}
                >
                  <MenuItem value={"Q1"}>Quarter 1</MenuItem>
                  <MenuItem value={"Q2"}>Quarter 2</MenuItem>
                  <MenuItem value={"Q3"}>Quarter 3</MenuItem>
                  <MenuItem value={"Q4"}>Quarter 4</MenuItem>
                </Select>
              </div>
              <div className="col-md-1" style={LabelStyle}>
                Year:
              </div>
              <div className="col-md-4" style={{ DataLabelStyle }}>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={Year}
                  label="Format"
                  onChange={handleChangeYear}
                  style={{ width: "80%" }}
                >
                  <MenuItem value={"2024"}>2024</MenuItem>
                  <MenuItem value={"2025"}>2025</MenuItem>
                  <MenuItem value={"2026"}>2026</MenuItem>
                  <MenuItem value={"2027"}>2027</MenuItem>
                </Select>
              </div>
            </div>
          </div>

          <div className="col-12">
            <div className="row justify-content-start align-items-center">
              <div className="col-md-3 align-items-center" style={LabelStyle}>
                Statement Type
              </div>
              {/* <div className="col-md-3" style={LabelStyle}>
                Bill Statement
              </div>
              <div className="col-md-3" style={LabelStyle}>
                Energy Report
              </div> */}

              <div className="col-md-6" style={LabelStyle}>
                <ToggleButtonGroup
                  value={statementType}
                  exclusive
                  onChange={handleChangeStatementType}
                  aria-label="Platform"
                >
                  <ToggleButton value="statement">Energy Report</ToggleButton>
                  <ToggleButton value="report">Bill Statement</ToggleButton>
                </ToggleButtonGroup>
              </div>

              <div className="col-md-3" style={LabelStyle}>
                {/* <IconButton style={{'fontSize': "xx-large"}} size="large" onClick={handlePdfClick}>
                  <PictureAsPdfIcon style={{'fontSize': "xx-large",color:"red"}} />
                </IconButton> */}

                <Button variant="contained" onClick={handlePdfClick}>
                  Print PDF
                </Button>
                {/* <IconButton color="primary" onClick={handlePrintClick}>
                  <LocalPrintshopIcon />
                </IconButton> */}
                {/* <ToggleButtonGroup
                  value={actionType}
                  exclusive
                  onChange={handleChangeActionType}
                  aria-label="action"
                >
                  <ToggleButton value="pdf">
                    <PictureAsPdfIcon />
                  </ToggleButton>
                  <ToggleButton value="print">
                    <LocalPrintshopIcon />
                  </ToggleButton>
                </ToggleButtonGroup> */}
                {/* 
                <PDFDownloadLink
                  document={<Invoice invoice={invoice} />}
                  fileName={device_id + ".pdf"}
                >


                  {({ blob, url, loading, error }) =>
                    loading ? (
                      "Loading"
                    ) : (
                      // <button>Download</button>
                      <>
                      {url}
                      <IconButton color="primary" onClick={handlePdfClick}>
                        <PictureAsPdfIcon />
                      </IconButton>
                      </>
                    )
                  }
                </PDFDownloadLink> */}
                <Modal open={open} onClose={onCloseModal} center>
                  {/* <h4 style={{ textAlign: "center" }}>
                    ENERGY STATEMENT REPORT
                  </h4> */}

                  {statementType == "statement" ? (
                    <h4 style={{ textAlign: "center" }}>
                      Energy Statement Report
                    </h4>
                  ) : (
                    <h4 style={{ textAlign: "center" }}>Bill Statement</h4>
                  )}

                  {/* <h4 style={{ textAlign: "center" }}>{docName}</h4> */}
                  <div style={{ textAlign: "center" }}>
                    <Button variant="contained" onClick={saveFile}>
                      Download File
                    </Button>
                  </div>
                  <PDFViewer
                    width="600"
                    height="600"
                    title={devicename}
                    fileName="myPdf.pdf"
                  >
                    <Invoice invoice={invoice} statementType={statementType} />
                  </PDFViewer>
                </Modal>
              </div>
              {/* <div className="col-md-2" style={LabelStyle}>

              </div> */}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default BillingView;
